/**
 * 推广内容，枚举值
 */
export enum PromotionContentEnum {
  /**
   * 组织安装
   */
  DD = "dd",
  /**
   * 商户邀约
   */
  YY = "yy",
  /**
   * 广告推广
   */
  TJ = "tj",
}
/**
 * 身份，枚举值
 */
export enum IdenEnum {
  /**
   * 个人
   */
  Common = "common",
  /**
   * 管理员
   */
  Admin = "admin",
}
/**
 * 个人合作状态，枚举值
 */
export enum PersonalCooperationStatusEnum {
  /**
   * 合作中
   */
  Cooperation = 1,
  /**
   * 停止合作
   */
  StopCooperation = 0,
}
/**
 * 结算状态，枚举值
 */
export enum SettlementStatusEnum {
  /**
   * 未结算
   * @example 30
   *  */
  Unsettled = 30,
  /**
   * 已结算
   * @example 40
   *  */
  Settled = 40,
}

/**
 * 企业合伙人-申请状态，枚举值
 */
export enum ApplyStatusEnum {
  /**
   * 申请中
   */
  Apply = "apply",
  /**
   * 已审核
   */
  Finish = "finish",
}
