import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./index.module.scss";
import usePageTitle from "@/hooks/usePageTitle";
import Table, { IColumn } from "../../components/Table";
import { useRequest } from "ahooks";
import { DatePicker } from "antd-mobile";
import PageLoading from "@/common/Component/PageLoading";

import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import Tab from "@/common/Component/Tabs";
import { DownFill } from "antd-mobile-icons";

import { mobileCountMonth } from "../../services/mobileCountMonth";
import { PickerDate } from "antd-mobile/es/components/date-picker/util";
import {
  FeeType,
  mobileChildPerfPage,
  IListItem,
} from "../../services/mobileChildPerfPage";
import { mobileCycleBillSummary } from "../../services/mobileCycleBillSummary";
import { formatCurrency } from "@/common/Utils/money";
interface ManageStaffPersonalDetailProps {}
const pageSize = 10;

/**
 * 员工子合伙人-业绩收益
 * */
const ManageStaffPersonal: React.FC<ManageStaffPersonalDetailProps> = (
  props
) => {
  //   const {} = props;
  const [search] = useSearchParams();
  const partnerId = search.get("partnerId");
  const partnerPerson = search.get("partnerPerson");

  usePageTitle({
    title: `${partnerPerson ? `${partnerPerson}的` : ""}业绩收益`,
  });

  const pageNum = useRef(1);
  const totalItem = useRef<Array<IListItem>>([]);
  const [activeKey, setActiveKey] = useState<FeeType>("enterprise");
  const [visible, setVisible] = useState(false);
  const [date, setDate] = useState<Date>(new Date());

  const changeActiveKey = (key: string) => {
    totalItem.current = [];
    setActiveKey(key as FeeType);
    pageRun({
      pageNum: 1,
      pageSize,
      partnerId: partnerId || undefined,
      feeType: key as FeeType,
      signDate: dayjs(date).format("YYYYMM"),
    });
  };

  const {
    runAsync: pageRun,
    data: pageData,
    loading: pageLoading,
  } = useRequest(mobileChildPerfPage, {
    defaultParams: [
      {
        pageNum: pageNum.current,
        pageSize,
        signDate: dayjs(date).format("YYYYMM"),
        feeType: activeKey,
        partnerId: partnerId || undefined,
      },
    ],
    onSuccess: (res, params) => {
      totalItem.current?.push(...(res.data?.list || []));
      pageNum.current = params[0].pageNum || 1;
    },
  });
  const {
    data: monthCount,
    loading: monthCountLoading,
    run: monthCountRun,
  } = useRequest(mobileCountMonth, {
    manual: true,
  });
  const {
    data: cycleData,
    loading: cycleLoading,
    run: cycleRun,
  } = useRequest(mobileCycleBillSummary, {
    manual: true,
    defaultParams: [
      {
        monthId: dayjs(date).format("YYYYMM"),
        partnerId: partnerId || "",
      },
    ],
  });

  const loadMore = async () => {
    await pageRun({
      pageNum: pageNum.current + 1,
      pageSize,
      feeType: activeKey,
      signDate: dayjs(date).format("YYYYMM"),
      partnerId: partnerId || undefined,
    });
  };
  const hasMore = useMemo(() => {
    return (pageData?.data?.total || 0) > (totalItem.current?.length || 0);
  }, [pageData]);

  useEffect(() => {
    monthCountRun({
      monthId: dayjs(date).format("YYYYMM"),
      partnerId: partnerId || "",
    });
  }, []);
  const columns = useMemo(() => {
    const renderSignDate = (date: string) => {
      if (!date) return "";
      return dayjs(`${date}`).format("MM/DD");
    };
    const shop: IColumn[] = [
      {
        title: "商户",
        dataIndex: "promotionEntityName",
        span: 1,
      },
    ];
    const enterprise: IColumn[] = [
      {
        title: "组织",
        dataIndex: "promotionEntityName",
        span: 1,
      },
    ];
    const referral: IColumn[] = [
      {
        title: "广告",
        dataIndex: "promotionEntityName",
        span: 1,
      },
    ];
    const data: Record<FeeType, IColumn[]> = {
      shop: shop,
      enterprise: enterprise,
      referral: referral,
    };
    data[activeKey].unshift({
      title: "时间",
      dataIndex: "signDate",
      span: 1,
      render: renderSignDate,
    });
    // data[activeKey].push({
    //   title: "合同时间",
    //   dataIndex: "contractTerm",
    //   span: 1,
    // });
    return data[activeKey];
  }, [activeKey]);
  const tabs = useMemo(() => {
    const tabData = [
      {
        title: `组织安装(${monthCount?.data?.enterpriseNum || 0})`,
        key: "enterprise",
      },
      {
        title: `商户邀约(${monthCount?.data?.shopNum || 0})`,
        key: "shop",
      },
      // {
      //   title: `广告推广(${monthCount?.data?.referralNum || 0})`,
      //   key: "referral",
      // },
    ];

    return tabData;
  }, [monthCount]);
  const onConfirm = (val: PickerDate) => {
    totalItem.current = [];
    setDate(val);
    const newDate = dayjs(val).format("YYYYMM");
    pageRun({
      pageNum: 1,
      pageSize,
      signDate: newDate,
      feeType: activeKey,
      partnerId: partnerId || undefined,
    });
    monthCountRun({ monthId: newDate, partnerId: partnerId || "" });
    cycleRun({ monthId: newDate, partnerId: partnerId || undefined });
  };
  return (
    <div className={styles.page}>
      <PageLoading loading={pageLoading || monthCountLoading || cycleLoading} />

      <DatePicker
        title="时间选择"
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        max={new Date()}
        value={date}
        onConfirm={onConfirm}
        precision={"month"}
      >
        {(value) => (
          <div onClick={() => setVisible(true)} className={styles.header}>
            <span className={styles.title}>
              {dayjs(value).format("YYYY年MM月")}
            </span>
            <DownFill className={styles.icon} />
          </div>
        )}
      </DatePicker>
      <div className={styles.money}>
        <div className={styles.moneyTitle}>收益(元)</div>
        <div className={styles.moneyNum}>
          {formatCurrency(cycleData?.data?.billCommis || 0)}
        </div>
      </div>
      <Tab
        model="out"
        itemData={tabs}
        activeKey={activeKey}
        onChange={changeActiveKey}
        stretch
        className={styles.tab}
        tabTitleClassName={styles.tabTitle}
        activeTitleClassName={styles.activeTabTitle}
      />
      <div className={styles.content}>
        <Table
          className={styles.table}
          columns={columns}
          dataSource={totalItem.current}
          bodyClassName={styles.tableBody}
          loadMore={loadMore}
          hasMore={hasMore}
        />
      </div>
    </div>
  );
};

export default React.memo(ManageStaffPersonal);
