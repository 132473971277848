import React, { RefObject, useEffect, useMemo, useRef } from "react";
import { Form, Input, Picker, PickerRef, Space, Toast } from "antd-mobile";
import usePageTitle from "@/hooks/usePageTitle";
import Button from "@/common/Component/Button";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import styles from "./index.module.scss";
import ApplyLayout from "@/common/Component/ApplyPage";
import FormCardTitle from "@/common/Component/FormCardTitle";
import { phoneRegExp } from "@/common/Config/regExp";
import VerificationCode, { IRef } from "@/common/Component/VerificationCode";
import { discountApplyCode } from "../../service/discountApplyCode";
import { discountGetSignUrl } from "../../service/discountGetSignUrl";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";
import get from "lodash/get";
import { useRequest } from "ahooks";
import { enterpriseApplyGet } from "@/pages/Enterprise/services/enterpriseApplyGet";
import {
  getEnterpriseLatestSign,
  SignStatusEnum,
} from "../../service/getEnterpriseLatestSign";

interface ISettledInProtocolProps {}

/**  开通企业专项折扣 */
const SettledInProtocol: React.FC<ISettledInProtocolProps> = (props) => {
  const {} = props;
  usePageTitle({ title: "企业专享折扣服务" });
  const [corpId, clientId] = useUserStore(
    useShallow((state) => [state.corpId, state.clientId])
  );
  const protocol = [
    "欢迎加入本地生活服务平台！通过签约企业专享折扣服务，贵司员工将能够享受合作商户提供的专属协议价折扣优惠，涵盖餐饮、购物等多种消费场景，帮助员工在忙碌的工作中便捷享受优质餐饮，提升员工满意度与工作动力。",
    "期待与您的合作，携手打造更美好的员工福利体验！",
  ];
  const [visible, setVisible] = React.useState(false);

  const openUrl = useOpenUrl();
  const [form] = Form.useForm();
  const phone = Form.useWatch("phone", form);
  const codeRef = useRef<IRef>({});
  const {
    data: applyData,
    // loading: getLoading,
    run: enterpriseApplyGetRun,
  } = useRequest(enterpriseApplyGet, {
    manual: true,
  });
  const {
    data: getEnterpriseLatestSignData,
    // loading: getEnterpriseLatestSignLoading,
    run: getEnterpriseLatestSignRun,
  } = useRequest(getEnterpriseLatestSign, {
    manual: true,
  });
  const onOk = (code: string, res: any) => {
    Toast.show({
      icon: "success",
      content: "提交成功",
    });
    openUrl({
      url: res,
      isRoute: false,
      isOpenHalf: false,
    });
  };
  const onFinish = (values: any) => {
    setVisible(true);
    codeRef.current.focus?.();
  };
  const selectNameData = useMemo(() => {
    const arr = [];
    if (applyData?.data?.legalPersonName) {
      arr.push({
        label: `法人-${applyData?.data?.legalPersonName}`,
        value: applyData?.data?.legalPersonPhone,
        phone: applyData?.data?.legalPersonPhone,
        name: applyData?.data?.legalPersonName,
      });
    }
    if (applyData?.data?.contactName) {
      arr.push({
        label: `联系人-${applyData?.data?.contactName}`,
        value: applyData?.data?.contactPhone,
        phone: applyData?.data?.contactPhone,
        name: applyData?.data?.contactName,
      });
    }
    arr.push({
      label: "其他",
      value: "3",
      name: "其他",
      phone: "",
    });
    if (arr.length) {
      return [arr];
    }
    return [];
  }, [applyData]);

  useEffect(() => {
    corpId &&
      enterpriseApplyGetRun({ applyOrgId: corpId, hasDesensitize: true });
  }, [corpId]);
  useEffect(() => {
    if (applyData?.data.enterpriseId) {
      getEnterpriseLatestSignRun({
        enterpriseId: applyData?.data.enterpriseId,
      });
    }
  }, [applyData?.data?.enterpriseId]);
  const name = Form.useWatch("name", form);
  const nameValue = get(name, "0");
  useEffect(() => {
    const name = getEnterpriseLatestSignData?.data?.signPerson;
    const phone = getEnterpriseLatestSignData?.data?.signPhone;
    const person = selectNameData[0].find((item) => item.phone === phone);
    if (name === "其他") {
      form.setFieldValue("name", ["3"]);
      return;
    }
    if (person && person.name === name) {
      form.setFieldValue("name", [person.phone]);
      form.setFieldValue("phone", person.phone);
    }
  }, [selectNameData, getEnterpriseLatestSignData, form]);
  return (
    <ApplyLayout>
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.title}>企业专享折扣服务</div>
          <Space block direction="vertical" className={styles.protocol}>
            <div className={styles.protocolTop}>尊敬的企业客户：</div>
            {protocol.map((item, index) => {
              return (
                <div className={styles.protocolItem} key={index}>
                  {item}
                </div>
              );
            })}
          </Space>
        </div>
        <div className={styles.bottom}>
          <Form
            disabled={
              getEnterpriseLatestSignData?.data?.status ===
              SignStatusEnum.SignSuccess
            }
            onFinish={onFinish}
            mode="card"
            form={form}
            layout="horizontal"
          >
            <FormCardTitle
              title="合同签约人"
              subTitle="即将跳转“E签宝平台”签约，请选定签约人"
            />
            <Form.Item
              name="name"
              label="签约人"
              rules={[{ required: true, message: "必填" }]}
              trigger="onConfirm"
              onClick={(e, ref: RefObject<PickerRef>) => {
                ref.current?.open();
              }}
            >
              <Picker
                onConfirm={(value, extend) => {
                  form.setFieldValue("phone", get(extend, "items.[0].phone"));
                }}
                columns={selectNameData}
              >
                {(value, actions) => {
                  return (
                    value.map((item) => item?.label)?.join("/") || "请选择"
                  );
                }}
              </Picker>
            </Form.Item>
            <Form.Item
              name="phone"
              label="手机号"
              rules={[
                { required: true, message: "必填" },
                {
                  pattern: phoneRegExp,
                  message: "请正确填写手机号",
                },
              ]}
              disabled={nameValue !== "3"}
            >
              <Input
                className={styles.input}
                placeholder="请输入手机号"
                type="text"
              />
            </Form.Item>
            {!!getEnterpriseLatestSignData?.data && (
              <Form.Item label="签约时间" disabled={true}>
                <Input
                  className={styles.input}
                  type="text"
                  value={getEnterpriseLatestSignData?.data.createAt}
                />
              </Form.Item>
            )}
          </Form>
          <VerificationCode
            onClose={() => setVisible(false)}
            onOk={onOk}
            ref={codeRef}
            phone={phone}
            visible={visible}
            getVerifyCode={(data) => {
              return discountApplyCode(data);
            }}
            verifyCode={(data) => {
              const values = form.getFieldsValue();
              const nameValue = get(values, "name.0");
              const name =
                get(selectNameData, "0", []).find(
                  (item) => item.value === nameValue
                )?.name || "";
              return discountGetSignUrl({
                phone: data.phone,
                code: data.code,
                data: {
                  applyOrgId: corpId || "",
                  clientId: clientId || "",
                  name,
                  phone: values.phone,
                },
              });
            }}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          block
          className={styles.prevButton}
          onClick={() => {
            openUrl({
              isRoute: true,
              url: "/arriveStore/settled/in",
              isOpenHalf: false,
              options: {
                replace: true,
              },
              params: {
                link: "false",
              },
            });
          }}
        >
          上一步
        </Button>
        <Button
          block
          color="primary"
          className={styles.button}
          onClick={() => {
            // openUrl({
            //   isRoute: true,
            //   url: "/arriveStore/settled/in/res",
            // });
            form.submit();
          }}
        >
          {getEnterpriseLatestSignData?.data?.status ===
            SignStatusEnum.SignSuccess && "查看签约"}
          {getEnterpriseLatestSignData?.data === null && "在线签约"}
          {getEnterpriseLatestSignData?.data?.status ===
            SignStatusEnum.WatingESignFill ||
            (getEnterpriseLatestSignData?.data?.status ===
              SignStatusEnum.WatingESignSeal &&
              "继续签约")}
        </Button>
      </div>
    </ApplyLayout>
  );
};

export default SettledInProtocol;
