import React, { useMemo, useRef } from "react";
import styles from "./index.module.scss";
import ApplyLayout from "@/common/Component/ApplyPage";
import usePageTitle from "@/hooks/usePageTitle";
import Table, { IColumn } from "../../components/Table";
import { useRequest } from "ahooks";
import { formatCurrency } from "@/common/Utils/money";
import PageLoading from "@/common/Component/PageLoading";
import { mobileCycleBillSummary } from "../../services/mobileCycleBillSummary";
import {
  mobileChildBillPage,
  IListItem,
} from "../../services/mobileChildBillPage";
import { useSearchParams } from "react-router-dom";
import Decimal from "decimal.js";
import dayjs from "dayjs";
interface EnterpriseIncomeDetailProps {}
const pageSize = 10;
/**
 * 企业收益*/
const EnterpriseIncomeDetail: React.FC<EnterpriseIncomeDetailProps> = (
  props
) => {
  //   const {} = props;
  usePageTitle({ title: "收益" });
  const [search] = useSearchParams();
  const monthId = search.get("monthId");
  const pageNum = useRef(1);
  const totalItem = useRef<IListItem[]>([]);
  const columns: IColumn[] = [
    {
      title: "子合伙人",
      dataIndex: "partnerName",
    },
    {
      title: "收益(元)",
      dataIndex: "billCommis",
      align: "right",

      render: (value) => (
        <div className={styles.incomeNum}>{formatCurrency(value || "0")}</div>
      ),
    },
  ];
  const {
    runAsync: mobileBillPageRun,
    data: mobileBillPageData,
    loading: mobileBillPageLoading,
  } = useRequest(mobileChildBillPage, {
    defaultParams: [
      {
        pageNum: pageNum.current,
        pageSize,
        monthId: monthId || "",
      },
    ],
    onSuccess: (res, params) => {
      totalItem.current?.push(...(res.data?.list || []));
      pageNum.current = params[0].pageNum || 1;
    },
  });
  const { data: cycleData, loading: cycleLoading } = useRequest(
    mobileCycleBillSummary,
    {
      defaultParams: [
        {
          monthId: monthId || "",
        },
      ],
    }
  );
  const loadMore = async () => {
    await mobileBillPageRun({
      pageNum: pageNum.current + 1,
      pageSize,
      monthId: monthId || "",
    });
  };
  const hasMore = useMemo(() => {
    return (
      (mobileBillPageData?.data?.total || 0) > (totalItem.current?.length || 0)
    );
  }, [mobileBillPageData]);
  return (
    <ApplyLayout className={styles.page}>
      <PageLoading loading={mobileBillPageLoading || cycleLoading} />
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.left}>
            <div className={styles.text}>
              {dayjs(monthId || "", "YYYYMM").format("YYYY年MM月")}收益(元)
            </div>
            <div className={styles.title}>
              {formatCurrency(
                new Decimal(cycleData?.data?.billCommis || 0).toString()
              )}
            </div>
          </div>
        </div>
        <Table
          className={styles.table}
          bodyClassName={styles.tableBody}
          columns={columns}
          dataSource={totalItem.current}
          loadMore={loadMore}
          hasMore={hasMore}
        />
      </div>
    </ApplyLayout>
  );
};

export default React.memo(EnterpriseIncomeDetail);
