import usePartnerStore from "@/store/partnerStore";
import { ICheckTypeRes } from "@/pages/Partner/services/checkType";

export interface IPartnerLoaderRes {
  checkType?: ICheckTypeRes;
}
/**
 * 合伙人身份校验
 */
export async function partnerLoader() {
  const { getCheckType } = usePartnerStore.getState();
  const data: IPartnerLoaderRes = {};
  const checkData = await getCheckType();
  if (checkData) {
    data.checkType = checkData;
  }
  return data;
}
