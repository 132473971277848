import { Toast } from "antd-mobile";
import * as dd from "dingtalk-jsapi";

export interface IDingLocateInMapRes {
  success?: boolean;
  /** 详细地址 */
  snippet?: string;
  /** 区名称 */
  adName?: string;
  /** 距离 */
  distance?: string;
  /** 省名称 */
  province?: string;
  /** 市名称 */
  city?: string;
  /** 市代码 */
  cityCode?: string;
  /** 省代码 */
  provinceCode?: string;
  /** 区代码 */
  adCode?: string;
  /** 纬度 */
  latitude?: number | string;
  /** 邮编 */
  postCode?: string;
  /** POI名称 */
  title?: string;
  /** 经度 */
  longitude?: number | string;
  /**
   *
   */
  poiSource?: number;
}

export interface IDingLocateInMap {
  /**
   * 纬度
   */
  latitude?: number;
  /**
   * 经度
   */
  longitude?: number;
  /**
   * 搜索范围
   */
  scope: number;
}
/**
 * 钉钉地图定位
 */
export const locateInMap = (
  data: IDingLocateInMap
): Promise<IDingLocateInMapRes> => {
  return new Promise((resolve, reject) => {
    if (dd.env.platform !== "notInDingTalk") {
      dd.ready(() => {
        dd.locateInMap({
          ...data,
          success: (res: IDingLocateInMapRes) => {
            res.success = true;
            resolve(res);
          },
          fail: (error: any) => {
            resolve({ success: false });
          },
          complete: () => {},
        });
      });
    } else {
      Toast.show("请在钉钉内打开");
      resolve({ success: false });
    }
  });
};
