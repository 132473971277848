import { SafeArea, TabBar } from "antd-mobile";

import React, { useEffect, useMemo, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";

import Icon from "@/common/Component/Icon";
import styles from "./index.module.scss";
import ToTop from "@/common/Component/ToTop";

interface ITabBarLayoutsProps {}
const tabsArr = [
  {
    key: "/tabBar",
    icon: "icon-shouye",
    title: "首页",
  },
  {
    key: "/tabBar/arriveStore",
    title: "到店",
    icon: "icon-daodian",
  },
  {
    key: "/tabBar/mall",
    title: "商城",
    icon: "icon-shangcheng",
  },
  {
    key: "/tabBar/my",
    title: "我的",
    icon: "icon-wode",
  },
];
/** tabBar 布局  */
const TabBarLayouts: React.FC<ITabBarLayoutsProps> = (props) => {
  const location = useLocation();
  const { pathname } = location;

  const contentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const getIcon = (active: boolean, icon: string) => {
    return (
      <Icon
        icon={icon}
        className={classNames(styles.icon, { [styles.activeIcon]: active })}
      />
    );
  };
  const getTitle = (active: boolean, title: string) => {
    return (
      <span
        className={classNames(styles.title, {
          [styles.activeTitle]: active,
        })}
      >
        {title}
      </span>
    );
  };
  const tab = useMemo(() => {
    return tabsArr.map((item) => {
      return {
        ...item,
        title: (active: boolean) => getTitle(active, item.title),
        icon: (active: boolean) => getIcon(active, item.icon),
      };
    });
  }, []);
  const setRouteActive = (value: string) => {
    navigate(value);
  };

  useEffect(() => {
    // 监听路由变化
    // console.log("监听路由变化", pathname);
    contentRef.current?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={styles.layout}>
      <div className={styles.content} ref={contentRef}>
        <Outlet />
        <ToTop className={styles.toTop} />
      </div>
      <TabBar
        className={styles.tabBar}
        activeKey={pathname}
        onChange={(value) => setRouteActive(value)}
        safeArea
      >
        {tab.map((item) => (
          <TabBar.Item
            className={styles.item}
            key={item.key}
            icon={item.icon}
            title={item.title}
          />
        ))}
      </TabBar>
      <div
        style={{ backgroundColor: "#fff", zIndex: 999, position: "relative" }}
      >
        <SafeArea position="bottom" />
      </div>
    </div>
  );
};

export default TabBarLayouts;
