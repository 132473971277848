import ApplyLayout from "@/common/Component/ApplyPage";
import Buttons from "@/common/Component/Button";
import FormCardTitle from "@/common/Component/FormCardTitle";
import usePageTitle from "@/hooks/usePageTitle";
import { Checkbox, Form } from "antd-mobile";
import React, { useRef } from "react";
import styles from "./index.module.scss";
import Input from "../../components/Input";
import FormItem from "../../components/FormItem";
import { CheckOutline } from "antd-mobile-icons";
import { CheckboxGroup } from "../../components/Checkbox";
import VerificationCode, { IRef } from "@/common/Component/VerificationCode";
import { phoneRegExp } from "@/common/Config/regExp";
const requiredRuleObj = { required: true, message: "必填" };

interface ApplyProps {}
/**
 * 合伙人申请
 */
const Apply: React.FC<ApplyProps> = (props) => {
  const {} = props;
  usePageTitle({ title: "合伙人报名表" });
  const [form] = Form.useForm();
  const ref = useRef<IRef>({});

  const onFinish = (values: any) => {
    console.log("Success:", values);
    ref.current?.show?.({
      phone: values.contactPhone,
      onOk: () => {
        console.log("onOk");
      },
    });
  };
  const validator = (rule: any, v: string) => {
    return new Promise((resolve, reject) => {
      let params: any;
      if (!v) {
        resolve(true);
        return;
      }
      //   checkApi(params)
      //     .then((res) => {
      //       if (!res.data) {
      //         reject(new Error(res.msg || "校验失败"));
      //       } else {
      //         resolve(true);
      //       }
      //     })
      //     .catch((e) => {
      //       reject(new Error(e.msg || "校验失败"));
      //     });
    });
  };
  return (
    <ApplyLayout className={styles.page}>
      <div style={{ width: "100%" }}>
        <Form
          className={styles.form}
          form={form}
          layout="horizontal"
          onFinish={onFinish}
          mode="card"
        >
          <Form.Header />
          <FormCardTitle title="基本信息" />
          <FormItem
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              requiredRuleObj,
              {
                validateTrigger: ["onBlur"],
                validator: (rule, v) => validator(rule, v),
              },
            ]}
            name="name"
            label="企业名称"
            required
          >
            <Input placeholder="请输入企业名称" />
          </FormItem>
          <FormItem
            name="code"
            label="企业信用码"
            required
            rules={[requiredRuleObj]}
          >
            <Input placeholder="请输入企业信用码" />
          </FormItem>
          <FormItem
            name="content"
            label="推广内容"
            rules={[requiredRuleObj]}
            required
          >
            <CheckboxGroup
              dataSource={[
                {
                  label: "组织安装",
                  value: 1,
                },
                {
                  label: "商户邀约",
                  value: 2,
                },
                {
                  label: "广告推广",
                  value: 3,
                },
              ]}
            />
          </FormItem>
          <FormItem
            name="code"
            label="联系人"
            required
            rules={[requiredRuleObj]}
          >
            <Input placeholder="请输入联系人" maxLength={10} />
          </FormItem>
          <FormItem
            name="code"
            label="联系人电话"
            required
            rules={[
              requiredRuleObj,
              {
                pattern: phoneRegExp,
                message: "请输入正确的手机号",
              },
            ]}
          >
            <Input placeholder="请输入联系电话" />
          </FormItem>
          <Form.Header />
          <FormCardTitle title="打款银行信息" />
          <FormItem
            name="bankName"
            label="开户银行"
            required
            rules={[requiredRuleObj]}
          >
            <Input placeholder="请输入银行名称" />
          </FormItem>
          <FormItem
            name="username"
            label="开户名"
            required
            rules={[requiredRuleObj]}
          >
            <Input placeholder="请输入姓名" />
          </FormItem>
          <FormItem
            name="bankNum"
            label="银行账户"
            required
            rules={[requiredRuleObj]}
          >
            <Input placeholder="请输入银行卡号" />
          </FormItem>
        </Form>
        <VerificationCode
          ref={ref}
          phone={form.getFieldValue("contactPhone")}
          //   getVerifyCode={(data) => {
          //     const values = form.getFieldsValue();
          //     return enterpriseVerify({
          //       ...restValues,
          //       applyOrgId: cropId,
          //       creditCert: creditCertStr,
          //     });
          //   }}
          //   verifyCode={(data) => {
          //     const values = form.getFieldsValue();
          //     return enterpriseApply({
          //       ...restValues,
          //       applyOrgId: cropId,
          //       creditCert: creditCertStr,
          //     });
          //   }}
        />
      </div>
      <div className={styles.footer}>
        <Buttons
          block
          color="primary"
          onClick={() => {
            form.submit();
          }}
          customColor="black"
        >
          提交申请
        </Buttons>
      </div>
    </ApplyLayout>
  );
};

export default Apply;
