import React from "react";
import { Image } from "antd-mobile";
import { getEnvImageUrl } from "@/common/Utils/env";
import { pxToRem } from "@/common/Utils/rem";
import styles from "./index.module.scss";
import usePageTitle from "@/hooks/usePageTitle";

function AuthSuccess({ userAuth }: { userAuth: any }) {
  usePageTitle({ title: "实名认证成功" });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.imageWrapper}>
          <Image 
            src={getEnvImageUrl("5b1d854548d9b6f722587864fcb445c8d3f6649c.png")} 
            width={pxToRem(244)}
            height={pxToRem(180)}
          />
        </div>
        <div className={styles.title}>实名认证成功</div>
      </div>
      <div className={styles.content}>
        <ul>
          <li className={styles.list}>
            <span className={styles.label}>证件姓名</span>
            <span className={styles.value}>{userAuth.userName}</span>
          </li>
          <li className={styles.list}>
            <span className={styles.label}>证件号</span>
            <span className={styles.value}>{userAuth.identityNum}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AuthSuccess;
