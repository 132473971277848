import apiClient from "src/common/Utils/APIClient";
// import { API_HOST } from "src/common/Config/Constant";

const getCardOrders = (params: any) => {
  const { cardId = "" } = params || {};
  return apiClient
    .get("/dmall_payment/buyerPay/BuyerBankCard/detail", {
      param: {
        cardId,
      },
    })
    .then((res: any) => {
      const { data = {} } = res || {};
      return data;
    });
};

const deleteBankCard = (params: any) => {
  const { cardId = "" } = params || {};
  return apiClient
    .post("/dmall_payment/buyerPay/BuyerBankCard/delete", { cardId })
    .then((res: any) => {
      const { data = {} } = res || {};

      console.log(555, res);
      return data;
    });
};

interface IRes {
  code: string;
  msg: string;
  data: Accounts[];
  success: boolean;
}

export interface Accounts {
  accountCd: string;
  accountId: string;
  accountName: string;
  accountType: string;
  balance: number;
  bankCardNo: string;
  // bankDTO: BankDTO;
  pwdPay: boolean;
  bankId: string;
  buyerId: string;
  createAt: null;
  createBy: number;
  dayAmount: number;
  enterpriseAccountId: string;
  // enterpriseApplyDTO: EnterpriseApplyDTO;
  extInfo: string;
  frozenAmount: number;
  inAmount: number;
  monthAmount: number;
  opCert: string;
  opName: string;
  opPhone: string;
  outAmount: number;
  status: number;
  tanentId: string;
  transitAmount: number;
  upAccountId: string;
  updateAt: null;
  updateBy: number;
  weekAmount: number;
  enterpriseName: string;
  applyOrgId?: string;
}

const getBankCard = (): Promise<IRes> => {
  return apiClient.get("/dmall_account/Account/accounts", {
    headers: {
      "Desensitize": "close",
    },
  });
};

export { getCardOrders, deleteBankCard, getBankCard };
