import React, { useEffect, useMemo } from "react";
import { useLoaderData, useOutletContext } from "react-router-dom";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import styles from "./index.module.scss";
import { IPartnerLoaderRes } from "@/router/loader/partnerLoader";
import { ApplyStatusEnum, IdenEnum } from "@/pages/Partner/enum";

interface AuthRoleProps {
  children: React.ReactNode;
}
const rolesMap = {
  [IdenEnum.Admin]: "/partner/enterprise",
  [IdenEnum.Common]: "/partner/personal/code",
};
const AuthRole: React.FC<AuthRoleProps> = (props) => {
  const { children } = props;
  const { data } = useOutletContext() as { data: IPartnerLoaderRes };
  const { roles } = useLoaderData() as { roles: IdenEnum[] };
  const openUrl = useOpenUrl();
  const objRole = useMemo(() => {
    const obj = { hasRole: false, redrictURL: "/" };
    if (data?.checkType?.iden) {
      obj.hasRole = roles.includes(data?.checkType?.iden);
    }
    if (data?.checkType?.applyStatus !== ApplyStatusEnum.Finish) {
      obj.redrictURL = "/partner/apply";
    }
    if (!obj.hasRole && data?.checkType?.iden) {
      obj.redrictURL = rolesMap[data?.checkType?.iden];
    }
    return obj;
  }, [data, roles]);
  useEffect(() => {
    if (!objRole.hasRole) {
      openUrl({ url: objRole.redrictURL, isOpenHalf: false, isRoute: true });
    }
  }, [objRole]);
  if (!objRole.hasRole) {
    return (
      <div className={styles.auth}>
        <div className={styles.title}>暂无权限</div>
        {/* <Buttons onClick={() => {}}>返回主页</Buttons> */}
      </div>
    );
  }
  return <>{children}</>;
};

export default React.memo(AuthRole);
