import axios, { IBaseRes } from "@/common/Utils/APIClient";

interface IReq {
  /**
   * 合伙人姓名
   */
  partnerPerson: string;
  partnerId: string;
  /***
   * 合伙人-个人-手机号
   */
  partnerPersonMobile: string;
}
interface IRes {}
/**
 * 修改子合伙人
 */
export const modifyChild = (params: IReq): Promise<IBaseRes<boolean>> => {
  const form = new FormData();
  form.append("partnerPerson", params.partnerPerson);
  form.append("partnerId", params.partnerId);
  form.append("partnerPersonMobile", params.partnerPersonMobile);
  return axios.post("/dmall-customer/partner/app/modify/child", form);
};
