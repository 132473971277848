import qs from "qs";
export type AppType = "h5" | "app";
export type ContainerType = "dd" | "web";
/** 容器
 * @description:
 **/
export class Container {
  static originH5: string[] = ["https://n.dingtalk.com"];
  /**
   * 容器类型
   */
  static containerType: ContainerType;
  /**
   * 容器应用类型
   */
  static appType: AppType | null = null;
  /**
   * 设置容器应用类型
   */
  static setAppType() {
    const referrer = document.referrer;
    if (referrer) {
      const url = new URL(referrer);
      const hasH5 = this.originH5.some((item) => item === url?.origin);
      if (hasH5) {
        localStorage.setItem("appType", "h5");
        this.appType = "h5";
        return;
      }
    }
    const search = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const appType = search?.app_type as AppType | null;
    if (appType) {
      localStorage.setItem("appType", appType);
      this.appType = appType;
      return;
    }
    const pathname = window.location.pathname;
    if (pathname.startsWith("/tabBar")) {
      localStorage.setItem("appType", "app");
      this.appType = "app";
      return;
    }
  }
  /**
   * 判断是否为H5
   */
  static isH5 = () => {
    const appType = localStorage.getItem("appType");
    if (appType === "h5") {
      return true;
    }
    return false;
  };
  /**
   * 判断是否为APP
   */
  static isApp = () => {
    const appType = localStorage.getItem("appType");
    if (appType === "app") {
      return true;
    }
    return false;
  };
  /**
   * 获取容器应用类型
   */
  static getAppType = () => {
    const appType = localStorage.getItem("appType") as AppType | null;
    return appType;
  };
}
