import React, { useEffect, useMemo, useState } from "react";
import styles from "./index.module.scss";
import { useRequest } from "ahooks";
// import { getLocation } from "dingtalk-jsapi/api/apiObj";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import dd from "dingtalk-jsapi";
import { useSearchParams } from "react-router-dom";
import { getShopRecommend } from "@/pages/ArriveStore/service/getShopRecommend";
import { getShopDetail } from "@/pages/ArriveStore/service/getShopDetail";
import { getDiscount } from "@/pages/ArriveStore/static";
import PageLoading from "@/common/Component/PageLoading";
import { Button, Empty, Image, List, Popup, Swiper } from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";
import Icon from "@/common/Component/Icon";
import { enterpriseCheckOpen } from "@/pages/ArriveStore/service/enterpriseCheckOpen";
import {
  formatDateShowStr,
  formatDateTime,
  isCurrentTimeInDateRange,
} from "@/common/Component/PopupDate/static";
import classNames from "classnames";
import usePageTitle from "@/hooks/usePageTitle";
import {
  calculateDistance,
  convertMetersToKm,
} from "@/common/Utils/distanceUtils";

interface ShopDetailV2Props {}

const ShopDetailV2: React.FC<ShopDetailV2Props> = (props) => {
  const {} = props;
  const env = {
    isAndroid: /android/i.test(navigator.userAgent),
    isIOS: /iphone|ipad|ipod/i.test(navigator.userAgent),
  };

  //   const { data: addressData } = useRequest(getLocation);
  usePageTitle({ title: "店铺详情" });

  const [visible, setVisible] = useState(false);
  const { data: enterpriseCheckOpenData } = useRequest(enterpriseCheckOpen);
  const openUrl = useOpenUrl();
  const openGaoDeMap = () => {
    let url = "";
    if (env.isAndroid) {
      url = `androidamap://viewMap?sourceApplication=appname&poiname=${address}&lat=${lat}&lon=${lng}&dev=0`;
    } else if (env.isIOS) {
      url = `iosamap://viewMap?sourceApplication=appname&poiname=${address}&lat=${lat}&lon=${lng}&dev=0`;
    }
    dd.openLink({
      url,
    });

    setTimeout(function () {
      if (!document.hidden) {
        //调用高德地图
        window.location.href =
          "https://uri.amap.com/marker?position=" +
          lng +
          "," +
          lat +
          "&name=" +
          address;
      }
    }, 2000);
  };

  const openBaiDuMap = () => {
    let url = "";
    if (env.isAndroid) {
      // url = `baidumap://map/marker?location=${lat},${lng}&title=${address}&content=${address}&src=yourCompanyName|yourAppName`
      url = `baidumap://map/direction?destination=name:${address}|latlng:${lat},${lng}&coord_type=gcj02&mode=driving&src=andr.jianghu.jianhao`;
    } else if (env.isIOS) {
      url = `baidumap://map/direction?destination=name:${address}|latlng:${lat},${lng}&coord_type=gcj02&mode=driving&src=andr.jianghu.jianhao`;
      // url = `baidumap://map/marker?location=${lat},${lng}&title=${address}&content=${address}&src=yourCompanyName|yourAppName`
    }
    const d = new Date();
    const t0 = d.getTime();

    dd.openLink({
      url,
    });

    let delay = setInterval(function () {
      const d = new Date();
      const t1 = d.getTime();
      if (t1 - t0 < 3000 && t1 - t0 > 2000) {
        window.location.href =
          "http://api.map.baidu.com/marker?location=" +
          lat +
          "," +
          lng +
          "&title=" +
          address +
          "&content=景点&output=html&src=webapp.baidu.openAPIdemo";
      }
      if (t1 - t0 >= 3000) {
        clearInterval(delay);
      }
    }, 1000);
  };
  const [searchParams] = useSearchParams();
  const shopId = searchParams.get("id") as string | undefined;
  const userLng = searchParams.get("lng") as string | undefined;
  const userLat = searchParams.get("lat") as string | undefined;
  const {
    run: runRecommend,
    // loading: recommendLoading,
    data: shopRecommendData,
  } = useRequest(getShopRecommend, {
    manual: true,
  });
  const {
    run: runDetail,
    loading,
    data: detailData,
  } = useRequest(getShopDetail, {
    manual: true,
  });
  useEffect(() => {
    shopId && runDetail({ shopId });
  }, [shopId]);

  const { addressStr, images, discount, address, lat, lng } = useMemo(() => {
    const images = detailData?.data?.scenePic || [];
    if (detailData?.data?.doorFrontPic) {
      images.unshift(detailData?.data?.doorFrontPic);
    }
    return {
      addressStr: detailData?.data?.shopAddress,
      images: detailData?.data?.scenePic || [],
      discount: getDiscount(detailData?.data?.discountRate || 0),
      address: detailData?.data?.shopAddress || "",
      lat: detailData?.data?.shopLatitude || 0,
      lng: detailData?.data?.shopLongitude || 0,
    };
  }, [detailData]);
  useEffect(() => {
    if (detailData?.data?.shopLongitude && detailData?.data?.shopLatitude) {
      runRecommend({
        latitude: detailData?.data?.shopLatitude.toString(),
        longitude: detailData?.data?.shopLongitude.toString(),
        shopId: shopId,
        radiusInKm: "3.0",
      });
    }
  }, [detailData]);
  const { showData, emptyShow } = useMemo(() => {
    // const data: Array<ShopRecord[]> = [[], []];
    // shopRecommendData?.data?.forEach((item, index) => {
    //   data[index % 2].push(item);
    // });
    return {
      showData: shopRecommendData?.data,
      emptyShow: !shopRecommendData?.data?.length,
    };
  }, [shopRecommendData]);
  const payOrder = () => {
    if (enterpriseCheckOpenData?.data) {
      openUrl({
        url: "/payOrder",
        isRoute: true,
        params: {
          id: detailData?.data?.shopId,
        },
      });
    } else {
      openUrl({
        url: "/arriveStore/open",
        isRoute: true,
        params: {
          shopId: detailData?.data?.shopId,
        },
      });
    }
  };

  const { hasBusiness, businessText } = useMemo(() => {
    // 是否在营业中
    const hasBusiness = isCurrentTimeInDateRange(
      formatDateTime(detailData?.data?.shopBusinessHoursDTO)
    );
    return { hasBusiness, businessText: hasBusiness ? "营业中" : "休息中" };
  }, [detailData]);
  const spacingObj = useMemo(() => {
    const distance = calculateDistance(
      userLat !== undefined ? Number(userLat) : undefined,
      userLng !== undefined ? Number(userLng) : undefined,
      detailData?.data?.shopLatitude,
      detailData?.data?.shopLongitude
    );
    return convertMetersToKm(distance);
  }, [detailData, userLat, userLng]);
  return (
    <div className={styles.container}>
      <PageLoading loading={loading} />

      <Swiper
        className={styles.swiper}
        indicator={(total, current) => (
          <div className={styles.customIndicator}>
            {`${current + 1}/${total}`}
          </div>
        )}
      >
        {images.map((image, index) => (
          <Swiper.Item key={index}>
            <Image className={styles.image} src={image} fit="cover" />
          </Swiper.Item>
        ))}
      </Swiper>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <img
              className={styles.discount}
              alt=""
              src="https://wanmi-ares.oss-cn-hangzhou.aliyuncs.com/upload/%E6%8A%98%E6%89%A3-6133d67f-4a84-4dce-be13-5a2f1211c660.png"
            />
            <div className={styles.title}>企业员工专享价</div>
            <div className={styles.discountValue}>
              <span className={styles.discountValueNumber}>{discount}</span>
              <span className={styles.discountValueUnit}>折</span>
            </div>
          </div>
          <img
            className={styles.tipImg}
            alt=""
            src="https://wanmi-ares.oss-cn-hangzhou.aliyuncs.com/upload/%E6%A0%87%E7%AD%BE-cbbbeef6-8581-47cd-a43a-0e2484f677b5.png"
          />
        </div>
        <div className={styles.detail}>
          <div className={styles.titleInfo}>
            <Image
              className={styles.restaurantLogo}
              src={detailData?.data?.shopLogo}
            />
            <div className={styles.restaurantInfo}>
              <div className={styles.restaurantName}>
                {detailData?.data?.shopName}
              </div>
              <div className={styles.time}>
                <span
                  className={classNames(styles.timeTip, {
                    [styles.disabledTip]: !hasBusiness,
                  })}
                >
                  {businessText}
                </span>
                <span className={styles.timeText}>
                  {formatDateShowStr(
                    formatDateTime(detailData?.data?.shopBusinessHoursDTO)
                  )}
                </span>
              </div>
            </div>
          </div>
          <div>
            {detailData?.data?.corpAgent === 1 && (
              <span className={styles.aliPayTip}>支持企业码支付</span>
            )}
          </div>
          <div className={styles.addressModel}>
            <div className={styles.addressInfo}>
              <div className={styles.address}>
                <div className={styles.addressText}>{addressStr}</div>
                {/* <RightOutline className={styles.addressRightIcon} /> */}
              </div>
              <div className={styles.addressDetail}>
                <Icon icon="icon-dingwei1" className={styles.addressIcon} />
                <span>{spacingObj?.num?.toFixed(1)}</span>
                {spacingObj && <span>{spacingObj?.hasKm ? "km" : "m"}</span>}
              </div>
            </div>
            <div className={styles.addressBtn}>
              <div
                className={styles.action}
                onClick={() => {
                  setVisible(true);
                }}
              >
                <Icon icon="icon-a-bianzu11" className={styles.actionIcon} />
                <div className={styles.actionText}>导航</div>
              </div>
              <a
                href={`tel:${detailData?.data?.shopMobile}`}
                className={styles.action}
              >
                <Icon icon="icon-a-bianzu10" className={styles.actionIcon} />
                <div className={styles.actionText}>电话</div>
              </a>
            </div>
          </div>
          <div className={styles.recommend}>
            <div className={styles.recommendTitle}>推荐</div>
            <div className={styles.recommendShopList}>
              {showData?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={styles.recommendShop}
                    onClick={() => {
                      openUrl({
                        url: "/arriveStore/detail",
                        isRoute: true,
                        params: {
                          id: item?.shopId,
                          lat: userLat,
                          lng: userLng,
                        },
                      });
                    }}
                  >
                    <div className={styles.recommendShopImg}>
                      <div style={{ position: "relative" }}>
                        <Image
                          className={styles.recommendShopImage}
                          src={item?.doorFrontPic}
                        />
                        <div className={styles.recommendShopImgTip}>
                          {getDiscount(item.discountRate || 0)}折
                        </div>
                      </div>
                    </div>
                    <div className={styles.recommendShopInfo}>
                      <div className={styles.recommendShopInfoContent}>
                        <div className={styles.recommendShopName}>
                          {item?.shopName}
                        </div>
                        <div className={styles.recommendShopTime}>
                          {formatDateShowStr(
                            formatDateTime(item?.shopBusinessHoursDTO)
                          )}
                        </div>
                        <div className={styles.recommendShopAddress}>
                          {item?.shopAddress}
                        </div>
                      </div>
                      <a
                        href={`tel:${item?.shopMobile}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          // e.preventDefault();
                        }}
                      >
                        <Icon
                          icon="icon-a-bianzu10"
                          className={styles.recommendShopPhone}
                        />
                      </a>
                    </div>
                  </div>
                );
              })}
              {emptyShow && <Empty description="暂无数据" />}
            </div>
          </div>
          <Popup
            visible={visible}
            onMaskClick={() => {
              setVisible(false);
            }}
          >
            <List className={styles.popup}>
              <List.Item onClick={openGaoDeMap} clickable={false}>
                高德地图
              </List.Item>
              <List.Item onClick={openBaiDuMap} clickable={false}>
                百度地图
              </List.Item>
              <List.Item
                onClick={() => {
                  setVisible(false);
                }}
                clickable={false}
              >
                关闭
              </List.Item>
            </List>
          </Popup>
          <Button
            className={styles.button}
            onClick={payOrder}
            fill="solid"
            color="primary"
          >
            立即买单
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ShopDetailV2);
