import { Form, Input, Popup } from "antd-mobile";
import React, { useCallback, useEffect, useRef } from "react";
import styles from "./index.module.scss";

interface AutoSelectProps {
  visible: boolean;
  onClose: () => void;
  AMap: any;
  onSelect: (address: string) => void;
}

const AutoSelect: React.FC<AutoSelectProps> = (props) => {
  const { visible, onClose, AMap, onSelect } = props;
  const autoCompleteOutPutRef = useRef<HTMLDivElement>(null);
  const autoComplete = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const [form] = Form.useForm();

  const afterShow = useCallback(() => {
    if (AMap) {
      const autoOptions = {
        // city: "010", //城市，默认全国
        input: inputRef.current.nativeElement,
        output: autoCompleteOutPutRef.current,
        closeResultOnScroll: false,
      };
      autoComplete.current = new AMap.AutoComplete(autoOptions);
      autoComplete.current.on("select", function (e: any) {
        onClose();
        onSelect && onSelect(e);
      });
    }
  }, [visible]);
  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);
  return (
    <Popup
      visible={visible}
      onMaskClick={onClose}
      onClose={onClose}
      bodyStyle={{ height: "60vh" }}
      afterShow={afterShow}
    >
      <div className={styles.container}>
        <Form form={form} className={styles.form}>
          <Form.Item name="detailAddress">
            <Input ref={inputRef} placeholder="请输入地址" />
          </Form.Item>
        </Form>
        <div
          style={{ visibility: "visible" }}
          className={styles.autoCompleteOutPut}
          ref={autoCompleteOutPutRef}
        />
      </div>
    </Popup>
  );
};

export default React.memo(AutoSelect);
