import React, { useCallback, useState, useEffect } from "react";
import { NumberKeyboard, PasscodeInput, Popup } from "antd-mobile";
import { regFenToYuan } from "src/common/Utils";

import styles from "./index.module.scss";

const MAX_LENGTH = 6;

function PayPwd(props: any) {
  const { visible = false, onClose, data= {}, params = {}, onOk } = props;
  const { payAmount = 0, feeAmount = 0 } = data || {};
  const [values, setValues] = useState<number[]>([]);
  const currentFeeAmount = params?.feeAmount || feeAmount;

  useEffect(() => {
    if (visible) {
      setValues([]);
    }
  }, [visible]);

  const onInput = useCallback(
    (val: any) => {
      if (values.length >= MAX_LENGTH) {
        return;
      }

      setValues([...values, val]);
    },
    [values]
  );

  const onDelete = useCallback(() => {
    if (values.length <= 0) {
      return;
    }

    values.pop();
    setValues([...values]);
  }, [values]);

  const onFill = useCallback(
    (val: any) => {
      // Toast.show(TOAST_LOADING);
      // setTimeout(() => {
      //   run({ ...params, payPassword: val });
      // }, 1000);
      onOk(val);
    },
    [values, params]
  );

  return (
    <Popup
      showCloseButton
      visible={visible}
      onClose={onClose}
      onMaskClick={onClose}
    >
      <ul className={styles.app}>
        <li className={styles.title}>请输入支付密码</li>
        <li className={styles.amount}>
          付款金额<span>¥{regFenToYuan(payAmount + currentFeeAmount)}</span>
        </li>
        <li>
          <PasscodeInput
            className={styles.passcode}
            seperated
            onFill={onFill}
            value={values.join("")} />
        </li>
        <li>
          <NumberKeyboard
            visible={visible}
            showCloseButton={false}
            onInput={onInput}
            onDelete={onDelete}
          />
        </li>
      </ul>
    </Popup>
  );
}

export default PayPwd;
