import axios, { IBaseRes } from "@/common/Utils/APIClient";

interface IParams {}

interface IRes {}
/**
 * 用户是否加入到店
 */
export const enterpriseCheckOpen = (
  data: IParams
): Promise<IBaseRes<boolean>> => {
  return axios.get("/dmall-account/arrive/enterprise/check/open", {
    param: data,
  });
};
