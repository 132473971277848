/**
 * 生成符合 UUID v4 标准的唯一标识符
 * @returns UUID 字符串
 */
export const getUUID = (): string => {
  const version = 4; // UUID 版本号
  const variant = 0x8; // UUID 变体

  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const randomValue = (Math.random() * 16) | 0;

    // 如果 c 是 'x'，则返回随机值；如果是 'y'，则返回 (randomValue & 0x3) | variant
    const value = c === "x" ? randomValue : (randomValue & 0x3) | variant;
    return value.toString(16);
  });
};
