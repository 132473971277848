import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./index.module.scss";
import usePageTitle from "@/hooks/usePageTitle";
import Table, { IColumn } from "../../components/Table";
import { useRequest } from "ahooks";
import { DatePicker } from "antd-mobile";
import PageLoading from "@/common/Component/PageLoading";

import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import Tab from "@/common/Component/Tabs";
import { DownFill } from "antd-mobile-icons";

import { mobileCountMonth } from "../../services/mobileCountMonth";
import { mobileCountDay } from "../../services/mobileCountDay";
import { PickerDate } from "antd-mobile/es/components/date-picker/util";
import {
  FeeType,
  mobileChildPerfPage,
  IListItem,
} from "../../services/mobileChildPerfPage";
interface EnterpriseIncomeDetailProps {}
const pageSize = 10;

const getDate = (monthId?: string | null, daysId?: string | null) => {
  if (monthId) {
    return dayjs(monthId, "YYYYMM").toDate();
  } else {
    return dayjs(daysId, "YYYYMMDD").toDate();
  }
};
/**
 * 企业收益-日/月详情
 * */
const EnterpriseIncomeDetail: React.FC<EnterpriseIncomeDetailProps> = (
  props
) => {
  //   const {} = props;
  usePageTitle({ title: "收益" });
  const [search] = useSearchParams();
  const monthId = search.get("monthId");
  const daysId = search.get("daysId");
  const pageNum = useRef(1);
  const totalItem = useRef<Array<IListItem>>([]);
  const [activeKey, setActiveKey] = useState<FeeType>("enterprise");
  const [visible, setVisible] = useState(false);
  const [date, setDate] = useState<Date>(getDate(monthId, daysId));

  const changeActiveKey = (key: string) => {
    totalItem.current = [];
    setActiveKey(key as FeeType);
    pageRun({
      pageNum: 1,
      pageSize,
      // partnerId: partnerId,
      feeType: key as FeeType,
      signDate: dayjs(date).format(monthId ? "YYYYMM" : "YYYYMMDD"),
    });
  };

  const {
    runAsync: pageRun,
    data: pageData,
    loading: pageLoading,
  } = useRequest(mobileChildPerfPage, {
    defaultParams: [
      {
        pageNum: pageNum.current,
        pageSize,
        signDate: monthId || daysId || "",
        feeType: activeKey,
      },
    ],
    onSuccess: (res, params) => {
      totalItem.current?.push(...(res.data?.list || []));
      pageNum.current = params[0].pageNum || 1;
    },
  });
  const {
    data: monthCount,
    loading: monthCountLoading,
    run: monthCountRun,
  } = useRequest(mobileCountMonth, {
    manual: true,
  });

  const {
    data: daysCount,
    loading: daysCountLoading,
    run: daysCountRun,
  } = useRequest(mobileCountDay, {
    manual: true,
  });
  const loadMore = async () => {
    await pageRun({
      pageNum: pageNum.current + 1,
      pageSize,
      feeType: activeKey,
      signDate: dayjs(date).format(monthId ? "YYYYMM" : "YYYYMMDD"),
    });
  };
  const hasMore = useMemo(() => {
    return (pageData?.data?.total || 0) > (totalItem.current?.length || 0);
  }, [pageData]);

  useEffect(() => {
    if (monthId) {
      monthCountRun({ monthId });
      return;
    }
    if (daysId) {
      daysCountRun({ daysId });
      return;
    }
  }, [monthId, daysId]);
  const columns = useMemo(() => {
    const shop: IColumn[] = [
      {
        title: "商户",

        dataIndex: "promotionEntityName",
        span: 1,
      },
      {
        title: "子合伙人",
        dataIndex: "partnerName",
        span: 1,
      },
    ];
    const enterprise: IColumn[] = [
      {
        title: "组织",

        dataIndex: "promotionEntityName",
        span: 1,
      },
      {
        title: "子合伙人",
        dataIndex: "partnerName",
        span: 1,
      },
    ];
    const referral: IColumn[] = [
      {
        title: "广告",
        dataIndex: "promotionEntityName",
        span: 1,
      },
      {
        title: "子合伙人",
        dataIndex: "partnerName",
        span: 1,
      },
    ];
    const data: Record<FeeType, IColumn[]> = {
      shop: shop,
      enterprise: enterprise,
      referral: referral,
    };
    if (monthId) {
      const renderSignDate = (date: string) => {
        if (!date) return "";
        return dayjs(`${date}`).format("MM/DD");
      };
      data[activeKey].unshift({
        title: "时间",
        dataIndex: "signDate",
        render: renderSignDate,
        span: 1,
      });
    }
    return data[activeKey];
  }, [activeKey, monthId]);
  const tabs = useMemo(() => {
    const tabData = [
      {
        title: `组织安装(${
          monthCount?.data?.enterpriseNum || daysCount?.data?.enterpriseNum || 0
        })`,
        key: "enterprise",
      },
      {
        title: `商户邀约(${
          monthCount?.data?.shopNum || daysCount?.data?.shopNum || 0
        })`,
        key: "shop",
      },
      // {
      //   title: `广告推广(${
      //     monthCount?.data?.referralNum || daysCount?.data?.referralNum || 0
      //   })`,
      //   key: "referral",
      // },
    ];

    return tabData;
  }, [monthCount, daysCount]);
  const onConfirm = (val: PickerDate) => {
    totalItem.current = [];
    setDate(val);
    const newDate = dayjs(val).format(monthId ? "YYYYMM" : "YYYYMMDD");
    pageRun({
      pageNum: 1,
      pageSize,
      signDate: newDate,
      feeType: activeKey,
    });
    if (monthId) {
      monthCountRun({ monthId: newDate });
      return;
    }
    if (daysId) {
      daysCountRun({ daysId: newDate });
      return;
    }
  };
  return (
    <div className={styles.page}>
      <PageLoading
        loading={pageLoading || monthCountLoading || daysCountLoading}
      />

      <DatePicker
        title="时间选择"
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        max={new Date()}
        value={date}
        onConfirm={onConfirm}
        precision={monthId ? "month" : "day"}
      >
        {(value) => (
          <div onClick={() => setVisible(true)} className={styles.header}>
            <span className={styles.title}>
              {dayjs(value).format(monthId ? "YYYY年MM月" : "YYYY年MM月DD日")}
            </span>
            <DownFill className={styles.icon} />
          </div>
        )}
      </DatePicker>

      <Tab
        model="out"
        itemData={tabs}
        activeKey={activeKey}
        onChange={changeActiveKey}
        stretch
        className={styles.tab}
        tabTitleClassName={styles.tabTitle}
        activeTitleClassName={styles.activeTabTitle}
      />
      <div className={styles.content}>
        <Table
          className={styles.table}
          columns={columns}
          dataSource={totalItem.current}
          bodyClassName={styles.tableBody}
          loadMore={loadMore}
          hasMore={hasMore}
        />
      </div>
    </div>
  );
};

export default React.memo(EnterpriseIncomeDetail);
