import { Image, Space } from "antd-mobile";
import React, { useMemo } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";

interface StatusInfoProps {
  status: "success" | "fail" | "wait";
  message?: React.ReactNode;
  className?: string;
  title?: string;
  children?: React.ReactNode;
}

const StatusInfo: React.FC<StatusInfoProps> = (props) => {
  const { status, message, className, title, children } = props;
  const { imgSrc, backgroundColor } = useMemo(() => {
    switch (status) {
      case "success":
        return {
          backgroundColor: "rgba(55, 190, 113, 0.08)",

          imgSrc:
            "https://cdn.wuhuxianmai.cn/feed/1736906512026-f546ad46b439443e2944d0515a2b7e391433930e40140966bd804308985401a0",
        };
      case "fail":
        return {
          backgroundColor: "rgba(254, 88, 98, 0.08)",
          imgSrc:
            "https://cdn.wuhuxianmai.cn/feed/1736906505050-1b004c72c7773709850b2efc55172f651b930d36554b5f3583244337b1e087e0",
        };
      case "wait":
        return {
          backgroundColor: "rgba(23, 119, 255, 0.08)",
          imgSrc:
            "https://cdn.wuhuxianmai.cn/feed/1736906508395-5e3f09792c0060e5a1398da07f55b8dcbbb31b44a6fa06df38a63e7282525ccf",
        };
      default:
        return {};
    }
  }, [status]);
  return (
    <Space block direction="vertical" className={styles.cardContent}>
      <div
        className={classNames(styles.container, className)}
        style={{ backgroundColor }}
      >
        <Image className={styles.img} src={imgSrc} alt="" />
        <div className={styles.title}>{title}</div>
      </div>
      {message && <div className={styles.message}>{message}</div>}
      {children}
    </Space>
  );
};

export default React.memo(StatusInfo);
