import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import usePageTitle from "@/hooks/usePageTitle";
import ApplyLayout from "@/common/Component/ApplyPage";
import { Tabs, Toast } from "antd-mobile";
import styels from "./index.module.scss";
import Buttons from "@/common/Component/Button";
import AgentOperation, {
  IAgentOperationRef,
} from "./components/AgentOperation";
import Discount from "./components/Discount";
import { useRequest } from "ahooks";
import { agentOperationApply } from "../../service/agentOperationApply";
import {
  ApplyStatusEnum,
  enterpriseApplyGet,
} from "@/pages/Enterprise/services/enterpriseApplyGet";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";
import PageLoading from "@/common/Component/PageLoading";

enum TabEnum {
  Discount = "1",
  AgentOperation = "2",
}
interface SettledInResProps {}

const SettledInRes: React.FC<SettledInResProps> = (props) => {
  const {} = props;
  usePageTitle({ title: "开通企业专项折扣" });
  const [corpId] = useUserStore(useShallow((state) => [state.corpId]));
  const [activeKey, setActiveKey] = useState(TabEnum.Discount);
  const ref = useRef<IAgentOperationRef>(null);
  const [hideSubmit, setHideSubmit] = useState(false);

  const {
    run: enterpriseApplyGetRun,
    data: enterpriseApplyGetData,
    loading: getDetailLoading,
  } = useRequest(enterpriseApplyGet, {
    manual: true,
  });
  const { run: agentOperationApplyRun, loading: applyLoading } = useRequest(
    agentOperationApply,
    {
      manual: true,
      onSuccess: (res) => {
        if (res.data && res.success) {
          Toast.show({
            icon: "success",
            content: "提交成功",
          });
          corpId &&
            enterpriseApplyGetRun({ applyOrgId: corpId, hasDesensitize: true });
        }
      },
    }
  );
  useEffect(() => {
    corpId &&
      enterpriseApplyGetRun({ applyOrgId: corpId, hasDesensitize: true });
  }, [corpId]);

  return (
    <ApplyLayout>
      <PageLoading loading={getDetailLoading} />
      <div className={styles.container}>
        <Tabs
          activeKey={activeKey}
          // defaultActiveKey={TabEnum.Discount}
          className={styels.tabs}
          activeLineMode="fixed"
          onChange={(key) => {
            // setHideSubmit(key === TabEnum.AgentOperation);
            setActiveKey(key as TabEnum);
          }}
        >
          <Tabs.Tab title="到店专享折扣开通" key={TabEnum.Discount}>
            <Discount data={enterpriseApplyGetData?.data} />
          </Tabs.Tab>

          <Tabs.Tab
            title="企业码代运营"
            key={TabEnum.AgentOperation}
            disabled={
              enterpriseApplyGetData?.data?.discountApplyStatus !==
              ApplyStatusEnum.REVIEW_PASSED
            }
          >
            <AgentOperation
              setHideSubmit={setHideSubmit}
              data={enterpriseApplyGetData?.data}
              ref={ref}
              onFinish={(v) => {
                corpId &&
                  agentOperationApplyRun({
                    enterpriseCodeAccount: v?.enterpriseCodeAccount,
                    applyOrgId: corpId,
                  });
              }}
            />
          </Tabs.Tab>
        </Tabs>
        {activeKey === TabEnum.Discount && (
          <div className={styles.footer}>有任何问题可联系我们：18879987876</div>
        )}
      </div>

      {hideSubmit && (
        <div className={styles.footer}>
          <Buttons
            block
            color="primary"
            onClick={() => {
              ref.current?.submit?.();
            }}
            loading={applyLoading}
          >
            提交
          </Buttons>
        </div>
      )}
    </ApplyLayout>
  );
};

export default React.memo(SettledInRes);
