import axios, { IBaseRes } from "@/common/Utils/APIClient";

interface IReq {
  /**
   * 合伙人姓名
   */
  partnerName: string;
  /**
   * 合伙人手机号
   */
  partnerPhone: string;
}
interface IRes {}
/**
 * 手动添加合伙人
 */
export const addChild = (params: IReq): Promise<IBaseRes<boolean>> => {
  const form = new FormData();
  Object.keys(params).forEach((key) => {
    // @ts-ignore
    form.append(key, params[key]);
  });
  return axios.post("/dmall-customer/partner/app/add/child", form, {
    headers: {
      "Content-Type": "form-data",
    },
  });
};
