import { shopPageQuery } from "@/pages/ArriveStore/service/shopPageQuery";
import { useRequest } from "ahooks";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ShopCard from "../ShopCard";
import { Empty, InfiniteScroll } from "antd-mobile";
import { calculateDistance } from "@/common/Utils/distanceUtils";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import PageLoading from "@/common/Component/PageLoading";
import { IDingLocateInMapRes } from "@/services/dd/locateInMap";
import styles from "./index.module.scss";

interface InfiniteScrollProps {
  addressData?: IDingLocateInMapRes;
  hot?: boolean;
}
const pageSize = 20;
const CloseInList: React.FC<InfiniteScrollProps> = (props) => {
  const { addressData, hot } = props;
  const pageNo = useRef(0);

  const [allMapData, setAllMapData] = useState<any[]>([]);
  const {
    data: pageData,
    run: shopPageQueryRun,
    runAsync: shopPageQueryRunAsync,

    loading: materialLoading,
    // cancel: cancelMaterialRun,
  } = useRequest(shopPageQuery, {
    manual: true,
    onSuccess: (res, params) => {
      pageNo.current = params[0].pageNum;
      const list = res?.data?.records || [];
      if (pageNo.current === 1) {
        setAllMapData([...list]);
        return;
      }
      setAllMapData([...allMapData, ...list]);
    },
    onError: () => {
      console.log("error");
    },
  });
  /** 加载更多 */
  async function loadMore() {
    if (materialLoading) {
      return;
    }
    await shopPageQueryRunAsync({
      pageNum: pageNo.current + 1,
      pageSize,
      cityName: addressData?.city,
      hot,
      //   firstCatId: activeKey,
      firstCatId: "",
      shopName: "",
    });
  }
  const hasMore = useMemo(() => {
    const total = pageData?.data?.total || 0;

    return total > allMapData.length;
  }, [pageData, allMapData]);
  useEffect(() => {
    if (typeof addressData?.success === "boolean" && addressData?.city) {
      shopPageQueryRun({
        pageNum: 1,
        pageSize,
        cityName: addressData?.city,
        firstCatId: "",
        shopName: "",
        hot,
      });
    }
  }, [addressData]);
  const openUrl = useOpenUrl();
  return (
    <div className={styles.container}>
      <PageLoading loading={materialLoading} />
      {allMapData?.map((item, index) => (
        <ShopCard
          key={index}
          data={[
            {
              url: item.doorFrontPic,
            },
          ]}
          onClick={() => {
            openUrl({
              url: "/arriveStore/detail",
              isRoute: true,
              isOpenHalf: false,
              params: {
                id: item.shopId,
                lng: addressData?.longitude,
                lat: addressData?.latitude,
              },
            });
          }}
          discountRate={item.discountRate}
          shopName={item.shopName}
          distance={calculateDistance(
            addressData?.latitude !== undefined
              ? Number(addressData?.latitude)
              : undefined,
            addressData?.longitude !== undefined
              ? Number(addressData?.longitude)
              : undefined,
            item.shopLatitude,
            item.shopLongitude
          )}
        />
      ))}
      {!!allMapData?.length && (
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
      )}
      {!allMapData.length && !materialLoading && (
        <Empty description="暂无数据" />
      )}
    </div>
  );
};

export default React.memo(CloseInList);
