import React from "react";
import usePageTitle from "@/hooks/usePageTitle";
import { Button} from "antd-mobile";
import { ExclamationCircleFill } from "antd-mobile-icons";
import styles from "./index.module.scss";
import { useOpenUrl } from "@/hooks/useOpenUrl";

function CancelAccountInfo() {
  usePageTitle({ title: "注销账户" });
  const openUrl = useOpenUrl();

  const showInfoList = [
    {
      title: '餐补钱包账户关闭',
      desc: '需联系公司协助关闭，完成余额清算及权限终止操作'
    },
    {
      title: '商户端人员绑定关系',
      desc: '应联系对应商户主体解除人员绑定关系，同步更新门店管理系统权限配置'
    },
    {
      title: '企业码授权管理',
      desc: '用户需通过支付宝官方平台自主解除企业权限关联'
    }
  ]

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.warningBox}>
          <h1 className={styles.title}>注销账户</h1>
          <div className={styles.warningTitle}>
            <ExclamationCircleFill className={styles.warningIcon} color="#FE5862"/>
            <span>注销操作仅涵盖个人账户信息清退，不可恢复</span>
          </div>
          <div className={styles.warningText}>以下关联业务模块需另行执行专项处理</div>
        </div>

        <div className={styles.optionsList}>
          {showInfoList.map((item, index) => (
            <div className={styles.optionItem} key={index}>
              <div className={styles.dot}></div>
              <div className={styles.optionContent}>
                <div className={styles.optionTitle}>{item.title}</div>
                <div className={styles.optionDesc}>{item.desc}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.bottomFixedContainer}>
        <div className={styles.verifyButtons}>
          <Button 
            className={styles.verifyButton} 
            block 
            onClick={() => openUrl({ url: '/securityCenter/cancelAccount', isRoute: true })}
            shape="rounded"
            size="large"
          >
            我已知晓，继续注销
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CancelAccountInfo;