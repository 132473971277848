import axios, { IBaseRes } from "@/common/Utils/APIClient";
import { IdenEnum, ApplyStatusEnum } from "../enum";

interface IParams {}

export interface ICheckTypeRes {
  /**
   * 申请状态
   */
  applyStatus?: ApplyStatusEnum;
  /** 身份 */
  iden?: IdenEnum;
}
/**
 * 判断当前用户身份
 */
export const checkType = (
  params: IParams
): Promise<IBaseRes<ICheckTypeRes>> => {
  return axios.get("/dmall-customer/partner/app/check/type", { param: params });
};
