import axios from "@/common/Utils/APIClient";

interface IIsbindedReq {
  bankCardNo: string;
}
export enum IIsbindedResCodeEnum {
  /** 未开通 */
  UNOPEN = 0,
  /** 已开通 */
  OPEN = 1,
  /** 已绑定 */
  BINDED = 2,
}
/**
 * 查询是否已绑定
 */
interface IIsbindedRes {
  /** 响应码 */
  code: string;
  /** 响应消息 */
  msg: string;
  /** 是否绑定,0 未开通 1已开通 2已绑定 */
  data: IIsbindedResCodeEnum;
  /** 是否成功 */
  success: boolean;
}
/**
 * 查询是否已绑定
 * @param params 查询参数
 * @returns Promise<IIsbindedRes>
 */

export const isbinded = (params: IIsbindedReq): Promise<IIsbindedRes> => {
  return axios.get("/dmall_account/Account/isbinded", { param: params });
};
