import React, { useCallback, useRef, useState } from "react";
import { Button, Toast, Modal, Grid } from "antd-mobile";
import { useRequest } from "ahooks";
import { useSearchParams } from "react-router-dom";
import Header from "./Component/Header";
import Content from "./Component/Content";
import PayPwd from "./Component/PayPwd";
import PageLoading from "src/common/Component/PageLoading";
import StorageService from "src/common/Utils/store";
import VerificationCode from "src/common/Component/VerificationCode";
import { getBuyerPay, getAccountVerificationCode } from "./Services";
import styles from "./index.module.scss";
import { confirmPay } from "./Services";
import usePageTitle from "@/hooks/usePageTitle";
import { usePageReturn } from '@/hooks/usePageReturn';
import { useOpenUrl } from "@/hooks/useOpenUrl";

import dd from "dingtalk-jsapi";

function Pay() {
  const [params] = useSearchParams();
  const openUrl = useOpenUrl();
  const [visibleVerify, setVisibleVerify] = useState(false);
  const [visiblePayCode, setVisiblePayCode] = useState(false);
  const [visiblePwdError, setVisiblePwdError] = useState(false);
  const [payInfo, setPayInfo] = useState<any>({});
  const [coupon, setCoupon] = useState([]);
  const payOrderId = params.get("payOrderId");
  const codeRef = useRef<any>(null);

  usePageTitle({ title: "芜湖生活支付" });

  const { data, loading, refresh } = useRequest(
    getBuyerPay({
      payOrderId: payOrderId,
    }),
    {
      onSuccess: (res: any) => {
        const { status, redirectUrl, orderNo } = res || {};
        if(status >= 400) {
          window.location.href = `${redirectUrl}?out_trade_no=${orderNo}`;
        }
      },
    }
  );

  const confirmPaySuccess = (code: string, data: any) => {
    setVisibleVerify(false);
    setVisiblePayCode(false);
    if (payInfo.payMethodCd === 'alipay' || payInfo.payMethodCd === 'wechat') {
      dd.openLink({
        url: data + encodeURIComponent("&token=" + StorageService.shared.getToken()),
      });
    } else {
      Modal.show({
        content: '支付成功，正在为您跳转'
      })
      // 饿了么订单状态慢，必须延迟跳转
      setTimeout(() => {
        Modal.clear();
        window.location.href = data;
      }, 2000);
    }
  }

  const confirmPayError = (msg: string, code?: string) => {
    if(code === 'B01001') {
      setVisiblePwdError(true);
      setVisiblePayCode(false);
    } else {
      Toast.show({
        icon: "fail",
        content: msg || "支付失败，请重新支付",
      });
    }
  }

  const onChangePayInfo = useCallback((type: any) => {
    setPayInfo(type);
  }, []);

  const onChangeCoupon = useCallback((val: any) => {
    setCoupon(val);
  }, []);

  const onConfirm = useCallback(() => {

    if (!payInfo || !payInfo?.payMethodId) {
      Toast.show({
        content: "请选择支付方式",
      });
      return;
    }

    if(payInfo.payMethodCd === 'alipay' || payInfo.payMethodCd === 'wechat') {
      confirmPay({
        payOrderId: payOrderId,
        payMethodId: payInfo.payMethodId,
      }).then((res: any) => {
        if(res.success) {
          confirmPaySuccess('', res.data);
        } else {
          confirmPayError(res.msg);
        }
      }).catch((err: any) => {
        confirmPayError(err.msg);
      });

      return;
    }

    if(payInfo.pwdPay) {
      setVisiblePayCode(true);
    } else {
      setVisibleVerify(true);
      codeRef.current?.focus();
    }
  }, [payInfo]);

  usePageReturn(() => {
      refresh();
  });

  return (
    <div className={styles.app}>
      <PageLoading loading={loading} />
      {data && <Header data={data} payInfo={payInfo} />}
      <Content
        coupon={coupon}
        payInfo={payInfo}
        data={data}
        onChangeCoupon={onChangeCoupon}
        onChangePayInfo={onChangePayInfo}
      />
      <div className={styles.footer}>
        <Button className={styles.footerBtn} onClick={onConfirm} block color="primary" size="large">
          确认支付
        </Button>
      </div>
      <PayPwd
        onClose={() => {
          setVisiblePayCode(false);
        }}
        visible={visiblePayCode}
        data={data}
        params={{
          ...payInfo,
          payOrderId: params.get("payOrderId"),
        }}
        onOk={(code: string) => 
          confirmPay({
            payOrderId: payOrderId,
            payMethodId: payInfo.payMethodId,
            cardId: payInfo.accountId,
            payPwd: code,
          }).then((res: any) => {
            if(res.success) {
              confirmPaySuccess('', res.data);
            } else {
              confirmPayError(res.msg, res.code);
            }
          })
        }
      />
      <VerificationCode
        visible={visibleVerify}
        phone={payInfo.phone}
        getVerifyCode={() => {
          return getAccountVerificationCode({
            accountId: payInfo.accountId,
          });
        }}
        verifyCode={(data) => {
          return confirmPay({
            payOrderId: payOrderId,
            payMethodId: payInfo.payMethodId,
            cardId: payInfo.accountId,
            smsCode: data.code,
          });
        }}
        onOk={confirmPaySuccess}
        isCheckAfter={true}
        onClose={() => {
          setVisibleVerify(false);
          (document.activeElement as HTMLElement)?.blur(); // 关闭手机软键盘
        }}
        ref={codeRef} 
      />
      <Modal
        title='支付密码错误，请重试'
        visible={visiblePwdError}
        content={
          <div className={styles.dialogContainer}>
            <Grid className={styles.dialogGrid} columns={2} gap={8}>
              <Grid.Item>
                <Button className={styles.continueBtn} block size="middle" onClick={() => openUrl({ url: `/changePassword/${payInfo.accountId}`, isRoute: true })}>
                  忘记密码
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button className={styles.backBtn} block size="middle" onClick={() => {
                  setVisiblePwdError(false);
                  setVisiblePayCode(true);
                }}>
                  重试
                </Button>
              </Grid.Item>
            </Grid>
          </div>
        }
      />
    </div>
  );
}

export default Pay;
