import React from "react";
import { List } from "antd-mobile";
import { LockOutline, MinusCircleOutline, RightOutline, UserOutline } from "antd-mobile-icons";
import usePageTitle from "@/hooks/usePageTitle";
import styles from "./index.module.scss";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow"; 

function SecurityCenter() {
  usePageTitle({ title: "安全中心" });
  const openUrl = useOpenUrl();
  const [platformContext] = useUserStore(
    useShallow((state) => [
      state.platformContext,
    ])
  );

  return (
    <div className={styles.container}>
      <List className={styles.infoList}>
        <List.Item
          prefix={
            <UserOutline />
          }
          extra={
            <div className={styles.avatarWrapper}>
              <RightOutline className={styles.arrowIcon} />
            </div>
          }
          arrow={false}
          onClick={() => openUrl({ url: '/protocolInfo/user', isRoute: true })}
        >
          用户协议
        </List.Item>
        <List.Item
          prefix={
            <LockOutline />
          }
          extra={
            <div className={styles.valueWrapper}>
              <RightOutline className={styles.arrowIcon} />
            </div>
          }
          arrow={false}
          onClick={() => openUrl({ url: '/protocolInfo/security', isRoute: true })}
        >
          隐私协议
        </List.Item>
        {platformContext.isH5Browser() && (
          <List.Item
            prefix={
              <MinusCircleOutline />
            }
            extra={
              <div className={styles.valueWrapper}>
                <RightOutline className={styles.arrowIcon} />
              </div>
            }
            arrow={false}
            onClick={() => openUrl({ url: '/securityCenter/cancelAccountInfo', isRoute: true })}
          >
            注销账户
          </List.Item>
        )}
      </List>
    </div>
  );
}

export default SecurityCenter;