import React, { useMemo } from "react";
import styles from "./index.module.scss";
import usePageTitle from "@/hooks/usePageTitle";
import Card from "../../components/Card";
import { Space } from "antd-mobile";
import Buttons from "@/common/Component/Button";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { useRequest } from "ahooks";
import { detail } from "../../services/detail";
import { useSearchParams } from "react-router-dom";
import PageLoading from "@/common/Component/PageLoading";

interface ManageInfoProps {}

/**
 * 企业合伙人-企业-管理信息
 */
const ManageInfo: React.FC<ManageInfoProps> = (props) => {
  usePageTitle({ title: "信息管理" });
  const [search] = useSearchParams();
  const partnerId = search.get("partnerId");
  const openUrl = useOpenUrl();
  const {
    run: detailRun,
    loading: detailLoading,
    data: detailData,
  } = useRequest(detail, {
    manual: true,
    // onSuccess: (res) => {
    //   if (res.success && res.data) {
    //     form.setFieldsValue({
    //       bankName: res.data.partnerBankDto?.bankName,
    //       bankNo: res.data.partnerBankDto?.bankNo,
    //       openingBank: res.data.partnerBankDto?.openingBank,
    //     });
    //   }
    // },
  });
  React.useEffect(() => {
    if (partnerId) {
      detailRun({ partnerId });
    }
  }, [partnerId]);
  const renderRow = (
    data: { label: string; value?: string | number },
    key: any
  ) => {
    return (
      <div className={styles.row} key={key}>
        <div className={styles.label}>{data?.label}</div>
        <div className={styles.value}>{data?.value}</div>
      </div>
    );
  };
  const baseInfoList = useMemo(() => {
    const {
      partnerName,
      creditCode,
      promotionContentStr,
      partnerPerson,
      partnerPersonMobile,
    } = detailData?.data || {};
    return [
      {
        label: "企业名称",
        value: partnerName,
      },
      {
        label: "社会信用代码",
        value: creditCode,
      },
      {
        label: "推广内容",
        value: promotionContentStr,
      },
      {
        label: "联系人",
        value: partnerPerson,
      },
      {
        label: "联系电话",
        value: partnerPersonMobile,
      },
    ];
  }, [detailData]);
  const bankInfoList = useMemo(() => {
    const { partnerBankDto } = detailData?.data || {};
    return [
      {
        label: "开户银行",
        value: partnerBankDto?.bankName,
      },
      {
        label: "开户名",
        value: partnerBankDto?.openingBank,
      },
      {
        label: "银行账户",
        value: partnerBankDto?.bankNo,
      },
    ];
  }, [detailData]);
  const onEdit = () => {
    openUrl({
      url: "/partner/manage/bank/edit",
      isRoute: true,
      isOpenHalf: false,
      params: {
        partnerId,
      },
    });
  };

  return (
    <div className={styles.page}>
      <PageLoading loading={detailLoading} />
      <Space direction="vertical" block className={styles.space}>
        <Card title="基本信息">
          <div className={styles.cardContent}>
            {baseInfoList.map((item, index) => {
              return renderRow(item, index);
            })}
          </div>
        </Card>
        <Card
          title="收款银行账户信息"
          extra={
            <Buttons color="primary" fill="none" onClick={onEdit}>
              修改
            </Buttons>
          }
        >
          <div className={styles.cardContent}>
            {bankInfoList.map((item, index) => {
              return renderRow(item, index);
            })}
          </div>
        </Card>
      </Space>
    </div>
  );
};

export default React.memo(ManageInfo);
