import React, { useState } from "react";
import { Form, Input, Button, Toast } from "antd-mobile";
import { useRequest } from "ahooks";
import { setPayPassword, getVerificationCode } from "../../Services";

import styles from "./index.module.scss";

interface IAccount {
  accountId: string;
  pwdPay: string;
  opPhone: string;
}

function SmsSetting({ account, successCallback }: { account: IAccount, successCallback: any }) {
  const { pwdPay, opPhone } = account;
  const [countdown, setCountdown] = useState(0);
  const [form] = Form.useForm();

  const { run: getCodeRun } = useRequest(getVerificationCode, {
    manual: true,
    onSuccess: (res) => {
      if (res.success) {
        Toast.show('验证码已发送');
        setCountdown(60);
        const timer = setInterval(() => {
          setCountdown((prev) => {
            if (prev <= 1) {
              clearInterval(timer);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      }
    }
  });

  const { run: setPayPasswordRun } = useRequest(setPayPassword, {
    manual: true,
    onSuccess: (res) => {
      if (res.success) {
        Toast.show(pwdPay ? '修改支付密码成功' : '密码修改成功');
        successCallback();
      } else {
        Toast.show(res.msg);
      }
    }
  });

  const handleGetCode = async () => {
    if (countdown > 0) return;

    getCodeRun({ accountId: account.accountId! });
  };

  if (!account.accountId) {
    Toast.show({
      content: '参数错误',
    });
    return null;
  }


  const onSubmit = async () => {
    try {
      const values = await form.validateFields();

      setPayPasswordRun({
        accountId: account.accountId,
        setType: 1,
        payPassword: values.password,
        code: values.code,
      });
    } catch (error) {
      console.log(error)
    }
  }

  
  return (
    <div className={styles.container}>
      <Form
        layout='horizontal'
        form={form}
        footer={
          <div className={styles.btnContainer}>
            <Button className={styles.btn} onClick={onSubmit} block size="large" type="submit">
              确认
            </Button>
          </div>
        }
      >
        <Form.Item
          label={pwdPay ? '新密码' : '密码'}
          name='password'
          validateTrigger={['onBlur']} // 禁用自动校验
          rules={[
            { required: true, message: '请输入密码' },
            { 
              validator: (_, value) => {
                if (value && value.length !== 6) {
                  return Promise.reject('密码必须为6位');
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input type="password" maxLength={6} pattern="[0-9]*" inputMode="numeric" placeholder="请输入6位支付密码" />
        </Form.Item>
        <Form.Item
          label={pwdPay ? '确认新密码' : '确认密码'}
          name='confirmPassword'
          validateTrigger={['onBlur']} // 在失去焦点时触发校验
          rules={[
            { required: true, message: '请输入确认密码' },
            { 
              validator: (_, value) => {
                if (value && value.length !== 6) {
                  return Promise.reject('密码必须为6位');
                }
                return Promise.resolve();
              }
            },
            { 
              validator: (rule, value) => {
                if (value !== form.getFieldValue('password')) {
                  return Promise.reject('两次密码不一致');
                }
                return Promise.resolve();
              },
            }
          ]}
        >
          <Input type="password" maxLength={6} pattern="[0-9]*" inputMode="numeric" placeholder="请输入6位支付密码" />
        </Form.Item>
        <Form.Item
          label='手机号'
          name='phone'
        >
          <div className={styles.phone}>{opPhone}</div>
        </Form.Item>
        <Form.Item
          label='验证码'
          name='code'
          validateTrigger={[]}
          rules={[
            { required: true, message: '请输入验证码' },
          ]}
          extra={
            <div className={styles.getCode}>
              <Button
                className={styles.getCodeBtn}
                fill='none'
                onClick={handleGetCode}
                style={{ 
                  color: countdown > 0 ? '#86909C' : '#1879FF',
                  cursor: countdown > 0 ? 'not-allowed' : 'pointer'
                }}
              >
                {countdown > 0 ? `${countdown}s后重新获取` : '获取验证码'}
              </Button>
            </div>
          }
        >
          <Input type="tel" maxLength={6} pattern="[0-9]*" inputMode="numeric" placeholder="请输入验证码" />
        </Form.Item>
      </Form>
    </div>
  );
}

export default SmsSetting;