import axios, { IBaseRes } from "@/common/Utils/APIClient";
import { AuditStatusEnum } from "../enum";

/**
 * 获取店铺列表
 */
export const shopPageQuery = (
  params: IShopPageQueryReq
): Promise<IBaseRes<IRes>> => {
  // TODO 测试限不传 cityName
  // delete params.cityName;
  return axios.post("/dmall-multishop/arrive/pageQuery", {
    ...params,
    auditStatus: AuditStatusEnum.AUDIT_PASS,
  });
};
interface IShopPageQueryReq {
  pageNum: number;
  pageSize: number;
  /**
   * 分类id
   */
  firstCatId?: string;
  /**
   * 店铺名称
   */
  shopName?: string;
  cityName?: string;
  /**
   * 热门推荐
   */
  hot?: boolean;
}
interface IRes {
  current: number;
  pages: number;
  records: ShopRecord[];
  size: number;
  total: number;
}

export interface ShopRecord {
  discountRate: number;
  doorFrontPic: string;
  geohash: string;
  scenePic: string[];
  shopAddress: string;
  shopFirstCatId: string;
  shopId: string;
  shopLatitude: number;
  shopLogo: string;
  shopLongitude: number;
  shopMobile: string;
  shopName: string;
  shopBusinessHoursDTO: IListBusHours;
  /**
   * 企业码代运营  0:未认证 1:认证成功
   */
  corpAgent?: 0 | 1;
}
export interface IListBusHours {
  businessDays: string;
  timeConfigDetail: TimeConfigDetail[];
}

interface TimeConfigDetail {
  businessTimeRange: string;
  days: string;
  hours: TimeConfigDetailHour[];
}

interface TimeConfigDetailHour {
  end?: string;
  begin?: string;
  businessTimeRange?: string;
  days?: string;
  hours?: HourHour[];
}

interface HourHour {
  end: string;
  begin: string;
}
