import axios, { IBaseRes } from "@/common/Utils/APIClient";

interface IReq {
  /**
   * 合伙人Id
   */
  partnerId: string;
  /**
   * 状态 0：关闭 1：开启
   */
  status: 0 | 1;
}
interface IRes {}
/**
 * 设置合伙人管理员
 */
export const setAdmin = (params: IReq): Promise<IBaseRes<boolean>> => {
  const form = new FormData();
  Object.keys(params).forEach((key) => {
    // @ts-ignore
    form.append(key, params[key]);
  });
  return axios.post("/dmall-customer/partner/app/set/admin", form, {
    headers: {
      "Content-Type": "form-data",
    },
  });
};
