import axios, { IBaseRes } from "@/common/Utils/APIClient";
interface ICheckAdminMobileReq {
  adminMobile: string;
  applyOrgId: string;
}
interface ICheckAdminMobileRes {}

/**
 * 企业申请入驻申请-校验管理员手机号
 */
export const checkAdminMobile = (
  data: ICheckAdminMobileReq
): Promise<IBaseRes<boolean>> => {
  return axios.post("/dmall-account/Enterprise/check/adminMobile", data, {
    param: data,
  });
};
