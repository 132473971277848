import React from "react";
import { Outlet, useLoaderData } from "react-router-dom";
import { IPartnerLoaderRes } from "@/router/loader/partnerLoader";

interface IAuthProps {
  children?: any;
}

/** 权限  */
const AuthPartner: React.FC<IAuthProps> = (props) => {
  const data = useLoaderData() as IPartnerLoaderRes;
  if (!data) {
    return <div className="fail" />;
  }
  return <Outlet context={{ data }} />;
};

export default React.memo(AuthPartner);
