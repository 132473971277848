import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
} from "react";
// import { Link } from "react-router-dom";
import { useRequest } from "ahooks";
import { Card, Image, Modal, Swiper, Toast, Grid, Button } from "antd-mobile";
import { useSearchParams } from "react-router-dom";
import style from "./index.module.scss";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";
import { getBankCard, Accounts } from "./Services";
import classNames from "classnames";
import Buttons from "@/common/Component/Button";
import BankCard from "./Component/BankCard";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { IIsbindedResCodeEnum, isbinded } from "./Services/isbinded";
import usePageTitle from "@/hooks/usePageTitle";
const getAccountIdByCardId = (cardId: string, listBackCard?: { data?: Accounts[] }) => {
  if (!listBackCard?.data?.length) return undefined;
  
  const account = listBackCard.data.find(item => item.bankCardNo === cardId);
  return account?.accountId;
};

function OneCard() {
  usePageTitle({ title: "我的餐卡" });

  const [params] = useSearchParams();
  const openUrl = useOpenUrl();
  const isOpenPay = params.get('isOpenPay');
  const cardId = params.get('cardId');
  const [visiblePayCode, setVisiblePayCode] = useState(false);
  const swiperRef = useRef<any>(null);

  const { data: bindedData, loading } = useRequest(isbinded, {});
  // const [hasBind, setHasBind] = useState<boolean>(false);
  const { data: listBackCard } = useRequest(getBankCard, {});

  const [userInfo, corpId] = useUserStore(
    useShallow((state) => [state.userInfo, state.corpId])
  );
  const hasBind = useMemo(
    () => bindedData?.data !== IIsbindedResCodeEnum.BINDED,
    [bindedData]
  );
  useEffect(() => {
    swiperRef.current?.swipeTo && swiperRef.current.swipeTo(0);
  }, [listBackCard, corpId]);

  enum CardTypeEnum {
    Card = "card",
    Add = "add",
  }

  const showSwiper: Array<{
    type: CardTypeEnum;
    data?: Accounts;
  }> = useMemo(() => {
    const cardList = listBackCard?.data?.map((item) => ({
      type: CardTypeEnum.Card,
      data: item,
    }));
    if (hasBind) {
      return [
        {
          type: CardTypeEnum.Add,
        },
        ...(cardList?.length ? cardList : []),
      ];
    }
    return cardList || [];
  }, [listBackCard, hasBind]);

  const tipsData = [
    {
      title: "安全",
      icon: "https://cdn.wuhuxianmai.cn/feed/1733480748177-37a0c3bbeb823aa190daa51675c7e52800c240a3db0bc7b4c4dc6fadcabe0830",
    },
    {
      title: "便捷",
      icon: "https://cdn.wuhuxianmai.cn/feed/1733480750439-06ff9f6f96468682c8e80b6dc52aa2d2924c597b88bd5cec82bc36dc215806d2",
    },
    {
      title: "高效",
      icon: "https://cdn.wuhuxianmai.cn/feed/1732593228706-b69054ea0660064ff15ec1cb1603aabccf0472e6d1bc8264fa6a403f44393751",
    },
  ];

  useEffect(() => {
    if(isOpenPay && cardId) {
      const bankCards = listBackCard?.data || [];
      const account = bankCards.find((card:any) => card.bankCardNo === cardId);
      if (account && !account.pwdPay) {
        setVisiblePayCode(true);
      }
    }
  }, [isOpenPay, cardId, listBackCard]);

  return (
    <div className={style.app}>
      <div className={style.container}>
        <ul className={style.header}>
          <li>{userInfo?.userName}</li>
          <li>欢迎使用芜湖本地生活</li>
        </ul>
        {
          <Swiper
            ref={swiperRef}
            slideSize={95}
            className={classNames(style.swiper, {
              [style.oneSwiper]: showSwiper?.length === 1,
            })}
            indicatorProps={{
              color: "white",
            }}
            indicator={(total, count) => {
              if (total <= 1) return null;
              return (
                <div className={style.customIndicator} key={count}>
                  {new Array(total).fill(1).map((_, i) => (
                    <div
                      key={i}
                      className={classNames(style.customIndicatorItem, {
                        [style.active]: i === count,
                      })}
                    />
                  ))}
                </div>
              );
            }}
          >
            {showSwiper?.map((item, index) => {
              if (item.type === CardTypeEnum.Card) {
                return (
                  <Swiper.Item key={index}>
                    <BankCard data={item?.data} />
                  </Swiper.Item>
                );
              } else {
                return (
                  <Swiper.Item key={index}>
                    <Card
                      className={classNames(style.card)}
                      title={"开通钱包，领福利"}
                      headerClassName={style.cardHeader}
                      bodyClassName={style.cardBody}
                    >
                      <Image
                        src="https://cdn.wuhuxianmai.cn/images/58cb364f5c6abf4d62b9a541e9c2b87c4f4f7d8a.png"
                        className={style.image}
                      />
                      <div className={style.tips}>
                        {tipsData.map((item, index) => (
                          <div key={index}>
                            <div className={style.tipsItem}>
                              <Image
                                src={item.icon}
                                className={style.tipsItemIcon}
                              />
                              <div className={style.tipsItemText}>
                                {item.title}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <Buttons
                        fill="solid"
                        color="primary"
                        block
                        className={style.button}
                        onClick={() =>
                          openUrl({ url: "/bankCardAdd", isRoute: true })
                        }
                      >
                        单位已帮我开通，去绑定
                      </Buttons>
                    </Card>
                  </Swiper.Item>
                );
              }
            })}
          </Swiper>
        }
      </div>
      <Modal
        title='绑定成功'
        visible={visiblePayCode}
        className={style.dialogContainer}
        content={
          <div className={style.dialogContent}>
            <div className={style.dialogTitle}>为了消费便利，请设置支付密码</div>
            <Grid className={style.dialogGrid} columns={2} gap={8}>
              <Grid.Item>
                <Button className={style.continueBtn} block size="middle" onClick={() => setVisiblePayCode(false)}>
                  取消
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button className={style.backBtn} block size="middle" onClick={() => {
                  const accountId = getAccountIdByCardId(cardId || '', listBackCard);
                  if (accountId) {
                    openUrl({ url: `/setPassword/${accountId}`, isRoute: true });
                  } else {
                    Toast.show('未找到对应账户');
                  }
                  setVisiblePayCode(false);
                }}>
                  设置密码
                </Button>
              </Grid.Item>
            </Grid>
          </div>
        }
      />
    </div>
  );
}

export default OneCard;
