import React from "react";
import { SafeArea } from "antd-mobile";
import classNames from "classnames";
import styles from "./index.module.scss";

interface IApplyLayoutProps {
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
}

/** 申请页面布局  */
const ApplyLayout: React.FC<IApplyLayoutProps> = (props) => {
  const { children, className, contentClassName } = props;

  return (
    <div className={classNames(styles.layout, className)}>
      <SafeArea position="top" />
      <div className={classNames(styles.content, contentClassName)}>
        {children}
      </div>
      <SafeArea position="bottom" />
    </div>
  );
};

export default ApplyLayout;
