import React, { useMemo, useRef, useState } from "react";
import styles from "./index.module.scss";
import ApplyLayout from "@/common/Component/ApplyPage";
import usePageTitle from "@/hooks/usePageTitle";
import Table, { IColumn } from "../../components/Table";
import { useRequest } from "ahooks";

import PageLoading from "@/common/Component/PageLoading";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import dayjs from "dayjs";
import Tabs from "@/common/Component/Tabs";
import { mobilePerfSummary } from "../../services/mobilePerfSummary";
import {
  mobilePerfPageMonth,
  IListItemMonth,
} from "../../services/mobilePerfPageMonth";
import {
  mobilePerfPageDay,
  IListItemDay,
} from "../../services/mobilePerfPageDay";

interface EnterprisePerformanceProps {}
const pageSize = 10;
const tabItem = [
  {
    title: "月业绩",
    key: "month",
  },
  {
    title: "日业绩",
    key: "day",
  },
];

/**
 * 总业绩*/
const EnterprisePerformance: React.FC<EnterprisePerformanceProps> = (props) => {
  usePageTitle({ title: "总业绩" });
  const openUrl = useOpenUrl();
  const pageNum = useRef(1);
  const totalItem = useRef<Array<IListItemDay | IListItemMonth>>([]);
  const [activeTab, setActiveTab] = useState("month");

  const columns = useMemo(() => {
    const defaultColumns: IColumn[] = [
      {
        title: "组织安装",
        dataIndex: "enterpriseNum",
      },
      {
        title: "商户邀约",
        dataIndex: "shopNum",
      },
      // {
      //   title: "广告推广",
      //   dataIndex: "referralNum",
      // },
    ];
    if (activeTab === "month") {
      defaultColumns.unshift({
        title: "月份",
        dataIndex: "monthId",
        render: (value) => {
          console.log("value", value);
          return (
            <div>{dayjs(value?.toString(), "YYYYMM")?.format("YYYY/MM")}</div>
          );
        },
      });
    }
    if (activeTab === "day") {
      defaultColumns.unshift({
        title: "日期",
        dataIndex: "monthId",
        render: (value) => {
          console.log("value", value);
          return (
            <div>
              {dayjs(value?.toString(), "YYYYMMDD")?.format("YYYY/MM/DD")}
            </div>
          );
        },
      });
    }
    return defaultColumns;
  }, [activeTab]);
  const {
    runAsync: pageMonthRun,
    data: pageMonthData,
    loading: pageMonthLoading,
  } = useRequest(mobilePerfPageMonth, {
    defaultParams: [
      {
        pageNum: pageNum.current,
        pageSize,
      },
    ],
    onSuccess: (res, params) => {
      totalItem.current?.push(...(res.data?.list || []));
      pageNum.current = params[0].pageNum || 1;
    },
  });
  const {
    runAsync: pageDayRun,
    data: pageDayData,
    loading: pageDayLoading,
  } = useRequest(mobilePerfPageDay, {
    defaultParams: [
      {
        pageNum: pageNum.current,
        pageSize,
      },
    ],
    manual: true,
    onSuccess: (res, params) => {
      totalItem.current?.push(...(res.data?.list || []));
      pageNum.current = params[0].pageNum || 1;
    },
  });
  const { data: perfData, loading: perfLoading } =
    useRequest(mobilePerfSummary);
  const loadMore = async () => {
    if (activeTab === "month") {
      await pageMonthRun({
        pageNum: pageNum.current + 1,
        pageSize,
      });
    }
    if (activeTab === "day") {
      await pageDayRun({
        pageNum: pageNum.current + 1,
        pageSize,
      });
    }
  };
  const hasMore = useMemo(() => {
    if (activeTab === "month") {
      const total = pageMonthData?.data?.total || 0;

      return total > totalItem.current.length;
    }
    if (activeTab === "day") {
      const total = pageDayData?.data?.total || 0;

      return total > totalItem.current.length;
    }
    return false;
  }, [pageDayData, pageMonthData, activeTab]);
  return (
    <ApplyLayout className={styles.page}>
      <PageLoading
        loading={pageDayLoading || pageMonthLoading || perfLoading}
      />
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.left}>
            <div className={styles.title}>
              {perfData?.data?.enterpriseNum || 0}
            </div>
            <div className={styles.text}>组织安装</div>
          </div>
          <div className={styles.middle} />
          <div className={styles.right}>
            <div className={styles.title}>{perfData?.data?.shopNum || 0}</div>
            <div className={styles.text}>商户邀约</div>
          </div>
          {/* <div className={styles.middle} />
          <div className={styles.right}>
            <div className={styles.title}>
              {perfData?.data?.referralNum || 0}
            </div>
            <div className={styles.text}>广告推广</div>
          </div> */}
        </div>
        <Tabs
          itemData={tabItem}
          onChange={(v) => {
            totalItem.current = [];
            setActiveTab(v);
            if (v === "month") {
              pageMonthRun({ pageNum: 1, pageSize: 10 });
            }
            if (v === "day") {
              pageDayRun({ pageNum: 1, pageSize: 10 });
            }
          }}
          model="out"
          activeKey={activeTab}
          stretch
          className={styles.tabs}
          tabTitleClassName={styles.tabTitle}
          activeTitleClassName={styles.activeTabTitle}
        />
        <Table
          rowClick={(record, index) => {
            openUrl({
              url: "/partner/enterprise/performance/detail",
              isOpenHalf: false,
              isRoute: true,
              params: {
                monthId: activeTab === "month" ? record.monthId : undefined,
                daysId: activeTab === "day" ? record.daysId : undefined,
              },
            });
          }}
          className={styles.table}
          columns={columns}
          dataSource={totalItem.current}
          bodyClassName={styles.tableBody}
          loadMore={loadMore}
          hasMore={hasMore}
        />
      </div>
    </ApplyLayout>
  );
};

export default React.memo(EnterprisePerformance);
