import axios, { IBaseRes } from "@/common/Utils/APIClient";

interface IParams {
  phone: string;
}
interface IRes {}
/**
 * 
企业到店折扣申请发送验证码
 */
export const discountApplyCode = (
  data: IParams
): Promise<IBaseRes<boolean>> => {
  return axios.post(
    `/dmall_account/Enterprise/discount/sendSmsCode/${data.phone}`
  );
};
