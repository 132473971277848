import axios, { IBaseRes } from "@/common/Utils/APIClient";

export enum StatusEnum {
  Open = 1,
  Close = 0,
}
interface IReq {
  /**
   * 合伙人Id
   */
  partnerId: string;
  /**
   * 0 停止合作 1 继续合作
   */
  status: StatusEnum;
}
interface IRes {}
/**
 * 合伙人停止/继续合作
 */
export const setCoopera = (params: IReq): Promise<IBaseRes<boolean>> => {
  const form = new FormData();
  form.append("partnerId", params.partnerId);

  // @ts-ignore
  form.append("status", params.status);

  return axios.post("/dmall-customer/partner/app/set/coopera", form);
};
