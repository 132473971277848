import axios from "@/common/Utils/APIClient";
interface IParams {
  /**
   * dd授权码
   */
  authCode: string;
  /**
   * 企业id
   */
  corpId: string;
}

export interface UserInfo {
  success: boolean;
  msg: string;
  data: UserInfoData;
}
export interface UserInfoData {
  gmtCreate: Date;
  gmtModified: Date;
  id: number;
  /**
   * 是否授权
   */
  isGrant: number;
  status: number;
  unionId: string;
  userAdmin: number;
  userId: string;
  realName: string;
  identityNum: string;
  userAvatar: string;
  userName?: string;
  userMobile?: string;
  corpName?: string;
  identityVerify?: boolean;
}

export const getDingUser = (params: IParams) => {
  return axios
    .get("/dmall_customer/staff/getDingUser", { param: params })
    .then((res: UserInfo) => {
      if (res.data) {
        return res.data
      }
      return undefined;
    });
};
