import { useState, useEffect, useRef, useMemo } from 'react';
import { Button, Modal, Popup, Form, Input, Toast } from 'antd-mobile';
import { RightOutline } from 'antd-mobile-icons';
import { useShallow } from 'zustand/react/shallow';
import useUserStore from '@/store/global';
import usePageTitle from '@/hooks/usePageTitle';
import { useOpenUrl } from '@/hooks/useOpenUrl';
import { Container } from '@/common/Utils/container';
import { openArriveStore, checkDefaultEnterprise } from './service';
import styles from './index.module.scss';
import qs from "qs";
interface IFormData {
  userName: string;
  mobile: string;
  // alipayAccount: string;
}

const OpenArriveStore = () => {
  const initDataRef = useRef<IFormData>({
    userName: "",
    mobile: "",
    // alipayAccount: '',
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [authPopupVisible, setAuthPopupVisible] = useState(false);
  const [hasDefaultEnterprise, setHasDefaultEnterprise] = useState(false);
  const [form] = Form.useForm();
  const openUrl = useOpenUrl();
  const [userInfo] = useUserStore(
    useShallow((state) => [
      state.userInfo,
    ])
  );
  const isH5 = Container.isH5();

  const roleList = useMemo(() => {
    const list = [
      {
        type: "staff",
        name: "企业员工",
        desc: "联系管理员开通",
        avatar:
          "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/icon-role-staff.png",
      },
      {
        type: "admin",
        name: "企业管理员",
        desc: "去开通，为员工加福利",
        avatar:
          "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/icon-role-admin.png",
        onClick: () => {
          setModalVisible(false);
          Modal.confirm({
            title: "即将打开本地生活应用",
            content: "进入本地生活-到店折扣进行开通",
            onConfirm: () => {
              window.location.href =
                "https://h5.dingtalk.com/org-center/index.html?showmenu=false&dd_share=false&goodsCode=DT_GOODS_881732000668740&fromQrCode=1&channelCode=121212&funnelsource=goodsOfflineQrcode&leadsFrom=401";
            },
          });
        },
      },
    ];

    if (hasDefaultEnterprise) {
      list.push({
        type: "citizen",
        name: "城市市民",
        desc: "加入城市生活，立享折扣",
        avatar:
          "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/icon-role-citizen.png",
        onClick: async () => {
          setModalVisible(false);
          setAuthPopupVisible(true);
        },
      });
    }

    return list;
  }, [hasDefaultEnterprise]);

  usePageTitle({ title: "开通到店服务" });

  const resetForm = () => {
    form.resetFields();
    form.setFieldsValue(initDataRef.current);
    // setAlipayAccountSame(false);
  };

  const handleCall = () => {
    window.location.href = "tel:18969091648";
  };

  const handleOpen = async () => {
    if (isH5) {
      const res = await checkDefaultEnterprise();

      setHasDefaultEnterprise(!!res);
      setModalVisible(true);
      return;
    }

    if (userInfo?.userAdmin) {
      openUrl({
        url: "/arriveStore/settled/in",
        isRoute: true,
      });
      return;
    }

    Toast.show({ content: "请联系管理员开通到店服务" });
  };

  const renderOpenText = () => {
    if (isH5 || userInfo?.userAdmin) {
      return '立即开通';
    }

    return "联系管理员开通到店服务";
  };

  const handleAuthCancel = () => {
    setAuthPopupVisible(false);
    resetForm();
  };

  // const handleCheckboxChange = (checked: boolean) => {
  //   if (checked) {
  //     form.setFieldsValue({
  //       alipayAccount: form.getFieldValue('mobile'),
  //     });
  //   }

  //   setAlipayAccountSame(checked);
  // };

  const handleSubmit = async () => {
    setAuthPopupVisible(false);
    await openArriveStore(form.getFieldsValue());
    Toast.show({
      icon: "success",
      content: "开通成功",
      duration: 1000,
      afterClose: () => {
        const query = qs.parse(window.location.search, {
          ignoreQueryPrefix: true,
        });

        if (query.shopId) {
          openUrl({
            url: "/payOrder",
            isRoute: true,
            params: {
              id: query.shopId,
            },
          });
        }
      },
    });
  };

  const renderModalContent = () => {
    return (
      <div className={styles.roles}>
        {roleList.map((role) => (
          <div
            className={`${styles.role}
            ${styles[role.type]}`}
            onClick={role.onClick}
          >
            <img
              src={role.avatar}
              className={styles.roleAvatar}
              alt={role.name}
            />
            <div className={styles.roleInfo}>
              <div className={styles.roleName}>{role.name}</div>
              <div className={styles.roleDesc}>{role.desc}</div>
            </div>
            {role.onClick && (
              <div className={styles.iconContainer}>
                <RightOutline />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderAuthPopup = () => {
    return (
      <div className={styles.authPopup}>
        <div className={styles.authPopupHeader}>
          <div className={styles.appTitle}>
            <img
              className={styles.appLogo}
              src="https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/ld2xhv6flt.png"
              alt="芜湖本地生活"
            />
            <div className={styles.appName}>芜湖本地生活</div>
          </div>
        </div>
        <div className={styles.authTips}>
          <div className={styles.authTipsTitle}>授权信息</div>
          <div className={styles.authTipsDesc}>
            您将加入城市生活享受到店折扣，请授权以下信息
          </div>
        </div>
        <Form
          layout="horizontal"
          form={form}
          onFinish={handleSubmit}
          className={styles.authForm}
        >
          <Form.Item
            label="姓名"
            name="userName"
            rules={[{ required: true, message: "请输入姓名" }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="手机号"
            name="mobile"
            rules={[{ required: true, message: "请输入手机号" }]}
          >
            <Input disabled />
          </Form.Item>
          {/* <Form.Item
            label="支付宝账号"
            name="alipayAccount"
            rules={[{ required: true, message: '请输入支付宝账号' }]}
          >
            <Input disabled={alipayAccountSame} />
          </Form.Item>
          <Form.Item>
            <Checkbox checked={alipayAccountSame} onChange={handleCheckboxChange} /> 支付宝账号与手机号一致
          </Form.Item> */}
        </Form>
        <div className={styles.authPopupButtons}>
          <Button
            className={styles.authPopupButton}
            shape="rounded"
            onClick={handleAuthCancel}
          >
            取消授权
          </Button>
          <Button
            color="primary"
            className={styles.authPopupButtonPrimary}
            shape="rounded"
            onClick={() => form.submit()}
          >
            确认授权
          </Button>
        </div>
      </div>
    );
  };

  const initUserInfo = async () => {

    initDataRef.current = {
      userName: userInfo?.userName || '',
      mobile: userInfo?.userMobile || '',
      // alipayAccount: '',
    };
    resetForm();
  };

  useEffect(() => {
    initUserInfo();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.operation}>
        <div className={styles.call} onClick={handleCall} />
        <div className={styles.open} onClick={handleOpen}>
          {renderOpenText()}
        </div>
      </div>
      <Modal
        bodyClassName={styles.roleModal}
        title="请选择你的角色"
        visible={modalVisible}
        content={renderModalContent()}
        onClose={() => setModalVisible(false)}
        showCloseButton
      />
      <Popup
        visible={authPopupVisible}
        bodyStyle={{
          height: "44vh",
          borderRadius: "16px 16px 0 0",
        }}
      >
        {renderAuthPopup()}
      </Popup>
    </div>
  );
};

export default OpenArriveStore;
