import { Button } from "antd-mobile";
import React from "react";

import "./index.scss";
import usePageTitle from "@/hooks/usePageTitle";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";
const TestWay: React.FC = (props) => {
  usePageTitle({ title: "测试链接集合" });
  const [corpId, clientId] = useUserStore(
    useShallow((state) => [state.corpId, state.clientId])
  );

  const origin = window.location.origin;

  const data = [
    // {
    //   name: 'H5首页',
    //   url: 'https://n.dingtalk.com/dingding/ding-edu-life-home/city/index.html?cityCode=340200&corpId=ding89550f75d92db09824f2f5cc6abecb85&preview=true',
    // },
    {
      name: "到店",
      url: `${origin}/arriveStore?app_type=h5&corpId=${corpId}`,
    },
    {
      name: "我的",
      url: `${origin}/my?app_type=h5&corpId=${corpId}`,
    },
    {
      name: "本地生活",
      url: `${origin}/locallife?app_type=h5&corpId=${corpId}`,
    },
    {
      name: "商城",
      url: `${origin}/mall?app_type=h5&corpId=${corpId}`,
    },
    {
      name: "H5登录",
      url: `${origin}/loginPage`,
    }
  ];

  return (
    <div>
      <div className="title">竭诚为我们优秀的测试服务</div>
      <div className="title">(用户版)</div>
      {data.map((item) => (
        <div className="button-item" key={item.name}>
          <Button
            block
            color="primary"
            size="large"
            onClick={() => {
              const url = new URL(item.url);
              clientId && url.searchParams.set("clientId", clientId);
              window.open(url.href, "_blank");
            }}
          >
            {item.name}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default TestWay;
