import axios from "@/common/Utils/APIClient";

/**
 * 账户状态
 */
export enum AccountTypeEnum {
  /**
   * 已开户
   */
  OPENED = 10,
  /**
   * 已绑定
   */
  BIND = 20,
  /**
   * 已冻结
   */
  BLOCK = 30,
  /**
   * 已注销
   */
  CLOSED = 40,
  /**
   * 组织未开通
   */
  NO_ENTERPRISE = 50,
  /**
   * 未绑定
   */
  NO_BIND = 60,
}

interface IGetAccountsStatusRes {
  code: string;
  data: AccountTypeEnum;
  msg: string;
  success: boolean;
}

/**
 * 获取账户状态
 */
export const getAccountsStatus = (): Promise<IGetAccountsStatusRes> => {
  return axios.get("/dmall_account/Account/accountsStatus");
};

/**
 * 获取企业码绑定状态
 */
export const getEnterpriseCodeShow = (params: { corpId?: string; }): Promise<boolean> => {
  return axios
    .get('/dmall-account/platform/enterprise/show/my/qym', { param: params })
    .then((res: any) => {
      return res?.data;
    });
};

/**
 * 获取企业码绑定状态
 */
export const getEnterpriseCodeStatus = () => {
  return axios
    .get('/dmall-account/arrive/enterprise/get/info')
    .then((res: any) => {
      return res?.data || {};
    });
};
