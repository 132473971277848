import { useEffect, useCallback, useRef } from 'react';

type PageReturnCallback = () => void;

export const usePageReturn = (callback: PageReturnCallback) => {
  // 使用 useCallback 缓存回调函数，避免不必要的重新创建
  const memoizedCallback = useCallback(callback, [callback]);
  const wasHidden = useRef(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        wasHidden.current = true;
      } else if (wasHidden.current) {
        // 如果之前是隐藏状态，现在变为可见，说明是从其他页面返回
        wasHidden.current = false;
        memoizedCallback();
      }
    };

    // 添加事件监听
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // 清理函数
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [memoizedCallback]);
}; 