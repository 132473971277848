import { TextArea } from "antd-mobile";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import styles from "./index.module.scss";
import {
  IDingLocateInMap,
  IDingLocateInMapRes,
  locateInMap,
} from "@/services/dd/locateInMap";

interface PopupMapProps {
  value?: IDingLocateInMapRes;
  onChange?: (date?: IDingLocateInMapRes) => void;
  render?: (address?: string, value?: IDingLocateInMapRes) => React.ReactNode;
  className?: string;
}
export interface PopupMapDDRef {
  open: () => void;
}
const PopupMap: React.ForwardRefRenderFunction<PopupMapDDRef, PopupMapProps> = (
  props,
  ref
) => {
  const { value, onChange, className, render } = props;

  const onOK = useCallback(
    async (data: IDingLocateInMapRes) => {
      await (onChange && onChange(data));
      setDefaultAddress(data);
    },
    [onChange]
  );
  const [defaultAddress, setDefaultAddress] = useState<
    IDingLocateInMapRes | undefined
  >(value);

  useImperativeHandle(
    ref,
    () => {
      return {
        open: () => {
          const data: IDingLocateInMap = {
            scope: 500,
            latitude: defaultAddress?.latitude
              ? Number(defaultAddress?.latitude)
              : undefined,
            longitude: defaultAddress?.longitude
              ? Number(defaultAddress?.longitude)
              : undefined,
          };
          locateInMap(data)
            .then((res) => {
              onOK(res);
            })
            .catch((e) => {});
        },
      };
    },
    [onOK, defaultAddress]
  );
  useEffect(() => {
    setDefaultAddress(value);
  }, [value]);
  const showText = useMemo(() => {
    const addressArr = [];
    if (defaultAddress?.province) {
      addressArr.push(defaultAddress.province);
    }
    if (defaultAddress?.city) {
      addressArr.push(defaultAddress.city);
    }
    if (defaultAddress?.adName) {
      addressArr.push(defaultAddress.adName);
    }
    if (defaultAddress?.snippet) {
      addressArr.push(defaultAddress.snippet);
    }
    return addressArr?.join("");
  }, [defaultAddress]);
  return (
    <div className={className}>
      {render ? (
        render(showText, defaultAddress)
      ) : (
        <TextArea
          autoSize
          className={styles.fontSize28}
          placeholder="请选择地址"
          readOnly
          value={showText}
        />
      )}
    </div>
  );
};

export default React.forwardRef(PopupMap);
