import React, { useMemo } from "react";
import { Empty, Grid, InfiniteScroll } from "antd-mobile";
import styles from "./index.module.scss";
import Icon from "@/common/Component/Icon";
import classNames from "classnames";

export interface IColumn {
  title: string;
  dataIndex: string;
  span?: number;
  render?: (value: string, record: any, index: number) => React.ReactNode;
  align?: "left" | "center" | "right";
}
interface TableProps {
  columns: IColumn[];
  dataSource: any[];
  className?: string;
  rowClick?: (record: any, index: number) => void;
  loadMore?: (isRetry: boolean) => Promise<void>;
  hasMore?: boolean;
  bodyClassName?: string;
}

const Table: React.FC<TableProps> = (props) => {
  const {
    columns,
    dataSource,
    className,
    rowClick,
    loadMore,
    hasMore = false,
    bodyClassName,
  } = props;
  const columnsNo = useMemo(() => {
    return columns.reduce((pre, cur) => pre + (cur.span || 1), 0);
  }, [columns]);
  return (
    <div className={classNames(styles.table, className)}>
      <Grid className={styles.tableHeader} columns={columnsNo}>
        {columns.map((item) => (
          <Grid.Item
            span={item?.span || 1}
            className={styles.tableHeaderItem}
            key={item.dataIndex}
            style={{ textAlign: item.align }}
          >
            {item.title}
          </Grid.Item>
        ))}
      </Grid>
      <div className={classNames(styles.tableBody, bodyClassName)}>
        {dataSource?.map((item, idx) => (
          <div
            className={styles.tableRow}
            key={idx}
            onClick={() => rowClick?.(item, idx)}
          >
            <Grid
              className={styles.tableRowContent}
              columns={columnsNo}
              // key={item.date}
            >
              {columns.map((column, index) => (
                <Grid.Item
                  span={column?.span || 1}
                  className={styles.tableCol}
                  key={column.dataIndex}
                  style={{ textAlign: column.align }}
                >
                  {column.render ? (
                    column.render(item[column.dataIndex], item, idx)
                  ) : (
                    <span>{item[column.dataIndex]}</span>
                  )}

                  {index === columns.length - 1 && !!rowClick && (
                    <Icon className={styles.icon} icon="icon-fanhui" />
                  )}
                </Grid.Item>
              ))}
            </Grid>
          </div>
        ))}
        {!dataSource?.length && <Empty description="暂无数据" />}
        {!!dataSource?.length && !!loadMore && (
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        )}
      </div>
    </div>
  );
};

export default React.memo(Table);
