import apiClient from "src/common/Utils/APIClient";

export const openArriveStore = (params: any) => {
  return apiClient
    .get('/dmall-account/arrive/enterprise/open/arrive', {
      param: params,
    })
    .then((res: any) => {
      return res?.data || {};
    });
};

export const checkDefaultEnterprise = () => {
  return apiClient
    .get('/dmall-account/platform/enterprise/check/default')
    .then((res: any) => {
      return res?.data;
    });
};
