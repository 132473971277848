import {
  Form,
  ImageUploader,
  ImageUploadItem,
  Input,
  Radio,
  Toast,
  Image,
  ImageViewer,
} from "antd-mobile";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import copy from "copy-to-clipboard";
import styles from "./index.module.scss";
import Buttons from "@/common/Component/Button";
import { OSSUpload, UploadDirEnum } from "@/common/Utils/OssSignature";
import { AddOutline } from "antd-mobile-icons";
import StatusInfo from "../../../../../../common/Component/StatusInfo";
import {
  EnterpriseCodeStatusEnum,
  IApplyResData,
} from "@/pages/Enterprise/services/enterpriseApplyGet";
import { useRequest } from "ahooks";
import { agentOperationApply } from "@/pages/ArriveStore/service/agentOperationApply";
import PageLoading from "@/common/Component/PageLoading";
const stepList = [
  "登录支付宝企业码",
  "选择公司",
  "切换身份-选择企业",
  "切换到账户中心",
  "在“账户中心”查看“公司信息”里的企业ID",
];
enum EAgentOperation {
  /**
   * 我已开通企业码服务
   */
  AlreadyOpened = 1,
  /**
   * 我有支付宝账号
   */
  HaveAlipay = 2,
  /**
   * 我没有支付宝账号
   */
  NoAlipay = 3,
}
const dataSource = [
  // {
  //   label: "我已开通企业码服务",
  //   value: EAgentOperation.AlreadyOpened,
  // },
  {
    label: "我有支付宝企业账号",
    value: EAgentOperation.HaveAlipay,
  },
  {
    label: "我没有支付宝企业账号",
    value: EAgentOperation.NoAlipay,
  },
];

interface AgentOperationProps {
  data?: IApplyResData;
  setHideSubmit?: (v: boolean) => void;
  onFinish: (v: any) => void;
}
export interface IAgentOperationRef {
  submit: () => void;
}
const AgentOperation: React.ForwardRefRenderFunction<
  IAgentOperationRef,
  AgentOperationProps
> = (props, ref) => {
  const { setHideSubmit, data, onFinish } = props;
  const [status, setStatus] = useState<
    "init" | "success" | "fail" | "wait" | undefined
  >(undefined);
  const [form] = Form.useForm();
  const [visibleImageViewer, setVisibleImageViewer] = useState(false);

  const type: EAgentOperation = Form.useWatch("type", form);
  const ossUpload = useRef(new OSSUpload({ uploadDir: UploadDirEnum.Test }));
  useEffect(() => {
    // 获取oss签名
    ossUpload.current.getOssSignature();
  }, []);
  const upload = (file: File): Promise<ImageUploadItem> =>
    new Promise((resolve, reject) => {
      ossUpload.current
        .upload(file)
        .then((res) => {
          if (res.success) {
            resolve({
              url: res.url || "",
            });
          } else {
            reject(new Error("上传失败"));
          }
        })
        .catch((e) => {
          reject(new Error("上传失败"));
        });
    });
  useImperativeHandle(
    ref,
    () => {
      return {
        submit: () => {
          form.submit();
        },
      };
    },
    [form]
  );
  useEffect(() => {
    if (
      data?.enterpriseCodeStatus ===
      EnterpriseCodeStatusEnum.PENDING_PLATFORM_REVIEW
    ) {
      setStatus("wait");
      setHideSubmit && setHideSubmit(false);
      return;
    }
    if (data?.enterpriseCodeStatus === EnterpriseCodeStatusEnum.REVIEW_FAILED) {
      setStatus("fail");
      setHideSubmit && setHideSubmit(false);
      return;
    }
    if (data?.enterpriseCodeStatus === EnterpriseCodeStatusEnum.REVIEW_PASSED) {
      setStatus("success");
      setHideSubmit && setHideSubmit(false);
      return;
    }
    setStatus("init");
    setHideSubmit && setHideSubmit(true);
  }, [data]);

  return (
    <>
      {status === "init" && (
        <>
          <Form
            layout="horizontal"
            form={form}
            initialValues={{
              type: EAgentOperation.HaveAlipay,
            }}
            className={styles.form}
            onFinish={onFinish}
          >
            <Form.Item
              rules={[{ required: true, message: "必填" }]}
              name="type"
              label="我公司在支付宝"
              layout="vertical"
              className={styles.type}
            >
              <Radio.Group
                onChange={(v) => {
                  setHideSubmit &&
                    setHideSubmit(v === EAgentOperation.HaveAlipay);
                }}
              >
                <div className={styles.radioGroup}>
                  {dataSource?.map((item, index) => {
                    return (
                      <Radio
                        className={styles.radio}
                        value={item.value}
                        key={item.value}
                      >
                        {item.label}
                      </Radio>
                    );
                  })}
                </div>
              </Radio.Group>
            </Form.Item>
            {/* {type === EAgentOperation.AlreadyOpened && (
          <Form.Item
            rules={[{ required: true, message: "必填" }]}
            label="支付宝企业账户"
            name="alipayAccount"
          >
            <Input clearable placeholder="请填写支付宝企业账户" />
          </Form.Item>
        )} */}
            {type === EAgentOperation.HaveAlipay && (
              <Form.Item
                rules={[{ required: true, message: "必填" }]}
                label="企业码企业ID"
                name="enterpriseCodeAccount"
              >
                <Input clearable placeholder="请填写企业码企业ID" />
              </Form.Item>
            )}
            {type === EAgentOperation.NoAlipay && (
              <>
                {/* <Form.Item
              rules={[{ required: true, message: "必填" }]}
              label="管理账户"
              name="manageAccount"
            >
              <Input clearable placeholder="请填写未注册支付宝的邮箱" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label={
                <Buttons
                  color="primary"
                  fill="none"
                  onClick={() => {
                    Toast.show({
                      content: "开发中",
                      duration: 1000,
                    });
                  }}
                >
                  下载开通函模板
                </Buttons>
              }
              className={styles.openLetter}
              rules={[{ required: true, message: "必填" }]}
              name="openLetter"
            >
              <ImageUploader
                beforeUpload={(file) =>
                  ossUpload.current.beforeUpload(file, {
                    hasCompressor: true,
                  })
                }
                // style={{ "--cell-size": "90px" }}
                maxCount={1}
                showFailed={false}
                upload={async (file) => {
                  const res = await upload(file);

                  return res;
                }}
                className={styles.upload}
              >
                <div className={styles.addUpload}>
                  <AddOutline className={styles.addIcon} />
                  <div className={styles.addUploadText}>
                    上传已盖章的企业码授权函
                  </div>
                </div>
              </ImageUploader>
            </Form.Item> */}
                <Form.Item>
                  <div className={styles.info}>
                    <div className={styles.title}>
                      请复制以下地址在电脑完成注册后再开通企业码代运营
                    </div>
                    <div className={styles.text}>https://b.alipay.com/</div>
                  </div>
                  <Buttons
                    fill="none"
                    color="primary"
                    onClick={() => {
                      copy("https://b.alipay.com/");
                    }}
                  >
                    复制网址
                  </Buttons>
                </Form.Item>
              </>
            )}
          </Form>
        </>
      )}
      {status === "init" && type === EAgentOperation.HaveAlipay && (
        <div className={styles.promptInfo}>
          <div className={styles.promptInfoTitle}>
            <img
              alt=""
              className={styles.promptInfoImg}
              src="https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/%E4%BD%8D%E5%9B%BE%402x-f582b293-2a02-4eb9-92b3-b79c6a2e0579.png"
            />
            企业ID查询步骤
          </div>
          <div className={styles.promptInfoList}>
            {stepList?.map((item, index) => (
              <div className={styles.stepItem}>
                {index + 1}、{item}
              </div>
            ))}
          </div>
          <div
            onClick={() => {
              setVisibleImageViewer(true);
            }}
          >
            <Image
              className={styles.promptImage}
              src="https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/%E7%BC%96%E7%BB%84%2029%402x-270f7e90-4eb3-4622-a172-2779278e093f.png"
            />
            <div className={styles.promptImageInfo}>点击查看大图</div>
          </div>

          <ImageViewer
            classNames={{
              mask: "customize-mask",
              body: "customize-body",
            }}
            image={
              "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/%E7%BC%96%E7%BB%84%2029%402x-270f7e90-4eb3-4622-a172-2779278e093f.png"
            }
            visible={visibleImageViewer}
            onClose={() => {
              setVisibleImageViewer(false);
            }}
          />
        </div>
      )}
      {status === "wait" && (
        <StatusInfo
          status="wait"
          title="您的企业码代运营申请中！"
          // message={
          //   <div>
          //     <div>请复制以下地址在电脑完成企业码入驻签约</div>
          //     <div>https://www.baidu.com</div>
          //   </div>
          // }
        >
          {/* <Buttons
            fill="none"
            color="primary"
            onClick={() => {
              copy("https://www.baidu.com");
              Toast.show({
                content: "复制成功",
              });
            }}
          >
            复制网址
          </Buttons> */}
        </StatusInfo>
      )}
      {status === "success" && (
        <StatusInfo
          status="success"
          title="您的企业码代运营申请成功！"
          message="您的企业码已经在代运营中！"
        />
      )}
      {status === "fail" && (
        <StatusInfo
          status="fail"
          title="您的企业码代运营申请被拒绝！"
          message="您的企业码代运营申请失败，如需申请，请重新填写支付宝企业账户！"
        >
          <Buttons
            fill="none"
            color="primary"
            onClick={() => {
              // Toast.show({
              //   content: "重新填写",
              //   position: "center",
              // });
              setStatus("init");
              setHideSubmit?.(true);
            }}
          >
            重新填写
          </Buttons>
        </StatusInfo>
      )}
    </>
  );
};

export default React.forwardRef(AgentOperation);
