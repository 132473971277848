import React, { useMemo, useRef, useState } from "react";
import styles from "./index.module.scss";
import {
  Image,
  IndexBar,
  SafeArea,
  SearchBar,
  Space,
  SwipeAction,
  Toast,
} from "antd-mobile";
import { Action } from "antd-mobile/es/components/swipe-action";
import Icon from "@/common/Component/Icon";
import { AddOutline } from "antd-mobile-icons";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import Modal, { IModalRef } from "../../components/Modal";
import { useRequest } from "ahooks";
import { getGroup, PartnerDtoList } from "../../services/getGroup";
import { PersonalCooperationStatusEnum } from "../../enum";
import { setAdmin } from "../../services/setAdmin";
import { setCoopera } from "../../services/setCoopera";
import PageLoading from "@/common/Component/PageLoading";

interface ManageStaffProps {}

enum ActionEnum {
  /** 编辑资料 */
  EDIT = "EDIT",
  /** 停用合作 */
  STOP = "STOP",
  /** 启用合作 */
  START = "START",
  /** 设置管理员 */
  SET_ADMIN = "SET_ADMIN",
  /** 取消管理员*/
  CANCEL_ADMIN = "CANCEL_ADMIN",
}

/**
 *
 * 管理员工
 */
const ManageStaff: React.FC<ManageStaffProps> = (props) => {
  // const {} = props;
  const openUrl = useOpenUrl();
  const modalRef = useRef<IModalRef>(null);
  const [searchName, setSearchName] = useState("");
  const {
    data: groupData,
    loading: groupLoading,
    refresh: getGroupRunRefresh,
  } = useRequest(getGroup);
  const { runAsync: setAdminRunAsync, loading: setAdminLoading } = useRequest(
    setAdmin,
    {
      manual: true,
    }
  );
  const { runAsync: setCooperaRunAsync, loading: setCooperaLoading } =
    useRequest(setCoopera, {
      manual: true,
    });

  const list = useMemo(() => {
    const group = groupData?.data?.map((item) => {
      const items = item.partnerDtoList
        ?.map((item) => {
          return {
            name: item.partnerPerson || "",
            phone: item.partnerPersonMobile,
            data: item,
          };
        })
        ?.filter((item) => {
          return (
            item.name.includes(searchName) || item.phone.includes(searchName)
          );
        });
      return {
        title: item.groupName,
        items,
      };
    });
    return group || [];
  }, [groupData, searchName]);

  const rightActions: Action[] = [
    {
      key: ActionEnum.SET_ADMIN,
      text: "设为管理员",
      color: "#06233A",
    },
    {
      key: ActionEnum.CANCEL_ADMIN,
      text: "取消管理员",
      color: "#F2302F",
    },
    {
      key: ActionEnum.EDIT,
      text: "修改资料",
      color: "#1777FF",
    },
    {
      key: ActionEnum.STOP,
      text: "停止合作",
      color: "#F2302F",
    },
    {
      key: ActionEnum.START,
      text: "继续合作",
      color: "#0FC6C2",
    },
  ];
  const handleAdd = () => {
    openUrl({
      url: "/partner/manage/staff/info",
      isOpenHalf: false,
      isRoute: true,
      params: {
        // partnerId: "",
        status: "add",
      },
    });
  };
  const onAction = (action: Action, data: PartnerDtoList) => {
    console.log("action", action);
    if (action.key === ActionEnum.EDIT) {
      openUrl({
        url: `/partner/manage/staff/info`,
        isOpenHalf: false,
        isRoute: true,
        params: {
          partnerId: data.partnerId,
          status: "edit",
        },
      });
    }
    if (action.key === ActionEnum.STOP || action.key === ActionEnum.START) {
      let title = "";
      let text = "";
      if (action.key === ActionEnum.STOP) {
        title = `确定与${data.partnerName}停止合作吗？`;
        text = "停止合作后，该成员将无法继续推广并获得佣金。";
      } else {
        title = `确定与${data.partnerName}继续合作吗？`;
        text = "继续合作后，该成员将恢复推广并获得佣金。";
      }

      const modal = modalRef.current?.open?.({
        title,
        content: text,
        onOk: () => {
          setCooperaRunAsync({
            status: action.key === ActionEnum.STOP ? 0 : 1,
            partnerId: data.partnerId,
          }).then((res) => {
            if (res.data && res.success) {
              getGroupRunRefresh();

              Toast.show({
                content: "操作成功",
              });
              modal?.close();
            }
          });
        },
        onCancel: () => {
          modal?.close();
        },
      });
    }
    if (
      action.key === ActionEnum.CANCEL_ADMIN ||
      action.key === ActionEnum.SET_ADMIN
    ) {
      let title = "";
      let text = "";
      if (action.key !== ActionEnum.CANCEL_ADMIN) {
        title = "确定取消管理员权限？";
        text = "设置成为管理员后将拥有管理成员及企业数据的权限。";
      } else {
        title = `确定将该成员设为管理员？`;
        text = "取消管理员权限后，该成员将无法管理成员及企业数据。";
      }
      const modal = modalRef.current?.open?.({
        title,
        content: text,
        onOk: () => {
          setAdminRunAsync({
            status: action.key === ActionEnum.CANCEL_ADMIN ? 0 : 1,
            partnerId: data.partnerId,
          }).then((res) => {
            if (res.data && res.success) {
              Toast.show({
                content: "操作成功",
              });
              getGroupRunRefresh();
              modal?.close();
            }
          });
        },
        onCancel: () => {
          modal?.close();
        },
      });
    }
  };
  const goPerson = (partnerId: string, partnerPerson?: string) => {
    openUrl({
      url: "/partner/manage/staff/personal",
      isOpenHalf: false,
      isRoute: true,
      params: {
        partnerId,
        partnerPerson,
      },
    });
  };
  return (
    <div className={styles.page}>
      <PageLoading loading={groupLoading} />
      <div className={styles.topSearch}>
        <SearchBar
          showCancelButton
          placeholder="搜索子合伙人"
          className={styles.searchBar}
          onSearch={setSearchName}
          // value={searchName}
        />
      </div>
      <div className={styles.content}>
        <IndexBar className={styles.indexBar}>
          {list.map((group, index) => {
            const { title, items } = group;
            return (
              <IndexBar.Panel index={title} title={title} key={index}>
                <div className={styles.personList}>
                  {items.map((item, idx) => {
                    const actions = rightActions?.filter((li) => {
                      if (
                        item.data.isAdmin &&
                        li.key === ActionEnum.SET_ADMIN
                      ) {
                        return false;
                      }
                      if (
                        !item.data.isAdmin &&
                        li.key === ActionEnum.CANCEL_ADMIN
                      ) {
                        return false;
                      }
                      if (
                        item.data.status ===
                          PersonalCooperationStatusEnum.Cooperation &&
                        li.key === ActionEnum.START
                      ) {
                        return false;
                      }
                      if (
                        item.data.status ===
                          PersonalCooperationStatusEnum.StopCooperation &&
                        li.key === ActionEnum.STOP
                      ) {
                        return false;
                      }
                      return true;
                    });
                    return (
                      <SwipeAction
                        key={idx}
                        rightActions={!item?.data?.isSelf ? actions : []}
                        onAction={(action) => onAction(action, item.data)}
                        className={styles.swipeAction}
                      >
                        <div
                          className={styles.person}
                          onClick={() => {
                            // console.log("item", 11);
                            goPerson(
                              item.data.partnerId,
                              item.data.partnerPerson
                            );
                          }}
                        >
                          <div className={styles.personInfo}>
                            <div className={styles.personInfoContentLeft}>
                              {item?.data?.avatar ? (
                                <Image
                                  className={styles.avatar}
                                  src={
                                    item?.data?.avatar ||
                                    "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/0e6ac4d7b4ff47b6987b3c8a738bcc0e7dce6fc2.png"
                                  }
                                />
                              ) : (
                                <div className={styles.avatarName}>
                                  {item?.name?.substring(0, 1)}
                                </div>
                              )}
                              <div className={styles.personInfoContent}>
                                <Space className={styles.personInfoTitle}>
                                  <span className={styles.personInfoName}>
                                    {item.name}
                                  </span>
                                  {item.data.isAdmin && (
                                    <span className={styles.adminTip}>
                                      管理员
                                    </span>
                                  )}
                                  {item.data.isSelf && (
                                    <span className={styles.myTip}>我</span>
                                  )}
                                  {item.data.status ===
                                    PersonalCooperationStatusEnum.StopCooperation && (
                                    <span className={styles.stopTip}>
                                      停止合作
                                    </span>
                                  )}
                                </Space>
                                <div className={styles.personInfoSubTitle}>
                                  <span>{item.phone}</span>
                                </div>
                              </div>
                            </div>
                            <Icon
                              className={styles.personInfoActionIcon}
                              icon="icon-fanhui"
                            />
                          </div>
                        </div>
                      </SwipeAction>
                    );
                  })}
                </div>
              </IndexBar.Panel>
            );
          })}
        </IndexBar>
        <div style={{ background: "#f5f5f5" }}>
          <SafeArea position="bottom" />
        </div>
      </div>
      <div className={styles.action} onClick={() => handleAdd()}>
        <AddOutline className={styles.icon} />
      </div>
      <Modal ref={modalRef} />
    </div>
  );
};

export default React.memo(ManageStaff);
