import apiClient, { IBaseRes } from "src/common/Utils/APIClient";

/**
 * 获取短信验证码
 */
export function getSmsVerificationCode(phone: string): Promise<IBaseRes<any>> {
  return apiClient.get('/dmall_customer/ua/buyer/sendSmsCode', {
    param: {
      phone,
      type: 5,
    },
  });
}

/**
 * 检查手机号是否已注册
 */
export function checkPhoneRegistered(phone: string): Promise<IBaseRes<any>> {
  return apiClient.get('/dmall-auth/ua/is_registered', {
    param: {
      phone,
      sysType: 0,
    },
  });
}

interface registerParam {
  nickName: string;
  phone: string;
  verificationCode: string;
  password: string;
}

/**
 * 用户注册
 */
export function register(params: registerParam): Promise<IBaseRes<any>> {
  return apiClient.post('/dmall_customer/ua/buyer/register', params);
}