import { Form, Input, Button, Toast } from 'antd-mobile';
import { UserOutline } from 'antd-mobile-icons';
import useUserStore from '@/store/global';
import { useShallow } from 'zustand/react/shallow';
import { bindAlipay } from '../../service';
import styles from './index.module.scss';

interface IProps {
  onSuccess: () => void;
}

const App = (props: IProps) => {
  const { onSuccess } = props;
  const [form] = Form.useForm();
  const [userInfo, corpId] = useUserStore(
    useShallow((state) => [
      state.userInfo,
      state.corpId,
    ])
  );

  const renderAvatar = () => {
    if (userInfo?.userAvatar) {
      return <img src={userInfo.userAvatar} alt="" />;
    } else if (userInfo?.userName) {
      return <div className={styles.avatarText}>{userInfo.userName.slice(0, 1)}</div>;
    }

    return <div className={styles.avatarLoading}><UserOutline fontSize={30} /></div>;
  };

  const handleFinish = async (values: any) => {
    Toast.show({
      icon: 'loading',
      content: '正在提交...',
      duration: 0,
    });
    const res = await bindAlipay({
      ...values,
      corpId,
    });

    Toast.clear();
    if (res?.success && res?.data?.alipaySignUrl && res?.data?.qrcode) {
      onSuccess();
    } else {
      Toast.show({
        icon: 'fail',
        content: res?.msg || res?.data?.result || '绑定失败',
      });
    }
  };

  const handleSubmit = () => {
    form.submit();
  };

  const handleCancel = () => {
    window.history.back();
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.name}>{userInfo?.corpName}</div>
        <div className={styles.desc}>已开通企业码支付，请确认绑定账号</div>
        <div className={styles.avatar}>
          {renderAvatar()}
        </div>
        <div className={styles.phone}>{userInfo?.userMobile}</div>
      </div>
      <div className={styles.form}>
        <Form
          layout="horizontal"
          form={form}
          style={{
            '--border-top': 'none',
            '--border-bottom': 'solid 1px #F2F3F5',
            '--border-inner': 'solid 1px #F2F3F5',
          }}
          onFinish={handleFinish}
        >
          <Form.Header>
            <div className={styles.formTitle}>绑定授权后，即可享用折扣</div>
          </Form.Header>
          <Form.Item
            label="支付宝账号"
            name="alipayAccount"
            required
            rules={[{ required: true, message: '请输入支付宝账号' }]}
          >
            <Input placeholder="请输入支付宝账号" clearable />
          </Form.Item>
          <Form.Item
            label="员工姓名"
            name="userName"
            required
            rules={[{ required: true, message: '请输入员工姓名' }]}
          >
            <Input placeholder="请输入员工姓名" clearable />
          </Form.Item>
        </Form>
      </div>
      <div className={styles.btnContainer}>
        <Button
          className={styles.primaryBtn}
          color="primary"
          shape="rounded"
          onClick={handleSubmit}
          block
        >
          确认信息并绑定
        </Button>
        <Button
          className={styles.secondaryBtn}
          shape="rounded"
          block
          onClick={handleCancel}
        >
          暂不绑定
        </Button>
      </div>
    </div>
  );
};

export default App;
