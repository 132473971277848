import axios, { IBaseRes } from "@/common/Utils/APIClient";

export enum EAuditType {
  /**
   * 餐补入驻
   */
  DELIVERY = 1,
  /**
   * 到店折扣
   */
  DISCOUNT = 2,
  /**
   * 企业码代运营
   */
  AGENT_OPERATION = 3,
}
interface IParams {
  auditType: EAuditType;
  /**
   * 企业id
   */
  enterpriseId: string;
}

interface IRes {
  /** 状态码 */
  status: number;
  /** 创建时间 */
  createAt: string;
  /** 更新时间 */
  updateAt: string;
  /** 租户ID */
  tanentId: number;
  /** 创建者ID */
  createBy: number;
  /** 更新者ID */
  updateBy: number;
  /** 企业审核ID */
  enterpriseAuditId: number;
  /** 企业ID */
  enterpriseId: number;
  /** 审核状态 */
  auditStatus: number;
  /** 审核人ID */
  auditBy: number;
  /** 审核日期 */
  auditDate: string;
  /** 审核描述 */
  auditDesc: string;
  /** 扩展信息 */
  extInfo: string;
  /** 审核类型 */
  auditType: number;
}

/**
 * 
审核最新记录
 */
export const auditLatest = (data: IParams): Promise<IBaseRes<IRes>> => {
  return axios.get("/dmall_account/Enterprise/auditLatest", { param: data });
};
