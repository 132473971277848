import axios from "src/common/Utils/APIClient";

import Compressor from "compressorjs";

import { Toast } from "antd-mobile";
interface IOssSignatureRes {
  credential: string;
  dir: string;
  host: string;
  ossdate: string;
  policy: string;
  signature: string;
  token: string;
  version: string;
}
export enum UploadDirEnum {
  /**
   * 测试
   */
  Test = "test",
}
interface IReq {
  /**
   * 文件上传目录
   */
  uploadDir: UploadDirEnum;
}

/**
 * OSS上传类
 */
export class OSSUpload {
  private ossOptions: IOssSignatureRes | undefined;
  private uploadDir: UploadDirEnum;
  /**
   * 最大上传大小
   */
  readonly maxSize = 2;
  constructor(param: IReq) {
    this.uploadDir = param.uploadDir;
  }
  /**
   * 获取oss签名
   * @param param
   */
  getOssSignature = async (): Promise<IOssSignatureRes | undefined> => {
    const data: IOssSignatureRes | undefined = await axios
      .get("/dmall-biz/aliyun/oss/signature", {
        param: { uploadDir: this.uploadDir },
      })
      .catch((e) => undefined);
    this.ossOptions = data;
    return data;
  };
  /**
   * 上传文件
   * @param file
   */
  upload = async (file: File): Promise<{ success: boolean; url?: string }> => {
    if (!this.ossOptions) {
      Toast.show({
        icon: "error",
        content: "未获取oss签名",
      });
      throw new Error("未获取oss签名");
    }
    const formData = new FormData();
    formData.append("success_action_status", "200");
    formData.append("policy", this.ossOptions?.policy);
    formData.append("x-oss-signature", this.ossOptions?.signature);
    formData.append("x-oss-signature-version", "OSS4-HMAC-SHA256");
    formData.append("x-oss-credential", this.ossOptions?.credential);
    formData.append("x-oss-date", this.ossOptions?.ossdate);
    formData.append("key", `${this.ossOptions.dir}/${file.name}`);
    formData.append("x-oss-security-token", this.ossOptions?.token);
    formData.append("file", file); // file 必须为最后一个表单域
    return axios
      .post(this.ossOptions.host?.replace("http://", "https://"), formData)
      .then(() => {
        return {
          success: true,
          url: `${this.ossOptions?.host?.replace("http://", "https://")}/${
            this.ossOptions?.dir
          }/${file.name}`,
        };
      })
      .catch((e) => ({ success: false }));
  };
  beforeUpload = async (
    file: File,
    config?: {
      quality?: number;
      // 单位M
      maxSize?: number;
      // 是否开启压缩
      hasCompressor?: boolean;
    }
  ): Promise<File | null> => {
    const { quality = 0.7, maxSize, hasCompressor } = config || {};
    let newFile: File = file;
    if (hasCompressor) {
      newFile = await new Promise((resolve, reject) => {
        new Compressor(file, {
          quality,
          convertSize: 5 * 1024 * 1024,
          success(result: Blob | File) {
            const newfile = new File([result], file.name);
            resolve(newfile);
          },
          error(err: any) {
            reject({ message: "文件压缩失败" });
            Toast.show({
              icon: "fail",
              content: err.message,
            });
          },
        });
      });
    }
    if (maxSize) {
      await new Promise((resolve, reject) => {
        if (newFile.size > maxSize * 1024 * 1024) {
          Toast.show({
            icon: "fail",
            content: `文件大小不能超过${maxSize}M`,
          });
          reject(new Error(`文件大小不能超过${maxSize}M`));
        } else {
          resolve(newFile);
        }
      });
    }
    return newFile;
  };
}
