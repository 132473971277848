import axios from "@/common/Utils/APIClient";
import { IAddressPOI } from "@/types/address";
export interface IApplyReq {
  /**
   * 申请入驻的组织id
   */
  applyOrgId: string;
  /**
   * 是否脱敏
   */
  hasDesensitize?: boolean;
}
/**
 * 审核状态
 */
export enum ApplyStatusEnum {
  // /**
  //  * 不通过
  //  */
  // REFUSE = 0,
  // /**
  //  * 通过
  //  */
  // PASS = 1,
  /**
   * 协议待签署
   */
  SIGN_CONTRACT = 5,
  /**
   * 填写中
   */
  WATING_ESIGN_FILL = 6,
  /**
   * 签署中
   */
  WATING_ESIGN_SEAL = 7,
  /**
   * 新申请
   */
  PENDING_PLATFORM_REVIEW = 10,
  /**
   * 退回
   */
  REVIEW_FAILED = 30,
  /**
   * 通过
   */
  REVIEW_PASSED = 60,
}
/**
 * 企业码状态
 */
export enum EnterpriseCodeStatusEnum {
  // PENDING_PLATFORM_REVIEW("新申请", 10),
  //   REVIEW_FAILED("已退回", 30),
  //   REVIEW_PASSED("已通过", 60),
  /**
   * 新申请
   */
  PENDING_PLATFORM_REVIEW = 10,
  /**
   * 退回
   */
  REVIEW_FAILED = 30,
  /**
   * 通过
   */
  REVIEW_PASSED = 60,
}
/**
 * 企业状态
 */
export enum StatusEnum {
  /**
   * 待审核
   */
  PendingPlatformReview = 10,
  /**
   * 审核不通过
   */
  ReviewFailed = 30,
  /**注销*/
  Cancelled = 40,
  /**
   * 作废
   */
  Void = 50,
  /**
   * 审核通过
   */
  ReviewPassed = 60,
  /**
   * 账簿已开通
   */
  AccountBookOpened = 70,
  /**
   * 账簿已冻结
   */
  AccountBookBlock = 80,
}
export interface IApplyRes {
  code: string;
  data: IApplyResData;
  msg: string;
  success: boolean;
}

export interface IApplyResData {
  /** 企业id */
  enterpriseId: string;
  /** 企业编码 */
  enterpriseCd: string;
  /** 企业名称 */
  enterpriseName: string;
  /** 社会信用代码 */
  creditCode: string;
  /**
   * 法人姓名
   */
  legalPersonName: string;
  /**
   * 法人手机号
   */
  legalPersonPhone: string;
  /** 营业执照 */
  creditCert: string;
  /** 管理员姓名 */
  adminName: string;
  /** 管理员手机号 */
  adminMobile: string;
  /** 联系人 */
  contactName: string;
  /** 手机号 */
  contactPhone: string;
  /** 联系人证件 */
  contactCert: string;
  /** 餐补账户ID */
  accountId: number;
  /** 餐补卡号 */
  bankCardNo: string;
  /** 注册组织/员工ID */
  applyOrgId: string;
  /** 注册时间 */
  applyTime: string;
  /** 申请状态 */
  applyStatus: ApplyStatusEnum;
  /**
   * 申请单备注
   */
  applyNote: string;
  /**
   * 扩展信息
   */
  extInfo: string;
  /**
   * 审核时间
   */
  reviewTime: string;
  /**
   * 审核人
   */
  reviewBy: number;
  /**
   * 状态
   */
  status: StatusEnum;
  /**
   * 创建时间
   */
  createAt: string;
  /**
   * 更新时间
   */
  updateAt: string;
  /**
   * 租户ID
   */
  tanentId: number;
  /**
   * 创建人
   */
  createBy: number;
  /**
   * 更新人
   */
  updateBy: number;
  /**
   * 企业简称
   */
  enterpriseShortName?: string;
  /**
   * 企业地址
   */
  enterpriseAddress?: string;
  enterpriseAddressPoi: IAddressPOI;
  /**
   * 合伙人推广吗
   */
  partnerUnionCode?: string;
  /** 企业折扣合同状态 */
  discountApplyStatus: ApplyStatusEnum;
  /** 企业折扣合同流程id */
  discountEsignFlowId: string;
  /** 企业码代运营申请状态 */
  enterpriseCodeStatus: EnterpriseCodeStatusEnum;
  /** 企业码账户 */
  enterpriseCodeAccount?: string;
}

/**
 * 通过组织id查询详情
 */
export const enterpriseApplyGet = (data: IApplyReq): Promise<IApplyRes> => {
  const { hasDesensitize, ...restValue } = data;
  const headers: any = {};
  if (hasDesensitize) {
    headers["Desensitize"] = "close";
  }
  return axios.get("/dmall-account/Enterprise/getByApplyOrgId", {
    param: restValue,
    headers,
  });
};
