import axios from "@/common/Utils/APIClient";

export enum AppCdEnum {
  /**
   * 饿了么
   */
  elm = "51851851990",
  /**
   * 365-喜茶
   */
  "365_heytea" = "365heytea",
  /**
   * 365-奈雪的茶
   */
  "365_nayuki" = "365nayuki",
  /**
   * 365-瑞幸
   */
  "365_luckin" = "365luckin",
  /**
   * 365-肯德基
   */
  "365_kfc" = "365kfc",
  /**
   * 365-麦当劳
   */
  "365_mc" = "365mc",
  /**
   * 365-星巴克
   */
  "365_sbk" = "365sbk",
  /**
   * 365-电影票
   */
  "365_ticket" = "365ticket",
  /**
   * 365-汉堡王
   */
  "365_burgerKing" = "365burgerKing",
  /**
   * 365-数字充值
   */
  "365_charge" = "365charge",
  /**
   * 365-库迪
   */
  "365_cotti" = "365cotti",
}
interface IReq {
  appCd: AppCdEnum;
  url?: string;
}
interface IGetAccountsStatusRes {
  code: string;
  data: string;
  msg: string;
  success: boolean;
}
/**
 * 获取三方点单入口
 */
export const getBizUrl = (params: IReq): Promise<IGetAccountsStatusRes> => {
  const { appCd, ...restParams } = params;
  return axios.get(`/dmall_payment/buyerPay/getBizUrl/${params.appCd}`, {
    param: restParams,
  });
};
