import React from "react";
import styles from "./index.module.scss";
import { Grid, Toast } from "antd-mobile";
import { useOpenUrl } from "@/hooks/useOpenUrl";
// import { useRequest } from "ahooks";
// import { getBizUrl, AppCdEnum } from "@/pages/ArriveStore/service/getBizUrl";

interface OrderOnlineProps {}

interface IList {
  title: string;
  // subTitle: string;
  img: string;
  key: string;
}
interface IItem {
  title: string;
  list: IList[];
}
/**
 * 在线点餐
 */
const OrderOnline: React.FC<OrderOnlineProps> = (props) => {
  const data: IItem[] = [
    {
      title: "品牌严选",
      list: [
        {
          title: "肯德基",
          // subTitle: "点餐9折起",
          img: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/logo-border-kfc-4a96e404-5899-406f-acf3-70abc3c30ecc.png",
          key: "365kfc",
        },
        {
          title: "麦当劳",
          // subTitle: "低至5折",
          img: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/logo-border-mcd-48b3638e-a2c6-437f-90a6-78d98b9ab0c4.png",
          key: "365mc",
        },
        {
          title: "汉堡王",
          // subTitle: "低至8折",
          img: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/logo-border-burger_king-097230b0-9b9b-480e-a5b4-27fe79d41b7f.png",
          key: "365burgerKing",
        },
        // {
        //   title: "必胜客",
        //   subTitle: "全场5折起",
        //   img: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/logo-border-pizza_hut-67c1355c-f5e5-4b61-a0ff-d63063382774.png",
        //   key: "",
        // },
        {
          title: "星巴克",
          // subTitle: "点餐8折起",
          img: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/logo-border-starbucks-26bc6eef-714f-42f1-8cb9-a4bd1506e802.png",
          key: "365sbk",
        },
        {
          title: "瑞幸咖啡",
          // subTitle: "5折起",
          img: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/logo-border-luckin-coffee-0978331a-18a5-4be3-b429-f395776c1b65.png",
          key: "365luckin",
        },
        {
          title: "喜茶",
          // subTitle: "5折起",
          img: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/logo-border-heytea-b797c612-f563-4083-b450-f1d96f9671d0.png",
          key: "365heytea",
        },
        {
          title: "奈雪的茶",
          // subTitle: "5折起",
          img: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/logo-border-nayuki-4d85a17c-9326-4b31-b201-0eb60e8e9c92.png",
          key: "365nayuki",
        },
        {
          title: "库迪咖啡",
          // subTitle: "5折起",
          img: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/logo-border-cotti-5387cb07-1151-45d6-a4ef-9cb89cc9af42.png",
          key: "365cotti",
        },
        // {
        //   title: "华莱士",
        //   subTitle: "5折起",
        //   img: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/logo-border-wallace-e5da487d-ded5-4b67-95b4-56d42f48f0a3.png",
        //   key: "",
        // },
        // {
        //   title: "库迪咖啡",
        //   subTitle: "5折起",
        //   img: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/logo-border-cotti-5387cb07-1151-45d6-a4ef-9cb89cc9af42.png",
        //   key: "",
        // },
      ],
    },
    // {
    //   title: "大牌畅饮",
    //   list: [
    //     {
    //       title: "瑞幸咖啡",
    //       subTitle: "5折起",
    //       img: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/logo-border-luckin-coffee-0978331a-18a5-4be3-b429-f395776c1b65.png",
    //       key: "365luckin",
    //     },
    //     {
    //       title: "喜茶",
    //       subTitle: "5折起",
    //       img: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/logo-border-heytea-b797c612-f563-4083-b450-f1d96f9671d0.png",
    //       key: "365heytea",
    //     },
    //     {
    //       title: "奈雪的茶",
    //       subTitle: "5折起",
    //       img: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/logo-border-nayuki-4d85a17c-9326-4b31-b201-0eb60e8e9c92.png",
    //       key: "365nayuki",
    //     },

    //     {
    //       title: "华莱士",
    //       subTitle: "5折起",
    //       img: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/logo-border-wallace-e5da487d-ded5-4b67-95b4-56d42f48f0a3.png",
    //       key: "",
    //     },
    //     {
    //       title: "库迪咖啡",
    //       subTitle: "5折起",
    //       img: "https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/logo-border-cotti-5387cb07-1151-45d6-a4ef-9cb89cc9af42.png",
    //       key: "365cotti",
    //     },
    //   ],
    // },
  ];

  return (
    <div className={styles.orderOnline}>
      <div className={styles.header}>
        <span className={styles.title}>超省钱</span>
        <span className={styles.subTitle}>
          <span>品质生活 天天低价</span>
        </span>
      </div>
      <div className={styles.content}>
        {data.map((item, index) => {
          return (
            <div className={styles.block} key={index}>
              <div className={styles.title}>{item.title}</div>
              <Grid columns={5} className={styles.list}>
                {item.list.map((item, index) => {
                  return <ActionItem item={item} key={index} />;
                })}
              </Grid>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const ActionItem = (props: { item: IList }) => {
  const { item } = props;
  const openUrl = useOpenUrl();
  // const { run } = useRequest(getBizUrl, {
  //   manual: true,
  //   debounceWait: 500,
  //   onSuccess: (res) => {
  //     if (res.data) {
  //       openUrl({ url: res.data, isRoute: false, isOpenHalf: false });
  //     }
  //   },
  // });
  // const key = item.key as AppCdEnum;

  const openApp = (item: IList) => {
    if (!item.key) {
      Toast.show({
        icon: "fail",
        content: "暂未开放",
      });
      return;
    }
    openUrl({
      url: `/tripartiteLogin/${item.key}`,
      isRoute: true,
      // options: {
      //   replace: true,
      // },
    });
  };
  return (
    <Grid.Item
      className={styles.item}
      onClick={() => {
        openApp(item);
      }}
    >
      <img className={styles.img} src={item.img} alt="" />
      <div className={styles.itemTitle}>{item.title}</div>
      {/* <div className={styles.itemSubTitle}>{item.subTitle}</div> */}
    </Grid.Item>
  );
};
export default React.memo(OrderOnline);
