import { useState, useEffect } from 'react';
import { getEnterpriseCodeShow, getEnterpriseCodeStatus } from '../service';

interface IActionItem {
  title: string;
  icon: string;
  link: {
    url: string;
    isRoute: boolean;
  };
  tip: string;
  onClick?: () => void;
}

type UseMyEnterpriseCodeResult = [IActionItem | null, boolean];

const useMyEnterpriseCode = (corpId?: string): UseMyEnterpriseCodeResult => {
  const [step, setStep] = useState(1);
  const [tip, setTip] = useState('');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    const [showRes, statusRes] = await Promise.all([
      getEnterpriseCodeShow({ corpId }),
      getEnterpriseCodeStatus()
    ]);

    setShow(showRes);
    setTip(statusRes.alipaySignStatus === 1 ? '已绑定' : '未绑定');
    setStep(statusRes.alipaySignStatus === 1 ? 2 : 1);
    setLoading(false);
  };

  // 组件挂载时获取数据
  useEffect(() => {
    fetchData();
  }, []);

  return [
    show ? {
      title: '我的企业码',
      icon: 'icon-wodeqiyema',
      tip,
      link: {
        url: `/apply/enterprise/code/${step}`,
        isRoute: true,
      },
    } : null,
    loading,
  ];
};

export default useMyEnterpriseCode;
