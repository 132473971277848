import axios from "@/common/Utils/APIClient";
interface IParams {
  phone: string;
}

export interface IVerificationCodeRes {
  success?: boolean;
  msg?: string;
  data?: {};
}

/**
 * 获取短信验证码
 */
export const getVerificationCode = (
  params: IParams
): Promise<IVerificationCodeRes> => {
  return axios.post(`/dmall_auth/ua/captcha/sms/${params?.phone}`);
};