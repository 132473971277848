import React, { useEffect } from "react";
import styles from "./index.module.scss";
import StatusInfo from "../../../../../../common/Component/StatusInfo";
import Buttons from "@/common/Component/Button";
import {
  IApplyResData,
  ApplyStatusEnum,
} from "@/pages/Enterprise/services/enterpriseApplyGet";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import {
  auditLatest,
  EAuditType,
} from "@/pages/ArriveStore/service/auditLatest";
import { useRequest } from "ahooks";

interface DiscountProps {
  data?: IApplyResData;
  // 初始化状态
}

const Discount: React.FC<DiscountProps> = (props) => {
  const { data } = props;
  const dataArr = [
    {
      label: "公司名称",
      value: data?.enterpriseName,
    },
    {
      label: "公司简称",
      value: data?.enterpriseShortName,
    },
  ];
  const openUrl = useOpenUrl();
  const { data: auditLatestData, run: auditLatestRun } = useRequest(
    auditLatest,
    { manual: true }
  );
  useEffect(() => {
    if (data?.discountApplyStatus === ApplyStatusEnum.REVIEW_FAILED) {
      auditLatestRun({
        enterpriseId: data?.enterpriseId,
        auditType: EAuditType.DISCOUNT,
      });
    }
  }, [data]);
  return (
    <div>
      {data?.discountApplyStatus === ApplyStatusEnum.REVIEW_PASSED && (
        <StatusInfo status="success" title="企业到店专享折扣已开通！">
          <div className={styles.successInfo}>
            {dataArr?.map((item, key) => {
              return (
                <div className={styles.row} key={key}>
                  <div className={styles.label}>{item.label}</div>
                  <div className={styles.value}>{item.value}</div>
                </div>
              );
            })}
          </div>
        </StatusInfo>
      )}
      {data?.discountApplyStatus ===
        ApplyStatusEnum.PENDING_PLATFORM_REVIEW && (
        <StatusInfo
          status="wait"
          title="企业到店专享折扣申请中！"
          message="您的开通申请已提交，工作人员会在2个工作日内审核，请耐心等待"
        />
      )}
      {data?.discountApplyStatus === ApplyStatusEnum.REVIEW_FAILED && (
        <StatusInfo
          status="fail"
          title="企业到店专享折扣审核不通过！"
          message={auditLatestData?.data?.auditDesc}
        >
          <div>
            <Buttons
              fill="none"
              color="primary"
              onClick={() => {
                openUrl({
                  url: "/arriveStore/settled/in",
                  isRoute: true,
                  params: {
                    link: "false",
                  },
                });
              }}
            >
              重新填写
            </Buttons>
          </div>
        </StatusInfo>
      )}
    </div>
  );
};

export default React.memo(Discount);
