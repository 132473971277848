import axios, { IBaseRes } from "@/common/Utils/APIClient";
interface ISignUrlReq {
  phone: string;
  code: string;
  data: {
    applyOrgId: string;
    name: string;
    clientId: string;
    enterpriseId: string;
  };
}
interface IBodyObj {
  code: number;
  message: string;
  data: Data;
}

interface Data {
  draftUrl: string;
  draftShortUrl: string;
}

interface ISignUrlRes {
  status: number;
  body: string;
  bodyObj?: IBodyObj;
}

/**
 * 企业申请入驻申请-获取协议地址
 */
export const getSignUrl = (
  data: ISignUrlReq
): Promise<IBaseRes<string>> => {
  return axios
    .post("/dmall-account/Enterprise/getSignUrl", data)
    // .then((res: IBaseRes<ISignUrlRes>) => {
    //   if (res.data?.body) {
    //     try {
    //       res.data.bodyObj = JSON.parse(res?.data?.body);
    //     } catch (error) {
    //       res.data.bodyObj = undefined;
    //     }
    //   }
    //   return res;
    // });
};
