import axios, { IBaseRes } from "@/common/Utils/APIClient";

interface IParams {
  partnerId?: string;
  /** 推广周期
   * @example 202401
   */
  monthId: string;
  pageNum: number;
  pageSize: number;
}

interface IRes {
  list: IListItem[];
  pages: number;
  total: number;
}
export interface IListItem {
  /**收益*/
  billCommis: number;
  /**合作伙伴 ID*/
  partnerId: number;
  /**合作伙伴名称*/
  partnerName: string;
}

/**
 * 子合伙人收益分页列表
 */
export const mobileChildBillPage = (
  params: IParams
): Promise<IBaseRes<IRes>> => {
  return axios.get("/dmall_statistic/stMonthPartnerBill/mobile/childBillPage", {
    param: params,
  });
};
