import { createBrowserRouter, Outlet, RouteObject } from "react-router-dom";
import React from "react";
import ProtocolInfo from "../pages/ProtocolInfo";
import SecurityCenter from "../pages/SecurityCenter";
import PersonalInfo from "../pages/PersonalInfo";
import EditNick from "../pages/PersonalInfo/Pages/EditNick";
import Layout from "@/layouts";
import Home from "../pages/Home";
import Pay from "../pages/Pay";
import Eleme from "../pages/Eleme";
import OneCard from "../pages/OneCard";
import PayResult from "../pages/PayResult";
import Wallet from "../pages/Wallet";
import NoMatch from "../pages/NoMatch";
import Coupon from "../pages/Coupon";
import BankCardDetail from "../pages/BankCardDetail";
import OrderHistory from "../pages/OrderHistory";
import InvoiceRecord from "../pages/InvoiceRecord";
import InvoiceTitle from "../pages/InvoiceTitle";
import DailyCoupon from "../pages/DailyCoupon";
import BankCardAdd from "../pages/BankCardAdd";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Redirect from "../pages/Redirect";
import Promotion from "../pages/Promotion";
import FeedDetail from "../pages/FeedDetail";
import Mall from "../pages/Mall";
import Activity from "../pages/Mall/pages/ActivityDetail";
import ArriveStore from "../pages/ArriveStore/pages/IndexV2";
import ActivityList from "../pages/Mall/pages/ActivityList";
import LocalLife from "@/pages/LocalLife";
import TabBarLayouts from "../layouts/TabBarLayout";
import SignContractProtocol from "../pages/ArriveStore/pages/SignContractProtocol";
import SignContractInfo from "../pages/ArriveStore/pages/SignContractInfo";
import SignContractInfoStatus from "../pages/ArriveStore/pages/SignContractInfoStatus";
import EnterpriseApplay from "@/pages/Enterprise/pages/Applay";
import SignContractConfirm from "../pages/ArriveStore/pages/SignContractConfirm";
import { bindDDInfoLoader } from "./loader/bindDDInfo";
import OpenPayNew from "@/pages/OpenPayNew";
import Auth from "@/common/Component/Auth";
import UploadProtocol from "@/pages/ArriveStore/pages/UploadProtocol";
import ShopDetailV2 from "@/pages/ArriveStore/pages/ShopDetail/V2";
import DiscountAuthorization from "@/pages/ArriveStore/pages/DiscountAuthorization";
import EmployeeManagement from "@/pages/EmployeeManagement";
import AddEmployee from "@/pages/AddEmployee";
import StoreOrder from "@/pages/StoreOrder";
import MyBill from "@/pages/MyBill";
import BillDetail from "@/pages/BillDetail";
import PayOrder from "@/pages/PayOrder";
import PayOrderRule from "@/pages/PayOrderRule";
import TriggerPay from "@/pages/TriggerPay";
import { useDDJSAPIConfig } from "@/services/dd/ddJSAPIConfig";
import Test from "@/pages/Test";
import MyV2 from "@/pages/MyV2";
import PartnerCode from "@/pages/PartnerCode";
import SettledIn from "@/pages/ArriveStore/pages/SettledIn";
import SettledInProtocol from "@/pages/ArriveStore/pages/SettledInProtocol";
import SettledInRes from "@/pages/ArriveStore/pages/SettledInRes";
import Contract from "@/pages/Enterprise/pages/Contract";
import OpenArriveStore from "@/pages/OpenArriveStore";
import EnterpriseCodeAuth from "@/pages/EnterpriseCodeAuth";
import TestWay from "@/pages/TestWay";
import SetPassword from "@/pages/SetPassword";
import WalletList from "@/pages/WalletList";
import SettledInAuth from "@/common/Component/SettledInAuth";
import { settledInLoader } from "./loader/settledInLoader";
import WalletDetail from "@/pages/WalletDetail";
import LoginPage from "@/pages/LoginPage";
import RegisterUser from "@/pages/RegisterUser";
import RealNameAuth from "@/pages/RealNameAuth";
import ChangePhoneInfo from "@/pages/PersonalInfo/Pages/ChangePhoneInfo";
import PhoneVerify from "@/pages/PersonalInfo/Pages/PhoneVerify";
import CancelAccount from "@/pages/SecurityCenter/Pages/CancelAccount";
import CancelAccountInfo from "@/pages/SecurityCenter/Pages/CancelAccountInfo";
import ResetLoginPassword from "@/pages/LoginPassword/Pages/ResetPassword";
import ChangeLoginPassword from "@/pages/LoginPassword/Pages/ChangePassword";
import ChangePayPassword from "@/pages/SetPassword/Pages/ChangePassword";
import EditAvatar from "@/pages/PersonalInfo/Pages/EditAvatar";
import ChangePhone from "@/pages/PersonalInfo/Pages/ChangePhone";
import Settings from "@/pages/Settings";

import PartnerApply from "@/pages/Partner/pages/Apply";
import PersonalCode from "@/pages/Partner/pages/PersonalCode";
import Enterprise from "@/pages/Partner/pages/Enterprise";
import ManageStaff from "@/pages/Partner/pages/ManageStaff";
import ManageStaffAdd from "@/pages/Partner/pages/ManageStaffAdd";
import ManageInfo from "@/pages/Partner/pages/ManageInfo";
import ManageBankEdit from "@/pages/Partner/pages/ManageBankEdit";
import PersonalPromotion from "@/pages/Partner/pages/PersonalPromotion";
import AuthPartner from "@/common/Component/AuthPartner";
import { partnerLoader } from "./loader/partnerLoader";
import EnterpriseIncome from "@/pages/Partner/pages/EnterpriseIncome";
import EnterpriseIncomeDetail from "@/pages/Partner/pages/EnterpriseIncomeDetail";
import TestLogin from "@/pages/TestLogin";
import EnterprisePerformance from "@/pages/Partner/pages/EnterprisePerformance";
import EnterprisePerformanceDetail from "@/pages/Partner/pages/EnterprisePerformanceDetail";
import ManageStaffPersonal from "@/pages/Partner/pages/ManageStaffPersonal";
import AuthRole from "@/common/Component/AuthRole";
import { partnerRoleLoader } from "./loader/partnerRoleLoader";
import { IdenEnum } from "@/pages/Partner/enum";
import TripartiteLogin from "@/pages/TripartiteLogin";

const Root = () => {
  useDDJSAPIConfig();
  return <Outlet />;
};

// Create a component that wraps NoMatch with Layout
const LayoutErrorElement = () => {
  return (
    <Layout>
      <NoMatch />
    </Layout>
  );
};

const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <Auth>
        <Root />
      </Auth>
    ),
    loader: () => bindDDInfoLoader(),
    children: [
      {
        element: <Layout />,
        errorElement: <LayoutErrorElement />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: "coupon",
            element: <Coupon />,
          },
          {
            path: "eleme",
            element: <Eleme />,
          },
          {
            path: "tripartiteLogin/:appCd",
            element: <TripartiteLogin />,
          },
          {
            path: "pay",
            element: <Pay />,
          },
          {
            path: "payResult",
            element: <PayResult />,
          },
          {
            path: "wallet",
            element: <Wallet />,
          },
          {
            path: "openPay",
            element: <OpenPayNew />,
          },
          {
            path: "oneCard",
            element: <OneCard />,
          },
          {
            path: "bankCardDetail",
            element: <BankCardDetail />,
          },
          {
            path: "orderHistory",
            element: <OrderHistory />,
          },

          {
            path: "invoiceTitle",
            element: <InvoiceTitle />,
          },
          {
            path: "invoiceRecord",
            element: <InvoiceRecord />,
          },
          {
            path: "dailyCoupon",
            element: <DailyCoupon />,
          },

          {
            path: "logout",
            element: <Logout />,
          },
          {
            path: "bankCardAdd",
            element: <BankCardAdd />,
          },
          {
            path: "feedDetail",
            element: <FeedDetail />,
          },

          {
            path: "promotion",
            element: <Promotion />,
          },
          {
            path: "mall",
            element: <Mall />,
          },
          {
            path: "mall/activity/detail",
            element: <Activity />,
          },
          {
            path: "mall/activity/list",
            element: <ActivityList />,
          },
          {
            path: "employeeManagement",
            element: <EmployeeManagement />,
          },
          {
            path: "addEmployee",
            element: <AddEmployee />,
          },
          {
            path: "storeOrder",
            element: <StoreOrder />,
          },
          {
            path: "/setPassword/:accountId",
            element: <SetPassword />,
          },
          {
            path: "/changePassword/:accountId",
            element: <ChangePayPassword />,
          },
          {
            path: "myBill",
            element: <MyBill />,
          },
          {
            path: "billDetail",
            element: <BillDetail />,
          },
          {
            path: "walletList",
            element: <WalletList />,
          },
          {
            path: "walletDetail",
            element: <WalletDetail />,
          },
          {
            path: "triggerPay",
            element: <TriggerPay />,
          },
          {
            path: "payOrder",
            element: <PayOrder />,
          },
          {
            path: "payOrderRule",
            element: <PayOrderRule />,
          },
          {
            path: "arriveStore",
            children: [
              {
                path: "",
                element: <ArriveStore />,
                // element: <InvestmentPromotion />,
              },
              {
                path: "detail",
                element: <ShopDetailV2 />,
              },
              // {
              //   path: "collection",
              //   element: <ShopCollection />,
              // },
              {
                path: "confirm",
                element: <DiscountAuthorization />,
              },
              {
                path: "settled",
                element: <SettledInAuth />,
                loader: settledInLoader,
                children: [
                  {
                    path: "in",
                    element: <SettledIn />,
                  },
                  {
                    path: "in/protocol",
                    element: <SettledInProtocol />,
                  },
                  {
                    path: "in/res",
                    element: <SettledInRes />,
                  },
                ],
              },
              {
                path: "open",
                element: <OpenArriveStore />,
              },
            ],
          },
          {
            path: "locallife",
            element: <LocalLife singlePage={true} />,
          },
          {
            path: "my",
            element: <MyV2 />,
          },
          {
            path: "realNameAuth",
            element: <RealNameAuth />,
          },
          {
            path: "personalInfo",
            element: <PersonalInfo />,
          },
          {
            path: "personalInfo/editNick",
            element: <EditNick />,
          },
          {
            path: "personalInfo/editAvatar",
            element: <EditAvatar />,
          },
          {
            path: "personalInfo/changePhoneInfo",
            element: <ChangePhoneInfo />,
          },
          {
            path: "personalInfo/phoneVerify",
            element: <PhoneVerify />,
          },
          {
            path: "personalInfo/changePhone",
            element: <ChangePhone />,
          },
          {
            path: "securityCenter",
            element: <SecurityCenter />,
          },
          {
            path: "securityCenter/cancelAccountInfo",
            element: <CancelAccountInfo />,
          },
          {
            path: "securityCenter/cancelAccount",
            element: <CancelAccount />,
          },
          {
            path: "changeLoginPassword",
            element: <ChangeLoginPassword />,
           
          },
          {
            path: "settings",
            element: <Settings />,
           
          },
          {
            path: "*",
            element: <NoMatch />,
          },
        ],
      },
      {
        path: "/tabBar",
        element: <TabBarLayouts />,
        children: [
          {
            index: true,
            element: <LocalLife />,
          },

          {
            path: "arriveStore",
            element: <ArriveStore />,
            // element: <InvestmentPromotion />,
          },
          {
            path: "mall",
            element: <Mall />,
          },
          {
            path: "my",
            // element: <My />,
            element: <MyV2 />,
          },
        ],
      },
      {
        path: "/apply",
        children: [
          {
            path: "enterprise/apply",
            element: <EnterpriseApplay />,
          },
          {
            path: "enterprise/contract",
            element: <Contract />,
          },
          {
            path: "enterprise/code/:step?",
            element: <EnterpriseCodeAuth />,
          },
        ],
      },
    ],
  },
  {
    path: "/open",
    children: [
      {
        path: "arriveStore",
        children: [
          {
            path: "merchant/confirm",
            element: <SignContractConfirm />,
          },
          {
            path: "merchant/protocol",
            element: <SignContractProtocol />,
          },
          {
            path: "merchant/info",
            element: <SignContractInfo />,
          },
          {
            path: "merchant/upload/protocol",
            element: <UploadProtocol />,
          },
          {
            path: "merchant/status",
            element: <SignContractInfoStatus />,
          },
        ],
      },
    ],
  },
  {
    path: "/partner",
    loader: partnerLoader,
    element: <AuthPartner />,
    children: [
      {
        path: "apply",
        element: <PartnerApply />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "changeLoginPassword",
        element: <ChangeLoginPassword />,
      },
      {
        path: "personal",
        loader: () =>
          partnerRoleLoader({
            roles: [IdenEnum.Admin, IdenEnum.Common],
          }),
        element: (
          <AuthRole>
            <Outlet />
          </AuthRole>
        ),
        children: [
          {
            path: "code",
            element: <PersonalCode />,
          },
          {
            path: "promotion",
            element: <PersonalPromotion />,
          },
        ],
      },
      {
        path: "enterprise",
        loader: async (data) => {
          return partnerRoleLoader({
            roles: [IdenEnum.Admin],
          });
        },
        element: (
          <AuthRole>
            <Outlet />
          </AuthRole>
        ),
        children: [
          {
            path: "",
            element: <Enterprise />,
          },
          {
            path: "income",
            element: <EnterpriseIncome />,
          },
          {
            path: "income/detail",
            element: <EnterpriseIncomeDetail />,
          },
          {
            path: "performance",
            element: <EnterprisePerformance />,
          },
          {
            path: "performance/detail",
            element: <EnterprisePerformanceDetail />,
          },
        ],
      },
      {
        path: "manage",
        loader: () =>
          partnerRoleLoader({
            roles: [IdenEnum.Admin],
          }),
        element: (
          <AuthRole>
            <Outlet />
          </AuthRole>
        ),
        children: [
          {
            path: "staff",
            element: <ManageStaff />,
          },
          {
            path: "staff/personal",
            element: <ManageStaffPersonal />,
          },
          {
            path: "staff/info",
            element: <ManageStaffAdd />,
          },
          {
            path: "info",
            element: <ManageInfo />,
          },
          {
            path: "bank/edit",
            element: <ManageBankEdit />,
          },
        ],
      },
    ],
  },
  {
    path: "/partnerCode/:code",
    element: <PartnerCode />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "loginPage",
    element: <LoginPage />,
  },
  {
    path: "registerUser",
    element: <RegisterUser />,
  },
  {
    path: "resetLoginPassword",
    element: <ResetLoginPassword />,
  },
  {
    path: "test",
    element: <Test />,
  },
  {
    path: "testMap",
    element: <TestLogin />,
  },
  {
    path: "protocolInfo/:code",
    element: <ProtocolInfo />,
  },
  {
    path: "/testWay",
    element: <TestWay />,
  },
  {
    path: "redirect",
    element: <Redirect />,
  },

  {
    path: "*",
    element: <NoMatch />,
  },
];
export default createBrowserRouter(routes);
