import React from "react";
import usePageTitle from "@/hooks/usePageTitle";
import { Button} from "antd-mobile";
import { ExclamationCircleFill } from "antd-mobile-icons";
import styles from "./index.module.scss";
import { useOpenUrl } from "@/hooks/useOpenUrl";

function ChangePhoneInfo() {
  usePageTitle({ title: "更换手机号" });
  const openUrl = useOpenUrl();
  const showInfoList = [
    {
      title: '餐补钱包手机号',
      desc: '需联系公司协助修改，完成更换操作'
    },
    {
      title: '企业员工联系手机号',
      desc: '需联系公司协助修改，完成更换操作'
    },
    {
      title: '店铺店员手机号',
      desc: '需联系商户管理员删除后再次添加'
    }
  ]

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.warningBox}>
          <h1 className={styles.title}>更换手机号</h1>
          <div className={styles.warningTitle}>
            <ExclamationCircleFill className={styles.warningIcon} color="#FF9500"/>
            <span>此手机号仅作为用户个人登录系统的凭证，</span>
          </div>
          <div className={styles.warningText}>以下信息需单独处理：</div>
        </div>

        <div className={styles.optionsList}>
          {showInfoList.map((item, index) => (
            <div className={styles.optionItem} key={index}>
              <div className={styles.dot}></div>
              <div className={styles.optionContent}>
                <div className={styles.optionTitle}>{item.title}</div>
                <div className={styles.optionDesc}>{item.desc}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.bottomFixedContainer}>
        <div className={styles.verifyButtons}>
          <Button 
            className={styles.verifyButton} 
            block 
            onClick={() => openUrl({ url: '/personalInfo/phoneVerify', isRoute: true })}
            shape="rounded"
            size="large"
          >
            手机号验证
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ChangePhoneInfo;