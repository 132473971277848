import React, { useState, useEffect } from 'react';
import { Button } from 'antd-mobile';
import styles from './index.module.scss';

interface SmsVerificationButtonProps {
  onGetCode: () => Promise<boolean> | void;
  loading?: boolean;
  btnText?: string;
  countdownText?: string;
  className?: string;
  countdownDuration?: number;
}

const SmsVerificationButton: React.FC<SmsVerificationButtonProps> = ({
  onGetCode,
  loading = false,
  btnText = '获取验证码',
  countdownText = '秒后重试',
  className = '',
  countdownDuration = 60,
}) => {
  const [countdown, setCountdown] = useState(0);
  const [targetTime, setTargetTime] = useState<number | null>(null);
  const [internalLoading, setInternalLoading] = useState(false);

  useEffect(() => {
    // Clean up function will be called when component unmounts
    return () => stopCountdown();
  }, []);

  useEffect(() => {
    if (targetTime === null) return;
    
    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      const remainingSeconds = Math.ceil((targetTime - currentTime) / 1000);
      
      if (remainingSeconds <= 0) {
        stopCountdown();
      } else {
        setCountdown(remainingSeconds);
      }
    }, 100); // Update more frequently to ensure accuracy
    
    return () => clearInterval(intervalId);
  }, [targetTime]);

  const startCountdown = () => {
    setTargetTime(Date.now() + countdownDuration * 1000);
    setCountdown(countdownDuration);
  };

  const stopCountdown = () => {
    setTargetTime(null);
    setCountdown(0);
  };

  const handleClick = async () => {
    if (countdown > 0 || loading || internalLoading) return;
    
    setInternalLoading(true);
    try {
      const result = await Promise.resolve(onGetCode());
      // Only start countdown if validation is successful
      // If onGetCode returns undefined (void), we assume it's successful
      // If it returns a boolean, we check if it's true
      if (result === undefined || result === true) {
        startCountdown();
      }
    } finally {
      setInternalLoading(false);
    }
  };

  return (
    <Button
      className={styles.verificationButton}
      fill="none"
      loading={loading || internalLoading}
      disabled={countdown > 0}
      onClick={handleClick}
    >
      {countdown > 0 ? `${countdown}${countdownText}` : btnText}
    </Button>
  );
};

export default SmsVerificationButton; 