import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { PasscodeInput, Popup, Toast } from "antd-mobile";
import styles from "./index.module.scss";
import Buttons from "@/common/Component/Button";
import { useRequest } from "ahooks";
import { getVerificationCode } from "@/services/auth/getVerificationCode";
import { checkVerificationCode } from "@/services/auth/checkVerificationCode";
import PageLoading from "../PageLoading";

interface IVerificationCodeProps {
  visible?: boolean;
  phone?: string;
  onClose?: () => void;
  onOk?: (code: string, resData: any) => void;
  isCheckAfter?: boolean;
  /**
   * 获取验证码
   */
  getVerifyCode?: (data: {
    phone: string;
  }) => ReturnType<typeof getVerificationCode>;
  /**
   * 验证码校验
   */
  verifyCode?: (data: { phone: string; code: string }) => Promise<any>;
  extInfo?: React.ReactNode;
}
interface IConfig {
  phone: string;

  onError?: () => void;

  onOk?: (code: string, resData?: any) => void;
}
export interface IRef {
  focus?: () => void;
  blur?: () => void;
  show?: (config: IConfig) => void;
  hide?: () => void;
}
/** 验证码  */
const VerificationCode: React.ForwardRefRenderFunction<
  IRef,
  IVerificationCodeProps
> = (props, ref) => {
  const {
    visible,
    onClose,
    phone,
    onOk,
    isCheckAfter = false,
    getVerifyCode,
    verifyCode,
  } = props;

  const [insideVisible, setInsideVisible] = useState(visible);

  const [insidePhone, setInsidePhone] = useState(phone);

  const configRef = useRef<IConfig>();
  const inputRef = useRef<{ blur: () => void; focus: () => void }>(null);
  const hideInputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState(false);
  const [value, setValue] = useState<string>("");
  const timeOut = useRef<ReturnType<typeof setInterval>>();
  const { loading, run } = useRequest(getVerifyCode || getVerificationCode, {
    manual: true,
    throttleWait: 300,
    onBefore: () => {
      let initTime = 60;
      timeOut.current = setInterval(() => {
        if (initTime >= 0) {
          setSec(initTime);
          initTime--;
        }
        if (initTime < 0) {
          clearInterval(timeOut.current);
          timeOut.current = undefined;
        }
      }, 1000);
    },
  });

  const { run: checkRun, loading: checkLoading } = useRequest(
    verifyCode || checkVerificationCode,
    {
      manual: true,
      debounceWait: 300,
      onSuccess: (res, params) => {
        if (res.data && res.success) {
          if (!isCheckAfter) {
            Toast.show({
              content: "验证成功",
              duration: 2000,
            });
          }
          onClose && onClose();
          configRef.current?.onOk?.(params[0].code, res.data);
          onOk && onOk(params[0].code, res.data);
        } else {
          inputRef.current?.focus();
          configRef.current?.onError?.();
          setError(true);
        }
      },
      onError: () => {
        inputRef.current?.focus();
        configRef.current?.onError?.();
        setError(true);
      },
    }
  );
  const [sec, setSec] = useState<number>();

  const getCode = useCallback(() => {
    if (!insideVisible) return;
    if (!insidePhone) {
      Toast.show({
        content: "请输入手机号",
      });

      return;
    }
    if (insideVisible && !timeOut.current) {
      run({ phone: insidePhone });
    }
  }, [insideVisible, insidePhone, run]);
  useEffect(() => {
    getCode();
  }, [getCode]);
  useEffect(() => {
    if (!visible) {
      setValue("");
    }
    setInsideVisible(visible);
  }, [visible]);
  useEffect(() => {
    setInsidePhone(phone);
  }, [phone]);

  const onFill = (val: string) => {
    if (insidePhone && val?.length === 6) {
      checkRun({ code: val, phone: insidePhone });
    }
  };
  useImperativeHandle(
    ref,
    () => {
      return {
        focus: () => {
          hideInputRef.current?.focus();
          inputRef.current?.focus();
        },
        blur: () => {
          hideInputRef.current?.blur();
          inputRef.current?.blur();
        },

        show: (config: IConfig) => {
          configRef.current = config;

          setInsideVisible(true);
          hideInputRef.current?.focus();

          setInsidePhone(config.phone);
        },

        hide: () => {
          hideInputRef.current?.blur();
          setInsideVisible(false);
        },
      };
    },
    []
  );
  const showPhone = useMemo(() => {
    if (insidePhone) {
      const start = insidePhone.slice(0, 3);
      const end = insidePhone.slice(-4);
      return `${start}****${end}`;
    }
    return "";
  }, [insidePhone]);
  return (
    <div>
      <Popup
        showCloseButton
        bodyClassName={styles.moduleBody}
        className={styles.module}
        visible={insideVisible}
        onClose={() => {
          onClose?.();
          setInsideVisible(false);
        }}
        afterShow={() => {
          inputRef.current?.focus();
        }}
        destroyOnClose={false}
      >
        <PageLoading loading={checkLoading} fullScreen={false}>
          <div className={styles.title}>请输入验证码</div>
          <div className={styles.subTitle}>
            <span className={styles.label}>验证码已发送至</span>
            <span className={styles.phone}>{showPhone}</span>
          </div>
          <PasscodeInput
            onFill={onFill}
            onFocus={() => {
              setError(false);
            }}
            className={styles.passcode}
            plain
            seperated
            ref={inputRef}
            value={value}
            error={error}
            onChange={setValue}
          />
          <div className={styles.passcodeFooter}>
            {!sec && (
              <Buttons
                onClick={() => {
                  getCode();
                  setError(false);
                  inputRef.current?.focus();
                }}
                color="primary"
                fill="none"
                loading={loading}
              >
                发送验证码
              </Buttons>
            )}
            {!!sec && (
              <span className={styles.timer}>
                重新发送<span className={styles.timerNum}>{sec}s</span>
              </span>
            )}
          </div>
        </PageLoading>
      </Popup>
      <input ref={hideInputRef} className={styles.input} />
    </div>
  );
};

export default React.forwardRef(VerificationCode);
