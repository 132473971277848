import Decimal from "decimal.js";
const DEG_TO_RAD = new Decimal(Math.PI).div(180); // 预定义转换系数

/**
 * 计算两个经纬度地点之间的距离（单位：米）
 * @param currentLat 当前位置的纬度
 * @param currentLng 当前位置的经度
 * @param shopLat 店铺位置的纬度
 * @param shopLng 店铺位置的经度
 * @returns 两个地点之间的距离，单位为米
 */
export function calculateDistance(
  currentLat?: number,
  currentLng?: number,
  shopLat?: number,
  shopLng?: number
): number | undefined {
  if (
    currentLat === undefined ||
    currentLng === undefined ||
    shopLat === undefined ||
    shopLng === undefined
  ) {
    return undefined;
  }

  const lat1 = new Decimal(currentLat).mul(DEG_TO_RAD);
  const lon1 = new Decimal(currentLng).mul(DEG_TO_RAD);
  const lat2 = new Decimal(shopLat).mul(DEG_TO_RAD);
  const lon2 = new Decimal(shopLng).mul(DEG_TO_RAD);

  const dLat = lat2.sub(lat1);
  const dLon = lon2.sub(lon1);

  const a = Decimal.sin(dLat.div(2))
    .pow(2)
    .plus(
      Decimal.cos(lat1)
        .mul(Decimal.cos(lat2))
        .mul(Decimal.sin(dLon.div(2)).pow(2))
    );

  const c = new Decimal(2).mul(
    Decimal.atan2(a.sqrt(), Decimal.sqrt(1).sub(a).sqrt())
  );

  return new Decimal(6371000).mul(c).toNumber();
}

/**
 * 将米转换为千米
 * @param meters 距离，单位为米
 * @returns 包含转换后的数值和单位的对象
 */
export function convertMetersToKm(meters?: number):
  | {
      num: number;
      hasKm: boolean;
    }
  | undefined {
  if (meters === undefined) {
    return undefined;
  }
  const metersDecimal = new Decimal(meters);
  const kmThreshold = new Decimal(1000);

  if (metersDecimal.lessThan(kmThreshold)) {
    return {
      num: metersDecimal.toNumber(),
      hasKm: false,
    };
  } else {
    return {
      num: metersDecimal.div(kmThreshold).toNumber(),
      hasKm: true,
    };
  }
}
