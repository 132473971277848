import axios, { IBaseRes } from "@/common/Utils/APIClient";
export type FeeType = "shop" | "enterprise" | "referral";
interface IParams {
  /** shop:到店分佣（组织安装） enterprise:餐补分佣（商户邀约） referral:商户推荐数（广告） */
  feeType: "shop" | "enterprise" | "referral";
  /**
   * 日期
   * @example 日:yyyyMMdd 月:yyyyMM
   */
  signDate: string;
  partnerId?: string;
  pageSize: number;
  pageNum: number;
}

interface IRes {
  list: IListItem[];
  pages: number;
  total: number;
}
export interface IListItem {
  /**
   * 列表中签名日期
   * @example 20250101
   */
  signDate: number;
  /**
   * 组织
   */
  promotionEntityName: string;
  /**
   * 列表中合作伙伴名称
   */
  partnerName: string;
  /**
   * 合同时间
   */
  contractTerm: string;
}

/**
 * 子合伙人业绩分页列表by推广周期

 */
export const mobileChildPerfPage = (
  params: IParams
): Promise<IBaseRes<IRes>> => {
  return axios.get("/dmall_customer/partnerPerf/mobile/childPerfPage", {
    param: params,
  });
};
