import axios from "@/common/Utils/APIClient";
interface ISendSmsCodeReq {
  phone: string;
}

interface ISendSmsCodeRes {
  code: string;
  data: boolean;
  msg: string;
  success: boolean;
}

/**
 * 餐补入驻发送验证码
 */
export const sendSmsCode = (data: ISendSmsCodeReq): Promise<ISendSmsCodeRes> => {
  return axios.post(`/dmall-account/Enterprise/sendSmsCode/${data.phone}`);
};
