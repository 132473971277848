import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./index.module.scss";
import AMapLoader from "@amap/amap-jsapi-loader";
import { Form, Input, SafeArea, Toast } from "antd-mobile";
import Buttons from "../Button";
import Icon from "../Icon";
import AutoSelect from "./AutoSelect";
import { MAP_CONFIG } from "@/common/Config/Constant";
import classNames from "classnames";
const { key, securityJsCode } = MAP_CONFIG;
export interface IMapData {
  center: any;
  result: any;
  detailAddress?: string;
  lat?: number;
  lng?: number;
}
interface IMapProps {
  onOk?: (data: IMapData) => void;
  defaultAddress?: IMapData;
  footer?: boolean;
  className?: string;
  disabledForm?: boolean;
}
export interface IMapRef {
  submit?: () => void;
}
const Map: React.ForwardRefRenderFunction<IMapRef, IMapProps> = (
  props,
  ref
) => {
  const {
    onOk,
    defaultAddress,
    footer = true,
    className,
    disabledForm,
  } = props;
  const AMap = useRef<any>(null);
  const map = useRef<any>(null);
  const container = useRef<HTMLDivElement>(null);
  const geocoder = useRef<any>(null);
  const marker = useRef<any>(null);
  const geolocation = useRef<any>(null);
  const [visibleSelect, setVisibleSelect] = useState(false);
  const [form] = Form.useForm();
  /***
   * 添加点标记
   */
  function addMarker(AMap: any) {
    if (!marker.current) {
      // 获取中心点位置
      const center = map.current.getCenter();
      const icon = new AMap.Icon({
        // 图标的取图地址
        image:
          "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        size: new AMap.Size(26.5, 34),
        imageSize: new AMap.Size(26.5, 34),
      });
      marker.current = new AMap.Marker({
        icon,
        position: center,
        anchor: "bottom-center",
      });
      marker.current.setMap(map.current);
    }
  }
  const setFormAddresss = (position: any) => {
    geocoder.current.getAddress(position, (status: string, result: any) => {
      if (status === "complete" && result.info === "OK") {
        console.log("当前地址:", result);
        const province = result.regeocode.addressComponent.province;
        const city = result.regeocode.addressComponent.city;
        const district = result.regeocode.addressComponent.district;
        const township = result.regeocode.addressComponent.township;
        const street = result.regeocode.addressComponent.street;
        const detailAddress = result.regeocode.formattedAddress
          ?.replace(province, "")
          .replace(city, "")
          .replace(district, "")
          .replace(township, "");
        form.setFieldsValue({
          // 省
          province,
          // 市
          city,
          // 区
          district,
          // 乡
          township,
          // 街道
          street,

          // 详细地址
          detailAddress: detailAddress,
          //   // 纬度
          //   latitude: result.position.lat,
          //   // 经度
          //   longitude: result.position.lng,
        });
        // 获取当前地图中心点的地址信息
        console.log(
          "当前地图中心点的地址:",
          result.regeocode.formattedAddress,
          result
        );
        // 这里可以处理获取到的地址信息
      } else {
        console.log("获取地址失败:", result.info);
      }
    });
  };
  const initMap = async () => {
    window._AMapSecurityConfig = {
      securityJsCode,
    };
    const plugins = [
      "AMap.Geocoder",
      "AMap.Marker",
      "AMap.AutoComplete",
      "AMap.Geolocation",
    ];
    AMap.current = await AMapLoader.load({
      key, // 申请好的Web端开发者Key，首次调用 load 时必填
      version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: plugins, //需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
    }).catch((e) => {
      console.log(e);
      return undefined;
    });
    if (AMap.current) {
      map.current = new AMap.current.Map(container.current, {
        // 设置地图容器id
        viewMode: "3D", // 是否为3D地图模式
        zoom: 11, // 初始化地图级别
        center: defaultAddress?.center || [116.397493, 39.907954], // 初始化地图中心点位置
      });

      AMap.current.plugin(plugins, function () {
        geolocation.current = new AMap.current.Geolocation({
          // city: "010", //城市，默认全国
          radius: 20, //范围，默认：500
          position: "RB", //返回格式，默认："GPS"
          offset: [10, 20],
          extensions: "all",
          showButton: true,
          convert: true,
          panToLocation: true, // 定位成功后是否自动移动到响应位置
          noIpLocate: 3, // 禁用IP定位
          enableHighAccuracy: true, // 是否使用高精度定位，默认:true
          timeout: 10000, // 超过10秒后停止定位，默认：无穷大
          zoomToAccuracy: true, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        });
        geocoder.current = new AMap.current.Geocoder({
          city: "全国", // 可选参数，指定搜索的城市
        });

        if (!defaultAddress?.center) {
          // 如果没有默认地址，获取当前地址作为中心点
          geolocation.current.getCurrentPosition();
        } else {
          //  如果有默认地址，进行回填工作
          map.current.setCenter(defaultAddress?.center, true);
          map.current.setZoom(20);
          setFormAddresss(defaultAddress?.center);
        }
        geolocation.current.on("complete", (data: any) => {
          // 设置中心点
          map.current.setCenter(data.position, true);
          // 放大地图
          map.current.setZoom(20);
        });
        geolocation.current.on("error", (e: any) => {
          console.log(e);

          Toast.show("定位失败");
        });

        addMarker(AMap.current);
      });

      map.current.on("mapmove", (e: any) => {
        // 这里可以添加选店的逻辑
        const center = map.current.getCenter();
        // 地图点标记始终处于中心位置
        marker.current.setPosition(center);
      });

      map.current.on("moveend", (e: any) => {
        const center = map.current.getCenter();
        // 地图点标记始终处于中心位置
        marker.current.setPosition(center);
        setFormAddresss(center);
      });
    }
  };
  useEffect(() => {
    initMap();
    return () => {
      map?.current?.destroy();
    };
  }, []);

  const province = Form.useWatch("province", form);
  const city = Form.useWatch("city", form);
  const district = Form.useWatch("district", form);
  const township = Form.useWatch("township", form);
  const street = Form.useWatch("street", form);
  const address = useMemo(() => {
    const str = [province, city, district, township, street]
      .filter(Boolean)
      .join("");
    return str;
  }, [province, city, district, township, street]);
  useImperativeHandle(
    ref,
    () => {
      return {
        submit: () => {
          form.submit();
        },
      };
    },
    [form]
  );
  return (
    <div className={classNames(styles.page, className)}>
      <div className={styles.content}>
        <div className={styles.map}>
          <div ref={container} className={styles.container} />
          <Icon
            onClick={() => {
              geolocation.current.getCurrentPosition();
            }}
            className={styles.iconDingwei}
            icon="icon-dingwei"
          />
        </div>
        <Form
          form={form}
          disabled={disabledForm}
          onFinish={(value) => {
            const center = map.current.getCenter();
            geocoder.current.getAddress(
              center,
              (status: string, result: any) => {
                if (status === "complete" && result.info === "OK") {
                  onOk &&
                    onOk({
                      center,
                      result,
                      detailAddress: value.detailAddress,
                      lat: center.lat,
                      lng: center.lng,
                    });
                } else {
                  Toast.show({
                    icon: "error",
                    content: "获取地址失败",
                  });
                }
              }
            );
          }}
        >
          <Form.Item disabled={false} onClick={() => setVisibleSelect(true)}>
            <div>搜索地址</div>
          </Form.Item>
          <AutoSelect
            visible={visibleSelect}
            onClose={() => setVisibleSelect(false)}
            AMap={AMap.current}
            onSelect={(e: any) => {
              // 放大地图
              map.current.setZoom(20);
              // 使地址居中
              map.current.setCenter(e.poi.location, true);
            }}
          />
          <Form.Item>
            <Input value={address} disabled />
          </Form.Item>
          <Form.Item name="detailAddress">
            <Input placeholder="请输入详细地址" clearable />
          </Form.Item>
          <Form.Item hidden name="province">
            <Input />
          </Form.Item>
          <Form.Item hidden name="city">
            <Input />
          </Form.Item>
          <Form.Item hidden name="district">
            <Input />
          </Form.Item>
          <Form.Item hidden name="township">
            <Input />
          </Form.Item>
          <Form.Item hidden name="street">
            <Input />
          </Form.Item>
          <Form.Item hidden name="longitude">
            <Input />
          </Form.Item>
          <Form.Item hidden name="latitude">
            <Input />
          </Form.Item>
        </Form>
      </div>
      {footer && (
        <Buttons
          block
          color="primary"
          type="submit"
          onClick={() => {
            form.submit();
          }}
        >
          提交
        </Buttons>
      )}
      <SafeArea position="bottom" />
    </div>
  );
};

export default React.forwardRef(Map);
