import axios, { IBaseRes } from "@/common/Utils/APIClient";

interface IParams {
  partnerId?: string;
  /** 月id
   *  @example 202301
   */
  monthId?: string;
}

interface IRes {
  /** 商户邀约数（餐补企业） */
  shopNum: number;
  /** 商户推荐数（广告） */
  referralNum: number;
  /** 组织安装数（到店） */
  enterpriseNum: number;
}

/**
 * 子合伙人日业绩分页列表count
 */
export const mobileCountMonth = (params: IParams): Promise<IBaseRes<IRes>> => {
  return axios.get("/dmall_statistic/partnerPerf/month/mobile/count", {
    param: params,
  });
};
