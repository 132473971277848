import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { useRequest } from "ahooks";
import { AppCdEnum, getBizUrl } from "./services";
import { useParams, useSearchParams } from "react-router-dom";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import PageLoading from "@/common/Component/PageLoading";

interface TripartiteLoginProps {}

/** 三方登录 */
const TripartiteLogin: React.FC<TripartiteLoginProps> = (props) => {
  const {} = props;
  const [search] = useSearchParams();
  const { appCd } = useParams<{ appCd: AppCdEnum }>();
  const openUrl = useOpenUrl();
  const url = search.get("url");
  const { run: getBizUrlRun, loading: getBizUrlLoading } = useRequest(
    getBizUrl,
    {
      manual: true,
      onSuccess: (res) => {
        if (res.data) {
          openUrl({
            url: res.data,
            isRoute: false,
            isOpenHalf: false,
            isReplace: true,
          });
        }
      },
    }
  );
  useEffect(() => {
    if (appCd) {
      getBizUrlRun({
        appCd,
        url: url || undefined,
      });
    }
  }, []);

  return <PageLoading loading={getBizUrlLoading} />;
};

export default React.memo(TripartiteLogin);
