import { CenterPopup } from "antd-mobile";
import React, {
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./index.module.scss";
import Buttons from "@/common/Component/Button";
interface ModalProps {}
export interface IModalRef {
  open?: (configData: IConfig) => { close: () => void };
  close?: () => void;
}
interface IConfig {
  title?: string;
  content?: string;
  onOk?: () => void;
  onCancel?: () => void;
}
const baseConfig = {
  title: "提示",
  content: "",
};
const Modal: React.ForwardRefRenderFunction<IModalRef, ModalProps> = (
  props,
  ref
) => {
  const {} = props;
  const config = useRef<IConfig>(baseConfig);
  const [visible, setVisible] = useState(false);
  useImperativeHandle(
    ref,
    () => {
      return {
        open: (configData: IConfig) => {
          config.current = configData;
          setVisible(true);
          return {
            close: () => {
              setVisible(false);
            },
          };
        },
        close: () => {
          config.current = baseConfig;
          setVisible(false);
        },
      };
    },
    []
  );
  const onOk = useCallback(async () => {
    await (config.current.onOk && config.current.onOk());
    // setVisible(false);
  }, []);
  const onCancel = useCallback(() => {
    config.current.onCancel && config.current.onCancel();
    // setVisible(false);
  }, []);

  return (
    <CenterPopup
      visible={visible}
      onMaskClick={() => {
        setVisible(false);
      }}
      className={styles.modal}
    >
      <div className={styles.header}>{config.current.title}</div>
      <div className={styles.body}>{config.current.content}</div>
      {(config.current.onOk || config.current.onCancel) && (
        <div className={styles.footer}>
          {config.current.onCancel && (
            <Buttons
              onClick={onCancel}
              block={!config.current.onOk}
              className={styles.cancel}
              color="default"
            >
              取消
            </Buttons>
          )}
          {config.current.onOk && (
            <Buttons
              block={!config.current.onCancel}
              onClick={onOk}
              color="primary"
              customColor="black"
            >
              确定
            </Buttons>
          )}
        </div>
      )}
    </CenterPopup>
  );
};

export default React.forwardRef(Modal);
