import apiClient, { IBaseRes } from "src/common/Utils/APIClient";

interface LoginParams {
  principal: string; //用户名（手机号）
  credentials: string; //密码/验证码
  loginType: number; //登录类型0-账密登录，1-免密登录
  sysType: number; //系统类型0.普通用户系统 1.商家端 2.平台端 3.企业端 4.合伙人端
}

export function login(params: LoginParams): Promise<IBaseRes<any>> {
  return apiClient.post("/dmall-auth/ua/login", params);
}

interface GetSmsVerificationCodeParams {
  phone: string;
  type: number;
  sysType: number;
}

/**
 * 获取短信验证码
 */
export function getSmsVerificationCode(params: GetSmsVerificationCodeParams): Promise<IBaseRes<any>> {
  return apiClient.get('/dmall-customer/ua/buyer/sendSmsCode', {
    param: params,
  });
}

interface loginBySmsCodeParams {
  phone: string;
  code: string;
}

/**
 * 通过短信验证码登录
 */
export function loginBySmsCode(params: loginBySmsCodeParams): Promise<IBaseRes<any>> {
  return apiClient.post("/dmall-auth/ua/login/sms", params);
}