import axios, { IBaseRes } from "@/common/Utils/APIClient";
import { SettlementStatusEnum } from "../enum";

interface IParams {
  partnerId?: string;
  pageSize: number;
  pageNum: number;
}

interface IRes {
  list: IListItem[];
  pages: number;
  total: number;
}
export interface IListItem {
  /**
   * 月份id
   * @example 202501
   *  */
  monthId: number;
  /**收益*/
  billCommis: number;
  /**
   * 状态
   * @example 30:未结算 40:已结算
   *  */
  status: SettlementStatusEnum;
}

/**
 * 合伙人收益分页列表byId
 */
export const mobileBillPage = (params: IParams): Promise<IBaseRes<IRes>> => {
  return axios.get("/dmall_statistic/stMonthPartnerBill/mobile/billPage", {
    param: params,
  });
};
