import React, { useEffect, useState } from "react";
import { Result, Toast, Image } from "antd-mobile";
import { useSearchParams } from "react-router-dom";
import usePageTitle from "@/hooks/usePageTitle";
import { useRequest } from "ahooks";
import { getBuyerPay } from "./Services";
import { formatCurrency } from "@/common/Utils/money";
import styles from "./index.module.scss";
import { RightOutline } from "antd-mobile-icons";
import { ICON_PAY } from "@/common/Config/Constant";

interface PayOrder {
  refundAmount: string;
}

interface Detail {
  successTime: string;
  orderNo: string;
  txnType: string;
  txnAmount: string;
  createAt: string;
  srcType: string;
  txnRemarks: string;
  relaOrderId: string;
  fromAccountName: string;
  toAccountName: string;
  txnCd: string;
  payOrder: PayOrder;
}

const PAY_TYPE = '钱包余额'


function BillDetail() {
  usePageTitle({ title: "钱包详情" });

  const [params] = useSearchParams();
  const id = params.get('id');
  const accountId = params.get('accountId');
  const [detail, setDetail] = useState<Detail>();

  const { run } = useRequest(getBuyerPay, {
    manual: true,
    onSuccess: (res: any) => {
      const { success, msg, data } = res || {};
      if (success) {
        setDetail(data);
      } else {
        Toast.show({
          content: msg || '获取详情失败',
          afterClose: () => {
            window.history.back();
          }
        });
      }
    },
  });

  useEffect(() => {
    if (id && accountId) {
      run({ id, accountId });
    } else {
      Toast.show({
        content: '缺少必要参数',
        afterClose: () => {
          window.history.back();
        }
      });
    }
  }, [id, accountId]);

  const renderPrice = (detail: Detail) => {
    const tag =  detail.txnType === 'p' ? '-' : '+';
    return <div className={styles.price}>{tag + formatCurrency(Number(txnAmount))}</div>;
  };

  const renderStatus = (detail: Detail) => {
    if (detail.srcType === 'M') {
      if (detail.txnType === 'p') {
        return '已回收';
      } else if (detail.txnType === 'r') {
        return '已到账';
      }
    } else {
      if (detail.txnType === 'p') {
        return '支付成功';
      } else if (detail.txnType === 'r') {
        return '退款成功';
      }
    }
  };

  const parseSubject = (item: Detail) => {
    if (item.srcType === 'M') {
      return '餐补' + parseMealText(item);
    } else {
      return item.txnRemarks;
    }
  }

  const parseMealText = (item: Detail) => {
    if (item.txnType === 'p') {
      return '回收';
    } else {
      return '发放';
    }
  }

  if (!detail) return null;

  const { txnType, createAt, txnCd, txnAmount, srcType, fromAccountName, toAccountName, payOrder } = detail;

  return (
    <div className={styles.container}>
      {
        srcType === 'P' && (
          <Result
            className={styles.result}
            status='success'
            title={parseSubject(detail)}
            description={renderPrice(detail)}
          />
        )
      }
      {
        srcType === 'M' && (
          <Result
            className={styles.result}
            icon={<Image src={ICON_PAY.MEAL} />}
            title={parseSubject(detail)}
            description={renderPrice(detail)}
          />
        )
      }
      {
        srcType === 'P' && txnType === 'p' && (
          <div className={styles.resultContent}>
            {
              Number(payOrder.refundAmount) > 0 && <li className={[styles.list, styles.refund].join(' ')}>
                  <span className={styles.label}>退款记录</span>
                  <span className={styles.value}>
                    已退款 ¥{formatCurrency(Number(payOrder.refundAmount))}
                  </span>
              </li>
            }
            <li className={styles.list}>
              <span className={styles.label}>当前状态</span>
              <span className={styles.value}>{renderStatus(detail)}</span>
            </li>
            <li className={styles.list}>
              <span className={styles.label}>支付时间</span>
              <span className={styles.value}>{createAt}</span>
            </li>
            <li className={styles.list}>
              <span className={styles.label}>支付方式</span>
              <span className={styles.value}>{PAY_TYPE}</span>
            </li>
            <li className={styles.list}>
              <span className={styles.label}>流水号</span>
              <span className={styles.value}>{txnCd}</span>
            </li>
          </div>
        )
      }

      {
        srcType === 'P' && txnType === 'r' && (
          <div className={styles.resultContent}>
            <li className={styles.list}>
              <span className={styles.label}>当前状态</span>
              <span className={styles.value}>{renderStatus(detail)}</span>
            </li>
            <li className={styles.list}>
              <span className={styles.label}>退款时间</span>
              <span className={styles.value}>{createAt}</span>
            </li>
            <li className={styles.list}>
              <span className={styles.label}>退款方式</span>
              <span className={styles.value}>{PAY_TYPE}</span>
            </li>
            <li className={styles.list}>
              <span className={styles.label}>流水号</span>
              <span className={styles.value}>{txnCd}</span>
            </li>
            {/* <li className={styles.list}>
              <span className={styles.label}>原支付记录</span>
              <span className={styles.value}>
                <Link to={`/billDetail?id=${relaOrderId}`}>查看</Link>
              </span>
            </li> */}
          </div>
        )
      }
      {
        srcType === 'M' && (
          <div className={styles.resultContent}>
            <li className={styles.list}>
              <span className={styles.label}>当前状态</span>
              <span className={styles.value}>{renderStatus(detail)}</span>
            </li>
            <li className={styles.list}>
              <span className={styles.label}>{parseMealText(detail)}账户</span>
              {txnType === 'p' && (
                <span className={styles.value}>{toAccountName}</span>
              )}
              {txnType === 'r' && (
                <span className={styles.value}>{fromAccountName}</span>
              )}
            </li>
            <li className={styles.list}>
              <span className={styles.label}>{parseMealText(detail)}时间</span>
              <span className={styles.value}>{createAt}</span>
            </li>
            <li className={styles.list}>
              <span className={styles.label}>流水号</span>
              <span className={styles.value}>{txnCd}</span>
            </li>
            <li className={styles.list}>
              <span className={styles.label}>备注</span>
              <span className={styles.value}>{detail.txnRemarks}</span>
            </li>
          </div>
        )
      }
    </div>
  );
}

export default BillDetail;