import React from "react";
import { Form, FormItemProps } from "antd-mobile";
import styles from "./index.module.scss";
import classNames from "classnames";

// interface FormItemProps {}

const FormItem: React.FC<FormItemProps> = (props) => {
  const { className, ...rest } = props;
  return (
    <Form.Item
      className={classNames(styles.formItem, props.className)}
      {...rest}
    />
  );
};

export default React.memo(FormItem);
