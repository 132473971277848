import React, { useState } from "react";
import { Button, Grid, List, Modal, Toast } from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";
import usePageTitle from "@/hooks/usePageTitle";
import styles from "./index.module.scss";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { useRequest } from "ahooks";
import { logout } from "./Services";
import { USER_CONFIG, USER_SETTING_LIST, PARTNER_SETTING_LIST } from "@/common/Config/Constant";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";
import { useSearchParams } from "react-router-dom";


function Settings() {
  usePageTitle({ title: "设置" });
  const openUrl = useOpenUrl();

  const pathname = window.location.pathname;
  let settingList = USER_SETTING_LIST;
  const [params] = useSearchParams();
  const needLogout = params.get('logout') || '';
  const currentConfig = USER_CONFIG.find(config => config.type && pathname.includes(config.type));
  if (currentConfig?.type === 'partner') {
    settingList = PARTNER_SETTING_LIST;
  }
  const [platformContext] = useUserStore(
    useShallow((state) => [
      state.platformContext,
    ])
  );
  
  const [visible, setVisible] = useState(false);
  
  const { run: runLogout } = useRequest(logout, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.success) {
        Toast.show({
          icon: 'success',
          content: '退出登录成功',
        });
        
        setTimeout(() => {
          openUrl({
            url: '/loginPage',
            params: {
              type: currentConfig?.sysType,
            },
            isRoute: true,
          });
        }, 2000);
      }
    }
  });

  const renderItem = (item: any) => {
    if (!item.control || item.control === platformContext.getPlatformType()) {
      return (<List.Item
        extra={
          <div className={styles.avatarWrapper}>
            <RightOutline className={styles.arrowIcon} />
          </div>
        }
        arrow={false}
        onClick={() => openUrl({ url: item.url, isRoute: true })}
      >
        {item.title}
      </List.Item>)
    }
    return null;
  }
  return (
    <div className={styles.container}>
      <List className={styles.infoList}>
        {settingList.map((item) => (
          renderItem(item)
        ))}
      </List>
      <div className={styles.bottomFixedContainer} style={{ display: needLogout === 'true' || platformContext.isH5Browser() ? 'block' : 'none' }}>
        <div className={styles.verifyButtons}>
          <Button 
            className={styles.verifyButton} 
            block 
            onClick={() => setVisible(true)}
            shape="rounded"
            size="large"
          >
            退出登录
          </Button>
        </div>
      </div>
      <Modal
        title='确认退出登录'
        visible={visible}
        content={
          <div className={styles.dialogContainer}>
            <Grid className={styles.dialogGrid} columns={2} gap={8}>
              <Grid.Item>
                <Button className={styles.continueBtn} block size="middle" onClick={() => {
                  runLogout()
                  setVisible(false)
                }}>
                  退出
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button className={styles.backBtn} block size="middle" onClick={() => {
                  setVisible(false)
                }}>
                  取消
                </Button>
              </Grid.Item>
            </Grid>
          </div>
        }
      />
    </div>
  );
}

export default Settings;