import { Tabs } from "antd-mobile";
import React, { useEffect, useRef } from "react";

import classNames from "classnames";

import styles from "./index.module.scss";

export interface ITabsItemData {
  title: string;
  key: string;
}

interface ITabsProps {
  activeKey?: string;
  onChange: (key: string) => void;
  /** 外层、内侧模式 */
  model: "out" | "in";
  itemData: ITabsItemData[];
  children?: React.ReactNode;
  className?: string;
  /** tab 标题样式 */
  tabTitleClassName?: string;
  /** tab 标题激活样式 */
  activeTitleClassName?: string;
  stretch?: boolean;
}

/** tab  */
const Tab: React.FC<ITabsProps> = (props) => {
  const {
    activeKey,
    onChange,
    model = "out",
    itemData,
    children,
    className,
    tabTitleClassName,
    activeTitleClassName,
    stretch = false,
  } = props;
  const renderTabsTab = (item: ITabsItemData) => {
    return (
      <Tabs.Tab
        // destroyOnClose
        title={
          <span
            className={classNames(styles.tabsItem, tabTitleClassName, {
              [styles.activeTab]: activeKey === item.key,
              [styles.noActiveIn]: activeKey !== item.key && model === "in",
              [activeTitleClassName || ""]: activeKey === item.key,
            })}
          >
            {item.title}
          </span>
        }
        key={item.key}
      >
        {children}
      </Tabs.Tab>
    );
  };
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
    }
    window.addEventListener("scroll", () => {
      console.log("ref", ref.current?.offsetTop);
    });
  }, []);
  return (
    <div className={styles.tab} ref={ref}>
      <Tabs
        stretch={stretch}
        className={classNames(
          {
            [styles.outTabs]: model === "out",
            [styles.inTabs]: model === "in",
          },
          className
        )}
        activeKey={activeKey}
        onChange={onChange}
        activeLineMode="fixed"
      >
        {itemData?.map((item) => renderTabsTab(item))}
      </Tabs>
    </div>
  );
};

export default Tab;
