import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./index.module.scss";
import usePageTitle from "@/hooks/usePageTitle";
import Table, { IColumn } from "../../components/Table";
import { useRequest } from "ahooks";
import { DatePicker } from "antd-mobile";
import PageLoading from "@/common/Component/PageLoading";

import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import Tab from "@/common/Component/Tabs";
import { DownFill } from "antd-mobile-icons";

import { mobileMyCountMonth } from "../../services/mobileMyCountMonth";
import { PickerDate } from "antd-mobile/es/components/date-picker/util";
import {
  FeeType,
  mobileMyPerfPage,
  IListItem,
} from "../../services/mobileMyPerfPage";
interface PersonalPromotionProps {}
const pageSize = 10;

/** 合伙人-个人-我的推广 */
const PersonalPromotion: React.FC<PersonalPromotionProps> = (props) => {
  //   const {} = props;
  usePageTitle({ title: "我的推广" });
  const [search] = useSearchParams();
  // const partnerId = search.get("partnerId");
  const pageNum = useRef(1);
  const totalItem = useRef<Array<IListItem>>([]);
  const [activeKey, setActiveKey] = useState<FeeType>("enterprise");
  const [visible, setVisible] = useState(false);
  const [date, setDate] = useState<Date>(new Date());

  const changeActiveKey = (key: string) => {
    totalItem.current = [];
    setActiveKey(key as FeeType);
    pageRun({
      pageNum: 1,
      pageSize,
      // partnerId: partnerId || "",
      feeType: key as FeeType,
      signDate: dayjs(date).format("YYYYMM"),
    });
  };

  const {
    runAsync: pageRun,
    data: pageData,
    loading: pageLoading,
  } = useRequest(mobileMyPerfPage, {
    defaultParams: [
      {
        pageNum: pageNum.current,
        pageSize,
        signDate: dayjs(date).format("YYYYMM"),
        feeType: activeKey,
        // partnerId: partnerId || "",
      },
    ],
    onSuccess: (res, params) => {
      totalItem.current?.push(...(res.data?.list || []));
      pageNum.current = params[0].pageNum || 1;
    },
  });
  const {
    data: monthCount,
    loading: monthCountLoading,
    run: monthCountRun,
  } = useRequest(mobileMyCountMonth, {
    manual: true,
  });

  const loadMore = async () => {
    await pageRun({
      pageNum: pageNum.current + 1,
      pageSize,
      feeType: activeKey,
      signDate: dayjs(date).format("YYYYMM"),
      // partnerId: partnerId || undefined,
    });
  };
  const hasMore = useMemo(() => {
    return (pageData?.data?.total || 0) > (totalItem.current?.length || 0);
  }, [pageData]);

  useEffect(() => {
    monthCountRun({ monthId: dayjs(date).format("YYYYMM") });
  }, []);
  const columns = useMemo(() => {
    const renderSignDate = (date: string) => {
      if (!date) return "";
      return dayjs(`${date}`).format("MM/DD");
    };
    const shop: IColumn[] = [
      {
        title: "商户",

        dataIndex: "promotionEntityName",
        span: 1,
      },
      {
        title: "签约时间",
        dataIndex: "signDate",
        render: renderSignDate,
        span: 1,
      },
      // {
      //   title: "合同时间",
      //   dataIndex: "contractTerm",
      //   span: 1,
      // },
    ];
    const enterprise: IColumn[] = [
      {
        title: "组织",
        dataIndex: "promotionEntityName",
        span: 1,
      },
      {
        title: "签约时间",
        dataIndex: "signDate",
        render: renderSignDate,
        span: 1,
      },
      // {
      //   title: "合同时间",
      //   dataIndex: "contractTerm",
      //   span: 1,
      // },
    ];
    const referral: IColumn[] = [
      {
        title: "广告",
        dataIndex: "promotionEntityName",
        span: 1,
      },
      {
        title: "签约时间",
        dataIndex: "signDate",
        render: renderSignDate,
        span: 1,
      },
      // {
      //   title: "合同时间",
      //   dataIndex: "contractTerm",
      //   span: 1,
      // },
    ];
    const data: Record<FeeType, IColumn[]> = {
      shop: shop,
      enterprise: enterprise,
      referral: referral,
    };
    // data[activeKey].unshift({
    //   title: "月份",
    //   dataIndex: "signDate",
    //   span: 1,
    // });
    return data[activeKey];
  }, [activeKey]);
  const tabs = useMemo(() => {
    const tabData = [
      {
        title: `组织安装(${monthCount?.data?.enterpriseNum || 0})`,
        key: "enterprise",
      },
      {
        title: `商户邀约(${monthCount?.data?.shopNum || 0})`,
        key: "shop",
      },
      // {
      //   title: `广告推广(${monthCount?.data?.referralNum || 0})`,
      //   key: "referral",
      // },
    ];

    return tabData;
  }, [monthCount]);
  const onConfirm = (val: PickerDate) => {
    totalItem.current = [];
    setDate(val);
    const newDate = dayjs(val).format("YYYYMM");
    pageRun({
      pageNum: 1,
      pageSize,
      signDate: newDate,
      feeType: activeKey,
      // partnerId: partnerId || undefined,
    });
    monthCountRun({ monthId: newDate });
  };
  return (
    <div className={styles.page}>
      <PageLoading loading={pageLoading || monthCountLoading} />

      <DatePicker
        title="时间选择"
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        max={new Date()}
        value={date}
        onConfirm={onConfirm}
        precision={"month"}
      >
        {(value) => (
          <div onClick={() => setVisible(true)} className={styles.header}>
            <span className={styles.title}>
              {dayjs(value).format("YYYY年MM月")}
            </span>
            <DownFill className={styles.icon} />
          </div>
        )}
      </DatePicker>

      <Tab
        model="out"
        itemData={tabs}
        activeKey={activeKey}
        onChange={changeActiveKey}
        stretch
        className={styles.tab}
        tabTitleClassName={styles.tabTitle}
        activeTitleClassName={styles.activeTabTitle}
      />
      <div className={styles.content}>
        <Table
          className={styles.table}
          bodyClassName={styles.tableBody}
          columns={columns}
          dataSource={totalItem.current}
          loadMore={loadMore}
          hasMore={hasMore}
        />
      </div>
    </div>
  );
};

export default React.memo(PersonalPromotion);
