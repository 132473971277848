import axios, { IBaseRes } from "@/common/Utils/APIClient";
interface ICheckCreditCodeReq {
  creditCode: string;
  applyOrgId: string;
}
interface ICheckCreditCodeRes {}

/**
 * 企业申请入驻申请-校验社会信用代码
 */
export const checkCreditCode = (
  data: ICheckCreditCodeReq
): Promise<IBaseRes<boolean>> => {
  return axios.post("/dmall-account/Enterprise/check/creditCode", data,{
    param: data,
  });
};
