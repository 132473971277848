import axios, { IBaseRes } from "@/common/Utils/APIClient";

interface IParams {
  partnerId?: string;
}

interface IRes {
  /**
   * 商户邀约数（餐补企业）
   */
  shopNum: number;
  /**
   * 商户推荐数（广告）
   */
  referralNum: number;
  /**
   * 组织安装数（到店）
   */
  enterpriseNum: number;
}

/**
 * 合伙人业绩汇总byId
 */
export const mobilePerfSummary = (params: IParams): Promise<IBaseRes<IRes>> => {
  return axios.get("/dmall_statistic/partnerPerf/mobile/perfSummary", {
    param: params,
  });
};
