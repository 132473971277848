import React from "react";
import { List, Avatar } from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";
import usePageTitle from "@/hooks/usePageTitle";
import styles from "./index.module.scss";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { useShallow } from "zustand/react/shallow";
import useUserStore from "@/store/global";

function PersonalInfo() {
  usePageTitle({ title: "个人资料" });
  const openUrl = useOpenUrl();
  const [userInfo, platformContext] = useUserStore(
    useShallow((state) => [
      state.userInfo,
      state.platformContext,
    ])
  );

  const avatar = userInfo?.userAvatar || "https://cdn.wuhuxianmai.cn/feed/1733196886985-80e6857f7a91674dd710945b825be538541e93aa47c448c04935a64ca4395cae";
  const nickname = userInfo?.userName || '';
  const phone = userInfo?.userMobile || '';

  return (
    <div className={styles.container}>
      <List className={styles.infoList}>
        <List.Item
          extra={
            <div className={styles.avatarWrapper}>
              <Avatar src={avatar} className={styles.avatar} />
              <RightOutline className={styles.arrowIcon} />
            </div>
          }
          arrow={false}
          onClick={() => openUrl({
            url: "/personalInfo/editAvatar",
            isRoute: true,
          })}
        >
          头像
        </List.Item>
        <List.Item
          extra={
            <div className={styles.valueWrapper}>
              <span className={styles.value}>{nickname}</span>
              <RightOutline className={styles.arrowIcon} />
            </div>
          }
          arrow={false}
          onClick={() => openUrl({
            url: "/personalInfo/editNick",
            isRoute: true,
          })}
        >
          昵称
        </List.Item>
        {platformContext.isH5Browser() && (
          <List.Item
            extra={
              <div className={styles.valueWrapper}>
                <span className={styles.value}>{phone}</span>
                <RightOutline className={styles.arrowIcon} />
              </div>
            }
            arrow={false}
            onClick={() => openUrl({
              url: "/personalInfo/changePhoneInfo",
              isRoute: true,
            })}
          >
            手机号码
          </List.Item>
        )}
      </List>
    </div>
  );
}

export default PersonalInfo;