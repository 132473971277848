import axios from "@/common/Utils/APIClient";
export interface IVerifyReq {
  /**
   * 企业id
   */
  enterpriseId?: number;
  /**
   * 企业编码
   */
  enterpriseCd?: string;
  /**
   * 企业名称
   */
  enterpriseName?: string;
  /**
   * 统一社会信用代码
   */
  creditCode?: string;
  /**
   * 营业执照
   */
  creditCert: string;
  /**
   * 管理员姓名
   */
  adminName?: string;
  /**
   * 管理员手机号
   */
  adminMobile: string;
  /**
   * 联系人
   */
  contactName?: string;
  /**
   * 手机号
   */
  contactPhone?: string;
  /**
   * 联系人证件
   */
  contactCert?: string;
  /**
   * 餐补账户 ID
   */
  accountId?: number;
  /**
   * 申请时间
   */
  applyTime?: string;
  /**
   * 申请状态
   */
  applyStatus?: number;
  /**
   * 申请单备
   */
  applyNote?: string;
  /**
   * 扩展信息
   */
  extInfo?: string;
  /**
   * 审核时间
   */
  reviewTime?: string;
  /**
   * 审核人
   */
  reviewBy?: number;
  /**
   * 状态
   */
  status?: number;
  /**
   * 创建时间
   */
  createAt?: string;
  /**
   * 更新时间
   */
  updateAt?: string;
  /**
   * 租户ID
   */
  tanentId?: number;
  /**
   * 创建人
   */
  createBy?: number;
  /**
   * 更新人
   */
  updateBy?: number;
  /**
   * 	注册组织/员工ID
   */
  applyOrgId: number;
}

export interface IVerifyRes {
  code: string;
  data: boolean;
  msg: string;
  success: boolean;
}

/**
 * 企业申请入驻申请校验
 */
export const enterpriseVerify = (data: IVerifyReq): Promise<IVerifyRes> => {
  return axios.post("/dmall-account/Enterprise/verify", data);
};
