import React, { useCallback } from "react";
import { List, Checkbox, Popover } from "antd-mobile";
import { QuestionCircleOutline } from "antd-mobile-icons";
import { regFenToYuan, getBankCardLastFour } from "src/common/Utils";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { useRequest } from "ahooks";
import { accounts } from "../../Services";
import { Decimal } from "decimal.js";

import styles from "./index.module.scss";

const FeeAmount = (props: any = {}) => {
  const { feeAmount, methodRate } = props;

  if (!feeAmount || feeAmount === 0) {
    return null;
  }

  const feeTips = `当您使用本地生活收银台支付时，基于平台技术开发及运营维护成本，本地生活平台将按照订单总价${new Decimal(methodRate).mul(100).toString()}%的标准向您收取技术服务费。`;

  return (
    <div className={styles.list}>
      <div>
        <span className={styles.detalText}>技术服务费 </span>
        <Popover
          mode="dark"
          placement="bottom"
          content={feeTips}
          trigger="click"
        >
          <QuestionCircleOutline color="#86909C" className={styles.question} fontSize={15} />
        </Popover>
      </div>
      <span className={styles.detalValue}>¥{regFenToYuan(feeAmount)}</span>
    </div>
  );
};

const ListItem = (props: any) => {
  const {
    onChange,
    checked,
    feeAmount = 0,
    payAmount = 0,
    cardNumber,
    balance = 0,
    canBeChecked = true,
  } = props || {};
  
  return (
    <React.Fragment>
      <List.Item className={styles.item} extra={<Checkbox className={styles.checkbox} onChange={onChange} checked={checked} disabled={!canBeChecked} />}>
        <div className={styles.detalTitle}>钱包（{getBankCardLastFour(cardNumber)}）</div>
        {
          balance < feeAmount + payAmount ? 
          <div className={styles.detalText}>余额不足，剩余: &yen;{regFenToYuan(balance)}</div> : 
          <div className={styles.detalText}>可用: &yen;{regFenToYuan(balance)}</div>
        }

      </List.Item>
    </React.Fragment>
  );
};

function OneCard(props: any) {
  const { payInfo, onChangePayInfo, data = {}, orderInfo = {} } = props;
  const { payAmount = 0 } = orderInfo || {};
  const { feeAmount = 0, methodId, methodCd, methodRate } = data || {};
  const { payMethodId, accountId } = payInfo || {};
  const { data: accountsData } = useRequest(accounts);
  const bankCards = accountsData?.data || [];
  const openUrl = useOpenUrl();

  const onClick = useCallback(() => {
    openUrl({
      url: '/openPay',
      isRoute: true,
      params: { 
        redirect: encodeURIComponent(window.location.href)
      },
    });
  }, []);

  const renderOpen = () => {
    if (bankCards.length === 0) {
      return (
        <List.Item className={styles.add} clickable onClick={onClick}>
          开通芜湖本地生活余额支付
        </List.Item>
      )
    }
  }

  const renderFee = (payAmount: number, feeAmount: number, methodRate: number) => {
    return (
      <List.Item className={styles.detail}>
        <div className={styles.list}>
          <span className={styles.detalText}>商品总价格</span>
          <span className={styles.detalValue}>¥{regFenToYuan(payAmount)}</span>
        </div>
        <FeeAmount feeAmount={feeAmount} methodRate={methodRate} />
      </List.Item>
    )
  }

  return (
    <div className={styles.app}>
      <List>
        {renderOpen()}
        {
          bankCards.map((el: any, index: number) => {
            const { bankCardNo: bankCardNoCurrent, opPhone, balance, accountId: accountIdCurrent, pwdPay } = el || {};
            const checked = payMethodId === methodId && accountId === accountIdCurrent;
            const canBeChecked = balance >= feeAmount + payAmount ? true : false;

            return (
              <ListItem
                key={bankCardNoCurrent}
                checked={checked}
                canBeChecked={canBeChecked}
                feeAmount={feeAmount}
                payAmount={payAmount}
                balance={balance}
                cardNumber={getBankCardLastFour(bankCardNoCurrent)}
                onChange={() =>
                  onChangePayInfo({
                    payMethodCd: methodCd,
                    payMethodId: methodId,
                    accountId: accountIdCurrent,
                    phone: opPhone,
                    pwdPay,
                    feeAmount,
                  })
                }
              />
            );
          })
        }
        {
          payMethodId === methodId && renderFee(payAmount, feeAmount, methodRate)
        }
      </List>
    </div>
  );
}

export default OneCard;
