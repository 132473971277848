import React, { useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import Tab from "@/common/Component/Tabs";
import Buttons from "@/common/Component/Button";
import Icon from "@/common/Component/Icon";
import { useOpenUrl } from "@/hooks/useOpenUrl";
// import { useSearchParams } from "react-router-dom";
import { useRequest } from "ahooks";
import { getPartnerCode } from "../../services/getPartnerCode";
import PageLoading from "@/common/Component/PageLoading";
import copy from "copy-to-clipboard";
import { SafeArea, Toast } from "antd-mobile";
import usePartnerStore from "@/store/partnerStore";
import { useShallow } from "zustand/react/shallow";
import { IdenEnum } from "../../enum";
import { ExclamationCircleOutline } from "antd-mobile-icons";

enum TabKeyEnum {
  /** 组织安装 */
  Org = "org",
  /** 商户邀约 */
  Merchant = "merchant",
  /** 广告推广 */
  Advertising = "advertising",
}

interface PersonalCodeProps {}
/** 个人-我的推广码 */
const PersonalCode: React.FC<PersonalCodeProps> = (props) => {
  // const [search] = useSearchParams();
  // const code = search.get("code");
  // const code = search.get("showSetting");
  const [checkType] = usePartnerStore(useShallow((state) => [state.checkType]));
  const [activeKey, setActiveKey] = React.useState("0");
  const openUrl = useOpenUrl();
  const {
    data: partnerCodeData,
    // run: getPartnerCodeRun,
    loading: getPartnerCodeLoading,
  } = useRequest(getPartnerCode);
  // useEffect(() => {
  //   if (code) {
  //     getPartnerCodeRun({ unionCode: code });
  //   }
  // }, [code]);
  const changeTab = (key: string) => {
    setActiveKey(key as TabKeyEnum);
  };
  // 点击我的推广
  const onClickMyPromotion = useCallback(() => {
    // 跳转我的推广
    openUrl({
      url: "/partner/personal/promotion",
      isRoute: true,
      isOpenHalf: false,
    });
  }, []);
  const setting = useCallback(() => {
    // 跳转设置
    openUrl({
      url: "/partner/settings",
      isRoute: true,
      isOpenHalf: false,
      params: {
        logout: true,
      },
    });
  }, []);
  const tabData = useMemo(() => {
    return (
      partnerCodeData?.data?.list?.map((item, index) => {
        return {
          title: item.title,
          data: item,
          key: index.toString(),
        };
      }) || []
    );
  }, [partnerCodeData]);
  const activeData = useMemo(() => {
    return tabData[Number(activeKey)]?.data || {};
  }, [tabData, activeKey]);
  const actionList = useMemo(() => {
    const data = [
      {
        title: "我的推广",
        icon: "icon-wodetuiguang",
        onClick: onClickMyPromotion,
      },
    ];
    if (checkType?.iden === IdenEnum.Common) {
      data.push({
        title: "设置",
        icon: "icon-shezhi",
        onClick: setting,
      });
    }
    return data;
  }, [onClickMyPromotion, setting, checkType]);
  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <PageLoading loading={getPartnerCodeLoading} />
        <div className={styles.content}>
          <Tab
            model="out"
            itemData={tabData}
            activeKey={activeKey}
            onChange={changeTab}
            stretch
            className={styles.tab}
          />
          {partnerCodeData?.data && !partnerCodeData.data?.status && (
            <div className={styles.warningTip}>
              <span className={styles.icon}>
                <ExclamationCircleOutline className={styles.warningIcon} />
              </span>
              <span className={styles.warningText}>
                您已经与{partnerCodeData?.data?.corpName}
                停止合作，继续推广将不会计算业绩和佣金
              </span>
            </div>
          )}
          <div className={styles.qrContainer}>
            <div className={styles.qrTitle}>手机扫描二维码</div>
            <div className={styles.qrCodeBody}>
              <img
                className={styles.qrCodeImage}
                src={activeData?.content}
                alt="二维码"
              />
            </div>
            <div className={styles.qrCodeText}>
              {activeData.name}：{partnerCodeData?.data?.unionCode}
            </div>
            <Buttons
              block
              color="primary"
              onClick={() => {
                copy(
                  `${window.location.origin}/partnerCode/${partnerCodeData?.data?.unionCode}`
                );
                Toast.show("复制成功");
              }}
              customColor="black"
              className={styles.button}
            >
              分享我的邀请码
            </Buttons>
          </div>
        </div>
        <div className={styles.actionList}>
          {/* <div className={styles.action} onClick={onClickMyPromotion}>
          <div className={styles.actionContent}>
            <Icon className={styles.icon} icon="icon-dingwei1" />
            <span className={styles.actionText}>我的推广</span>
          </div>
          <Icon className={styles.actionIcon} icon="icon-fanhui" />
        </div> */}
          {actionList?.map((item, index) => {
            return (
              <div key={index} className={styles.action} onClick={item.onClick}>
                <div className={styles.actionContent}>
                  <Icon className={styles.icon} icon={item.icon} />
                  <span className={styles.actionText}>{item.title}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ background: "transparent" }}>
        <SafeArea position="bottom" />
      </div>
    </div>
  );
};

export default React.memo(PersonalCode);
