import axios, { IBaseRes } from "@/common/Utils/APIClient";

interface IParams {
  // /** 状态 */
  // status: number;
  // /** 创建时间 */
  // createAt: string;
  // /** 更新时间 */
  // updateAt: string;
  // /** 租户 */
  // tenantId: number; // 修正了字段名，假设应该是 tenantId 而不是 tanentId
  // /** 创建人 */
  // createBy: number;
  // /** 更新人 */
  // updateBy: number;
  // /** 企业id */
  // enterpriseId: number;
  // /** 企业编码 */
  // enterpriseCd: string;
  // /** 企业名称 */
  // enterpriseName: string;
  // /** 企业简称 */
  // enterpriseShortName: string;
  // /** 地址 */
  // enterpriseAddress: string;
  // /** 社会信用代码 */
  // creditCode: string;
  // /** 营业执照 */
  // creditCert: string;
  // /** 法人姓名 */
  // legalPersonName: string;
  // /** 法人手机号 */
  // legalPersonPhone: string;
  // /** 管理员姓名 */
  // adminName: string;
  // /** 管理员手机号 */
  // adminMobile: string;
  // /** 联系人 */
  // contactName: string;
  // /** 手机号 */
  // contactPhone: string;
  // /** 联系人证件 */
  // contactCert: string;
  // /** 餐补账户ID */
  // accountId: number;
  // /** 餐补卡号 */
  // bankCardNo: string;

  // /** 申请时间 */
  // applyTime: string;
  // /** 餐补申请状态 */
  // applyStatus: number;
  // /** 申请单备注 */
  // applyNote: string;
  // /** 扩展信息 */
  // extInfo: string;
  // /** 审核时间 */
  // reviewTime: string;
  // /** 审核人 */
  // reviewBy: number;
  // /** 签约合伙人 */
  // partnerId: number;
  // /** 餐补入驻合同流程id */
  // esignFlowId: string;
  // /** 合伙人推广码 */
  // partnerUnionCode: string;
  // /** 折扣申请时间 */
  // discountApplyTime: string;
  // /** 折扣申请状态 */
  // discountApplyStatus: number;
  // /** 企业折扣合同流程id */
  // discountEsignFlowId: string;

  // /** 企业码状态 */
  // enterpriseCodeStatus: number;
  /** 企业码账户 */
  enterpriseCodeAccount: string;
  /** 注册组织ID */
  applyOrgId: string;
}

interface IRes {}
/**
 * 
企业码代运营申请
 */
export const agentOperationApply = (
  data: IParams
): Promise<IBaseRes<boolean>> => {
  return axios.post("/dmall_account/Enterprise/code/apply", data);
};
