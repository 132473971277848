import React from "react";
import { Form, Input, Button, Toast } from "antd-mobile";
import { useRequest } from "ahooks";
import usePageTitle from "@/hooks/usePageTitle";
import { updateUserInfo, getSmsVerificationCode, checkPhoneRegistered } from "../../Services";
import SmsVerificationButton from "@/common/Component/SmsVerification";

import styles from "./index.module.scss";
import { useShallow } from "zustand/react/shallow";
import useUserStore from "@/store/global";

interface FormValues {
  phone: string;
  code: string;
}

function ChangePhone() {
  usePageTitle({ title: "更换手机号" });
  const [form] = Form.useForm<FormValues>();
  const [platformContext] = useUserStore(
    useShallow((state) => [
      state.platformContext,
    ])
  );

  const { loading, run: runUpdateUserInfo } = useRequest(updateUserInfo, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.success) {
        Toast.show({
          icon: 'success',
          content: '更换手机号成功，请重新登录',
        });

        platformContext.gotoLoginPage();
      }
    }
  });

  // 检查手机号是否已注册
  const { run: checkPhone } = useRequest(
    (phone: string, context?: any) => checkPhoneRegistered(phone),
    {
      manual: true,
      onSuccess: (result: any, params: [string, any?]) => {
        const [_, context] = params.length > 1 ? params : [null, undefined];
        
        if (result.success && result.data) {
          // 手机号已注册，不能使用已注册的手机号
          Toast.show({
            icon: "fail",
            content: "该手机号已被注册，请更换其他手机号",
          });
          form.setFields([
            {
              name: 'phone',
              errors: ['该手机号已被注册，请更换其他手机号'],
            },
          ]);
          // 如果是通过获取验证码触发的检查，返回失败结果
          if (context && context.resolve) {
            context.resolve(false);
          }
        } else if (result.success && !result.data) {
          // 手机号未注册，可以发送验证码
          const phone = form.getFieldValue('phone');
          if (phone) {
            getSmsCode(phone);
            // 如果是通过获取验证码触发的检查，返回成功结果
            if (context && context.resolve) {
              context.resolve(true);
            }
          }
        }
      }
    }
  );

  // 获取验证码
  const { loading: smsLoading, run: getSmsCode } = useRequest(getSmsVerificationCode, {
    manual: true,
    onSuccess: (result) => {
      if (result.success) {
        Toast.show({
          content: '验证码已发送',
        });
      }
    }
  });

  const onFinish = async (values: FormValues) => {
    // 提交前再次检查手机号是否已注册
    const result = await checkPhoneRegistered(values.phone);
    if (result.success && result.data) {
      Toast.show({
        icon: "fail",
        content: "该手机号已被注册，请更换其他手机号",
      });
      return;
    }
    
    runUpdateUserInfo({
      phone: values.phone,
      verificationCode: values.code // Use code as password for reset
    });
  };

  const handleGetSmsCode = () => {
    return form.validateFields(['phone']).then(values => {
      // 先检查手机号是否已注册
      return new Promise<boolean>((resolve) => {
        const context = { resolve };
        checkPhone(values.phone, context);
        // 验证结果会在checkPhone的回调中通过resolve返回
      });
      // 在 checkPhone 请求的回调里处理发送验证码
    }).catch(() => {
      Toast.show({
        icon: "fail",
        content: "请输入正确的手机号",
      });
      return false;
    });
  };

  // 检查手机号是否已注册的处理函数
  const handlePhoneBlur = () => {
    const phone = form.getFieldValue('phone');
    if (phone && /^1[3-9]\d{9}$/.test(phone)) {
      checkPhone(phone);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>更换手机号</h1>
      <div className={styles.description}>
        更换后可使用新手机号登录，更换成功后将与原手机号解绑
      </div>
      <div className={styles.formContainer}>
        <Form
          form={form}
          layout="horizontal"
          mode="card"
          onFinish={onFinish}
          footer={
            <Button 
              block 
              type="submit" 
              color="primary" 
              loading={loading}
              className={styles.submitButton}
            >
              确定
            </Button>
          }
          style={{
            '--border-inner': 'none',
          }}
        >
          <Form.Item
            name="phone"
            label="新手机号"
            validateTrigger={['onBlur']}
            rules={[
              { required: true, message: '请输入新手机号' },
              { pattern: /^1[3-9]\d{9}$/, message: "请输入正确的手机号" }
            ]}
          >
            <Input placeholder="请输入新手机号" onBlur={handlePhoneBlur} />
          </Form.Item>
          
          <Form.Item
            name="code"
            label="验证码"
            validateTrigger={['onBlur']}
            rules={[{ required: true, message: '请输入验证码' }]}
            extra={
              <div className={styles.verifyCodeButton}>
                <SmsVerificationButton
                  onGetCode={handleGetSmsCode}
                  loading={smsLoading}
                />
              </div>
            }
          >
            <Input type="tel" maxLength={6} pattern="[0-9]*" inputMode="numeric" placeholder="请输入验证码" />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default ChangePhone;