import React from "react";
import styles from "./index.module.scss";
import Icon from "@/common/Component/Icon";
import classNames from "classnames";
interface CardProps {
  children?: React.ReactNode;
  title?: React.ReactNode;
  titleClick?: () => void;
  extra?: React.ReactNode;
  className?: string;
}

const Card: React.FC<CardProps> = (props) => {
  const { children, title, titleClick, extra, className } = props;
  return (
    <div className={classNames(styles.card, className)}>
      {title && (
        <div
          className={classNames(styles.header, {
            [styles.actionHeader]: titleClick,
          })}
          onClick={titleClick}
        >
          <div className={styles.title}>{title}</div>
          <span>
            {extra}
            {titleClick && (
              <Icon className={styles.actionIcon} icon="icon-fanhui" />
            )}
          </span>
        </div>
      )}
      <div
        className={classNames(styles.body, { [styles.noHeaderBody]: !title })}
      >
        {children}
      </div>
    </div>
  );
};

export default React.memo(Card);
