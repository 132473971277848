import axios, { IBaseRes } from "@/common/Utils/APIClient";

interface IParams {
  partnerId?: string;
  pageSize: number;
  pageNum: number;
}

interface IRes {
  list: IListItemDay[];
  pages: number;
  total: number;
}
export interface IListItemDay {
  /**
   * 天数ID
   * @example 20250101
   */
  daysId: number;
  /** 商户邀约数（餐补企业） */
  shopNum: number;
  /** 商户推荐数（广告） */
  referralNum: number;
  /** 组织安装数（到店） */
  enterpriseNum: number;
}

/**
 * 合伙人日业绩分页列表byId

 */
export const mobilePerfPageDay = (params: IParams): Promise<IBaseRes<IRes>> => {
  return axios.get("/dmall_statistic/partnerPerf/day/mobile/perfPage", {
    param: params,
  });
};
