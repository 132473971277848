import React, { useState, useRef } from "react";
import { Dropdown, Grid, Selector, Input, DatePicker, Button, NumberKeyboard, Mask } from "antd-mobile";
import dayjs from 'dayjs';
import { pxToRem } from "@/common/Utils/rem";
import { DropdownRef } from "antd-mobile/es/components/dropdown";
import styles from "./index.module.scss";
import { IParamData } from "./Services";

interface IFormData {
  timeType: string[];
  beginDate: string;
  endDate: string;
  beginAmount: string;
  endAmount: string;
}

interface TimeSelectProps {
  onSubmit: (params: IParamData) => void;
}

const initialData: IFormData = {
  timeType: ['all'],
  beginDate: '',
  endDate: '',
  beginAmount: '',
  endAmount: '',
};

function TimeSelect({ onSubmit }: TimeSelectProps) {
  const [visible, setVisible] = useState<boolean>(false);
  const [numberKeyboardVisible, setNumberKeyboardVisible] = useState<boolean>(false);
  const [data, setData] = useState<IFormData>(initialData);
  const [showTitle, setShowTitle] = useState<string>('全部');
  const [activeInput, setActiveInput] = useState<'start' | 'end'>('start');
  const [activeAmount, setActiveAmount] = useState<'low' | 'high'>('low');
  const dropdownRef = useRef<DropdownRef>(null)
  const now = dayjs().startOf('day')

  const options = [
    { label: '全部', value: 'all' },
    { label: '近一个月', value: '1' },
    { label: '近三个月', value: '3' },
    { label: '近六个月', value: '6' },
    { label: '近一年', value: '12' },
  ];

  const timeSelect = (arr: string[]) => {
    const time = arr[0];
    let beginDate;
    let endDate;
    if (time === 'all') {
      beginDate = '';
      endDate = ''
    } else {
      beginDate = now.subtract(Number(time), 'month').startOf('day').format('YYYY-MM-DD');
      endDate = now.format('YYYY-MM-DD');
    }

    setData({
      ...data,
      timeType: arr,
      beginDate,
      endDate,
    });
  }

  const reset = () => {
    setData(initialData);
  }
  
  const setDefaultDate = () => {
    if (activeInput === 'start') {
      return data.beginDate ? new Date(data.beginDate) : now.toDate()
    } else if (activeInput === 'end') {
      return data.endDate ? new Date(data.endDate) : now.toDate()
    }
  }

  const onNumberKeyboardInput = (val: string) => {
    if (activeAmount === 'low') {
      setData({
        ...data,
        beginAmount: data.beginAmount + val
      })
    } else if (activeAmount === 'high') {
      setData({
        ...data,
        endAmount: data.endAmount + val
      })
    }
  }

  const onNumberKeyboardDelete = () => {
    if (activeAmount === 'low') {
      setData({
        ...data,
        beginAmount: data.beginAmount.slice(0, data.beginAmount.length - 1)
      })
    } else if (activeAmount === 'high') {
      setData({
        ...data,
        endAmount: data.endAmount.slice(0, data.endAmount.length - 1)
      })
    }
  }

  const onNumberKeyboardClose = () => {
    setNumberKeyboardVisible(false)
  }

  const processTitle = (params: IFormData) => {
    const { beginDate, endDate } = params;
    if (beginDate && endDate && beginDate !== endDate) {
      return `${beginDate} - ${endDate}`
    } else if (beginDate) {
      return `${beginDate}`
    } else if (endDate) {
      return `${endDate}`
    } else {
      return '全部'
    }
  }

  const handleSubmit = () => {
    const params: IParamData = {}
    setShowTitle(processTitle(data))
    data.beginDate && (params.beginDate = `${data.beginDate} 00:00:00`)
    data.endDate && (params.endDate = `${data.endDate} 23:59:59`)
    data.beginAmount && (params.beginAmount = Number(data.beginAmount) * 100)
    data.endAmount && (params.endAmount = Number(data.endAmount) * 100)

    if (!data.beginDate || !data.endDate) {
      if (data.beginDate) {
        params.endDate = `${data.beginDate} 23:59:59`
      }
      if (data.endDate) {
        params.beginDate = `${data.endDate} 00:00:00`
      }
    }
    if (data.beginAmount && data.endAmount && data.beginAmount > data.endAmount) {
      params.beginAmount = Number(data.endAmount) * 100
      params.endAmount = Number(data.beginAmount) * 100
    }

    onSubmit(params);
    dropdownRef.current?.close();
  }

  return (
    <div className={styles.container}>
      <Dropdown ref={dropdownRef}>
          <Dropdown.Item key='sorter' title={showTitle}>
            <div className={styles.selectorContainer}>
              <div className={styles.selectorTitle}>入账时间</div>
              <Selector
                style={{
                  '--text-color': '#4E5969',
                  '--border-radius': pxToRem(16),
                }}
                options={options}
                columns={3}
                showCheckMark={false}
                // defaultValue={timeType}
                value={data.timeType}
                onChange={timeSelect}
              />
            </div>
            <div className={styles.selectorContainer}>
              <div className={styles.selectorTitle}>自定义</div>
              <Grid columns={19} gap={0}>
                <Grid.Item
                  span={9}
                  onClick={() => {
                    setActiveInput('start');
                    setVisible(true);
                  }}
                >
                  <Input
                    placeholder='自定义开始时间'
                    className={styles.dateInput}
                    value={data.beginDate}
                    readOnly
                  />
                </Grid.Item>
                <Grid.Item span={1}>
                  <div className={styles.dateSeparator}></div>
                </Grid.Item>
                <Grid.Item
                  span={9}
                  onClick={() => {
                    setActiveInput('end');
                    setVisible(true);
                  }}
                >
                  <Input
                    placeholder='自定义结束时间'
                    className={styles.dateInput}
                    value={data.endDate}
                    readOnly
                  />
                </Grid.Item>
              </Grid>
            </div>
            <div className={styles.selectorContainer}>
              <div className={styles.selectorTitle}>账单金额</div>
              <Grid columns={19} gap={0}>
                <Grid.Item
                  span={9}
                  onClick={() => {
                    setActiveAmount('low');
                    setNumberKeyboardVisible(true);
                  }}
                >
                  <Input
                    placeholder='&yen;&nbsp;最低金额'
                    className={styles.dateInput}
                    value={data.beginAmount}
                    readOnly
                    onChange={val => {
                      setData({
                        ...data,
                        beginAmount: val,
                      })
                    }}
                  />
                </Grid.Item>
                <Grid.Item span={1}>
                  <div className={styles.dateSeparator}></div>
                </Grid.Item>
                <Grid.Item
                  span={9}
                  onClick={() => {
                    setActiveAmount('high');
                    setNumberKeyboardVisible(true);
                  }}
                >
                  <Input
                    placeholder='&yen;&nbsp;最高金额'
                    className={styles.dateInput}
                    value={data.endAmount}
                    readOnly
                    onChange={val => {
                      setData({
                        ...data,
                        endAmount: val,
                      })
                    }}
                  />
                </Grid.Item>
              </Grid>
            </div>
            <div className={styles.selectorContainer}>
              <Grid columns={19}>
                <Grid.Item span={9}>
                  <Button
                    // className={styles.resetButton}
                    style={{
                      '--background-color': '#FFFFFF',
                      '--border-radius': pxToRem(44),
                      '--border-width': pxToRem(2),
                      'height': pxToRem(88),
                    }}
                    block
                    onClick={() => {
                      reset()
                    }}
                  >
                    重置
                  </Button>
                </Grid.Item>
                <Grid.Item span={1}>
                </Grid.Item>
                <Grid.Item span={9}>
                  <Button
                    style={{
                      'background': 'linear-gradient( 269deg, #33AFFF 0%, #1777FF 100%)',
                      '--border-radius': pxToRem(44),
                      '--border-width': pxToRem(2),
                      '--text-color': '#FFFFFF',
                      'height': pxToRem(88),
                    }}
                    block
                    onClick={handleSubmit}
                  >
                    确定
                  </Button>
                </Grid.Item>
              </Grid>
            </div>
          </Dropdown.Item>
        </Dropdown>
        <DatePicker
          title='时间选择'
          visible={visible}
          onClose={() => {
            setVisible(false)
          }}
          value={setDefaultDate()}
          max={now.toDate()}
          onConfirm={val => {
            const date = val.toISOString().split('T')[0]
            if (activeInput === 'start') {
              setData({
                ...data,
                timeType: [],
                beginDate: date,
              })
            } else {
              setData({
                ...data,
                timeType: [],
                endDate: date,
              })
            }
          }}
        />
        <NumberKeyboard
          visible={numberKeyboardVisible}
          onClose={onNumberKeyboardClose}
          onInput={onNumberKeyboardInput}
          onDelete={onNumberKeyboardDelete}
          showCloseButton={false}
          confirmText='确定'
        />
        <Mask
          style={{
            '--z-index': '1020',
          }}
          visible={numberKeyboardVisible}
          onMaskClick={() => setNumberKeyboardVisible(false)}
        />

    </div>
  );
}

export default TimeSelect;