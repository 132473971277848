import usePageTitle from "@/hooks/usePageTitle";
import { getLocation, IDingLocationRes } from "@/services/dd/getLocation";
import useUserStore from "@/store/global";
import { useRequest } from "ahooks";
import { Ellipsis, Image, Skeleton, Tabs } from "antd-mobile";
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useShallow } from "zustand/react/shallow";
import styles from "./index.module.scss";
import CloseInList from "./components/CloseInList";
import Location, {
  ILocation,
  ILocationRef,
} from "@/common/Component/Location/v2";
import OrderOnline from "./components/OrderOnline";
import { useSearchParams } from "react-router-dom";
import PopupMap, { PopupMapRef } from "@/common/Component/PopupMap";
import PopupMapDD, { PopupMapDDRef } from "@/common/Component/PopupMapDD";
import Icon from "@/common/Component/Icon";
import StorageService from "@/common/Utils/store";
import { PlatformType } from "@/common/Utils";
import { IDingLocateInMapRes } from "@/services/dd/locateInMap";
import { getEnvImageUrl } from "@/common/Utils/env";

interface IndexV2Props {}
enum TabKeyEnum {
  /**
   * 火热招商
   */
  HOT = "hot",
  /**
   * 在线点餐
   */
  ORDER_ONLINE = "online",
  /**
   * 爆品推荐
   */
  RECOMMEND = "recommend",
  /**
   * 附近商家
   */
  NEARBY = "nearby",
}

const IndexV2: React.FC<IndexV2Props> = (props) => {
  usePageTitle({ title: "到店" });

  const [search, setSearch] = useSearchParams();
  const defaultKey = (search.get("key") || TabKeyEnum.RECOMMEND) as TabKeyEnum;
  const [activeKey, setActiveKey] = useState<TabKeyEnum>(defaultKey);
  const locationRef = useRef<ILocationRef>(null);
  const mapRef = useRef<PopupMapRef>(null);
  const mapDDRef = useRef<PopupMapDDRef>(null);

  const [isInstallApp, dingLocation, dingLocationLoading] = useUserStore(
    useShallow((state) => [
      state.isInstallApp,
      state.dingLocation,
      state.dingLocationLoading,
    ])
  );

  const [gaoDeAddress, setGaoDeAddress] = useState<ILocation>();
  const [gaoDeLoading, setGaodeLoading] = useState(false);
  const [ddAddress, setDDAddress] = useState<IDingLocateInMapRes>();

  const addressData: IDingLocateInMapRes | undefined = useMemo(() => {
    if (ddAddress?.latitude && ddAddress?.longitude) {
      return ddAddress;
    }
    if (gaoDeAddress?.lat && gaoDeAddress?.lng) {
      const data: IDingLocationRes = {
        success: true,
        latitude: gaoDeAddress.lat?.toString?.(),
        longitude: gaoDeAddress.lng?.toString?.(),
        city: gaoDeAddress?.result?.regeocode.addressComponent.city,
        province: gaoDeAddress?.result?.regeocode.addressComponent.province,
        district: gaoDeAddress?.result?.regeocode.addressComponent.district,
      };
      return data;
    }
    return undefined;
  }, [gaoDeAddress, ddAddress]);

  const renderMapAction = (address?: string) => {
    return (
      <div
        className={styles.location}
        onClick={() => {
          if (hasShowDingLocation) {
            mapDDRef.current?.open();
          } else {
            mapRef.current?.open();
          }
        }}
      >
        <div className={styles.locationContent}>
          <Icon className={styles.mapIcon} icon="icon-dingwei1" />
          <Ellipsis
            className={styles.mapText}
            direction="end"
            content={
              gaoDeLoading || dingLocationLoading
                ? "获取位置中"
                : address || "定位失败，请重新选择"
            }
          />
        </div>
        <Icon className={styles.openIcon} icon="icon-fanhui" />
      </div>
    );
  };
  const hasShowDingLocation = useMemo(() => {
    const platformType = StorageService.shared.getItem(
      "platformType"
    ) as PlatformType;
    if (platformType === PlatformType.DINGTALK && isInstallApp) {
      return true;
    }
    if (platformType === PlatformType.DINGTALK_H5 && isInstallApp) {
      return true;
    }
    return false;
  }, [isInstallApp]);

  useLayoutEffect(() => {
    if (!hasShowDingLocation) {
      setGaodeLoading(true);
      locationRef.current?.getLocation(
        {
          onOk: (addrsss) => {
            console.log("location", addrsss);
            setGaoDeAddress(addrsss);
            setGaodeLoading(false);
          },
          onError: () => {
            console.log("error");
            setGaodeLoading(false);
          },
        },
        true
      );
    }
  }, [hasShowDingLocation]);
  useEffect(() => {
    console.log("dingLocation---", dingLocation);
    if (dingLocation && hasShowDingLocation) {
      setDDAddress({
        success: true,
        /** 详细地址 */
        snippet: dingLocation?.address,
        /** 区名称 */
        adName: dingLocation?.district,
        /** 距离 */
        // distance: string,
        /** 省名称 */
        province: dingLocation?.province,
        /** 市名称 */
        city: dingLocation?.city,
        /** 市代码 */
        cityCode: dingLocation?.cityAdcode,
        /** 省代码 */
        // provinceCode: res.code,
        /** 区代码 */
        adCode: dingLocation?.districtAdcode,
        /** 纬度 */
        latitude: dingLocation?.latitude,
        /** 邮编 */
        // postCode: res.po,
        /** POI名称 */
        title: dingLocation?.address,
        /** 经度 */
        longitude: dingLocation?.longitude,
        /**
         *
         */
        // poiSource: res.pois,
      });
    }
  }, [dingLocation, dingLocationLoading, hasShowDingLocation]);

  return (
    <div className={styles.page}>
      <div className={styles.bannerContainer}>
        <Image
          className={styles.banner}
          placeholder={<Skeleton.Paragraph lineCount={5} animated />}
          // src="https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/%E5%88%B0%E5%BA%97%E8%83%8C%E6%99%AF-1A0E3BDF-4CD8-4DA9-ADCB-FEDC397CE436.png"
          src={getEnvImageUrl(
            "%E5%88%B0%E5%BA%97%E8%83%8C%E6%99%AF-1A0E3BDF-4CD8-4DA9-ADCB-FEDC397CE436.png"
          )}
        />
        {!hasShowDingLocation && (
          <PopupMap
            ref={mapRef}
            disabledForm
            v2
            render={renderMapAction}
            className={styles.map}
            value={gaoDeAddress}
            onChange={(v) => {
              console.log("change-map", v);
              setGaoDeAddress(v);
            }}
          />
        )}
        {hasShowDingLocation && (
          <PopupMapDD
            ref={mapDDRef}
            render={renderMapAction}
            className={styles.map}
            value={ddAddress}
            onChange={(v) => {
              console.log("change-dd", v);

              setDDAddress(v);
            }}
          />
        )}
      </div>
      <Tabs
        activeLineMode="fixed"
        className={styles.tabs}
        defaultActiveKey={defaultKey}
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key as TabKeyEnum);
          // search.set("key", key as TabKeyEnum)
          // setSearch({ key });
        }}
      >
        <Tabs.Tab title="火热招商" key={TabKeyEnum.HOT} />
        <Tabs.Tab title="在线点餐" key={TabKeyEnum.ORDER_ONLINE} />

        <Tabs.Tab title="爆品推荐" key={TabKeyEnum.RECOMMEND} />
        <Tabs.Tab title="离我最近" key={TabKeyEnum.NEARBY} />
      </Tabs>
      {activeKey === TabKeyEnum.NEARBY && (
        <div className={styles.content}>
          <CloseInList addressData={addressData} />
        </div>
      )}
      {activeKey === TabKeyEnum.RECOMMEND && (
        <div className={styles.content}>
          <CloseInList hot addressData={addressData} />
        </div>
      )}
      {activeKey === TabKeyEnum.ORDER_ONLINE && <OrderOnline />}
      {activeKey === TabKeyEnum.HOT && (
        <div
          className={styles.content}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <Image
            className={styles.banner}
            src="https://wanmi-ares.oss-cn-hangzhou.aliyuncs.com/upload/%E7%81%AB%E7%83%AD%E6%8B%9B%E5%95%86-f564eab4-f0b3-4b1b-a59f-39c3e9e1abf1.png"
          />
        </div>
      )}
      {!hasShowDingLocation && <Location ref={locationRef} />}
    </div>
  );
};

export default React.memo(IndexV2);
