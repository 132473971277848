import { useParams } from 'react-router-dom';
import { useOpenUrl } from '@/hooks/useOpenUrl';
import Bind from './components/Bind';
import Result from './components/Result';
import usePageTitle from '@/hooks/usePageTitle';

const EnterpriseCodeAuth = () => {
  const params = useParams();
  const step = params.step ? Number(params.step) : 1;
  const openUrl = useOpenUrl();

  usePageTitle({ title: '到店折扣授权开通' });

  const handleBindSuccess = () => {
    openUrl({
      url: '/apply/enterprise/code/2',
      isRoute: true,
    });
  };

  return step === 1 ? <Bind onSuccess={handleBindSuccess} /> : <Result />;
};

export default EnterpriseCodeAuth;
