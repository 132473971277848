import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { disableIOSWebView } from "./services/dd/webView";
import globalStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";
import { useSetCorpId } from "./hooks/useSetCorpId";
import { useHideDingRight } from "./hooks/useHideDingRight";
import { Container } from "./common/Utils/container";
import StorageService from "./common/Utils/store";

interface IAppProps {}

/** 根应用  */
const App: React.FC<IAppProps> = (props) => {
  const {} = props;
  const [setAppType, setClientId, updatePlatformContext, platformContext] = globalStore(
    useShallow((state) => [
      state.setAppType, 
      state.setClientId, 
      state.updatePlatformContext,
      state.platformContext,
    ])
  );

  useSetCorpId();
  useHideDingRight();
  
  useEffect(() => {
    // 保持原有的初始化逻辑
    Container.setAppType();
    setAppType();
        
    // 获取当前平台类型
    updatePlatformContext();
    const platformType = platformContext.getPlatformType();
    
    console.log('当前平台环境:', platformType);
    
    // 根据不同环境执行对应的初始化操作
    if (platformContext.isDingTalk()) {
      console.log('钉钉环境初始化');
      disableIOSWebView();
    } else if (platformContext.isDingTalkH5()) {
      console.log('钉钉H5环境初始化');
      // 这里可以添加微信环境特有的初始化操作
    } else {
      console.log('H5浏览器环境初始化');
      // 这里可以添加H5环境特有的初始化操作
    }
    
    setClientId();
        
    // 可以将平台类型存储在本地存储中，方便后续使用
    StorageService.shared.setItem('platformType', platformType);
  }, []);

  return <RouterProvider router={router} />;
};

export default React.memo(App);
