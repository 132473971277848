import usePageTitle from "@/hooks/usePageTitle";
import React from "react";
import styles from "./index.module.scss";
import { Form, Toast } from "antd-mobile";
import FormItem from "../../components/FormItem";
import Input from "../../components/Input";
import Buttons from "@/common/Component/Button";
import { useRequest } from "ahooks";
import { detail } from "../../services/detail";
import { useSearchParams } from "react-router-dom";
import PageLoading from "@/common/Component/PageLoading";
import { modifyBank } from "../../services/modifyBank";
import { useOpenUrl } from "@/hooks/useOpenUrl";

interface ManageBankEditProps {}
const requiredRule = {
  required: true,
  message: "必填",
};

/**
 * 企业合伙人-信息管理-收款银行账户信息

 */
const ManageBankEdit: React.FC<ManageBankEditProps> = (props) => {
  // const {} = props;
  usePageTitle({ title: "收款银行账户信息" });
  const [search] = useSearchParams();
  const partnerId = search.get("partnerId");
  const openUrl = useOpenUrl();
  const [form] = Form.useForm();
  const {
    run: detailRun,
    loading: detailLoading,
    // data: detailData,
  } = useRequest(detail, {
    manual: true,
    onSuccess: (res) => {
      if (res.success && res.data) {
        form.setFieldsValue({
          bankName: res.data.partnerBankDto?.bankName,
          bankNo: res.data.partnerBankDto?.bankNo,
          openingBank: res.data.partnerBankDto?.openingBank,
        });
      }
    },
  });
  const { run: modifyBankRun, loading: modifyBankLoading } = useRequest(
    modifyBank,
    {
      manual: true,
      onSuccess: (res) => {
        if (res.success) {
          Toast.show({
            content: "修改成功",
            icon: "success",
          });
          openUrl({
            url: "/partner/manage/info",
            isOpenHalf: false,
            isRoute: true,
            params: {
              partnerId,
            },
          });
        }
      },
    }
  );
  const submit = () => {
    form.submit();
  };
  const onFinish = (values: any) => {
    console.log(values);
    partnerId &&
      modifyBankRun({
        partnerId,
        bankName: values.bankName,
        bankNo: values.bankNo,
        openingBank: values.openingBank,
      });
  };
  React.useEffect(() => {
    if (partnerId) {
      detailRun({ partnerId });
    }
  }, [partnerId]);
  return (
    <div className={styles.page}>
      <PageLoading loading={detailLoading} />
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
        className={styles.form}
      >
        <FormItem
          className={styles.formItem}
          label="开户银行"
          name="bankName"
          rules={[requiredRule]}
          required
        >
          <Input placeholder="请输入银行名称" />
        </FormItem>
        <FormItem
          className={styles.formItem}
          label="开户名"
          name="openingBank"
          rules={[requiredRule]}
          required
        >
          <Input placeholder="请输入姓名" />
        </FormItem>
        <FormItem
          className={styles.formItem}
          label="银行账户"
          name="bankNo"
          rules={[requiredRule]}
          required
        >
          <Input placeholder="请输入银行卡号" />
        </FormItem>
      </Form>
      <div className={styles.footer}>
        <Buttons
          loading={modifyBankLoading}
          block
          color="primary"
          onClick={submit}
          customColor="black"

        >
          提交
        </Buttons>
      </div>
    </div>
  );
};

export default React.memo(ManageBankEdit);
