import React from "react";
import { Form, Input, Button, Toast } from "antd-mobile";
import { useRequest } from "ahooks";
import usePageTitle from "@/hooks/usePageTitle";
import { unregister } from "../../Services";
import styles from "./index.module.scss";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";

interface FormValues {
  phone: string;
  password: string;
}

function CancelAccount() {
  usePageTitle({ title: "注销账户" });
  const [platformContext] = useUserStore(
    useShallow((state) => [
      state.platformContext,
    ])
  );
  const [form] = Form.useForm<FormValues>();

  const { loading, run: runUnregister } = useRequest(unregister, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.success) {
        Toast.show({
          icon: 'success',
          content: '账户注销成功',
        });
        
        platformContext.gotoLoginPage();
      }
    }
  });

  const onFinish = (values: FormValues) => {
    runUnregister({
      phone: values.phone,
      password: values.password
    });
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>注销账号</h1>
      <div className={styles.description}>
        账户注销后不可恢复，您将无法使用芜湖本地生活APP，对应相关权益将同时全部作废。
      </div>
      <div className={styles.formContainer}>
        <Form
          form={form}
          layout="horizontal"
          mode="card"
          onFinish={onFinish}
          footer={
            <Button 
              block 
              type="submit" 
              color="primary" 
              loading={loading}
              className={styles.submitButton}
            >
              注销账号
            </Button>
          }
          style={{
            '--border-inner': 'none',
          }}
        >
          <Form.Item
            name="phone"
            label="手机号"
            rules={[{ required: true, message: '请输入手机号' }]}
          >
            <Input placeholder="请输入手机号" />
          </Form.Item>
          
          <Form.Item
            name="password"
            label="密码"
            rules={[
              { required: true, message: '请输入密码' },
            ]}
          >
            <Input type="password" placeholder="请输入密码" />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default CancelAccount;