import apiClient, { IBaseRes } from "@/common/Utils/APIClient";
interface IUserInfo {
  nickName: string;
  avatar: string;
  phone: string;
  verificationCode: string;
}

export const updateUserInfo = (params: Partial<IUserInfo>): Promise<IBaseRes<any>> => {
  return apiClient.post("/dmall-customer/buyer/update", params);
};

/**
 * 获取短信验证码
 */
export function getSmsVerificationCode(phone?: string): Promise<IBaseRes<any>> {
  return apiClient.get('/dmall-customer/ua/buyer/sendSmsCode', {
    param: {
      phone: phone || '',
      type: '9',
    },
  });
}

interface IVerifyCodeParams {
  code: string;
}

/**
 * 验证短信验证码
 */
export function VerifyCode(params: IVerifyCodeParams): Promise<IBaseRes<any>> {
  return apiClient.post('/dmall_customer/buyer/verify', {
    code: params.code,
    type: 'sms',
    smsVerifyCodeTypeIndex: 9,
  });
}

/**
 * 检查手机号是否已注册
 */
export function checkPhoneRegistered(phone: string): Promise<IBaseRes<any>> {
  return apiClient.get('/dmall-auth/ua/is_registered', {
    param: {
      phone,
      sysType: 0,
    },
  });
}