import React, { useRef, useState } from "react";
import { useRequest } from "ahooks";
import { useParams } from "react-router-dom";
import { Tabs } from "antd-mobile";
import Swiper, { SwiperRef } from 'antd-mobile/es/components/swiper'
import PasswordSetting from "../../Components/PasswordSetting";
import SmsSetting from "../../Components/SmsSetting";
import { pxToRem } from "@/common/Utils/rem";
import { accounts } from "../../Services";

import styles from './index.module.scss';

function ChangePassword() {
  const { accountId } = useParams<{ accountId: string }>();
  const swiperRef = useRef<SwiperRef>(null);
  const [activeIndex, setActiveIndex] = useState(0);


  const { data: accountsData } = useRequest(accounts);
  const bankCards = accountsData?.data || [];
  const account = bankCards.find((card:any) => card.accountId === accountId);


  const successCallback = () => {
    setTimeout(() => {
      window.history.back();
    }, 2000);
  }

  const data = [{
      title: '通过密码修改',
      content: <PasswordSetting account={account} successCallback={successCallback} />
    }, {
      title: '通过验证码修改',
      content: <SmsSetting account={account} successCallback={successCallback} />
    }
  ]

  if (!accountsData) {
    return null;
  }


  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <Tabs
          activeLineMode='fixed'
          activeKey={activeIndex.toString()}
          onChange={(key) => {
            const index = Number(key)
            setActiveIndex(index)
            swiperRef.current?.swipeTo(index)
          }}
          style={{
            '--active-line-height': pxToRem(8),
            '--fixed-active-line-width': pxToRem(40),
            '--title-font-size': pxToRem(28),
            '--active-line-color': '#1879FF',
            '--active-line-border-radius': pxToRem(4),
            '--active-title-color': '#242931',
          }}
          className={styles.tabs}
        >
          {data.map((item, index) => (
            <Tabs.Tab title={item.title} key={index} />
          ))}
        </Tabs>
        <Swiper
          className={styles.swiper}
          direction='horizontal'
          loop
          indicator={() => null}
          ref={swiperRef}
          defaultIndex={activeIndex}
          onIndexChange={index => {
            setActiveIndex(index)
          }}
        >
          {data.map((item, index) => (
            <Swiper.Item key={index}>
              {item.content}
            </Swiper.Item>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default ChangePassword;