import axios, { IBaseRes } from "@/common/Utils/APIClient";
interface ICheckEnterpriseNameReq {
  enterpriseName: string;
  applyOrgId: string;
}
interface ICheckEnterpriseNameRes {}

/**
 * 企业申请入驻申请-校验企业名称
 */
export const checkEnterpriseName = (
  data: ICheckEnterpriseNameReq
): Promise<IBaseRes<boolean>> => {
  return axios.post("/dmall-account/Enterprise/check/enterpriseName", data, {
    param: data,
  });
};
