import React from "react";
import DefaultInfo from "@/common/Component/DefaultInfo";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";

function NoMatch() {
  const [platformContext] = useUserStore(
    useShallow((state) => [
      state.platformContext,
    ])
  );
  
  return (
    <DefaultInfo
      imgSrc="https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/404error.png"
      title="系统异常"
      btnText="返回首页"
      btnLink={platformContext.getHomePage().url}
    />
  );
}

export default NoMatch;