/**
 * 平台策略模式
 * 用于判断当前运行环境（钉钉、微信、普通H5浏览器）并提供相应的策略处理
 */

import * as dd from 'dingtalk-jsapi';
import { Toast } from 'antd-mobile';
import { getAuthCode } from '@/services/dd/getAuthCode';
import { getDingUser, UserInfoData } from '@/services/user/getDingUser';
import { getBuyerUser } from '@/services/user/getBuyerUser';
import { openAuthDialog, userDingDingAuthFieldScope, userDingDingAuthRpcScope } from '@/services/dd/openAuth';
import { userDingConfirm } from '@/services/user/userDingConfirm';
import { getDingUserById } from '@/services/user/getDingUserById';
import { Container } from "@/common/Utils/container";

interface IOpenUrlParams {
  url: string;
  isRoute: boolean;
}

export enum PlatformType {
  DINGTALK = 'dingtalk',
  DINGTALK_H5 = 'dingtalk_h5',
  H5 = 'h5'
}

/**
 * 平台策略接口
 */
export interface IPlatformStrategy {
  /**
   * 获取平台类型
   */
  getPlatformType(): PlatformType;
  
  /**
   * 获取首页路径
   */
  getHomePage(): IOpenUrlParams;

  /**
   * 获取登录页路径
   */
  getLoginPage(): string;

  /**
   * 跳转到登录页
   */
  gotoLoginPage(redirectUrl?: string): void;

  /**
   * 判断当前是否为该平台
   */
  isCurrentPlatform(): boolean;

  /**
   * 获取饿了么渠道key
   */
  getElemeChannelKey(): string;

  /**
   * 获取钉钉用户信息
   */
  getUserInfo(corpId?: string, clientId?: string): Promise<UserInfoData | undefined>;
}

/**
 * 钉钉平台策略
 */
class DingTalkStrategy implements IPlatformStrategy {
  protected static instance: DingTalkStrategy;
  
  protected constructor() {}
  
  static getInstance(): DingTalkStrategy {
    if (!DingTalkStrategy.instance) {
      DingTalkStrategy.instance = new DingTalkStrategy();
    }
    return DingTalkStrategy.instance;
  }
  
  getPlatformType(): PlatformType {
    return PlatformType.DINGTALK;
  }
  
  getHomePage(): IOpenUrlParams {
    return {
      url: '/tabBar',
      isRoute: true
    }
  }
  
  isCurrentPlatform(): boolean {
    // 判断是否在钉钉环境中
    return dd?.env?.platform !== 'notInDingTalk';
  }

  getLoginPage(): string {
    return '/login';
  }

  gotoLoginPage(redirectUrl?: string): void {
    const route = redirectUrl ? `?route=${redirectUrl}` : '';
    window.location.href = this.getLoginPage() + route;
  }

  getElemeChannelKey(): string {
    return 'app_1';
  }

  private async getAuthCodeFC(corpId: string): Promise<UserInfoData | undefined> {
    let userInfo: UserInfoData | undefined = undefined;

    const authCodeObj = await getAuthCode({ corpId }).catch((e) => {
      return { authCode: undefined };
    });
    if (authCodeObj?.authCode) {
      userInfo = await getDingUser({
        authCode: authCodeObj?.authCode,
        corpId: corpId,
      }).catch((e) => {
        return undefined;
      });
    }
    return userInfo;
  }

  async getUserInfo(corpId?: string, clientId?: string): Promise<UserInfoData | undefined> {
    if (corpId && clientId) {
      let userInfo: UserInfoData | undefined = undefined;
      userInfo = await this.getAuthCodeFC(corpId);
      if (!userInfo?.isGrant && userInfo?.unionId) {
        const openAuthData = await openAuthDialog({
          corpId,
          type: 0,
          rpcScope: userDingDingAuthRpcScope.ContactUserRead,
          fieldScope: userDingDingAuthFieldScope.ContactUserMobile,
          clientId,
        });
        console.log("openAuth", openAuthData);
        if (openAuthData.success && openAuthData.authCode) {
          const confirm = await userDingConfirm({
            authCode: openAuthData.authCode,
            corpId,
            unionId: userInfo?.unionId,
          });
          console.log("confirm", confirm);
          if (confirm.success) {
            userInfo = await this.getAuthCodeFC(corpId);
            console.log("userInfo2", userInfo);
          }
        } else {
          Toast.show({
            icon: "fail",
            content: openAuthData.message || "授权失败",
          });
        }
      }
      console.log("userInfo3", typeof userInfo);
      return userInfo;
    } else {
      console.log("skip");
      return undefined;
    }
  }
}

/**
 * 钉钉H5平台策略
 */
class DingTalkH5Strategy extends DingTalkStrategy {
  protected static override instance: DingTalkH5Strategy;
  
  protected constructor() {
    super();
  }
  
  static override getInstance(): DingTalkH5Strategy {
    if (!DingTalkH5Strategy.instance) {
      DingTalkH5Strategy.instance = new DingTalkH5Strategy();
    }
    return DingTalkH5Strategy.instance;
  }
  
  override getPlatformType(): PlatformType {
    return PlatformType.DINGTALK_H5;
  }
  
  override getHomePage(): IOpenUrlParams {
    return {
      url: 'https://n.dingtalk.com/dingding/ding-edu-life-home/city/index.html?cityCode=340200&corpId=ding89550f75d92db09824f2f5cc6abecb85&preview=true',
      isRoute: false,
    }
  }
  
  override isCurrentPlatform(): boolean {
    // 判断是否在钉钉环境中
    return dd?.env?.platform !== 'notInDingTalk' && Container.isH5();
  }

  override getLoginPage(): string {
    return '/login';
  }

  override gotoLoginPage(redirectUrl?: string): void {
    const route = redirectUrl ? `?route=${redirectUrl}` : '';
    window.location.href = this.getLoginPage() + route;
  }

  getElemeChannelKey(): string {
    return 'h5_1';
  }

  override async getUserInfo(corpId?: string, clientId?: string): Promise<UserInfoData | undefined> {
    // 导入全局状态
    const { default: useUserStore } = await import('@/store/global');
    const isInstallApp = useUserStore.getState().isInstallApp;

    // 如果已安装应用，调用父类方法
    if (isInstallApp) {
      return super.getUserInfo(corpId, clientId);
    }
    
    // 否则使用原逻辑
    const userInfo = await getDingUserById() as unknown as UserInfoData;
    return userInfo;
  }
}

/**
 * H5浏览器平台策略
 */
class H5Strategy implements IPlatformStrategy {
  private static instance: H5Strategy;
  
  private constructor() {}
  
  static getInstance(): H5Strategy {
    if (!H5Strategy.instance) {
      H5Strategy.instance = new H5Strategy();
    }
    return H5Strategy.instance;
  }
  
  getPlatformType(): PlatformType {
    return PlatformType.H5;
  }
  
  getHomePage(): IOpenUrlParams {
    return {
      url: '/tabBar',
      isRoute: true
    }
  }
  
  isCurrentPlatform(): boolean {
    // 如果既不是钉钉也不是微信，则默认为H5浏览器
    return !DingTalkStrategy.getInstance().isCurrentPlatform()
           && !DingTalkH5Strategy.getInstance().isCurrentPlatform();
  }

  getLoginPage(): string {
    return '/loginPage';
  }

  gotoLoginPage(redirectUrl?: string): void {
    const route = redirectUrl ? `?route=${redirectUrl}` : '';
    window.location.href = this.getLoginPage() + route;
  }

  getElemeChannelKey(): string {
    return 'h5_1';
  }

  async getUserInfo(): Promise<UserInfoData | undefined> {
    const userInfo = await getBuyerUser() as unknown as UserInfoData;
    return userInfo;
  }
}

/**
 * 平台工厂类
 * 根据当前环境返回对应的平台策略
 */
export class PlatformFactory {
  /**
   * 获取当前平台策略
   */
  static getCurrentPlatform(): IPlatformStrategy {
    if (DingTalkH5Strategy.getInstance().isCurrentPlatform()) {
      return DingTalkH5Strategy.getInstance();
    } else if (DingTalkStrategy.getInstance().isCurrentPlatform()) {
      return DingTalkStrategy.getInstance();
    } else {
      return H5Strategy.getInstance();
    }
  }
  
  /**
   * 获取指定类型的平台策略
   */
  static getPlatformByType(type: PlatformType): IPlatformStrategy {
    switch (type) {
      case PlatformType.DINGTALK:
        return DingTalkStrategy.getInstance();
      case PlatformType.DINGTALK_H5:
        return DingTalkH5Strategy.getInstance();
      case PlatformType.H5:
      default:
        return H5Strategy.getInstance();
    }
  }
}

/**
 * 平台上下文类
 * 用于在应用中使用平台策略
 */
export class PlatformContext {
  private strategy: IPlatformStrategy;
  
  constructor(strategy?: IPlatformStrategy) {
    this.strategy = strategy || PlatformFactory.getCurrentPlatform();
  }
  
  /**
   * 设置策略
   */
  setStrategy(strategy: IPlatformStrategy): void {
    this.strategy = strategy;
  }
  
  /**
   * 获取当前策略
   */
  getStrategy(): IPlatformStrategy {
    return this.strategy;
  }
  
  /**
   * 获取平台类型
   */
  getPlatformType(): PlatformType {
    return this.strategy.getPlatformType();
  }

  /**
   * 获取首页路径
   */
  getHomePage(): IOpenUrlParams {
    return this.strategy.getHomePage();
  }

  getLoginPage(): string {
    return this.strategy.getLoginPage();
  }

  gotoLoginPage(redirectUrl?: string): void {
    this.strategy.gotoLoginPage(redirectUrl);
  }

  /**
   * 获取钉钉用户信息
   */
  getUserInfo(corpId?: string, clientId?: string): Promise<UserInfoData | undefined> {
    return this.strategy.getUserInfo(corpId, clientId);
  }
  
  /**
   * 判断是否为钉钉环境
   */
  isDingTalk(): boolean {
    return this.strategy.getPlatformType() === PlatformType.DINGTALK;
  }
  
  /**
   * 判断是否为微信环境
   */
  isDingTalkH5(): boolean {
    return this.strategy.getPlatformType() === PlatformType.DINGTALK_H5;
  }

  getElemeChannelKey(): string {
    return this.strategy.getElemeChannelKey();
  }
  
  /**
   * 判断是否为H5浏览器环境
   */
  isH5Browser(): boolean {
    return this.strategy.getPlatformType() === PlatformType.H5;
  }
} 