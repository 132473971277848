import apiClient from "src/common/Utils/APIClient";

interface IRes {
  code: string;
  msg: string;
  data: any;
  success: boolean;
}

interface IParams {
  accountId: string;
  setType: number;
  payPassword: string;
  code: string;
}

const setPayPassword = (params: IParams): Promise<IRes> => {
  return apiClient.post("/dmall_account/Account/setPayPwd", params);
};


const accounts = (): Promise<IRes> => {
  return apiClient.get("/dmall_account/Account/accounts", {
    param: {
      noBalance: true
    }
  });
};

interface IVerificationCodeParams {
  accountId: string;
}

interface IVerificationCodeRes {
  success: boolean;
  msg: string;
  data: string;
  code: string;
}

/**
 * 获取短信验证码
 */
const getVerificationCode = (
  params: IVerificationCodeParams
): Promise<IVerificationCodeRes> => {
  return apiClient.get('/dmall_account/Account/sendSmsCode', { param: params });
};

export { setPayPassword, accounts, getVerificationCode };