import React from "react";
import styles from "./index.module.scss";
import Icon from "@/common/Component/Icon";

interface TipsProps {
  style?: React.CSSProperties;
  icon?: string;
  children?: React.ReactNode;
}

const Tips: React.FC<TipsProps> = (props) => {
  const { children, style, icon } = props;
  return (
    <div style={style} className={styles.tips}>
      <Icon icon={icon} className={styles.icon} />
      <span>{children}</span>
    </div>
  );
};

export default React.memo(Tips);
