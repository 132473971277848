import React, { useEffect } from "react";
import StorageService from "src/common/Utils/store";
import PageLoading from "src/common/Component/PageLoading";


function Logout() {

  useEffect(() => {
    StorageService.shared.removeToken();
    window.location.href = "/login?route=wallet"
  }, []);

  return <PageLoading />;
}

export default Logout;
