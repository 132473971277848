import { Toast } from "antd-mobile";
import * as dd from "dingtalk-jsapi";

export interface IDingLocationRes {
  country?: string;
  address?: string;
  LBSWuaCacheId?: string;
  city?: string;
  streetNumber?: string;
  netType?: string;
  latitude?: string;
  districtAdcode?: string;
  resultCode?: 0 | 1;
  errorMessage?: string;
  accuracy?: number;
  province?: string;
  road?: string;
  countryCode?: string;
  cityAdcode?: string;
  district?: string;
  time?: number;
  operatorType?: string;
  pois?: any[];
  longitude?: string;
  success: boolean;
}
/**
 * 获取当前位置
 */
export const getLocation = (): Promise<IDingLocationRes> => {
  return new Promise((resolve, reject) => {
    if (dd.env.platform !== "notInDingTalk") {
      dd.ready(() => {
        dd.getLocation({
          targetAccuracy: "200",
          cacheTimeout: 20,
          coordinate: "1",
          useCache: true,
          withReGeocode: true,
          type: 1,
          success: (res: IDingLocationRes) => {
            res.success = true;
            resolve(res);
          },
          fail: (error: any) => {
            console.log("获取位置信息失败11", error);
            Toast.show({
              icon: "fail",
              content: JSON.stringify(error),
            });
            resolve({ success: false });
            // reject(new Error("获取位置信息失败1"));
          },
          complete: () => {},
        });
      });
    } else {
      // reject(new Error("获取位置信息失败2"));
      resolve({ success: false });
    }
  });
};
