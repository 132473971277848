import React from "react";
import { Image, Swiper } from "antd-mobile";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import styles from "./index.module.scss";
import { IGetFeedInfoPageResResult } from "../../services/feedBanner";
import classNames from "classnames";

interface IBannerProps {
  bannerList?: IGetFeedInfoPageResResult[];
}

/** 轮播图  */
const Banner: React.FC<IBannerProps> = (props) => {
  const { bannerList } = props;
  const openUrl = useOpenUrl();

  return (
    <Swiper
      className={classNames(styles.bannerList, {
        [styles.hideIndicator]: (bannerList?.length || 0) <= 1,
      })}
    >
      {bannerList?.map((item, index) => {
        console.log("log", item);
        return (
          <Swiper.Item
            key={index}
            onClick={() => {
              openUrl({
                url: "/feedDetail",
                params: {
                  id: item.uniqueId,
                },
                isRoute: true,
              });
            }}
          >
            <Image
              fit="cover"
              src={item.coverImg || item.picUrl}
              alt=""
              className={styles.bannerImg}
            />
          </Swiper.Item>
        );
      })}
    </Swiper>
  );
};

export default Banner;
