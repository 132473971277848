import axios, { IBaseRes } from "@/common/Utils/APIClient";

interface IParams {
  partnerId?: string;
}

interface IRes {
  /**
   * 商户邀约数（餐补企业）
   */
  shopNum: number;
  /**
   * 合伙人基本信息
   */
  partnerInfo: PartnerInfo;
  /**
   * 商户推荐数（广告）
   */
  referralNum: number;
  /**
   * 全部收益
   */
  settledIncome: string;
  /**
   * 组织安装数（到店）
   */
  enterpriseNum: number;
  /**
   * 未结算收益
   */
  unSettledIncome: string;
}

interface PartnerInfo {
  applyStatus: number;
  applyTime: Date;
  auditBy: number;
  auditTime: Date;
  corpBankNo: string;
  corpOpenBank: string;
  creditCode: string;
  /**
   * 二维码
   */
  ddQr: string;
  /**
   * 企业名称
   */
  enterpriseName: string;
  /**
   * 创建时间
   */
  gmtCreate: Date;
  gmtModified: Date;
  id: string;
  idenNo: string;
  parentId: string;
  parentPartnerName: string;
  parentStatus: number;
  /**
   * 合伙人-企业名称
   */
  partnerName: string;
  /**
   * 合伙人-姓名
   */
  partnerPerson: string;
  partnerPersonMobile: string;
  partnerSource: number;
  /**
   * 合伙人-类型
   */
  partnerType: number;
  partnerTypeCn: string;
  partnerUnionCode: string;
  personBankNo: string;
  personOpenBank: string;
  promotionContent: string;
  settlementType: number;
  status: number;
  tjQr: string;
  yyQr: string;
}

/**
 * 合伙人移动端管理页总览
 */
export const mobileOverview = (params: IParams): Promise<IBaseRes<IRes>> => {
  return axios.get("/dmall_statistic/partnerPerf/mobile/overview", {
    param: params,
  });
};
