import React, { RefObject, useEffect, useMemo, useRef } from "react";
import { Form, Input, Picker, PickerRef, Space, Toast } from "antd-mobile";
import get from "lodash/get";
import usePageTitle from "@/hooks/usePageTitle";
import Button from "@/common/Component/Button";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import styles from "./index.module.scss";
import ApplyLayout from "@/common/Component/ApplyPage";
import { useRequest } from "ahooks";
import useGlobal from "@/store/global";
import { useShallow } from "zustand/react/shallow";
import PageLoading from "@/common/Component/PageLoading";
import { getSignUrl } from "../../services/getSignUrl";
import VerificationCode, { IRef } from "@/common/Component/VerificationCode";
import FormCardTitle from "@/common/Component/FormCardTitle";
import { phoneRegExp } from "@/common/Config/regExp";
import { sendSmsCode } from "../../services/sendSmsCode";
import { enterpriseApplyGet } from "../../services/enterpriseApplyGet";
import {
  getMealLatestSign,
  SignStatusEnum,
} from "@/pages/ArriveStore/service/getMealLatestSign";

interface IContractProps {}

/**  商户签约协议 */
const Contract: React.FC<IContractProps> = (props) => {
  const {} = props;
  usePageTitle({ title: "企业餐补入驻" });
  const [clientId, corpId] = useGlobal(
    useShallow((state) => [state.clientId, state.corpId])
  );
  const {
    data: applyData,
    // loading: getLoading,
    run: enterpriseApplyGetRun,
  } = useRequest(enterpriseApplyGet, {
    manual: true,
  });
  // getMealLatestSign
  const {
    data: signData,
    loading: getLoading,
    run: getMealLatestSignRun,
  } = useRequest(getMealLatestSign, {
    manual: true,
  });
  const protocol = [
    "欢迎加入本地生活服务平台！通过签约餐补入驻服务，您将能够轻松管理餐补发放，提升福利管理的便捷性；同时平台为员工提供多样化的餐饮选择，让员工在繁忙的工作中享受到便捷的餐饮选择，提升员工的满意度和工作积极性。",
    "期待与您的合作，携手打造更美好的员工福利体验！",
  ];
  const [visible, setVisible] = React.useState(false);

  const openUrl = useOpenUrl();
  const [form] = Form.useForm();
  const phone = Form.useWatch("phone", form);
  const codeRef = useRef<IRef>({});
  const okCode = (code: string, resData: string) => {
    Toast.show({
      icon: "success",
      content: "提交成功",
    });
    openUrl({
      url: resData,
      isRoute: false,
      isOpenHalf: false,
    });
  };
  useEffect(() => {
    corpId &&
      enterpriseApplyGetRun({ applyOrgId: corpId, hasDesensitize: true });
  }, [corpId]);
  useEffect(() => {
    if (applyData?.data.enterpriseId) {
      getMealLatestSignRun({
        enterpriseId: applyData?.data.enterpriseId,
      });
    }
  }, [applyData?.data.enterpriseId]);
  const selectNameData = useMemo(() => {
    const arr = [];
    if (applyData?.data?.legalPersonName) {
      arr.push({
        label: `法人-${applyData?.data?.legalPersonName}`,
        value: applyData?.data?.legalPersonPhone,
        phone: applyData?.data?.legalPersonPhone,
        name: applyData?.data?.legalPersonName,
      });
    }
    if (applyData?.data?.contactName) {
      arr.push({
        label: `联系人-${applyData?.data?.contactName}`,
        value: applyData?.data?.contactPhone,
        phone: applyData?.data?.contactPhone,
        name: applyData?.data?.contactName,
      });
    }
    arr.push({
      label: "其他",
      value: "3",
      name: "其他",
      phone: "",
    });
    if (arr.length) {
      return [arr];
    }
    return [];
  }, [applyData]);
  const name = Form.useWatch("name", form);
  const nameValue = get(name, "0");
  useEffect(() => {
    const name = signData?.data?.signPerson;
    const phone = signData?.data?.signPhone;
    const person = selectNameData[0].find((item) => item.phone === phone);
    if (name === "其他") {
      form.setFieldValue("name", ["3"]);
      return;
    }
    if (person && person.name === name) {
      form.setFieldValue("name", [person.phone]);
      form.setFieldValue("phone", person.phone);
    }
  }, [selectNameData, signData, form]);

  return (
    <PageLoading>
      <ApplyLayout>
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.title}>企业餐补入驻</div>
            <Space block direction="vertical" className={styles.protocol}>
              <div className={styles.protocolTop}>尊敬的企业客户：</div>
              {protocol.map((item, index) => {
                return (
                  <div className={styles.protocolItem} key={index}>
                    {item}
                  </div>
                );
              })}
            </Space>
          </div>
          <div className={styles.bottom}>
            <Form
              mode="card"
              form={form}
              layout="horizontal"
              disabled={signData?.data?.status === SignStatusEnum.SignSuccess}
              onFinish={(v) => {
                console.log("vvv", v);
                setVisible(true);
                codeRef.current?.focus?.();
              }}
            >
              <FormCardTitle
                title="合同签约人"
                subTitle="即将跳转“E签宝平台”签约，请选定签约人"
              />
              <Form.Item
                name="name"
                label="签约人"
                rules={[{ required: true, message: "必填" }]}
                trigger="onConfirm"
                onClick={(e, ref: RefObject<PickerRef>) => {
                  ref.current?.open();
                }}
              >
                <Picker
                  onConfirm={(value, extend) => {
                    form.setFieldValue("phone", get(extend, "items.[0].phone"));
                  }}
                  columns={selectNameData}
                >
                  {(value, actions) => {
                    return (
                      value.map((item) => item?.label)?.join("/") || "请选择"
                    );
                  }}
                </Picker>
              </Form.Item>
              <Form.Item
                name="phone"
                label="手机号"
                rules={[
                  { required: true, message: "必填" },
                  {
                    pattern: phoneRegExp,
                    message: "请正确填写手机号",
                  },
                ]}
                disabled={nameValue !== "3"}
              >
                <Input
                  className={styles.input}
                  placeholder="请输入手机号"
                  type="text"
                />
              </Form.Item>
              {!!signData?.data && (
                <Form.Item label="签约时间" disabled={true}>
                  <Input
                    className={styles.input}
                    type="text"
                    value={signData?.data.createAt}
                  />
                </Form.Item>
              )}
            </Form>
            <VerificationCode
              onClose={() => setVisible(false)}
              // onOk={save}
              ref={codeRef}
              phone={phone}
              visible={visible}
              onOk={okCode}
              getVerifyCode={(data) => {
                return sendSmsCode({
                  phone: data.phone,
                });
              }}
              verifyCode={(data) => {
                const values = form.getFieldsValue();
                if (clientId && applyData?.data?.enterpriseId && corpId) {
                  const nameValue = get(values, "name.0");
                  const name =
                    get(selectNameData, "0", []).find(
                      (item) => item.value === nameValue
                    )?.name || "";
                  return getSignUrl({
                    code: data.code,
                    phone: data.phone,
                    data: {
                      clientId,
                      name,
                      enterpriseId: applyData?.data?.enterpriseId,
                      applyOrgId: corpId,
                    },
                  });
                } else {
                  return Promise.reject(new Error("请先验证手机号"));
                }
              }}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            block
            className={styles.prevButton}
            onClick={() => {
              openUrl({
                isRoute: true,
                url: "/apply/enterprise/apply",
                isOpenHalf: false,
                options: {
                  replace: true,
                },
              });
            }}
          >
            上一步
          </Button>
          <Button
            block
            color="primary"
            onClick={() => {
              form.submit();
            }}
            className={styles.button}
          >
            {signData?.data?.status === SignStatusEnum.SignSuccess &&
              "查看签约"}
            {signData?.data === null && "在线签约"}
            {signData?.data?.status === SignStatusEnum.WatingESignFill ||
              (signData?.data?.status === SignStatusEnum.WatingESignSeal &&
                "继续签约")}
          </Button>
        </div>
      </ApplyLayout>
    </PageLoading>
  );
};

export default Contract;
