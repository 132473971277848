import axios, { IBaseRes } from "@/common/Utils/APIClient";
/**
 * 签署状态
 */
export enum SignStatusEnum {
  /**
   * 填写中
   */
  WatingESignFill = 6,
  /**
   * 签署中
   */
  WatingESignSeal = 7,
  /**
   * 签署完成
   */
  SignSuccess = 10,
  /**
   * 签署失败
   */
  SignFail = 20,
}

/**
 * 主体类型
 */
export enum EntityTypeEnum {
  /**
   * 商户入驻
   */
  MERCHANT = 1,
  /**
   * 餐补入驻
   */
  MEAL_SUPPLEMENT = 2,
  /**
   * 到店企业入驻
   */
  ARRIVE_STORE = 3,
}
interface IRes {
  /**
   * 签约流程id
   */
  flowId: string;
  /**
   * 状态
   */
  status: SignStatusEnum;
  /**
   * 创建时间
   */
  createAt: string;
  /**
   * 类型 1:商户入驻 2:餐补入驻 3:到店企业入驻
   */
  entityType: EntityTypeEnum;
  /**
   * 对应主体id
   */
  entityId: number;
  /**
   * 签约人姓名
   */
  signPerson: string;
  /**
   * 签约人手机号
   */
  signPhone: string;
}

interface IReq {
  enterpriseId: string;
}
/**
 * 查询最近的到店企业签约信息
 */
export const getEnterpriseLatestSign = (
  data: IReq
): Promise<IBaseRes<IRes | null>> => {
  return axios.get("/dmall_account/Enterprise/getEnterpriseLatestSign", {
    param: data,
  });
};
