import ApplyLayout from "@/common/Component/ApplyPage";
import FormCardTitle from "@/common/Component/FormCardTitle";
import FormItem from "@/common/Component/FormItem";
import {
  Form,
  ImageUploader,
  ImageUploadItem,
  Input,
  Toast,
} from "antd-mobile";
import React, { useEffect, useRef } from "react";
import { OSSUpload, UploadDirEnum } from "@/common/Utils/OssSignature";

import styles from "./index.module.scss";
import Buttons from "@/common/Component/Button";
import { useRequest } from "ahooks";
import { orcLicense } from "@/services/orc/orcLicense";
import usePageTitle from "@/hooks/usePageTitle";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { phoneRegExp } from "@/common/Config/regExp";
import get from "lodash/get";
import { discountApply } from "../../service/discountApply";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";
import { enterpriseApplyGet } from "@/pages/Enterprise/services/enterpriseApplyGet";
import PopupMapDD, { PopupMapDDRef } from "@/common/Component/PopupMapDD";
interface SettledInProps {}

const SettledIn: React.FC<SettledInProps> = (props) => {
  const {} = props;
  usePageTitle({ title: "开通企业专项折扣" });
  const [corpId] = useUserStore(useShallow((state) => [state.corpId]));
  const openUrl = useOpenUrl();

  const [form] = Form.useForm();
  const ossUpload = useRef(new OSSUpload({ uploadDir: UploadDirEnum.Test }));
  const { loading: applyLoading, run: applyRun } = useRequest(discountApply, {
    manual: true,
    onSuccess: (res) => {
      if (res.data && res.success) {
        Toast.show({
          icon: "success",
          content: "提交成功",
        });
        openUrl({
          url: "/arriveStore/settled/in/protocol",
          isRoute: true,
          isOpenHalf: false,
          params: {
            link: "false",
          },
        });
      }
    },
  });
  const {
    data: applyData,
    loading: getLoading,
    run: enterpriseApplyGetRun,
  } = useRequest(enterpriseApplyGet, {
    manual: true,
    onSuccess: (res) => {
      // 回显表单
      if (res.data && res.success) {
        form.setFieldsValue({
          creditCert: res.data?.creditCert
            ? [{ url: res.data?.creditCert }]
            : [],
          enterpriseName: res.data?.enterpriseName,
          enterpriseShortName: res.data?.enterpriseShortName,
          enterpriseAddress: res.data?.enterpriseAddress,
          legalPersonName: res.data?.legalPersonName,
          legalPersonPhone: res.data?.legalPersonPhone,
          contactName: res.data?.contactName,
          contactPhone: res.data?.contactPhone,
          partnerUnionCode: res.data?.partnerUnionCode,
          enterpriseAddressPoi: {
            snippet: res.data?.enterpriseAddressPoi?.address,
            adName: res.data?.enterpriseAddressPoi?.districtName,
            city: res.data?.enterpriseAddressPoi?.cityName,
            province: res.data?.enterpriseAddressPoi?.provinceName,
            latitude: res.data?.enterpriseAddressPoi?.latitude,
            longitude: res.data?.enterpriseAddressPoi?.longitude,
          },
        });
      }
    },
  });
  useEffect(() => {
    ossUpload.current.getOssSignature();
  }, []);
  useEffect(() => {
    corpId &&
      enterpriseApplyGetRun({ applyOrgId: corpId, hasDesensitize: true });
  }, [corpId]);
  const onSubmit = () => {
    form.submit();
  };
  const upload = (file: File): Promise<ImageUploadItem> =>
    new Promise((resolve, reject) => {
      ossUpload.current
        .upload(file)
        .then((res) => {
          if (res.success) {
            resolve({
              url: res.url || "",
            });
          } else {
            reject(new Error("上传失败"));
          }
        })
        .catch((e) => {
          reject(new Error("上传失败"));
        });
    });
  const onFinish = (values: any) => {
    console.log("Success:", values);
    const creditCert = get(values, "creditCert.0.url", "");
    applyRun({
      ...values,
      creditCert,
      applyOrgId: corpId,
      enterpriseAddressPoi: {
        provinceName: values?.enterpriseAddressPoi?.province,
        cityName: values?.enterpriseAddressPoi?.city,
        districtName: values?.enterpriseAddressPoi?.adName,
        address: values?.enterpriseAddressPoi?.snippet,
        latitude: values?.enterpriseAddressPoi?.latitude,
        longitude: values?.enterpriseAddressPoi?.longitude,
      },
    });
  };
  const popupMapRef = useRef<PopupMapDDRef>(null);
  const { run: orcLicenseRun } = useRequest(orcLicense, {
    manual: true,
    onSuccess: async (res) => {
      console.log("res", res);
      if (res.success) {
        // let begin = undefined;
        // let end = undefined;
        // try {
        //   begin = res.data?.begin
        //     ? new Date(dayjs(res.data?.begin).valueOf())
        //     : undefined;
        //   end = res.data?.end
        //     ? new Date(dayjs(res.data?.end).valueOf())
        //     : undefined;
        // } catch (error) {}
        form.setFieldsValue({
          enterpriseAddress: res.data?.address,
          // regNum: res.data?.regNum,
          enterpriseName: res.data?.name,
          // begin: begin,
          // end: end,
          // type: res.data?.type,
          legalPersonName: res.data?.person,
        });
      }
    },
  });
  return (
    <ApplyLayout>
      <Form
        layout="horizontal"
        onFinish={onFinish}
        className={styles.form}
        mode="card"
        form={form}
      >
        <Form.Header />
        <FormCardTitle title="企业信息" />
        <FormItem
          label="营业执照"
          name="creditCert"
          // rules={[{ required: true, message: "必填" }]}
        >
          <ImageUploader
            maxCount={1}
            beforeUpload={(file) =>
              ossUpload.current.beforeUpload(file, {
                hasCompressor: true,
              })
            }
            showFailed={false}
            upload={async (file) => {
              const res = await upload(file);
              if (res?.url) {
                orcLicenseRun({ url: res?.url });
              }
              return res;
            }}
          />
        </FormItem>
        <FormItem
          label="企业名称"
          name="enterpriseName"
          rules={[{ required: true, message: "必填" }]}
        >
          <Input placeholder="请输入企业名称" clearable />
        </FormItem>
        <FormItem
          label="企业简称"
          name="enterpriseShortName"
          rules={[{ required: true, message: "必填" }]}
        >
          <Input maxLength={20} placeholder="企业简称不超过20个字" clearable />
        </FormItem>
        <FormItem label="营业执照地址" name="enterpriseAddress" hidden>
          <Input placeholder="请填写企业地址" clearable />
        </FormItem>
        <FormItem
          label="地址"
          name="enterpriseAddressPoi"
          rules={[{ required: true, message: "必填" }]}
          onClick={() => {
            popupMapRef.current?.open();
          }}
        >
          <PopupMapDD ref={popupMapRef} />
        </FormItem>
        <FormItem
          label="法人姓名"
          name="legalPersonName"
          rules={[{ required: true, message: "必填" }]}
        >
          <Input placeholder="请填写企业联系人" clearable />
        </FormItem>
        <FormItem
          label="法人手机号"
          name="legalPersonPhone"
          rules={[
            { required: true, message: "必填" },
            { pattern: phoneRegExp, message: "请输入正确的手机号" },
          ]}
        >
          <Input placeholder="请填写联系人手机号" clearable />
        </FormItem>
        <FormItem
          label="企业联系人"
          name="contactName"
          rules={[{ required: true, message: "必填" }]}
        >
          <Input placeholder="请填写企业联系人" clearable />
        </FormItem>
        <FormItem
          label="联系人手机号"
          name="contactPhone"
          rules={[
            { required: true, message: "必填" },
            { pattern: phoneRegExp, message: "请输入正确的手机号" },
          ]}
        >
          <Input placeholder="请填写联系人手机号" clearable />
        </FormItem>
        <FormItem
          label="合伙人推广码"
          name="partnerUnionCode"
          // rules={[{ required: true, message: "必填" }]}
        >
          <Input placeholder="请填写合伙人推广码" clearable />
        </FormItem>
      </Form>
      <div className={styles.footer}>
        <Buttons
          block
          color="primary"
          loading={applyLoading}
          onClick={onSubmit}
        >
          保存并下一步
        </Buttons>
      </div>
    </ApplyLayout>
  );
};

export default React.memo(SettledIn);
