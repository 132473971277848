import React from 'react';
import QRCode from 'react-qr-code';

interface IQRCodeProps {
  value: string;
  size?: number;
}

const QRCodeComponent: React.FC<IQRCodeProps> = ({ value, size }) => {
  return (
    <QRCode value={value} size={size} />
  );
};

export default QRCodeComponent;
