import usePageTitle from "@/hooks/usePageTitle";
import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { Form, Toast } from "antd-mobile";
import FormItem from "../../components/FormItem";
import Input from "../../components/Input";
import { phoneRegExp } from "@/common/Config/regExp";
import Buttons from "@/common/Component/Button";
import { useSearchParams } from "react-router-dom";
import { useRequest } from "ahooks";
import { addChild } from "../../services/addChild";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { detail } from "../../services/detail";
import PageLoading from "@/common/Component/PageLoading";
import { modifyChild } from "../../services/modifyChild";

interface ManageStaffAddProps {}
const requiredRule = {
  required: true,
  message: "必填",
};

/**
 * 企业合伙人-管理员工-添加员工

 */
const ManageStaffAdd: React.FC<ManageStaffAddProps> = (props) => {
  // const {} = props;
  usePageTitle({ title: "添加人员" });
  const [search] = useSearchParams();
  const status = search.get("status") as "edit" | "add";
  const partnerId = search.get("partnerId") as string;
  const [form] = Form.useForm();

  const openUrl = useOpenUrl();
  const { run: addChildRun, loading: addChildLoading } = useRequest(addChild, {
    manual: true,
    onSuccess: (res) => {
      if (res.success) {
        Toast.show("添加成功");
        openUrl({
          url: "/partner/manage/staff",
          isRoute: true,
          isOpenHalf: false,
        });
      }
    },
  });
  const { run: editChildRun, loading: editChildLoading } = useRequest(
    modifyChild,
    {
      manual: true,
      onSuccess: (res) => {
        if (res.success) {
          Toast.show("编辑成功");
          openUrl({
            url: "/partner/manage/staff",
            isRoute: true,
            isOpenHalf: false,
          });
        }
      },
    }
  );
  const { run: getDetail, loading: detailLoading } = useRequest(detail, {
    manual: true,
    onSuccess: (res) => {
      if (res.success && res.data) {
        form.setFieldsValue({
          partnerPerson: res.data.partnerPerson,
          partnerPersonMobile: res.data.partnerPersonMobile,
        });
      }
    },
  });
  const submit = () => {
    form.submit();
  };
  const onFinish = (values: any) => {
    if (status === "edit") {
      editChildRun({
        partnerId,
        ...values,
      });
      return;
    }
    addChildRun({
      ...values,
    });
  };
  useEffect(() => {
    if (status === "edit") {
      getDetail({ partnerId });
    }
  }, [status, partnerId]);
  return (
    <div className={styles.page}>
      <PageLoading loading={detailLoading} />
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
        className={styles.form}
      >
        <FormItem
          className={styles.formItem}
          label="姓名"
          name="partnerPerson"
          rules={[requiredRule]}
          required
        >
          <Input placeholder="请输入员工姓名" />
        </FormItem>
        <FormItem
          label="手机号"
          name="partnerPersonMobile"
          className={styles.formItem}
          rules={[
            requiredRule,
            {
              pattern: phoneRegExp,
              message: "请输入正确的手机号",
            },
          ]}
          required
        >
          <Input placeholder="请输入预留手机号" />
        </FormItem>
      </Form>
      <div className={styles.footer}>
        <Buttons
          loading={editChildLoading || addChildLoading}
          block
          color="primary"
          onClick={submit}
          customColor="black"

        >
          提交
        </Buttons>
      </div>
    </div>
  );
};

export default React.memo(ManageStaffAdd);
