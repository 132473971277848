import React, { useMemo, useState } from "react";
import style from "./index.module.scss";
import { Accounts } from "@/pages/Wallet/Services";
import { STATIC_HOST } from "@/common/Config/Constant";
import { EyeInvisibleOutline, EyeOutline } from "antd-mobile-icons";
import { formatCurrency } from "@/common/Utils/money";
import { useOpenUrl } from "@/hooks/useOpenUrl";

interface IBankCardProps {
  data?: Accounts;
}

/**   */
const BankCard: React.FC<IBankCardProps> = (props) => {
  const { data } = props;
  const { enterpriseName, bankCardNo, accountId, balance } = data || {};
  const [visible, setVisible] = useState(false);
  const openUrl = useOpenUrl();

  const onToggleBalance = () => {
    setVisible(!visible);
  }
  
  const money = useMemo(() => {
    if (!visible) {
      return "*****";
    }
    if (typeof balance === "number") {
      const money = formatCurrency(balance);
      return (
        <span>
          <span>{money}</span>
        </span>
      );
    }
    return "-";
  }, [balance, visible]);

  const balanceNoText = useMemo(() => {
    if (data?.bankCardNo) {
      const start = data?.bankCardNo.slice(0, 4);
      const end = data?.bankCardNo.slice(-4);
      return `${start}******${end}`;
    }
    return "";
  }, [data]);

  return (
    <div
      className={style.content}
      style={{
        backgroundImage: `url('${STATIC_HOST}/b2ade5cdafd071e26d2704daeed6ab864728069a.png')`,
      }}
    >
      <div className={style.info}>
        <div className={style.infoContent}>
          <div className={style.enterpriseName}>{enterpriseName}</div>
          <div className={style.balanceNo}>
            <span className={style.balanceNoText}>{balanceNoText}</span>
          </div>
          <div className={style.top}>
            <div
              className={style.balanceName}
              onClick={() => onToggleBalance()}
            >
              <span>可用金额(元)</span>
              {visible ? <EyeOutline /> : <EyeInvisibleOutline />}
            </div>
          </div>
          <div className={style.balance}>
            {visible ? (
              <div>
                <span>{money}</span>
              </div>
            ) : (
              <div>
                <span>*****</span>
              </div>
            )}
          </div>
        </div>
        <div className={style.actions}>
          <div
            className={style.actionItem}
            onClick={() => {
              openUrl({
                url: "/walletList",
                params: {
                  id: accountId
                },
                isRoute: true
              });
            }}
          >
            钱包明细
          </div>
          <div className={style.divider}></div>
          <div
            className={style.actionItem}
            onClick={() => {
              openUrl({ url: `/setPassword/${accountId}`, isRoute: true });
            }}
          >
            设置密码
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankCard;
