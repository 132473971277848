import Map, { IMapData, IMapRef } from "../Map";
import MapV2 from "../Map/v2";

import { Button, Popup, TextArea } from "antd-mobile";
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import styles from "./index.module.scss";

interface PopupMapProps {
  value?: IMapData;
  onChange?: (date?: IMapData) => void;
  render?: (address?: string, map?: IMapData) => React.ReactNode;
  className?: string;
  disabledForm?: boolean;
  v2?: boolean;
}
export interface PopupMapRef {
  open: () => void;
  close: () => void;
}
const PopupMap: React.ForwardRefRenderFunction<PopupMapRef, PopupMapProps> = (
  props,
  ref
) => {
  const { value, onChange, render, className, disabledForm, v2 } = props;
  const [visible, setVisible] = useState(false);
  const mapRef = React.createRef<IMapRef>();

  const onOK = async (data: IMapData) => {
    console.log(data);
    await (onChange && onChange(data));
    setDefaultAddress(data);
    setVisible(false);
  };
  const [defaultAddress, setDefaultAddress] = useState<IMapData | undefined>(
    value
  );
  useImperativeHandle(
    ref,
    () => {
      return {
        open: (address?: IMapData) => {
          setVisible(true);
        },
        close: () => {
          setVisible(false);
        },
      };
    },
    []
  );
  useEffect(() => {
    setDefaultAddress(value);
  }, [value]);
  const showText = useMemo(() => {
    return defaultAddress?.detailAddress;
  }, [defaultAddress]);

  const Component = useMemo(() => (v2 ? MapV2 : Map), [v2]);
  return (
    <div className={className}>
      <Popup visible={visible} bodyClassName={styles.body}>
        <div className={styles.container}>
          <div className={styles.header}>
            <Button fill="none" size="small" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button
              onClick={() => {
                mapRef.current?.submit?.();
              }}
              color="primary"
              fill="none"
              size="small"
            >
              确认
            </Button>
          </div>
          <Component
            footer={false}
            ref={mapRef}
            defaultAddress={defaultAddress}
            onOk={onOK}
            className={styles.map}
            disabledForm={disabledForm}
          />
        </div>
      </Popup>
      {render ? (
        render(showText, defaultAddress)
      ) : (
        <TextArea
          autoSize
          className={styles.fontSize28}
          placeholder="请选择地址"
          readOnly
          value={showText}
        />
      )}
    </div>
  );
};

export default React.forwardRef(PopupMap);
