import apiClient, { IBaseRes } from "src/common/Utils/APIClient";

export interface IItem {
  content: string;
  name: string;
  title: string;
}
interface IRes {
  list: IItem[];
  unionCode: string;
  status?: boolean;
  corpName?: string;
}

interface IParams {
  unionCode: string;
}

interface IParams {
  unionCode: string;
}

const getPartnerCode = (params: IParams): Promise<IBaseRes<IRes>> => {
  return apiClient.get("/dmall-customer/partner/ua/detail", { param: params });
};

export { getPartnerCode };
