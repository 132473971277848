import React, { useState, useEffect, useRef } from "react";
import { useRequest } from "ahooks";
import styles from "./index.module.scss";
import usePageTitle from "@/hooks/usePageTitle";
import { Toast, ImageUploader, ImageUploadItem, ImageUploaderRef } from "antd-mobile";
import { getBase64 } from "@/common/Utils/file";
import { updateUserInfo } from "../../Services";
import { OSSUpload, UploadDirEnum } from "@/services/upload/OssSignature";
import { pxToRem } from "@/common/Utils/rem";
import CropImage, { ICropImageRef } from "@/common/Component/CropImage";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";

function EditAvatar(props: any) {
  usePageTitle({ title: "设置头像" });

  const [userInfo, getUserInfo] = useUserStore(
    useShallow((state) => [
      state.userInfo,
      state.getUserInfo,
    ])
  );
  const cropImageRef = useRef<ICropImageRef>(null);
  const input = useRef<ImageUploaderRef>(null)

  const avatar = userInfo?.userAvatar || '';
  const ossUpload = useRef(new OSSUpload({ uploadDir: UploadDirEnum.Test }));
  const [fileList, setFileList] = useState<ImageUploadItem[]>([])

  const { run: runUpdateUserInfo } = useRequest(updateUserInfo, {
    manual: true,
    onSuccess: (result) => {
      if (result.success) {
        Toast.show({
          icon: "success",
          content: "修改成功",
        });
        // 刷新用户信息
        getUserInfo();
        setTimeout(() => {
          window.history.back();
        }, 2000);
      }
    }
  });

  useEffect(() => {
    if (avatar) {
      setFileList([{
        url: avatar,
      }])
    }
  }, [avatar]);

  const upload = (file: File): Promise<ImageUploadItem> =>
    new Promise((resolve, reject) => {
      console.log(file, 'file')

      // 先创建一个本地URL显示图片，实现即时替换效果
      const localUrl = URL.createObjectURL(file);
      setFileList([{
        url: localUrl,
      }]);

      ossUpload.current
        .upload(file)
        .then((res) => {
          console.log(res, 'res')
          if (res.success) {
            // 上传成功后，更新为服务器返回的URL
            const imageItem = {
              url: res.url || "",
            };
            setFileList([imageItem]);
            resolve(imageItem);
            
            runUpdateUserInfo({
              avatar: res.url || ""
            })
          } else {
            // 上传失败时恢复原来的图片
            if (avatar) {
              setFileList([{
                url: avatar,
              }]);
            }
            reject(new Error("上传失败"));
          }
        })
        .catch((e) => {
          // 发生错误时恢复原来的图片
          if (avatar) {
            setFileList([{
              url: avatar,
            }]);
          }
          reject(new Error("上传失败"));
        });
    });
  
  const beforeUpload = (
    file: File,
    files: File[],
    aspect: number
  ): Promise<File | null> => {
    return new Promise((resolve, reject) => {
      getBase64(file)
        .then((res) => {
          if (res) {
            cropImageRef.current?.show({
              src: res,
              onCancel: () => {},
              onOk: (f) => {
                f && resolve(f);
              },
              fileName: file.name,
              aspect,
            });
          }
        })
        .catch(() => {});
    });
  };

  const onOpenCrop = () => {
    const nativeInput = input.current?.nativeElement
    if (nativeInput) {
      nativeInput.click()
    }
  }

  useEffect(() => {
    // 获取oss签名
    ossUpload.current.getOssSignature();
  }, []);

  return (
    <div className={styles.container}>
      {/* 页面内容 */}
      <div className={styles.rowContainer}>
        <ImageUploader
          ref={input}
          deletable={false}
          beforeUpload={(file, files) => beforeUpload(file, files, 1)}
          style={{ "--cell-size": pxToRem(750) }}
          value={fileList}
          maxCount={2}
          showFailed={false}
          upload={upload}
        />
        <CropImage ref={cropImageRef} />
      </div>
      <div onClick={onOpenCrop} className={styles.bottomFixedContainer}>
        <div className={styles.verifyButtons}>
          {fileList.length > 0 ? '修改头像' : '上传头像'}
        </div>
      </div>
      <CropImage ref={cropImageRef} />
    </div>
  );
}

export default EditAvatar;