import React from "react";
import { Form, Input, Button, Toast } from "antd-mobile";
import { useRequest } from "ahooks";
import usePageTitle from "@/hooks/usePageTitle";
import { resetPassword, getSmsVerificationCode } from "../../Services";
import SmsVerificationButton from "@/common/Component/SmsVerification";

import styles from "./index.module.scss";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { useSearchParams } from "react-router-dom";
import { USER_CONFIG } from "@/common/Config/Constant";

interface FormValues {
  phone: string;
  code: string;
}

function ResetPassword() {
  usePageTitle({ title: "重置密码" });
  const openUrl = useOpenUrl();
  const [params] = useSearchParams();
  const showTitle = params.get('showTitle') ? true : false;
  const type = params.get('type') ? parseInt(params.get('type') as string) : 0;
  const [form] = Form.useForm<FormValues>();

  const { loading, run: runResetPassword } = useRequest(resetPassword, {
    manual: true,
    onSuccess: (res: any, req: any) => {
      if (res.success) {
        Toast.show({
          icon: 'success',
          content: `您的登录密码重置完成，新密码以短信形式发送到${req[0].phone}手机`,
        });
        
        setTimeout(() => {
          openUrl({
            url: '/loginPage',
            params: {
              type: type,
            },
            isRoute: true,
          });
        }, 2000);
      }
    }
  });

  // 获取验证码
  const { loading: smsLoading, run: getSmsCode } = useRequest(getSmsVerificationCode, {
    manual: true,
    onSuccess: (result) => {
      if (result.success) {
        Toast.show({
          content: '验证码已发送',
        });
      }
    }
  });

  const onFinish = (values: FormValues) => {
    runResetPassword({
      phone: values.phone,
      verifyCode: values.code, // Use code as password for reset
      sysType: type,
    });
  };

  const handleGetSmsCode = () => {
    return form.validateFields(['phone']).then(values => {
      getSmsCode({
        phone: values.phone,
        type: 1,
        sysType: type,
      });
      return true; // Return true to indicate validation success
    }).catch(() => {
      Toast.show({
        icon: "fail",
        content: "请输入正确的手机号",
      });
      return false; // Return false to prevent countdown
    });
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        重置{showTitle && USER_CONFIG.find(config => config.sysType === type)?.title}密码
      </h1>
      <div className={styles.description}>
        忘记密码了？别担心，点击这里轻松重置您的密码！
      </div>
      <div className={styles.formContainer}>
        <Form
          form={form}
          layout="horizontal"
          mode="card"
          onFinish={onFinish}
          footer={
            <Button 
              block 
              type="submit" 
              color="primary" 
              loading={loading}
              className={styles.submitButton}
            >
              重置密码
            </Button>
          }
          style={{
            '--border-inner': 'none',
          }}
        >
          <Form.Item
            name="phone"
            label="手机号"
            validateTrigger={['onBlur']}
            rules={[
              { required: true, message: '请输入手机号' },
              { pattern: /^1[3-9]\d{9}$/, message: "请输入正确的手机号" }
            ]}
          >
            <Input placeholder="请输入手机号" />
          </Form.Item>
          
          <Form.Item
            name="code"
            label="验证码"
            validateTrigger={['onBlur']}
            rules={[{ required: true, message: '请输入验证码' }]}
            extra={
              <div className={styles.verifyCodeButton}>
                <SmsVerificationButton
                  onGetCode={handleGetSmsCode}
                  loading={smsLoading}
                />
              </div>
            }
          >
            <Input type="tel" maxLength={6} pattern="[0-9]*" inputMode="numeric" placeholder="请输入验证码" />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default ResetPassword;