import {
  Empty,
  Grid,
  Image,
  InfiniteScroll,
  SpinLoading,
  Swiper,
} from "antd-mobile";
import React, { useEffect, useMemo, useRef, useState } from "react";

import chunk from "lodash/chunk";
import get from "lodash/get";
import classNames from "classnames";
import { useRequest } from "ahooks";

import usePageTitle from "src/hooks/usePageTitle";
import { useOpenUrl } from "src/hooks/useOpenUrl";
import { useConfigJSON } from "src/hooks/useConfigJSON";

import { PageItem } from "src/common/Component/Page";
import Search from "src/common/Component/Search";
import FeaturedCards from "./components/FeaturedCards";
import Tabs from "../../common/Component/Tabs";

import styles from "./index.module.scss";
import ProductCard from "./components/ProductCard";
import { getMaterial, MapDatum } from "./service/getMaterial";
import { getCategoryTree, Config } from "./static";
import { getFavoritesId } from "./service/getFavoritesId";
import { useSearchParams } from "react-router-dom";
const pageSize = 20;

interface IProps {}
/** 商城-首页  */
const Mall: React.FC<IProps> = (props) => {
  const openUrl = useOpenUrl();
  usePageTitle({ title: "商城" });

  const [activeKey, setActiveKey] = useState<string>();
  const [activeInKey, setActiveInKey] = useState<string>("");
  const [queryStr, setQueryStr] = useState("");
  const [categoryTree, setCategoryTree] = useState<
    ReturnType<typeof getCategoryTree>
  >([]);
  const tabRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  // const id = searchParams.get("id") || 1856258893593808897;
  const { config, loading } = useConfigJSON<Config>({
    params: { id: "1856258893593808897" },
  });

  const pageNum = useRef(0);
  const hasInit = useRef(true);
  const [allMapData, setAllMapData] = useState<MapDatum[]>([]);
  const [hasError, setError] = useState(false);
  const {
    data: materialData,
    run: getMaterialRun,
    loading: materialLoading,
    cancel: cancelMaterialRun,
  } = useRequest(getMaterial, {
    manual: true,
    onSuccess: (res, params) => {
      pageNum.current = params[0].pageNum;
      const list = get(
        res,
        "tbk_dg_material_optional_upgrade_response.result_list.map_data",
        []
      );
      if (params[0].pageNum === 1) {
        setAllMapData(list);
      } else {
        setAllMapData([...allMapData, ...list]);
      }
      setError(false);
    },
    onError: () => {
      setError(true);
      console.log("error");
    },
  });
  const { data: favoritesIdData } = useRequest(getFavoritesId);

  /**
   * 收藏夹展示数据
   */
  const showFavoritesList = useMemo(() => {
    const favoritesList = get(
      favoritesIdData,
      "tbk_dg_material_recommend_response.result_list.map_data.0.favorites_info.favorites_list.favorites_detail",
      undefined
    );
    const listData = config?.activity?.map((li) => {
      const data = favoritesList?.find((item) => item.favorites_id === li.id);
      return {
        title: data?.favorites_title || li.title,
        moreLink: li.moreLink,
        productList: li.list,
        favoritesId: data?.favorites_id,
        hasCustomize: li.hasCustomize,
      };
    });
    return chunk(listData, 2);
  }, [favoritesIdData, config]);
  /**
   *  类目id
   */
  const catKey = useMemo(() => {
    // 如果是空字符串认为是二级全部，所以返回父级key
    if (activeInKey === "") {
      return activeKey;
    }
    return activeInKey;
  }, [activeInKey, activeKey]);
  const items = config?.bannerList?.map((item, index) => (
    <Swiper.Item
      key={index}
      onClick={() => {
        if (item.link) {
          openUrl(item.link);
        }
      }}
    >
      <Image
        src={item.imageUrl}
        fit="cover"
        lazy
        // width={"100%"}
        // height={"100%"}
        className={styles.swiperItem}
      />
    </Swiper.Item>
  ));

  /**
   *  一级类目切换
   */
  const changeTab = (key: string) => {
    hasInit.current = true;
    pageNum.current = 0;
    cancelMaterialRun();
    setAllMapData([]);
    setActiveKey(key);
    const activeInItem = get(categoryListIn, "0", undefined);
    setActiveInKey(activeInItem?.key || "");
    // tabRef.current?.scrollIntoView();
    getMaterialRun({
      pageNum: pageNum.current + 1,
      pageSize,
      cat: key,
      q: queryStr,
    });
  };
  /**
   *  二级类目切换
   */
  const changeInTab = (key: string) => {
    hasInit.current = true;
    pageNum.current = 0;
    cancelMaterialRun();
    setAllMapData([]);
    setActiveInKey(key);
    getMaterialRun({
      pageNum: pageNum.current + 1,
      pageSize,
      // 如果是全部取所有类目，全部是空字符串
      cat: key || activeKey,
      q: queryStr,
    });
    // tabRef.current?.scrollIntoView();
  };
  async function loadMore() {
    if (materialLoading || hasError) {
      return;
    }
    if (hasInit.current) {
      hasInit.current = false;
    }
    getMaterialRun({
      pageNum: pageNum.current + 1,
      pageSize,
      cat: catKey,
      q: queryStr,
    });
  }

  /**
   * 二级类目列表
   */
  const categoryListIn = useMemo(() => {
    const list =
      categoryTree?.find((item) => item.key === activeKey)?.children || [];
    return list;
  }, [activeKey, categoryTree]);

  const hasMore = useMemo(() => {
    const total = get(
      materialData,
      "tbk_dg_material_optional_upgrade_response.total_results",
      0
    );
    if (hasInit.current) {
      return true;
    }

    return total > allMapData.length;
  }, [materialData, allMapData]);

  const showData = useMemo(() => {
    const data: Array<typeof allMapData> = [[], []];
    allMapData.forEach((item, index) => {
      data[index % 2].push(item);
    });
    return data;
  }, [allMapData]);
  const onSearch = (value: string) => {
    hasInit.current = true;
    pageNum.current = 0;
    cancelMaterialRun();
    setAllMapData([]);
    getMaterialRun({
      pageNum: pageNum.current + 1,
      pageSize,
      q: value,
      cat: catKey,
    });
  };

  useEffect(() => {
    // 初始化类目
    if (!config) return;
    const data = getCategoryTree(config.tabTree);
    const activeIndex = searchParams.get("activeIndex");
    setCategoryTree(data);
    const cat = get(data, `${activeIndex || 0}.key`);
    if (cat) {
      getMaterialRun({
        pageNum: pageNum.current + 1,
        pageSize,
        cat,
      });
      setActiveKey(cat);
    }
  }, [config]);

  if (loading) {
    return (
      <div className={styles.indexLoading}>
        <SpinLoading />
      </div>
    );
  }
  return (
    <div style={{ width: "100%" }}>
      <PageItem className={styles.firstPageItem}>
        <Search
          clearable
          onChange={setQueryStr}
          placeholder="纸巾"
          value={queryStr}
          onBlur={(e) => {
            const value = e.currentTarget.value;
            onSearch(value);
          }}
        />
      </PageItem>
      <PageItem>
        <Swiper
          className={styles.swiper}
          autoplay
          loop
          indicatorProps={{
            className: classNames({
              [styles.swiperHide]: items?.length === 1,
            }),
          }}
        >
          {items}
        </Swiper>
      </PageItem>
      <PageItem>
        {showFavoritesList?.map((item, index) => {
          return (
            <Grid key={index} columns={2} className={styles.featuredGrid}>
              {item.map((li, iIndex: number) => {
                return (
                  <Grid.Item key={iIndex}>
                    <FeaturedCards
                      title={li.title}
                      productList={li.productList}
                      moreLink={li.moreLink}
                      favoritesId={li.favoritesId}
                      hasCustomize={li.hasCustomize}
                    />
                  </Grid.Item>
                );
              })}
            </Grid>
          );
        })}
      </PageItem>

      <div
        className={classNames(styles.tab, {
          // [styles.fullScreenTab]: hideTitle,
        })}
        ref={tabRef}
      >
        <Tabs
          activeKey={activeKey}
          model="out"
          itemData={categoryTree}
          className={styles.outTabs}
          onChange={changeTab}
          // tabTitleClassName={styles.tabTitleClassName}
        >
          <Tabs
            activeKey={activeInKey}
            model="in"
            itemData={categoryListIn}
            onChange={changeInTab}
            activeTitleClassName={styles.activeTitleClassName}
          />
        </Tabs>
      </div>
      {!materialLoading && !allMapData.length && (
        <Empty description="暂无数据" />
      )}
      {materialLoading && !allMapData.length && (
        <div className={styles.loading}>
          <SpinLoading />
        </div>
      )}
      {!!allMapData?.length && (
        <PageItem className={styles.content}>
          <Grid columns={2} className={styles.productGrid}>
            {showData.map((item, index) => {
              return (
                <Grid.Item key={index}>
                  <Grid columns={1} key={index}>
                    {item.map((li, iIndex: number) => {
                      return (
                        <Grid.Item
                          key={iIndex}
                          className={styles.productGridItem}
                        >
                          <ProductCard data={li} />
                        </Grid.Item>
                      );
                    })}
                  </Grid>
                </Grid.Item>
              );
            })}
          </Grid>
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        </PageItem>
      )}

      {/* <ToTop /> */}
    </div>
  );
};

export default Mall;
