import React, { useState } from "react";
import { Form, Input, Button, Checkbox, Toast } from "antd-mobile";
import { useRequest } from "ahooks";
import usePageTitle from "@/hooks/usePageTitle";
import { submitRealNameAuth } from "./Services";
import AuthSuccess from "./Components/Success";

import styles from "./index.module.scss";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";

interface FormValues {
  userName: string;
  identityNum: string;
}

function RealNameAuthentication() {
  usePageTitle({ title: "实名认证" });
  const [form] = Form.useForm<FormValues>();
  const [userInfo, getUserInfo] = useUserStore(
    useShallow((state) => [
      state.userInfo,
      state.getUserInfo,
    ])
  );
  const [userAuth, setUserAuth] = useState<FormValues>({
    userName: userInfo?.realName || '',
    identityNum: userInfo?.identityNum ||  ''
  });
  const [identityVerify, setIdentityVerify] = useState<boolean>(userInfo?.identityVerify || false);
  const [agreed, setAgreed] = useState(false);

  const { loading, run: submitAuth } = useRequest(submitRealNameAuth, {
    manual: true,
    onSuccess: (res: any) => {
      const { success, data } = res;
      if (success) {
        Toast.show({
          icon: 'success',
          content: '实名认证成功',
        });

        setTimeout(() => {
          getUserInfo();
          setIdentityVerify(true);
          setUserAuth(data);
        }, 2000);
      }
    }
  });

  const onFinish = (values: FormValues) => {
    if (!agreed) {
      Toast.show({
        content: '请阅读并同意用户协议和隐私协议',
      });
      return;
    }
    
    submitAuth({
      userName: values.userName,
      identityNum: values.identityNum
    });
  };

  return (
    <div className={styles.container}>
      <div style={{ display: identityVerify ? 'none' : 'block' }}>
        <h1 className={styles.title}>实名认证</h1>
        <div className={styles.description}>
          完成实名认证后可使用餐补、消费券及其它需要实名认证的功能或服务，也可找回您的账号或验证您账号的安全性。
        </div>
        <div className={styles.formContainer}>
          <Form
            form={form}
            layout="horizontal"
            mode="card"
            onFinish={onFinish}
            footer={
              <Button 
                block 
                type="submit" 
                color="primary" 
                loading={loading}
                disabled={!agreed}
                className={styles.submitButton}
              >
                完成
              </Button>
            }
            style={{
              '--border-inner': 'none',
            }}
          >
            <Form.Item
              name="userName"
              label="姓名"
              rules={[{ required: true, message: '请输入真实姓名' }]}
            >
              <Input placeholder="请输入真实姓名" />
            </Form.Item>
            
            <Form.Item
              name="identityNum"
              label="身份证号"
              rules={[
                { required: true, message: '请输入身份证号' },
                { 
                  pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, 
                  message: '请输入正确的身份证号' 
                }
              ]}
            >
              <Input placeholder="请输入身份证号" />
            </Form.Item>
          </Form>

          <div className={styles.agreementSection}>
              <Checkbox
                checked={agreed}
                onChange={setAgreed}
                className={styles.agreementCheckbox}
              />
              <span className={styles.agreementText}>
                已阅读并同意 <a className={styles.agreementLink} href="/protocolInfo/user">《用户协议》</a> 和 <a className={styles.agreementLink} href="/protocolInfo/security">《隐私协议》</a>
              </span>
            </div>
          </div>
      </div>
      <div style={{ display: identityVerify ? 'block' : 'none' }}>
        <AuthSuccess userAuth={userAuth} />
      </div>
    </div>
  );
}

export default RealNameAuthentication;