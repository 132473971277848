import { useState, useEffect } from 'react';
import { Button } from 'antd-mobile';
import QRCode from '@/common/Component/QrCode';
import { getAlipaySignUrl } from '../../service';
import styles from './index.module.scss';

const App = () => {
  const [alipaySignUrl, setAlipaySignUrl] = useState('');

  const init = async () => {
    const { alipaySignUrl } = await getAlipaySignUrl();

    setAlipaySignUrl(alipaySignUrl);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.invite}>
          <div className={styles.method}>
            <div className={styles.title}>方法1:通过链接邀请</div>
            <div className={styles.content}>
              <Button
                className={styles.primaryBtn}
                color="primary"
                shape="rounded"
                onClick={() => {
                  window.open(alipaySignUrl, '_blank');
                }}
              >
                点此登录支付宝授权
              </Button>
            </div>
          </div>
          <div className={styles.method}>
            <div className={styles.title}>方法2:通过二维码邀请</div>
            <div className={styles.content}>
              <QRCode value={alipaySignUrl} size={150} />
            </div>
          </div>
        </div>
        <div className={styles.footer} />
      </div>
    </div>
  );
};

export default App;
