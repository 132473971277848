import axios, { IBaseRes } from "@/common/Utils/APIClient";

interface IParams {
  partnerId?: string;
}

interface IRes {
  /** 已结算收益 */
  settledIncome: number;
  /** 未结算收益 */
  unSettledIncome: number;
}

/**
 * 合伙人收益汇总byId
 */
export const mobileBillSummary = (params: IParams): Promise<IBaseRes<IRes>> => {
  return axios.get("/dmall_statistic/stMonthPartnerBill/mobile/billSummary", {
    param: params,
  });
};
