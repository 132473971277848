export const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject(new Error("Failed to read file as data URL."));
      }
    };

    reader.onerror = (event) => {
      const error = event.target?.error;
      reject(
        error || new Error("An unknown error occurred while reading the file.")
      );
    };

    reader.readAsDataURL(file);
  });
};
