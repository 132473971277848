import React from "react";
import { Form, Input, Button, Toast } from "antd-mobile";
import { useRequest } from "ahooks";
import usePageTitle from "@/hooks/usePageTitle";
import { updatePassword } from "../../Services";

import styles from "./index.module.scss";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { USER_CONFIG } from "@/common/Config/Constant";

interface FormValues {
  oldPassword_changepwd: string;
  password_changepwd: string;
  confirmPassword_changepwd: string;
}

function ChangePassword() {
  usePageTitle({ title: "修改密码" });
  const openUrl = useOpenUrl();
  const [form] = Form.useForm<FormValues>();
  const pathname = window.location.pathname;
  const currentConfig = USER_CONFIG.find(config => config.type && pathname.includes(config.type));
  const sysType = currentConfig?.sysType || 0;


  const { run: runUpdatePassword } = useRequest(updatePassword, {
    manual: true,
    onSuccess: (res: any) => {
      if (res.success) {
        Toast.show({
          icon: 'success',
          content: '密码修改成功，请重新登录',
        });
        
        setTimeout(() => {
          openUrl({
            url: '/loginPage',
            params: {
              type: sysType,
            },
            isRoute: true,
          });
        }, 2000);
      }
    }
  });

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();

      runUpdatePassword({
        oldPassword: values.oldPassword_changepwd,
        newPassword: values.password_changepwd,
      });
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>修改密码</h1>
      <div className={styles.description}>
        为了您的账户安全，请及时更新密码。
        <span className={styles.forgetPassword} onClick={() => {
          openUrl({
            url: "/resetLoginPassword",
            params: {
              type: sysType,
            },
            isRoute: true,
          });
        }}>忘记密码？</span>
      </div>
      <div className={styles.formContainer}>
      <Form
        layout='horizontal'
        form={form}
        footer={
          <div className={styles.btnContainer}>
            <Button className={styles.submitButton} onClick={onSubmit} block size="large" type="submit">
              确认
            </Button>
          </div>
        }
      >
        <Form.Item
          label='旧密码'
          name='oldPassword_changepwd'
          validateTrigger={['onBlur']} // 禁用自动校验
          rules={[
            { required: true, message: "请输入密码" },
            { min: 6, max: 8, message: "密码长度为6-8位" }
          ]}
        >
          <Input type="password" placeholder="请输入旧密码" />
        </Form.Item>
        <Form.Item
          label='密码'
          name='password_changepwd'
          validateTrigger={['onBlur']} // 禁用自动校验
          rules={[
            { required: true, message: "请输入6-8位新密码" },
            { min: 6, max: 8, message: "密码长度为6-8位" },
            { 
              validator: (rule, value) => {
                const oldPassword = form.getFieldValue('oldPassword_changepwd');
                if (value && oldPassword && value === oldPassword) {
                  return Promise.reject('新密码与旧密码相同,请重新输入');
                }
                return Promise.resolve();
              },
            }
          ]}
        >
          <Input type="password" placeholder="请输入6-8位新密码" />
        </Form.Item>
        <Form.Item
          label='确认密码'
          name='confirmPassword_changepwd'
          validateTrigger={['onBlur']} // 在失去焦点时触发校验
          rules={[
            { required: true, message: '请确认输入新密码' },
            { min: 6, max: 8, message: "密码长度为6-8位" },
            { 
              validator: (rule, value) => {
                if (value !== form.getFieldValue('password_changepwd')) {
                  return Promise.reject('两次填写的密码不一致');
                }
                return Promise.resolve();
              },
            }
          ]}
        >
          <Input type="password" placeholder="请再次输入新密码" />
        </Form.Item>
      </Form>
      </div>
    </div>
  );
}

export default ChangePassword;