import axios from "@/common/Utils/APIClient";
import { UserInfo } from "./getDingUser";

export const getDingUserById = () => {
  return axios
    .get("/dmall_customer/staff/getDingUserById")
    .then((res: UserInfo) => {
      console.log("res", res);
      const { data, success } = res;
      if (success) {
        return data;
      }
      return undefined;
    });
};
