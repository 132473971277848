import axios, { IBaseRes } from "src/common/Utils/APIClient";
import { IParamData } from "@/common/Component/TimeSelect/Services";

interface IWalletListParam extends IParamData {
  accountId: string;
}

export const getWalletList = (
  param: IWalletListParam
): Promise<IBaseRes<any>> => {
  return axios.get("/dmall_account/TransactionEvt/txn/page", {
    param: {
      pageNum: 1,
      pageSize: 20,
      orders: 'DESC',
      columns: 'createAt',
      ...param,
    },
  });
};