import React from "react";
import { Outlet } from "react-router-dom";
// import { SafeArea } from "antd-mobile";
import styles from "./index.module.scss";
import ToTop from "@/common/Component/ToTop";

function Layout(props: any) {
  return (
    <div className={styles.App}>
      {props.children || <Outlet />}
      <ToTop className={styles.toTop} />
    </div>
  );
}

export default Layout;
