import { useRequest } from "ahooks";
import { Toast } from "antd-mobile";
import * as dd from "dingtalk-jsapi";
import { getDingConfig } from "./dingConfig";
import useGlobal from "@/store/global";
import { useShallow } from "zustand/react/shallow";
import { useEffect } from "react";
import { getLocation } from "./getLocation";
import { Container } from "@/common/Utils/container";

/**
 * 钉钉JSAPI配置
 */
export const useDDJSAPIConfig = () => {
  const [corpId, setDingLocation, isInstallApp] = useGlobal(
    useShallow((state) => [
      state.corpId,
      state.setDingLocation,
      state.isInstallApp,
    ])
  );
  const data = useRequest(getDingConfig, {
    onSuccess: (res) => {
      if (dd?.env?.platform !== "notInDingTalk") {
        dd.config({
          ...(res?.result || {}),
          jsApiList: ["getLocation", "locateInMap", "device.geolocation.get"],
        });
        dd.error(function (error) {
          Toast.show({
            icon: "fail",
            content: JSON.stringify(error),
          });
        });
        setDingLocation();
      }
    },
    manual: true,
    // defaultParams: [{ url: window.location.href, corpId }],
  });
  const { run } = data;
  useEffect(() => {
    if (corpId && isInstallApp) {
      run({ url: window.location.href, corpId });
    }
  }, [corpId]);
  return data;
};
