import React, { useMemo, useRef } from "react";
import styles from "./index.module.scss";
import ApplyLayout from "@/common/Component/ApplyPage";
import usePageTitle from "@/hooks/usePageTitle";
import Table, { IColumn } from "../../components/Table";
import { useRequest } from "ahooks";
import { IListItem, mobileBillPage } from "../../services/mobileBillPage";
import { mobileBillSummary } from "../../services/mobileBillSummary";
import { formatCurrency } from "@/common/Utils/money";
import Decimal from "decimal.js";
import PageLoading from "@/common/Component/PageLoading";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import dayjs from "dayjs";
interface EnterpriseIncomeProps {}
const pageSize = 10;
/**
 * 企业收益*/
const EnterpriseIncome: React.FC<EnterpriseIncomeProps> = (props) => {
  usePageTitle({ title: "收益" });
  const openUrl = useOpenUrl();
  const pageNum = useRef(1);
  const totalItem = useRef<IListItem[]>([]);
  const columns: IColumn[] = [
    {
      title: "推广周期",
      dataIndex: "monthId",
      render: (value) => {
        console.log("value", value);
        return (
          <div>{dayjs(value?.toString(), "YYYYMM")?.format("YYYY/MM")}</div>
        );
      },
    },
    {
      title: "收益(元)",
      dataIndex: "billCommis",
      align: "right",

      render: (value) => (
        <div className={styles.incomeNum}>{formatCurrency(value || "0")}</div>
      ),
    },
    {
      title: "状态",
      dataIndex: "status",
      render: (value) => {
        return (
          <>
            {value?.toString() === "30" && (
              <span className={styles.failStatus}>未结算</span>
            )}
            {value?.toString() === "40" && (
              <span className={styles.successStatus}>已结算</span>
            )}
            <span />
          </>
        );
      },
    },
  ];
  const {
    runAsync: mobileBillPageRunAsync,
    data: mobileBillPageData,
    loading: mobileBillPageLoading,
  } = useRequest(mobileBillPage, {
    defaultParams: [
      {
        pageNum: pageNum.current,
        pageSize,
      },
    ],
    onSuccess: (res, params) => {
      totalItem.current?.push(...(res.data?.list || []));
      pageNum.current = params[0].pageNum || 1;
    },
  });
  const { data: summaryData, loading: summaryLoading } =
    useRequest(mobileBillSummary);
  const loadMore = async () => {
    await mobileBillPageRunAsync({
      pageNum: pageNum.current + 1,
      pageSize,
    });
  };
  const hasMore = useMemo(() => {
    return (
      (mobileBillPageData?.data?.total || 0) > (totalItem.current?.length || 0)
    );
  }, [mobileBillPageData]);
  return (
    <ApplyLayout className={styles.page}>
      <PageLoading loading={mobileBillPageLoading || summaryLoading} />
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.left}>
            <div className={styles.title}>
              {formatCurrency(
                new Decimal(summaryData?.data?.settledIncome || 0)
                  .add(new Decimal(summaryData?.data?.unSettledIncome || 0))
                  .toString()
              )}
            </div>
            <div className={styles.text}>全部收益（元）</div>
          </div>
          <div className={styles.middle} />
          <div className={styles.right}>
            <div className={styles.title}>
              {formatCurrency(summaryData?.data?.unSettledIncome || 0)}
            </div>
            <div className={styles.text}>未结算收益（元）</div>
          </div>
        </div>
        <Table
          rowClick={(record, index) => {
            openUrl({
              url: "/partner/enterprise/income/detail",
              isOpenHalf: false,
              isRoute: true,
              params: {
                monthId: record.monthId,
              },
            });
          }}
          className={styles.table}
          bodyClassName={styles.tableBody}
          columns={columns}
          dataSource={totalItem.current}
          loadMore={loadMore}
          hasMore={hasMore}
        />
      </div>
    </ApplyLayout>
  );
};

export default React.memo(EnterpriseIncome);
