import axios, { IBaseRes } from "@/common/Utils/APIClient";
import { PersonalCooperationStatusEnum, PromotionContentEnum } from "../enum";

interface IParams {}

export interface PartnerDtoList {
  /** 头像 */
  avatar: string;
  /** 信用代码 */
  creditCode: string;
  /** 企业id */
  enterpriseId: string;
  /** 企业名称 */
  enterpriseName: string;
  /** 银行信息 */
  partnerBankDto: null;
  /** 合伙人姓名 */
  partnerName: string;
  /** 合伙人手机号 */
  partnerPersonMobile: string;
  /** 推广内容 */
  promotionContent: PromotionContentEnum;
  /** 推广内容-字符串，:分割 */
  promotionContentStr: string;
  /** 是否是管理员 */
  isAdmin?: boolean;
  /** 合作状态 */
  status: PersonalCooperationStatusEnum;
  partnerId: string;
  partnerPerson?: string;
  /** 是否是本人 */
  isSelf?: boolean;
}

interface IGroupItem {
  /** 分组名称 */
  groupName: string;
  /** 合伙人员列表 */
  partnerDtoList: PartnerDtoList[];
}
/**
 * 合伙人员管理列表
 */
export const getGroup = (params: IParams): Promise<IBaseRes<IGroupItem[]>> => {
  return axios.get("/dmall-customer/partner/app/group", {
    param: params,
    headers: {
      Desensitize: "close",
    },
  });
};
