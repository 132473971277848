import axios from "@/common/Utils/APIClient";
import { UserInfo } from "./getDingUser";

export const getBuyerUser = () => {
  return axios
    .get("/dmall-customer/buyer/info")
    .then((res: UserInfo) => {
      console.log("res", res);
      const { data, success } = res;
      if (success) {
        return data;
      }
      return undefined;
    });
};
