import React, { useRef, useState } from "react";
import usePageTitle from "@/hooks/usePageTitle";
import { Button } from "antd-mobile";
import styles from "./index.module.scss";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { getSmsVerificationCode, VerifyCode } from "../../Services";
import VerificationCode from "src/common/Component/VerificationCode";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";

function PhoneVerify() {
  usePageTitle({ title: "更换手机号" });
  const openUrl = useOpenUrl();

  const [visibleVerify, setVisibleVerify] = useState(false);
  const [userInfo] = useUserStore(
    useShallow((state) => [
      state.userInfo,
    ])
  );
  const phone = userInfo?.userMobile || '';
  const codeRef = useRef<any>(null);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>安全验证</h1>
      <div className={styles.tips}>为了保证你的账号安全，需先验证你的身份，验证成功后可以进行下一步操作。</div>
      <div className={styles.phone}>{phone}</div>
      <div className={styles.actionContainer}>
        <Button 
          block 
          color="primary" 
          className={styles.submitVerifyButton}
          onClick={() => {
            setVisibleVerify(true);
            codeRef.current?.focus();
          }}
        >
          获取验证码
        </Button>
      </div>
      <VerificationCode
        visible={visibleVerify}
        phone={phone}
        getVerifyCode={() => {
          return getSmsVerificationCode();
        }}
        verifyCode={(data) => {
          return VerifyCode({
            code: data.code,
          });
        }}
        onOk={() => {
          setVisibleVerify(false);
          (document.activeElement as HTMLElement)?.blur(); // 关闭手机软键盘
          openUrl({
            url: '/personalInfo/changePhone',
            isRoute: true,
          });
        }}
        isCheckAfter={true}
        onClose={() => {
          setVisibleVerify(false);
          (document.activeElement as HTMLElement)?.blur(); // 关闭手机软键盘
        }}
        ref={codeRef} 
      />
    </div>
  );
}

export default PhoneVerify;