import React, { useEffect, useState } from "react";
import { Toast, InfiniteScroll } from "antd-mobile";
import OrderList from "./Components/OrderList";
import { getOrderList } from "./Services";
import usePageTitle from "@/hooks/usePageTitle";
import { useRequest } from "ahooks";

import styles from "./index.module.scss";

interface IOrder {
  createAt: string;
  subject: string;
  payAmount: number;
  body: string;
  shopId: string;
  successTime: string;
  refundStatus: number;
}

function HomeBill() {
  usePageTitle({ title: "我的订单" });

  const [orderList, setOrderList] = useState<IOrder[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [nextPage, setNextPage] = useState(1);

  const { run } = useRequest(getOrderList, {
    manual: true,
    onSuccess: (res: any) => {
      const { success, msg, data } = res || {};
      const { records, current, pages } = data || {};
      if (success) {
        const orderListResponse: IOrder[] = [];

        records.forEach((item: any) => {
          const order = {
            createAt: item.createAt,
            subject: item.subject,
            payAmount: item.payAmount,
            body: item.body,
            shopId: item.shopId,
            successTime: item.successTime,
            refundStatus: item.refundStatus,
          }
          orderListResponse.push(order);
        })

        setOrderList([
          ...orderList,
          ...orderListResponse
        ]);

        current < pages ? setHasMore(true) : setHasMore(false);
        if (current < pages) {
          setHasMore(true)
          setNextPage(current + 1)
        }
      } else {
        Toast.show(msg)
      }
    },
  });

  const loadMore = async() => {
    setHasMore(false)
    await run({ pageNum: nextPage })
  }

  useEffect(() => {
    run()
  }, [])

  return (
    <div className={styles.container}>
      {/* <TimeSelect onSubmit={run} /> */}
      <div className={styles.orderListTitle}>全部订单</div>
      <OrderList orderList={orderList} />
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
    </div>
  );
}

export default HomeBill;