import React, { useEffect, useState } from "react";
import { Toast, InfiniteScroll } from "antd-mobile";
import TimeSelect from "@/common/Component/TimeSelect";
import BillList, { IBill, IBillList } from "@/common/Component/BillList";
import { getBillList } from "./Services";
import usePageTitle from "@/hooks/usePageTitle";
import styles from "./index.module.scss";
import { useRequest } from "ahooks";
import dayjs from 'dayjs';
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { processIcon } from "@/common/Utils/render";

function MyBill() {
  usePageTitle({ title: "我的账单" });
  const openUrl = useOpenUrl();
  const [billList, setBillList] = useState<IBillList[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [nextPage, setNextPage] = useState(1);

  // 处理账单数据分组
  const processBills = (bills: IBill[], existingGroups: IBillList[] = []) => {
    // 创建临时对象存储所有分组
    const groups: { [key: string]: IBill[] } = {};
    
    // 加载现有分组数据
    existingGroups.forEach(group => {
      groups[group.title] = group.list;
    });

    // 处理新的账单数据
    bills.forEach(bill => {
      const dateKey = dayjs(bill.createAt).format('YYYY-MM');
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(bill);
    });

    // 转换回数组格式并按日期排序
    return Object.entries(groups)
      .map(([title, list]) => ({ title, list }))
      // .sort((a, b) => dayjs(b.title).valueOf() - dayjs(a.title).valueOf());
  };

  const { run } = useRequest(getBillList, {
    manual: true,
    onSuccess: (res: any) => {
      const { success, msg, data } = res || {};
      const { records, current, pages } = data || {};
      if (success) {
        const billListResponse = records.map((item: any) => ({
          createAt: item.createAt,
          subject: item.subject,
          orderAmount: item.orderAmount,
          orderId: item.orderId,
          orderType: item.orderType,
          refundAmount: item.refundAmount,
          refundStatus: item.refundStatus,
          icon: processIcon(item.appNo),
        }));

        // 更新分组数据，保留现有数据
        setBillList(prevGroups => {
          if (current === 1) {
            prevGroups = [];
          }
          const newGroups = processBills(billListResponse, prevGroups);
          return newGroups;
        });

        // 更新分页状态
        setHasMore(current < pages);
        if (current < pages) {
          setNextPage(current + 1);
        }
      } else {
        Toast.show(msg);
      }
    },
  });

  const loadMore = async() => {
    setHasMore(false);
    await run({ pageNum: nextPage });
  };

  useEffect(() => {
    run();
  }, []);

  return (
    <div className={styles.container}>
      <TimeSelect onSubmit={run} />
      <BillList billList={billList} gotoList={(item: IBill) => {
        openUrl({
          isRoute: true,
          url: '/billDetail',
          params: { id: item.orderId },
        });
      }} />
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
    </div>
  );
}

export default MyBill;