import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import AMapLoader from "@amap/amap-jsapi-loader";
import { MAP_CONFIG } from "@/common/Config/Constant";
import { Toast } from "antd-mobile";
const { key, securityJsCode } = MAP_CONFIG;
export interface ILocation {
  center: number[];
  result: Result;
  lat?: number;
  lng?: number;
  detailAddress?: string;
}

interface Result {
  info: string;
  regeocode: Regeocode;
}

interface Regeocode {
  addressComponent: AddressComponent;
  formattedAddress: string;
  roads: any[];
  crosses: any[];
  pois: any[];
}

interface AddressComponent {
  citycode: string;
  adcode: string;
  businessAreas: any[];
  neighborhoodType: string;
  neighborhood: string;
  building: string;
  buildingType: string;
  street: string;
  streetNumber: string;
  province: string;
  city: string;
  district: string;
  towncode: string;
  township: string;
}

interface ILocationProps {}
interface IConfig {
  onOk?: (data: ILocation) => void;
  onError?: () => void;
}
export interface ILocationRef {
  getLocation: (data?: IConfig) => void;
}
const Location: React.ForwardRefRenderFunction<ILocationRef, ILocationProps> = (
  props,
  ref
) => {
  //   let map = null;
  const AMap = useRef<any>(null);
  const map = useRef<any>(null);
  const container = useRef<HTMLDivElement>(null);
  const geolocation = useRef<any>(null);
  const geocoder = useRef<any>(null);
  const config = useRef<IConfig>();

  const completeLocation = useCallback((data: any) => {
    const center = data.position;
    geocoder.current.getAddress(data.position, (status: any, result: any) => {
      if (status === "complete" && result.info === "OK") {
        const province = result.regeocode.addressComponent.province;
        const city = result.regeocode.addressComponent.city;
        const district = result.regeocode.addressComponent.district;
        const township = result.regeocode.addressComponent.township;
        // const street = result.regeocode.addressComponent.street;
        const detailAddress = result.regeocode.formattedAddress
          ?.replace(province, "")
          .replace(city, "")
          .replace(district, "")
          .replace(township, "");
        const data = {
          center,
          result,
          lat: center.lat,
          lng: center.lng,
          detailAddress,
        };
        // setData(data);
        config.current?.onOk && config.current.onOk(data);
      } else {
        Toast.show({
          icon: "error",
          content: "获取地址失败",
        });
      }
    });
  }, []);
  const errorLocation = useCallback((e: any) => {
    console.log(e);
    Toast.show("定位失败");
    config.current?.onError && config.current.onError();
  }, []);
  const init = useCallback(async () => {
    window._AMapSecurityConfig = {
      securityJsCode,
    };
    const plugins = ["AMap.Geolocation", "AMap.Geocoder"];
    AMap.current = await AMapLoader.load({
      key, // 申请好的Web端开发者Key，首次调用 load 时必填
      version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins, //需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
    }).catch((e) => {
      console.log(e);
    });
    if (AMap.current) {
      map.current = new AMap.current.Map(container.current, {
        // 设置地图容器id
        viewMode: "3D", // 是否为3D地图模式
        zoom: 11, // 初始化地图级别
        center: [116.397493, 39.907954], // 初始化地图中心点位置
      });
      AMap.current.plugin(plugins, function () {
        geocoder.current = new AMap.current.Geocoder({
          city: "全国", // 可选参数，指定搜索的城市
        });
        geolocation.current = new AMap.current.Geolocation({
          // city: "010", //城市，默认全国
          radius: 20, //范围，默认：500
          position: "RB", //返回格式，默认："GPS"
          offset: [10, 20],
          extensions: "all",
          showButton: true,
          convert: true,
          noIpLocate: 3, // 禁用IP定位
          panToLocation: true, // 定位成功后是否自动移动到响应位置
          enableHighAccuracy: true, // 是否使用高精度定位，默认:true
          timeout: 10000, // 超过10秒后停止定位，默认：无穷大
          zoomToAccuracy: true, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        });
        geolocation.current.on("complete", completeLocation);
        geolocation.current.on("error", errorLocation);
        geolocation.current?.getCurrentPosition?.();
      });
    }
  }, []);

  useEffect(() => {
    init().catch((e) => {
      console.log(e);
    });
    return () => {
      // geolocation.current.off("complete", completeLocation);
      // geolocation.current.off("error", errorLocation);
      map.current?.destroy();
    };
  }, []);
  useImperativeHandle(ref, () => {
    return {
      getLocation: (data) => {
        config.current = data;
        geolocation.current?.getCurrentPosition?.();
      },
    };
  });

  return <div style={{ display: "none", height: 100 }} ref={container}></div>;
};

export default React.forwardRef(Location);
