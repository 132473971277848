import axios from "@/common/Utils/APIClient";
export interface IApplyReq {
  id: number;
}

export interface IApplyRes {
  code: string;
  data: IApplyResData;
  msg: string;
  success: boolean;
}

// 响应数据中的 AccountDTO 接口
export interface IApplyResData {
  accountCd: string;
  accountId: string;
  accountName: string;
  accountType: string;
  balance: number;
  bankCardNo: string;
  // bankDTO: BankDTO;
  bankName: string;
  bankId: string;
  buyerId: string;
  createAt: null;
  createBy: number;
  dayAmount: number;
  enterpriseAccountId: string;
  extInfo: string;
  frozenAmount: number;
  inAmount: number;
  monthAmount: number;
  opCert: string;
  opName: string;
  opPhone: string;
  outAmount: number;
  status: number;
  tanentId: string;
  transitAmount: number;
  upAccountId: string;
  updateAt: null;
  updateBy: number;
  weekAmount: number;
}

/**
 * 银行信息
 */
export interface BankDTO {
  /** 地址 */
  address: string;
  /** 地区 */
  area: string;
  /** 银行编码 */
  bankCd: string;
  /** 银行ID */
  bankId: string;
  /** 银行名称 */
  bankName: string;
  /** 城市 */
  city: string;
  createAt: null;
  createBy: number;
  extInfo: string;
  phoneNumber: string;
  status: number;
  tanentId: string;
  updateAt: null;
  updateBy: number;
}

/**
 * 通过accountId查询详情
 */
export const getAccountDetail = (data: IApplyReq): Promise<IApplyRes> => {
  return axios.get("/dmall_account/Account", {
    param: data,
    headers: {
      "Desensitize": "close",
    },
  });
};
