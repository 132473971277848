import apiClient, { IBaseRes } from "src/common/Utils/APIClient";

interface IRealNameAuthParams {
  userName: string;
  identityNum: string;
}

/**
 * 提交实名认证
 */
export const submitRealNameAuth = (
  params: IRealNameAuthParams
): Promise<IBaseRes<any>> => {
  return apiClient.post('/dmall_customer/buyer/id2MetaVerify', params);
};