import React, { useEffect } from "react";
import Filter from "./Component/Filter";
import Content from "./Component/Content";

import NoCoupon from "./Component/NoCoupon";
import style from './index.module.scss';

function Coupon() {

  return (
    <div className={style.app}>
      {/* <Filter />
      <Content /> */}
      <NoCoupon />
    </div>
  );
}



export default Coupon;