import React, { useEffect, useMemo, useRef, useState } from "react";
import { useRequest } from "ahooks";
import { useParams, useNavigate } from "react-router-dom";
import { getPartnerCode, IItem } from "./Services";
import { Toast, Image, Tabs, SafeArea } from "antd-mobile";
import Swiper, { SwiperRef } from "antd-mobile/es/components/swiper";
import styles from "./index.module.scss";
import { pxToRem } from "@/common/Utils/rem";
import Icon from "@/common/Component/Icon";
import Tab from "@/common/Component/Tabs";
import PageLoading from "@/common/Component/PageLoading";
import { ExclamationCircleOutline } from "antd-mobile-icons";

// interface IData {
//   title: string;
//   content: string;
//   name: string;
// }

function PartnerCode() {
  const { code } = useParams<{ code: string }>();
  // const [data, setData] = useState<IItem[]>([]);
  const swiperRef = useRef<SwiperRef>(null);
  const [activeKey, setActiveKey] = React.useState("0");
  const navigate = useNavigate();

  const { run, loading, data } = useRequest(getPartnerCode, {
    manual: true,
    onSuccess: (res) => {
      const { success, msg, data } = res;
      if (success) {
        // setData(data?.list || []);
      } else {
        Toast.show({
          content: msg || "合作伙伴码无效",
          afterClose: () => {
            navigate("/");
          },
        });
      }
    },
  });

  useEffect(() => {
    if (code) {
      run({ unionCode: code });
    }
  }, [code, run]);

  const tabData = useMemo(() => {
    return (
      data?.data?.list?.map((item, index) => {
        return {
          title: item.title,
          data: item,
          key: index.toString(),
        };
      }) || []
    );
  }, [data]);
  const changeTab = (key: string) => {
    setActiveKey(key);
  };
  const activeData = useMemo(() => {
    return tabData[Number(activeKey)]?.data || {};
  }, [tabData, activeKey]);
  return (
    // <div className={styles.container}>
    //   <div className={styles.page} style={{ paddingTop: pxToRem(250) }}>
    //     <div className={styles.content}>
    //       <Tabs
    //         activeLineMode="fixed"
    //         activeKey={activeIndex.toString()}
    //         onChange={(key) => {
    //           const index = Number(key);
    //           setActiveIndex(index);
    //           swiperRef.current?.swipeTo(index);
    //         }}
    //         style={{
    //           "--active-line-height": pxToRem(8),
    //           "--fixed-active-line-width": pxToRem(40),
    //           "--title-font-size": pxToRem(28),
    //           "--active-line-color": "#1879FF",
    //           "--active-line-border-radius": pxToRem(4),
    //           "--active-title-color": "#242931",
    //         }}
    //         className={styles.tabs}
    //       >
    //         {data.map((item, index) => (
    //           <Tabs.Tab title={item.title} key={index} />
    //         ))}
    //       </Tabs>
    //       <Swiper
    //         className={styles.swiper}
    //         direction="horizontal"
    //         loop
    //         indicator={() => null}
    //         ref={swiperRef}
    //         defaultIndex={activeIndex}
    //         onIndexChange={(index) => {
    //           setActiveIndex(index);
    //         }}
    //       >
    //         {data.map((item, index) => (
    //           <Swiper.Item>
    //             <div className={styles.qrCodeBg}>
    //               <Image className={styles.qrCode} src={item.content} />
    //             </div>
    //           </Swiper.Item>
    //         ))}
    //       </Swiper>
    //       <div className={styles.partnerInfo}>
    //         {data?.[activeIndex]?.name}：{code}
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className={styles.page}>
      <div className={styles.container}>
        <PageLoading loading={loading} />
        <div className={styles.content}>
          <Tab
            model="out"
            itemData={tabData}
            activeKey={activeKey}
            onChange={changeTab}
            stretch
            className={styles.tab}
          />
          {data?.data && !data.data?.status && (
            <div className={styles.warningTip}>
              <span className={styles.icon}>
                <ExclamationCircleOutline className={styles.warningIcon} />
              </span>
              <span className={styles.warningText}>
                您已经与{data?.data?.corpName}
                停止合作，继续推广将不会计算业绩和佣金
              </span>
            </div>
          )}
          <div className={styles.qrContainer}>
            <div className={styles.qrTitle}>手机扫描二维码</div>
            <div className={styles.qrCodeBody}>
              <img
                className={styles.qrCodeImage}
                src={activeData?.content}
                alt="二维码"
              />
            </div>
            <div className={styles.qrCodeText}>
              {activeData.name}：{data?.data?.unionCode}
            </div>
            {/* <Buttons
              block
              color="primary"
              onClick={() => {
                copy(
                  `${window.location.origin}/partnerCode/${partnerCodeData?.data?.unionCode}`
                );
                Toast.show("复制成功");
              }}
              customColor="black"
              className={styles.button}
            >
              分享我的邀请码
            </Buttons> */}
          </div>
        </div>
        {/* <div className={styles.actionList}></div> */}
      </div>
      <div style={{ background: "transparent" }}>
        <SafeArea position="bottom" />
      </div>
    </div>
  );
}

export default PartnerCode;
