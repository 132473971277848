import React, { useMemo } from "react";
import styles from "./index.module.scss";
import ApplyLayout from "@/common/Component/ApplyPage";
import usePageTitle from "@/hooks/usePageTitle";
import Card from "../../components/Card";
import { getEnvImageUrl } from "@/common/Utils/env";
import { Divider, Grid, Space } from "antd-mobile";
import Icon from "@/common/Component/Icon";
import { formatCurrency } from "@/common/Utils/money";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { useRequest } from "ahooks";
import { mobileOverview } from "../../services/mobileOverview";
import PageLoading from "@/common/Component/PageLoading";

interface EnterpriseProps {}
/** 合伙人-企业管理 */
const Enterprise: React.FC<EnterpriseProps> = (props) => {
  // const {} = props;
  usePageTitle({ title: "企业合伙人" });
  const { data: overviewData, loading: overviewLoading } = useRequest(
    mobileOverview,
    {}
  );
  const openUrl = useOpenUrl();
  const renderIncome = (num: string, text: string) => {
    return (
      <div className={styles.incomeItem}>
        <div className={styles.incomeNum}>{formatCurrency(num, true) || 0}</div>
        <div className={styles.incomeText}>{text}</div>
      </div>
    );
  };
  // 渲染业绩
  const renderPerformance = () => {
    const data = [
      { num: overviewData?.data?.enterpriseNum || 0, text: "组织安装" },
      { num: overviewData?.data?.shopNum || 0, text: "商户邀约" },
      // { num: overviewData?.data?.referralNum || 0, text: "广告推广" },
    ];
    return (
      <Grid columns={3}>
        {data.map((item, index) => {
          return (
            <Grid.Item key={index}>
              <div className={styles.performanceItem}>
                <div className={styles.performanceNum}>{item.num || 0}</div>
                <div className={styles.performanceText}>{item.text}</div>
              </div>
            </Grid.Item>
          );
        })}
      </Grid>
    );
  };
  const { service } = useMemo(() => {
    const service = [
      {
        icon: "icon-yuangongguanli",
        text: "人员管理",
        onClick: () => {
          openUrl({
            url: "/partner/manage/staff",
            isRoute: true,
            isOpenHalf: false,
          });
        },
      },
      {
        icon: "icon-xinxiguanli",
        text: "企业信息",
        onClick: () => {
          openUrl({
            url: "/partner/manage/info",
            isRoute: true,
            isOpenHalf: false,
            params: {
              partnerId: overviewData?.data?.partnerInfo.id,
            },
          });
        },
      },
      {
        icon: "icon-qiyema",
        text: "推广码",
        onClick: () => {
          openUrl({
            url: "/partner/personal/code",
            isRoute: true,
            isOpenHalf: false,
            params: {
              partnerId: overviewData?.data?.partnerInfo.id,
              code: "C373F14D",
            },
          });
        },
      },
    ];
    // const more = [
    //   {
    //     icon: "icon-qiyema",
    //     text: "推广码",
    //     onClick: () => {
    //       openUrl({
    //         url: "/partner/personal/code",
    //         isRoute: true,
    //         isOpenHalf: false,
    //         params: {
    //           partnerId: overviewData?.data?.partnerInfo.id,
    //           code: "C373F14D",
    //         },
    //       });
    //     },
    //   },
    //   {
    //     icon: "icon-zhanghaomima",
    //     text: "账号密码",
    //     onClick: () => {
    //       openUrl({
    //         // url: "/partner/manage/info",
    //         isRoute: true,
    //         isOpenHalf: false,
    //         params: {
    //           partnerId: overviewData?.data?.partnerInfo.id,
    //         },
    //       });
    //     },
    //   },
    //   {
    //     icon: "icon-tuichudenglu",
    //     text: "退出登录",
    //     onClick: () => {
    //       openUrl({
    //         // url: "/partner/manage/info",
    //         isRoute: true,
    //         isOpenHalf: false,
    //         params: {
    //           partnerId: overviewData?.data?.partnerInfo.id,
    //         },
    //       });
    //     },
    //   },
    // ];
    return { service };
  }, [overviewData]);
  // 渲染功能服务
  const renderService = (data: typeof service) => {
    return (
      <Grid columns={4} className={styles.service}>
        {data.map((item, index) => {
          return (
            <Grid.Item key={index}>
              <div className={styles.serviceItem} onClick={item.onClick}>
                <Icon className={styles.serviceItemIcon} icon={item.icon} />
                <div className={styles.serviceItemText}>{item.text}</div>
              </div>
            </Grid.Item>
          );
        })}
      </Grid>
    );
  };
  // 去设置
  const goSetting = () => {
    openUrl({
      url: "/partner/settings",
      isRoute: true,
      isOpenHalf: false,
      params: {
        logout: true,
      },
    });
  };
  return (
    <ApplyLayout
      contentClassName={styles.container}
      className={styles.enterprise}
    >
      <div style={{ width: "100%" }}>
        <PageLoading loading={overviewLoading} />
        <div className={styles.header}>
          <div className={styles.logoContainer}>
            <img
              src={getEnvImageUrl(
                "%E7%BC%96%E7%BB%84%206448910b-6842-4753-abe5-35bfc1f74993.png"
              )}
              alt=""
              className={styles.logo}
            />
            <span className={styles.title}>
              {overviewData?.data?.partnerInfo.partnerName}
            </span>
          </div>
          <div className={styles.settingButton}>
            <Icon
              onClick={() => goSetting()}
              className={styles.setting}
              icon="icon-a-shezhi1x"
            />
            <span className={styles.settingText}>设置</span>
          </div>
        </div>
        <Space className={styles.space} block direction="vertical">
          <Card
            title={"总收益"}
            titleClick={() => {
              openUrl({
                url: "/partner/enterprise/income",
                isRoute: true,
                isOpenHalf: false,
              });
            }}
          >
            <div className={styles.income}>
              {renderIncome(
                overviewData?.data?.settledIncome || "0",
                "全部收益(元)"
              )}
              <Divider className={styles.divider} direction="vertical" />
              {renderIncome(
                overviewData?.data?.unSettledIncome || "0",
                "未结算收益(元)"
              )}
            </div>
          </Card>
          <Card
            title={"总业绩"}
            titleClick={() => {
              openUrl({
                url: "/partner/enterprise/performance",
                isRoute: true,
                isOpenHalf: false,
              });
            }}
          >
            {renderPerformance()}
          </Card>
          <Card title={"功能服务"}>{renderService(service)}</Card>
          {/* <Card title={"更多服务"}>{renderService(more)}</Card> */}
        </Space>
      </div>
    </ApplyLayout>
  );
};
export default React.memo(Enterprise);
