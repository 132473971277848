import apiClient from "src/common/Utils/APIClient";
interface IParams {
  /**
   * 账户ID
   */
  noBalance?: boolean;
}
/**
 * 获取银行卡列表
 */
const getBankCard = (data?: IParams): Promise<IRes> => {
  return apiClient.get("/dmall_account/Account/accounts", { param: data });
};

export { getBankCard };
interface IRes {
  code: string;
  msg: string;
  data: Accounts[];
  success: boolean;
}

// export interface Accounts {
//   /** 账户ID */
//   accountId: string;
//   /** 银行ID */
//   bankId: number;
//   /** 买家ID */
//   buyerId: number;
//   /** 父账户ID */
//   upAccountId: number;
//   /** 企业餐补账户ID */
//   enterpriseAccountId: number;
//   /** 银行卡号 */
//   bankCardNo: string;
//   /** 账户编码 */
//   accountCd: string;
//   /** 账户名称 */
//   accountName: string;
//   /** 账户类型 */
//   accountType: string;
//   /** 姓名 */
//   opName: string;
//   /** 证件号 */
//   opCert: string;
//   /** 手机号 */
//   opPhone: string;
//   /** 可用余额 */
//   balance: number;
//   /** 在途金额 */
//   transitAmount: number;
//   /** 冻结金额 */
//   frozenAmount: number;
//   /** 累计转入额 */
//   inAmount: number;
//   /** 累计转出额 */
//   outAmount: number;
//   /** 今日交易额 */
//   dayAmount: number;
//   /** 本周交易额 */
//   weekAmount: number;
//   /** 本月交易额 */
//   monthAmount: number;
//   /** 扩展信息 */
//   extInfo: string;
//   /** 状态 */
//   status: number;
//   /** 创建时间 */
//   createAt: string;
//   /** 更新时间 */
//   updateAt: string;
//   /** 租户 */
//   tanentId: number;
//   /** 创建人 */
//   createBy: number;
//   /** 更新人 */
//   updateBy: number;
// }

export interface Accounts {
  accountCd: string;
  accountId: string;
  accountName: string;
  accountType: string;
  balance: number;
  bankCardNo: string;
  // bankDTO: BankDTO;
  bankId: string;
  buyerId: string;
  createAt: null;
  createBy: number;
  dayAmount: number;
  enterpriseAccountId: string;
  // enterpriseApplyDTO: EnterpriseApplyDTO;
  extInfo: string;
  frozenAmount: number;
  inAmount: number;
  monthAmount: number;
  opCert: string;
  opName: string;
  opPhone: string;
  outAmount: number;
  status: number;
  tanentId: string;
  transitAmount: number;
  upAccountId: string;
  updateAt: null;
  updateBy: number;
  weekAmount: number;
  enterpriseName: string;
  applyOrgId?: string;
}

interface BankDTO {
  address: string;
  area: string;
  bankCd: string;
  bankId: string;
  bankName: string;
  city: string;
  createAt: null;
  createBy: number;
  extInfo: string;
  phoneNumber: string;
  status: number;
  tanentId: string;
  updateAt: null;
  updateBy: number;
}

interface EnterpriseApplyDTO {
  accountId: string;
  adminAccount: null;
  adminMobile: string;
  adminName: string;
  applyNote: string;
  applyOrgId: string;
  applyStatus: number;
  applyTime: null;
  bankAccount: null;
  bankCardNo: string;
  contactCert: string;
  contactName: string;
  contactPhone: string;
  createAt: Date;
  createBy: number;
  creditCert: string;
  creditCode: string;
  enterpriseCd: string;
  enterpriseId: string;
  enterpriseName: string;
  extInfo: string;
  reviewBy: number;
  reviewTime: null;
  status: number;
  tanentId: string;
  updateAt: Date;
  updateBy: number;
  useBankAccount: null;
}
