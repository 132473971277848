import * as dd from "dingtalk-jsapi";
import { Toast } from "antd-mobile";

// const openMiniApp = () => {
//   dd.ready(() => {
//     openAuthMiniApp({
//       panelHeight: "percent75",
//       path: "pages/home/home", //不要改,这里是小程序dingwlanwvdmrtjjwdmd下的一个页面地址
//       extraData: {
//         clientId: APP_ID, // 应用ID(唯一标识)
//         rpcScope: "Contact.User.Read",
//         fieldScope: "Contact.User.mobile",
//         type: 0,
//         ext: JSON.stringify({}),
//         from: "",
//       },
//     }).then((res) => {
//       Toast.show({
//         content: JSON.stringify(res),
//         duration: 20000,
//       });
//       // 处理返回数据
//       console.log(res);
//     });
//   });
// };

interface IOpenHalfScreenH5 {
  title?: string;
  width?: string;
  height?: string;
  onSuccess?: (res: any) => void;
  onFail?: (err: any) => void;
}
export const openHalfScreenH5 = (url: string, options?: IOpenHalfScreenH5) => {
  if (dd?.env?.platform === "notInDingTalk") {
    Toast.show({
      content: "请在钉钉客户端内打开",
      duration: 2000,
    });
    return;
  }
  dd.ready(() => {
    // @ts-ignore
    dd.biz.util.openLink({
      url: url, // 要打开的 H5 页面 URL
      // @ts-ignore
      title: options?.title || "标题", // 可选，H5 页面的标题
      width: options?.width || "75%", // 可选，弹出窗口的宽度，默认为 "100%"
      height: options?.height || "75%", // 可选，弹出窗口的高度，默认为 "100%"
      // @ts-ignore
      onSuccess: (result) => {
        // 打开成功后的回调
        options?.onSuccess && options.onSuccess(result);
      },
      // @ts-ignore

      onFail: (err) => {
        // 打开失败后的回调
        Toast.show({
          content: "H5 页面打开失败：" + JSON.stringify(err),
          duration: 2000,
        });
        options?.onFail && options.onFail(err);
      },
    });
  });
};
