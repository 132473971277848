import React from "react";
import { Form, Input, Button, Toast } from "antd-mobile";
import { setPayPassword } from "../../Services";

import styles from "./index.module.scss";
import { useRequest } from "ahooks";

function SmsSetting({ account, successCallback }: { account: any, successCallback: Function }) {
  const [form] = Form.useForm();

  const { run: setPayPasswordRun } = useRequest(setPayPassword, {
    manual: true,
    onSuccess: (res) => {
      if (res.success) {
        Toast.show('支付密码修改成功');
        successCallback();
      } else {
        Toast.show(res.msg);
      }
    }
  });

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();

      setPayPasswordRun({
        accountId: account.accountId,
        setType: 2,
        payPassword: values.password_changepwd,
        code: values.oldPassword_changepwd,
      });
    } catch (error) {
      console.log(error)
    }
  }
  
  return (
    <div className={styles.container}>
      <Form
        layout='horizontal'
        form={form}
        footer={
          <div className={styles.btnContainer}>
            <Button className={styles.btn} onClick={onSubmit} block size="large" type="submit">
              确认
            </Button>
          </div>
        }
      >
        <Form.Item
          label='旧密码'
          name='oldPassword_changepwd'
          validateTrigger={['onBlur']} // 禁用自动校验
          rules={[
            { required: true, message: '请输入旧密码' },
            { 
              validator: (_, value) => {
                if (value && value.length !== 6) {
                  return Promise.reject('密码必须为6位');
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input type="password" maxLength={6} pattern="[0-9]*" inputMode="numeric" placeholder="请输入旧密码" />
        </Form.Item>
        <Form.Item
          label='密码'
          name='password_changepwd'
          validateTrigger={['onBlur']} // 禁用自动校验
          rules={[
            { required: true, message: '请输入密码' },
            { 
              validator: (_, value) => {
                if (value && value.length !== 6) {
                  return Promise.reject('密码必须为6位');
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input type="password" maxLength={6} pattern="[0-9]*" inputMode="numeric" placeholder="请输入6位支付密码" />
        </Form.Item>
        <Form.Item
          label='确认密码'
          name='confirmPassword_changepwd'
          validateTrigger={['onBlur']} // 在失去焦点时触发校验
          rules={[
            { required: true, message: '请输入确认密码' },
            { 
              validator: (_, value) => {
                if (value && value.length !== 6) {
                  return Promise.reject('密码必须为6位');
                }
                return Promise.resolve();
              }
            },
            { 
              validator: (rule, value) => {
                if (value !== form.getFieldValue('password_changepwd')) {
                  return Promise.reject('两次填写的密码不一致');
                }
                return Promise.resolve();
              },
            }
          ]}
        >
          <Input type="password" maxLength={6} pattern="[0-9]*" inputMode="numeric" placeholder="请输入6位支付密码" />
        </Form.Item>
      </Form>
    </div>
  );
}

export default SmsSetting;