import React from "react";
import styles from "./index.module.scss";
import { RightOutline } from "antd-mobile-icons";
import Icon from "@/common/Component/Icon";
import classNames from "classnames";

interface CardsProps {
  title?: string;
  extra?: string;
  children?: React.ReactNode;
  extraClick?: () => void;
  contentClassName?: string;
  showContentClassName?: string;
}

const Cards: React.FC<CardsProps> = (props) => {
  const {
    title,
    extra,
    children,
    extraClick,
    contentClassName,
    showContentClassName,
  } = props;
  const [showAll, setShowAll] = React.useState(false);
  return (
    <div className={styles.contentCard}>
      <div className={styles.cardTitle}>
        <span>{title}</span>
        <span className={styles.extra} onClick={extraClick}>
          {extra}
          {extraClick && <RightOutline className={styles.icon} />}
        </span>
      </div>
      <div
        className={classNames(styles.content, {
          [contentClassName || ""]: !!contentClassName,
          [styles.showAll]: showAll,
          [showContentClassName || ""]: showAll,
        })}
      >
        {children}
      </div>
      {!!showContentClassName && (
        <div className={styles.action} onClick={() => setShowAll(!showAll)}>
          <div className={styles.actionText}>
            {showAll ? "收起" : "展示全部"}
          </div>
          <Icon
            icon="icon-fanhui"
            className={classNames(styles.actionIcon, {
              [styles.actionIconRotate]: showAll,
            })}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(Cards);
