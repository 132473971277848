import React, { useEffect } from "react";
import { Swiper, Image, NavBar, Ellipsis, SafeArea } from "antd-mobile";
import { useRequest } from "ahooks";
import { useSearchParams } from "react-router-dom";
import { getFeedDetail } from "./Services";
import PageLoading from "src/common/Component/PageLoading";

import style from "./index.module.scss";

function FeedDetail() {
  const [params] = useSearchParams();

  const { data, run, loading } = useRequest(getFeedDetail, { manual: true });

  useEffect(() => {
    run({ id: params.get("id") });
  }, []);

  const onBack = () => {
    window.history.back();
  };

  const left = () => {
    return (
      <div className={style.icon}>
        <img src={data?.authAvatar} alt="" />
        <span>{data?.authName}</span>
      </div>
    );
  };

  const items = data?.picUrl.split(";").map((image: string, index: number) => (
    <Swiper.Item key={index}>
      <Image src={image} fit="cover" />
    </Swiper.Item>
  ));

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div className={style.app}>
      <NavBar back={null} left={left()} />
      <Swiper
        className={style.swiper}
        autoplay
        indicator={(total, current) => (
          <div className={style.customIndicator}>
            {`${current + 1}/${total}`}
          </div>
        )}
        style={{
          "--track-padding": " 0 0 16px",
        }}
      >
        {items}
      </Swiper>
      <div className={style.title}>
        <Ellipsis direction="end" rows={2} content={data?.title} />
      </div>
      <div className={style.content}>
        <pre className={style.pre}>{data?.description}</pre>
      </div>
      <div style={{ backgroundColor: "#f4f9ff" }} className={style.safeArea}>
        <SafeArea style={{ backgroundColor: "#f4f9ff" }} position="bottom" />
      </div>
    </div>
  );
}

export default FeedDetail;
