import axios, { IBaseRes } from "@/common/Utils/APIClient";
import { PromotionContentEnum } from "../enum";

interface IParams {}

interface PartnerBankDto {
  /**
   *  银行名称
   */
  bankName: string;
  /**
   *  银行账号
   */
  bankNo: string;
  /**
   *  开户名
   */
  openingBank: string;
}

interface IRes {
  /** 头像 */
  avatar: string;
  /** 企业信用码 */
  creditCode: string;
  // /** 企业名称 */
  // enterpriseName: string;
  /**
   *  是否是管理员
   */
  isAdmin: boolean;
  /**
   *  合伙人银行信息
   */
  partnerBankDto: PartnerBankDto;
  /**
   *  企业名称
   */
  partnerName: string;
  /**
   *  合伙人名称
   */
  partnerPerson?: string;
  /**
   *  合伙人手机号
   */
  partnerPersonMobile: string;
  /**
   *  推广内容
   */
  promotionContent: PromotionContentEnum;
  /**
   *  推广内容文案
   */
  promotionContentStr: string;
  /**
   *  申请状态 0 1 无效 有效
   */
  status: 0 | 1;
}
/**
 * 获取详情
 */
export const detail = (params: IParams): Promise<IBaseRes<IRes>> => {
  return axios.get("/dmall-customer/partner/app/detail", {
    param: params,
    headers: {
      Desensitize: "close",
    },
  });
};
