import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import Page from "@/common/Component/Page";
import Buttons from "@/common/Component/Button";
import { Form, Input, Picker } from "antd-mobile";
import { idCardRegExp, phoneRegExp } from "@/common/Config/regExp";
import VerificationCode from "@/common/Component/VerificationCode";
import usePageTitle from "@/hooks/usePageTitle";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { useRequest } from "ahooks";
import { accountBind } from "./Services";
import useUserStore from "@/store/global";
import useWalletStore from "@/store/wallet";
const requiredRuleObj = { required: true, message: "必填" };

function BankCardAdd(props: any) {
  usePageTitle({ title: "绑卡" });
  const openUrl = useOpenUrl();
  const [form] = Form.useForm();
  const codeRef = useRef<any>(null);
  const corpId = useUserStore((state) => state.corpId);
  const redirect = useWalletStore((store) => store.redirect);
  const { run: accountBindRun } = useRequest(accountBind, {
    manual: true,
    onSuccess: (res) => {
      if (res.success && res.data) {
        redirect
          ? openUrl({
              isRoute: false,
              isOpenHalf: false,
              url: decodeURIComponent(redirect),
            })
          : openUrl({
              isRoute: true,
              url: "/oneCard",
              params: {
                cardId: res.data,
                isOpenPay: true,
              },
            });
      }
    },
  });
  useEffect(() => {
    form.setFieldValue("idCard", ["idCard"]);
  }, []);
  const onFinish = (values: any) => {
    setVisible(true);
    codeRef.current?.focus();
  };
  const [visible, setVisible] = useState(false);
  const [visiblePicker, setVisiblePicker] = useState(false);
  const okCode = (code: string) => {
    setVisible(false);
    const { idCard, ...values } = form.getFieldsValue();
    accountBindRun({ ...values, orgId: corpId, payPassword: code });
  };
  const pickerData = [[{ label: "身份证", value: "idCard" }]];
  const account = Form.useWatch("idCard", form);
  const opPhone = form.getFieldValue("opPhone");
  return (
    <Page className={styles.app} style={{ backgroundColor: "#fff" }}>
      <Form
        form={form}
        className={styles.form}
        layout="horizontal"
        onFinish={onFinish}
      >
        <Form.Item label="姓名" name="opName" rules={[requiredRuleObj]}>
          <Input className={styles.input} placeholder="请输入姓名" />
        </Form.Item>
        <Form.Item
          label="证件类型"
          name="idCard"
          // rules={[requiredRuleObj]}
          // onClick={() => {
          //   setVisiblePicker(true);
          // }}
          initialValue={["idCard"]}
        >
          {/* <Picker
            columns={pickerData}
            visible={visiblePicker}
            onClose={() => {
              setVisiblePicker(false);
            }}
            onConfirm={(value) => {
              form.setFieldValue("idCard", value);
            }}
          /> */}
          <div className={styles.picker}>
            {account?.map((item: string, index: number) => {
              return pickerData[index].find((li) => li.value === item)?.label;
            })}
          </div>
        </Form.Item>
        <Form.Item
          label="证件号码"
          name="opCert"
          rules={[
            requiredRuleObj,
            { pattern: idCardRegExp, message: "请输入正确的证件号码" },
          ]}
        >
          <Input className={styles.input} placeholder="请输入持卡人证件号" />
        </Form.Item>
        <Form.Item
          label="手机号"
          name="opPhone"
          rules={[
            requiredRuleObj,
            { pattern: phoneRegExp, message: "请输入正确的手机号" },
          ]}
          // extra={
          //   <Buttons
          //     color="primary"
          //     fill="none"
          //     onClick={() => {
          //       setVisible(true);
          //     }}

          //   >
          //     发送验证码
          //   </Buttons>
          // }
        >
          <Input className={styles.input} placeholder="请输入预留手机号" />
        </Form.Item>
      </Form>
      <VerificationCode
        visible={visible}
        phone={opPhone}
        onClose={() => {
          setVisible(false);
        }}
        onOk={okCode}
        ref={codeRef}
      />
      <div className={styles.footer}>
        <Buttons
          color="primary"
          block
          onClick={() => {
            form.submit();
          }}
          // disabled={!hasCheckedPhone}
        >
          提交
        </Buttons>
      </div>
    </Page>
  );
}

export default BankCardAdd;
