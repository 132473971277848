import { checkType, ICheckTypeRes } from "@/pages/Partner/services/checkType";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface IPartnerStore {
  /**
   * 身份
   */
  checkType?: ICheckTypeRes;
  /**
   * 获取身份
   */
  getCheckType: () => Promise<ICheckTypeRes | undefined>;
}

/**
 * 合伙人store
 */
const usePartnerStore = create<IPartnerStore>()(
  persist(
    (set, get) => ({
      /** 获取身份 */
      getCheckType: async () => {
        const checkData = await checkType({});
        set({ checkType: checkData.success ? checkData.data : undefined });
        return checkData.data;
      },
    }),
    {
      name: "partner", // 存储中的项目名称，必须是唯一的
      storage: createJSONStorage(() => localStorage), // 使用localStorage作为存储
      partialize: (state) => ({
        // 仅存储特定的字段
      }),
    }
  )
);

export default usePartnerStore;
