// import { daysData } from "@/config/constant";
import { IListBusHours } from "@/pages/ArriveStore/service/shopPageQuery";
import dayjs, { Dayjs } from "dayjs";
/**
 * 周几
 */
export enum DaysEnum {
  /**
   * 周一
   */
  MONDAY = 1,
  /**
   * 周二
   */
  TUESDAY = 2,
  /**
   * 周三
   */
  WEDNESDAY = 3,
  /**
   * 周四
   */
  THURSDAY = 4,
  /**
   * 周五
   */
  FRIDAY = 5,
  /**
   * 周六
   */
  SATURDAY = 6,
  /**
   * 周日
   */
  SUNDAY = 7,
}

/**
 * 周几
 */
export const daysData = [
  {
    label: "周一",
    value: DaysEnum.MONDAY,
  },
  {
    label: "周二",
    value: DaysEnum.TUESDAY,
  },
  {
    label: "周三",
    value: DaysEnum.WEDNESDAY,
  },
  {
    label: "周四",
    value: DaysEnum.THURSDAY,
  },
  {
    label: "周五",
    value: DaysEnum.FRIDAY,
  },
  {
    label: "周六",
    value: DaysEnum.SATURDAY,
  },
  {
    label: "周日",
    value: DaysEnum.SUNDAY,
  },
];
/**
 * 营业日 枚举
 */
export enum IDaysTypeEnum {
  /** 每日 */
  Every = 1,
  /** 指定日期 */
  Custom = 2,
}

export enum ITimeTypeEnum {
  /** 全天 */
  All = 1,
  /** 自定义时间段 */
  Custom = 2,
}
export const daysTypeData = [
  {
    label: "每日营业",
    value: IDaysTypeEnum.Every,
  },
  {
    label: "选择营业日",
    value: IDaysTypeEnum.Custom,
  },
];

export const timeTypeData = [
  {
    label: "全天",
    value: ITimeTypeEnum.All,
  },
  {
    label: "选择时间段",
    value: ITimeTypeEnum.Custom,
  },
];

type IDays = [Dayjs | null, Dayjs | null];
export interface IDate {
  /** 营业日类型 */
  daysType: IDaysTypeEnum;
  /** 时间段类型 */
  timeRangType?: ITimeTypeEnum;
  /** 营业时间段 */
  timeRang?: IDays[];
  /** 自定义营业日 */
  customDays?: Array<{
    days: number[];
    timeRangType: ITimeTypeEnum;
    timeRang?: IDays[];
  }>;
}
/**
 * @description: 格式化营业时间展示
 * @param {IDate} date
 * @return {string}
 */
export const formatDateShowStr = (date?: IDate): string | undefined => {
  if (!date) {
    return undefined;
  }
  if (
    date.daysType === IDaysTypeEnum.Every &&
    date.timeRangType === ITimeTypeEnum.All
  ) {
    return "周一至周日 00:00-23:59";
  }
  if (
    date.daysType === IDaysTypeEnum.Every &&
    date.timeRangType === ITimeTypeEnum.Custom
  ) {
    const timeRang = date.timeRang
      ?.map((item) => {
        const start = item[0] ? dayjs(item[0]).format("HH:mm") : "";
        const end = item[1] ? dayjs(item[1]).format("HH:mm") : "";
        return `${start}-${end}`;
      })
      .join(",");
    return `周一至周日 ${timeRang}`;
  }
  if (date.daysType === IDaysTypeEnum.Custom) {
    const days = date.customDays?.map((item) => {
      const obj = { daysStr: "", time: "" };
      obj.daysStr = item.days
        .map((itemD) => daysData.find((li) => li.value === itemD)?.label)
        ?.join("、");
      if (item.timeRangType === ITimeTypeEnum.All) {
        obj.time = "00:00-23:59";
      }
      if (item.timeRangType === ITimeTypeEnum.Custom) {
        obj.time =
          item.timeRang
            ?.map((item) => {
              const start = item[0] ? dayjs(item[0]).format("HH:mm") : "";
              const end = item[1] ? dayjs(item[1]).format("HH:mm") : "";
              return `${start}-${end}`;
            })
            .join(",") || "";
      }
      return `${obj.daysStr} ${obj.time}`;
    });
    return days?.join("；") || "";
  }
};

/**
 * @description: 判断当前时间是否在IDate时间内
 * @param {IDate} date
 * @return {boolean}
 */
export const isCurrentTimeInDateRange = (date: IDate): boolean => {
  const now = dayjs();
  const currentDay = now.day() || 7; // 获取当前是周几，dayjs的周日是0，我们需要转换为7

  if (date.daysType === IDaysTypeEnum.Every) {
    if (date.timeRangType === ITimeTypeEnum.All) {
      return true; // 如果是全天营业，直接返回true
    } else if (date.timeRangType === ITimeTypeEnum.Custom && date.timeRang) {
      return date.timeRang.some(([start, end]) => {
        const startTime = start ? start : dayjs().startOf('day');
        const endTime = end ? end : dayjs().endOf('day');
        return now.isAfter(startTime) && now.isBefore(endTime);
      });
    }
  } else if (date.daysType === IDaysTypeEnum.Custom && date.customDays) {
    const customDay = date.customDays.find(item => item.days.includes(currentDay));
    if (customDay) {
      if (customDay.timeRangType === ITimeTypeEnum.All) {
        return true; // 如果是全天营业，直接返回true
      } else if (customDay.timeRangType === ITimeTypeEnum.Custom && customDay.timeRang) {
        return customDay.timeRang.some(([start, end]) => {
          const startTime = start ? start : dayjs().startOf('day');
          const endTime = end ? end : dayjs().endOf('day');
          return now.isAfter(startTime) && now.isBefore(endTime);
        });
      }
    }
  }

  return false;
};

const toDayjs = (time: string) => {
  const [hour, minute] = time.split(":");
  return dayjs()
    .set("hour", Number(hour))
    .set("minute", Number(minute) || 0);
};
/**
 * @description: 回显-时间段格式化
 * @param {IListBusHours} data
 * @return {*}
 */
export const formatDateTime = (data?: IListBusHours | undefined): IDate => {
  const res: IDate = {
    daysType:
      data?.businessDays === "custom"
        ? IDaysTypeEnum.Custom
        : IDaysTypeEnum.Every,
  };
  if (
    data?.timeConfigDetail.length === 1 &&
    data.timeConfigDetail[0].days === "1,2,3,4,5,6,7" &&
    data.timeConfigDetail[0].businessTimeRange === "all" &&
    data?.businessDays === "all"
  ) {
    res.timeRangType = ITimeTypeEnum.All;
  }
  if (
    data?.timeConfigDetail.length === 1 &&
    data.timeConfigDetail[0].days === "1,2,3,4,5,6,7" &&
    data.timeConfigDetail[0].businessTimeRange === "custom" &&
    data?.businessDays === "all"
  ) {
    res.timeRangType = ITimeTypeEnum.Custom;
    res.timeRang = data.timeConfigDetail[0].hours.map((item) => {
      const res: [Dayjs | null, Dayjs | null] = [
        item.begin ? toDayjs(item.begin) : null,
        item.end ? toDayjs(item.end) : null,
      ];
      return res;
    });
  }
  if (data?.businessDays === "custom") {
    res.customDays = data.timeConfigDetail.map((item) => {
      const res: {
        days: number[];
        timeRangType: ITimeTypeEnum;
        timeRang?: [Dayjs, Dayjs][];
      } = {
        days: item.days?.split(",").map((item) => Number(item)) || [],
        timeRangType:
          item.businessTimeRange === "custom"
            ? ITimeTypeEnum.Custom
            : ITimeTypeEnum.All,
      };
      if (item.businessTimeRange === "custom") {
        res.timeRang = item.hours.map((item) => {
          const res: [Dayjs, Dayjs] = [
            toDayjs(item.begin || "00:00"),
            toDayjs(item.end || "23:59"),
          ];

          return res;
        });
      }
      return res;
    });
  }

  return res;
};

