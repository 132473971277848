import React from "react";
import usePageTitle from "@/hooks/usePageTitle";
import styles from "./index.module.scss";
import { useParams } from "react-router-dom";

function ProtocolInfo(props: any) {
  const { code } = useParams<{ code: string }>();

  const protocol = require(`./Protocols/${code}.json`);
  usePageTitle({ title: protocol.title });
  // const { data, loading } = useRequest(getData);
  
  return (
    <div className={styles.container}>
      <div className={styles.protocolContainer}>
        <div className={styles.protocolContent}>
          <div dangerouslySetInnerHTML={{ __html: protocol.content }} />
        </div>
      </div>
    </div>
  );
}

export default ProtocolInfo;