import axios, { IBaseRes } from "src/common/Utils/APIClient";
import { IParamData } from "@/common/Component/TimeSelect/Services";

export const getBillList = (
  param?: IParamData
): Promise<IBaseRes<any>> => {
  return axios.get("/dmall_payment/buyerPay/billPage", {
    param: {
      pageNum: 1,
      pageSize: 20,
      orders: 'DESC',
      columns: 'createAt',
      ...param,
    },
  });
};