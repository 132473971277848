import axios, { IBaseRes } from "@/common/Utils/APIClient";

interface IParams {
  phone: string;
  code: string;
  data: Data;
}
interface Data {
  applyOrgId: string;
  clientId: string;
  name: string;
  phone: string;
}
interface IRes {
  status: number;
  body: string;
}
/**
 * 
获取企业到店折扣签约地址
 */
export const discountGetSignUrl = (data: IParams): Promise<IBaseRes<IRes>> => {
  return axios.post("/dmall_account/Enterprise/discount/getSignUrl", data);
};
