import axios, { IBaseRes } from "@/common/Utils/APIClient";
interface IParams {
  corpId?: string;
}
// interface IRes {}

/**
 * 检查用户是否安装APP
 */
export const checkInstall = (params: IParams): Promise<IBaseRes<boolean>> => {
  return axios.get("/dmall_customer/ding/checkInstall", { param: params });
};
