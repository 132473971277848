import {
  enterpriseApplyGet,
  IApplyRes,
} from "@/pages/Enterprise/services/enterpriseApplyGet";
import useUserStore from "@/store/global";
import {  } from "react-router-dom";

export interface ISettledInLoaderRes {
  enterpriseApplyData?: IApplyRes;
}
/**
 * 企业入驻到店
 */
export async function settledInLoader() {
  const { corpId: localCorpId, clientId: localClientId } =
    useUserStore.getState();
  const data: ISettledInLoaderRes = {};
  if (localCorpId) {
    data.enterpriseApplyData = await enterpriseApplyGet({
      applyOrgId: localCorpId,
    });
  }

  return data;
}
