import axios, { IBaseRes } from "@/common/Utils/APIClient";
/**
 * 营业执照类型
 */
export enum LicenseTypeEnum {
  /**
   *  个体
   */
  Persion = "persion",
  /**
   * 企业
   */
  Org = "org",
}
interface IParams {
  /**
   * 营业执照图片地址
   */
  url: string;
}
interface IRes {
  address: string;
  regNum: string;
  name: string;
  end: string;
  begin: string;
  /**
   * 营业执照类型-中文
   */
  type: string;
  /**
   * 营业执照类型-code
   */
  licenseType: LicenseTypeEnum;
  person: string;
}
/**
 * 获取营业执照信息
 */
export const orcLicense = (data: IParams): Promise<IBaseRes<IRes>> => {
  return axios.get("/dmall-multishop/arrive/ocr/license", { param: data });
};
