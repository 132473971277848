import Buttons from "@/common/Component/Button";
import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import {
  Form,
  ImageUploader,
  ImageUploadItem,
  Input,
  Toast,
} from "antd-mobile";
import FormCardTitle from "@/common/Component/FormCardTitle";
import FormItem from "@/common/Component/FormItem";
import { phoneRegExp, socialCreditCodeRegExp } from "@/common/Config/regExp";
import Step from "../../components/Step";
import VerificationCode, {
  IRef,
} from "../../../../common/Component/VerificationCode";
import Result from "../../components/Result";
import { useRequest } from "ahooks";
import { enterpriseApply } from "../../services/enterpriseApply";
import useUserStore from "@/store/global";
import {
  ApplyStatusEnum,
  enterpriseApplyGet,
  StatusEnum,
} from "../../services/enterpriseApplyGet";
import { OSSUpload, UploadDirEnum } from "@/common/Utils/OssSignature";

import ApplyLayout from "@/common/Component/ApplyPage";
import { orcLicense } from "@/services/orc/orcLicense";
import dayjs from "dayjs";
import get from "lodash/get";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { enterpriseVerify } from "../../services/enterpriseVerify";
import { checkAdminMobile } from "../../services/checkAdminMobile";
import { checkCreditCode } from "../../services/checkCreditCode";
import { checkEnterpriseName } from "../../services/checkEnterpriseName";
import usePageTitle from "@/hooks/usePageTitle";
const checkApi = {
  enterpriseName: checkEnterpriseName,
  creditCode: checkCreditCode,
  adminMobile: checkAdminMobile,
};
const requiredRuleObj = { required: true, message: "必填" };
enum StepEnum {
  /** 步骤1 */
  One = 1,
  /** 步骤2 */
  Two = 2,
  /** 步骤3 */
  Three = 3,
}
const stepDataSource = [
  {
    title: "提交入驻资料",
  },
  { title: "平台审核" },
  { title: "开通企业账户" },
];
interface IApplayProps {}

/** 企业入驻申请  */
const Applay: React.FC<IApplayProps> = (props) => {
  const {} = props;
  usePageTitle({ title: "企业餐补入驻" });
  const openUrl = useOpenUrl();
  const ref = useRef<IRef>({});
  const {
    data: applyData,
    // loading: getLoading,
    run: enterpriseApplyGetRun,
  } = useRequest(enterpriseApplyGet, {
    manual: true,
    onSuccess: (res) => {
      if (res.success && res.data) {
        // if (
        //   res.data.applyStatus === ApplyStatusEnum.SIGN_CONTRACT ||
        //   res.data.applyStatus === ApplyStatusEnum.WATING_ESIGN_FILL ||
        //   res.data.applyStatus === ApplyStatusEnum.WATING_ESIGN_SEAL
        // ) {
        //   openUrl({
        //     url: "/apply/enterprise/contract",
        //     isRoute: true,
        //     params: { enterpriseId: res.data.enterpriseId },
        //   });
        //   return;
        // }
        switch (res.data.status) {
          case StatusEnum.PendingPlatformReview:
            setActiveStep(1);
            break;
          case StatusEnum.ReviewFailed:
            setActiveStep(2);
            break;
          case StatusEnum.Cancelled:
            setActiveStep(2);
            break;
          case StatusEnum.Void:
            setActiveStep(2);
            break;
          case StatusEnum.ReviewPassed:
            setActiveStep(2);
            break;
          case StatusEnum.AccountBookOpened:
            setActiveStep(2);
            break;
          case StatusEnum.AccountBookBlock:
            setActiveStep(2);
            break;
          default:
            setActiveStep(0);
        }
        form.setFieldsValue({
          creditCert: res.data.creditCert ? [{ url: res.data.creditCert }] : [],
          enterpriseName: res.data.enterpriseName,
          creditCode: res.data.creditCode,
          legalPersonName: res.data.legalPersonName,
          legalPersonPhone: res.data.legalPersonPhone,
          contactName: res.data.contactName,
          contactPhone: res.data.contactPhone,
          adminName: res.data.adminName,
          adminMobile: res.data.adminMobile,
        });
      } else {
        setActiveStep(0);
      }
    },
  });
  const {
    // loading: getLoading,
    run: enterpriseApplyRun,
  } = useRequest(enterpriseApply, {
    manual: true,
    onSuccess: (res) => {
      if (res.success && res.data) {
        openUrl({
          url: "/apply/enterprise/contract",
          isRoute: true,
          params: { enterpriseId: res.data },
        });
      }
    },
  });
  const { run: orcLicenseRun } = useRequest(orcLicense, {
    manual: true,
    onSuccess: async (res) => {
      if (res.success) {
        let begin = undefined;
        let end = undefined;
        try {
          begin = res.data?.begin
            ? new Date(dayjs(res.data?.begin).valueOf())
            : undefined;
          end = res.data?.end
            ? new Date(dayjs(res.data?.end).valueOf())
            : undefined;
        } catch (error) {}
        form.setFieldsValue({
          // shopLicenseAddress: res.data?.address,
          creditCode: res.data?.regNum,
          enterpriseName: res.data?.name,
          // shopLicenseBegin: begin,
          // shopLicenseEnd: end,
          // shopLicenseType: res.data?.type,
          legalPersonName: res.data?.person,
          // shopLicenseTypeCode: res.data?.licenseType,
        });
      }
    },
  });

  const cropId = useUserStore((state) => state.corpId);
  const [form] = Form.useForm();
  const [activeStep, setActiveStep] = useState<number>();
  const [visible, setVisible] = useState(false);
  const ossUpload = useRef(new OSSUpload({ uploadDir: UploadDirEnum.Test }));

  const onFinish = (values: any) => {
    // setVisible(true);
    // ref.current?.focus?.();
    const { creditCert, ...restValues } = values;
    const creditCertStr = get(creditCert, "0.url");
    enterpriseApplyRun({
      ...restValues,
      applyOrgId: cropId,
      creditCert: creditCertStr,
    });
  };
  const okCode = (code: string, data: any) => {
    cropId &&
      enterpriseApplyGetRun({ applyOrgId: cropId, hasDesensitize: true });
    setActiveStep((activeStep || 0) + 1);
    Toast.show({
      icon: "success",
      content: "提交成功",
    });
    openUrl({
      url: "/apply/enterprise/contract",
      isRoute: true,
      params: {
        enterpriseId: data,
      },
    });
  };

  useEffect(() => {
    cropId &&
      enterpriseApplyGetRun({ applyOrgId: cropId, hasDesensitize: true });
  }, [cropId]);
  useEffect(() => {
    // 获取oss签名
    ossUpload.current.getOssSignature();
  }, []);
  const upload = (file: File): Promise<ImageUploadItem> =>
    new Promise((resolve, reject) => {
      ossUpload.current
        .upload(file)
        .then((res) => {
          if (res.success) {
            resolve({
              url: res.url || "",
            });
          } else {
            reject(new Error("上传失败"));
          }
        })
        .catch((e) => {
          reject(new Error("上传失败"));
        });
    });

  const validator = (rule: any, v: string, fcName: keyof typeof checkApi) => {
    return new Promise((resolve, reject) => {
      if (!cropId) {
        reject("未找到组织ID");
        return;
      }
      let params: any;

      switch (fcName) {
        case "enterpriseName":
          params = { enterpriseName: v, applyOrgId: cropId };
          break;
        case "creditCode":
          params = { creditCode: v, applyOrgId: cropId };
          break;
        case "adminMobile":
          params = { adminMobile: v, applyOrgId: cropId };
          break;
        default:
          reject("未知的验证类型");
          return;
      }
      if (!v) {
        resolve(true);
        return;
      }
      checkApi[fcName](params)
        .then((res) => {
          if (!res.data) {
            reject(new Error(res.msg || "校验失败"));
          } else {
            resolve(true);
          }
        })
        .catch((e) => {
          reject(new Error(e.msg || "校验失败"));
        });
    });
  };
  return (
    <ApplyLayout>
      <div className={styles.content}>
        <div className={styles.step}>
          <Step current={activeStep} dataSource={stepDataSource} />
        </div>
        {activeStep === 0 && (
          <Form
            form={form}
            className={styles.form}
            layout="horizontal"
            onFinish={onFinish}
            mode="card"
          >
            <Form.Header />
            <FormCardTitle title="企业基础信息" />
            <FormItem
              label="营业执照"
              name="creditCert"
              rules={[{ required: true, message: "必填" }]}
            >
              <ImageUploader
                beforeUpload={(file) =>
                  ossUpload.current.beforeUpload(file, {
                    hasCompressor: true,
                  })
                }
                style={{ "--cell-size": "90px" }}
                maxCount={1}
                showFailed={false}
                upload={async (file) => {
                  const res = await upload(file);
                  if (res?.url) {
                    orcLicenseRun({ url: res?.url });
                  }
                  return res;
                }}
              />
            </FormItem>
            <FormItem
              label="企业名称"
              name="enterpriseName"
              validateTrigger={["onChange", "onBlur"]}
              rules={[
                requiredRuleObj,
                {
                  validateTrigger: ["onBlur"],
                  validator: (rule, v) => validator(rule, v, "enterpriseName"),
                },
              ]}
            >
              <Input placeholder="请输入企业名称" clearable maxLength={20} />
            </FormItem>
            <FormItem
              label="社会信用代码"
              name="creditCode"
              validateTrigger={["onChange", "onBlur"]}
              rules={[
                requiredRuleObj,
                {
                  pattern: socialCreditCodeRegExp,
                  message: "请正确输入社会信用代码",
                },
                {
                  validator: (rule, v) => validator(rule, v, "creditCode"),
                },
              ]}
            >
              <Input
                placeholder="请输入社会信用代码"
                maxLength={40}
                clearable
              />
            </FormItem>
            <FormItem
              label="法人姓名"
              name="legalPersonName"
              rules={[requiredRuleObj]}
            >
              <Input placeholder="请输入法人姓名" clearable maxLength={20} />
            </FormItem>
            <FormItem
              label="法人手机号"
              name="legalPersonPhone"
              rules={[
                requiredRuleObj,
                { pattern: phoneRegExp, message: "请输入正确的手机号" },
              ]}
            >
              <Input placeholder="请输入联系电话" />
            </FormItem>
            <FormItem
              label="联系人"
              name="contactName"
              rules={[requiredRuleObj]}
            >
              <Input placeholder="请输入联系人" clearable maxLength={20} />
            </FormItem>
            <FormItem
              label="联系电话"
              name="contactPhone"
              rules={[
                requiredRuleObj,
                { pattern: phoneRegExp, message: "请输入正确的手机号" },
              ]}
            >
              <Input placeholder="请输入联系电话" />
            </FormItem>

            <Form.Header />
            <FormCardTitle title="餐补管理员" />
            <FormItem
              label="管理员姓名"
              name="adminName"
              validateTrigger={["onChange", "onBlur"]}
              rules={[
                requiredRuleObj,
                {
                  validator: (rule, v) => validator(rule, v, "adminMobile"),
                },
              ]}
            >
              <Input placeholder="请输入管理员姓名" />
            </FormItem>
            <FormItem
              label="手机号码"
              name="adminMobile"
              rules={[
                requiredRuleObj,
                { pattern: phoneRegExp, message: "请输入正确的手机号" },
              ]}
            >
              <Input placeholder="请输入管理员手机号码" />
            </FormItem>
          </Form>
        )}
        {(activeStep === 1 || activeStep === 2) && (
          <Result
            data={applyData?.data}
            onReApply={() => {
              setActiveStep(0);
            }}
          />
        )}
        <VerificationCode
          visible={visible}
          ref={ref}
          phone={form.getFieldValue("contactPhone")}
          onClose={() => {
            setVisible(false);
          }}
          onOk={okCode}
          getVerifyCode={(data) => {
            const values = form.getFieldsValue();
            const { creditCert, ...restValues } = values;
            const creditCertStr = get(creditCert, "0.url");
            return enterpriseVerify({
              ...restValues,
              applyOrgId: cropId,
              creditCert: creditCertStr,
            });
          }}
          verifyCode={(data) => {
            const values = form.getFieldsValue();
            const { creditCert, ...restValues } = values;
            const creditCertStr = get(creditCert, "0.url");
            return enterpriseApply({
              ...restValues,
              applyOrgId: cropId,
              creditCert: creditCertStr,
            });
          }}
        />
      </div>

      {activeStep === 0 && (
        <div className={styles.footer}>
          <Buttons
            color="primary"
            block
            onClick={() => {
              form.submit();
            }}
          >
            提交
          </Buttons>
        </div>
      )}
    </ApplyLayout>
  );
};

export default Applay;
