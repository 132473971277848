import React, { useEffect, useState } from "react";
import { useRequest } from "ahooks";
import qs from "qs";
import { ELEME_HOST } from "src/common/Config/Constant";
import { useShallow } from "zustand/react/shallow";
import useGlobalStore from "@/store/global";
import { bindEleme } from "./Services";
import styles from "./index.module.scss";
import { useOpenUrl } from "@/hooks/useOpenUrl";

function Eleme() {
  const [dingLocation, platformContext] = useGlobalStore(
    useShallow((state) => [state.dingLocation, state.platformContext])
  );
  const openUrl = useOpenUrl();
  const [data, setData] = useState("");
  const {
    run: bindElemeRun,
    data: bindElemeData,
    loading: bindElemeLoading,
    error: bindElemeError,
  } = useRequest(bindEleme, {
    manual: true,
    onSuccess: (res: any) => {
      if (!res.success) {
        setData("登录失败，请刷新重试！");
      }
    },
    onError: (err: any) => {
      setData("登录失败，请刷新重试！");
    },
  });

  useEffect(() => {
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const elemeUrl = query?.route_params as string;

    bindElemeRun({
      elemeUrl: elemeUrl ? ELEME_HOST[elemeUrl] : "",
      channelKey: platformContext.getElemeChannelKey(),
    });
  }, []);

  useEffect(() => {
    if (!(bindElemeError || bindElemeData)) {
      return;
    }
    // if (!dingLocation) {
    //   return;
    // }
    const { data = {}, success } = bindElemeData || {};
    const { latitude, longitude } = dingLocation || {};
    if (success === true) {
      const url = new URL(decodeURIComponent(data));
      latitude && url.searchParams.set("latitude", latitude);
      longitude && url.searchParams.set("longitude", longitude);
      // redirect(url.href);
      // window.location.href = url.href;
      openUrl({
        url: url.href,
        isOpenHalf: false,
        isReplace: true,
        isRoute: false,
      });
    }
  }, [dingLocation, bindElemeError, bindElemeData]);

  return (
    <div className={styles.app}>{!bindElemeLoading && <div>{data}</div>}</div>
  );
}

export default Eleme;
