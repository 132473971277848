import React, { useState, useEffect } from "react";
import { useRequest } from "ahooks";
import style from "./index.module.scss";
import usePageTitle from "@/hooks/usePageTitle";
import { Input, Button, Toast } from "antd-mobile";
import { updateUserInfo } from "../../Services";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";

function EditNick(props: any) {
  usePageTitle({ title: "修改昵称" });

  const [value, setValue] = useState('');
  const [userInfo, getUserInfo] = useUserStore(
    useShallow((state) => [
      state.userInfo,
      state.getUserInfo,
    ])
  );

  const nickname = userInfo?.userName || '';

  const { run: runUpdateUserInfo } = useRequest(updateUserInfo, {
    manual: true,
    onSuccess: (result) => {
      if (result.success) {
        Toast.show({
          icon: "success",
          content: "修改成功",
        });
        // 刷新用户信息
        getUserInfo();
        setTimeout(() => {
          window.history.back();
        }, 2000);
      }
    }
  });

  useEffect(() => {
    if (nickname) {
      setValue(nickname);
    }
  }, [nickname]);

  const handleSave = () => {
    if (!value) {
      Toast.show({
        icon: "fail",
        content: "昵称不能为空",
      });
      return;
    }
    runUpdateUserInfo({
      nickName: value
    });
  };

  return (
    <div className={style.container}>
      {/* 页面内容 */}
      <div className={style.rowContainer}>
        <div className={style.inputContainer}>
          <Input
            enterKeyHint="done"
            className={style.input}
            value={value}
            maxLength={8}
            onChange={val => {
              setValue(val);
            }}
            clearable
          />
        </div>
        <Button 
          block
          color="primary" 
          className={style.saveButton}
          onClick={handleSave}
        >
          保存
        </Button>
      </div>
      
    </div>
  );
}

export default EditNick;