import { Checkbox, CheckboxGroupProps, CheckboxProps } from "antd-mobile";
import React from "react";
import styles from "./index.module.scss";
import { CheckOutline } from "antd-mobile-icons";
import { CheckboxValue } from "antd-mobile/es/components/checkbox";

const Checkboxs: React.FC<CheckboxProps> = (props) => {
  const { children, ...restProps } = props;
  return (
    <Checkbox
      icon={(checked) => {
        return checked ? (
          <div className={styles.checkboxIconContainerChecked}>
            <CheckOutline className={styles.checkboxIcon} />
          </div>
        ) : (
          <div className={styles.checkboxIconContainer} />
        );
      }}
      className={styles.checkbox}
      {...restProps}
    >
      <span className={styles.checkboxLabel}>{children}</span>
    </Checkbox>
  );
};

export default React.memo(Checkboxs);

interface ICheckboxGroupProps extends CheckboxGroupProps {
  dataSource?: { label: React.ReactNode; value: CheckboxValue }[];
  className?: string;
  columns?: number;
}
export const CheckboxGroup: React.FC<ICheckboxGroupProps> = (props) => {
  const { dataSource, className, children, columns = 2, ...restProps } = props;
  // grid-template-columns: repeat(2, 1fr);
  return (
    <div
      className={styles.checkboxGroup}
      style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
    >
      <Checkbox.Group {...restProps}>
        {!children
          ? dataSource?.map((item, index) => (
              <Checkboxs key={item.value} value={item.value}>
                {item.label}
              </Checkboxs>
            ))
          : children}
      </Checkbox.Group>
    </div>
  );
};
