import { UserInfoData } from "@/services/user/getDingUser";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import qs from "qs";
import { getLocation, IDingLocationRes } from "@/services/dd/getLocation";
import { ILocation } from "@/common/Component/Location";
import { checkInstall } from "@/services/user/checkInstall";
import { PlatformContext, PlatformType } from "@/common/Utils/PlatformStrategy";
type AppType = "h5" | "app" | "devb";

interface UserState {
  userInfo?: UserInfoData;
  /**
   * 用户组织id
   */
  corpId?: string;

  getUserInfo: () => Promise<UserInfoData | undefined>;
  setCorpId: (corpId: string) => void;
  /**
   * 应用
   */
  appType?: AppType;
  setAppType: () => void;
  clientId?: string;
  setClientId: () => void;
  reset: () => void;
  dingLocation?: IDingLocationRes;
  dingLocationLoading?: boolean;
  setDingLocation: () => Promise<IDingLocationRes>;
  gaodeLocation?: ILocation;
  setGaodeLocation: (location: ILocation) => void;
  /**
   * 是否安装应用
   */
  isInstallApp?: boolean;
  setIsInstallApp: (corpId: string) => Promise<{ isInstall: boolean }>;
  /**
   * 平台类型
   */
  platformType?: PlatformType;
  /**
   * 设置平台类型
   */
  updatePlatformContext: () => void;

  platformContext: PlatformContext;

  // setPlatformContext: (platformContext: PlatformContext) => void;
}

const useUserStore = create<UserState>()(
  persist(
    (set, get) => ({
      corpId: undefined,
      platformContext: new PlatformContext(),
      setCorpId: (corpId: string) => set(() => ({ corpId })),
      getUserInfo: async () => {
        console.log("getUserInfo", get().clientId, get().corpId);
        const { clientId, corpId } = get();
        const userInfo = await get().platformContext.getUserInfo(
          corpId,
          clientId
        );
        set((state) => ({
          userInfo: userInfo,
        }));
        return userInfo;
      },
      setAppType: () => {
        const hostname = window.location.hostname;
        switch (hostname) {
          case "localhost":
            set(() => ({ appType: "h5" }));
            break;
          case "app.wuhuxianmai.cn":
            set(() => ({ appType: "app" }));
            break;
          case "h5.wuhuxianmai.cn":
            set(() => ({ appType: "h5" }));
            break;
          case "devb.wuhuxianmai.cn":
            set(() => ({ appType: "devb" }));
            break;
          default:
            set(() => ({ appType: undefined }));
        }
      },
      setClientId: () => {
        const clientId = qs.parse(window.location.search, {
          ignoreQueryPrefix: true,
        })?.clientId as string | undefined;
        clientId && set(() => ({ clientId }));
      },
      setDingLocation: async () => {
        set(() => ({ dingLocationLoading: true }));
        const location = await getLocation().catch((e) => {
          console.log("获取位置信息失败", e);
          return {
            success: false,
          };
        });
        console.log("dingLocation", location);
        set(() => ({
          dingLocation: location,
          dingLocationLoading: false,
        }));
        return location;
      },
      setGaodeLocation: (location: ILocation) => {
        set((state) => ({
          gaodeLocation: location,
        }));
      },
      setIsInstallApp: async (corpId: string) => {
        const check = await checkInstall({ corpId });
        set(() => ({ isInstallApp: !!check?.data }));
        return { isInstall: !!check?.data };
      },
      updatePlatformContext: () => {
        const platformContext = new PlatformContext();
        const platformType = platformContext.getPlatformType();
        set(() => ({
          platformType,
          platformContext,
        }));
      },
      reset: () => {
        set(() => ({
          corpId: "",
          clientId: "",
        }));
      },
    }),
    {
      name: "bear-storage", // 存储中的项目名称，必须是唯一的
      storage: createJSONStorage(() => localStorage), // 使用localStorage作为存储
      partialize: (state) => ({
        // 仅存储特定的字段
        corpId: state.corpId,
        clientId: state.clientId,
        dingLocation: state.dingLocation,
      }),
    }
  )
);

export default useUserStore;
