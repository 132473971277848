import apiClient from "src/common/Utils/APIClient";

export const bindAlipay = (params: any) => {
  return apiClient
    .post('/dmall-account/arrive/enterprise/bind/alipay', params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res: any) => {
      return res
    });
};

export const getAlipaySignUrl = (): Promise<{ alipaySignUrl: string, alipaySignStatus: 0 | 1 }> => {
  return apiClient
    .get('/dmall-account/arrive/enterprise/get/info')
    .then((res: any) => {
      return res?.data || {};
    });
};
