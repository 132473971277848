import React from "react";
import OneCard from "../OneCard";
import BankCard from "../BankCard";
// import CouponCard from "../CouponCard";
import ThirdParty from "../ThirdParty";
import { STATIC_HOST } from "src/common/Config/Constant";
import styles from "./index.module.scss";

const listStyle = {
  "--border-top": "solid 0px #ccc",
  "--border-bottom": "solid 0px #ccc",
};

function Content(props: any) {
  const { payInfo, onChangePayInfo, setFeeAmount, data = {} } = props;
  const { payMethods } = data || {};

  return (
    <div className={styles.app}>
      {
        payMethods?.onePay?.total > 0 ? (
          <div className={styles.content}>
            <div className={styles.head}>
              <div className={styles.left}>
                <img src="https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/e49f22bdc0677873282d333c0b69b2fd152a0364.png" alt="" />
                钱包支付
              </div>
              {/* <div className={styles.right}>
                安全有保障
                <img src={`${STATIC_HOST}/9d2c6685d64f61e5.png`} alt="" />
              </div> */}
            </div>
            <OneCard
              orderInfo={data}
              data={payMethods?.onePay}
              payInfo={payInfo}
              onChangePayInfo={onChangePayInfo}
              setFeeAmount={setFeeAmount}
            />
          </div>
        ) : null
      }
      {/* <BankCard
        listStyle={listStyle}
        payInfo={payInfo}
        data={payMethods?.bankCard}
        onChangePayInfo={onChangePayInfo}
      /> */}
      {/* <CouponCard
        listStyle={listStyle}
        coupon={coupon}
        onChangeCoupon={onChangeCoupon}
      /> */}
      <ThirdParty
        listStyle={listStyle}
        payInfo={payInfo}
        data={payMethods}
        onChangePayInfo={onChangePayInfo}
      />
    </div>
  );
}

export default Content;
