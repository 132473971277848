import React, { useState, useRef, useMemo } from "react";
import { Form, Input, Button, Toast, Picker } from "antd-mobile";
import { PhoneFill, LockFill, CheckShieldFill } from 'antd-mobile-icons'
import styles from "./index.module.scss";
import { useRequest } from "ahooks";
import { login, getSmsVerificationCode } from "./Services";
import type { InputRef } from "antd-mobile/es/components/input";
import StorageService from "@/common/Utils/store";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import usePageTitle from "@/hooks/usePageTitle";
import { getEnvImageUrl } from "@/common/Utils/env";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";
import { useSearchParams, useNavigate } from "react-router-dom";
import { USER_CONFIG } from "@/common/Config/Constant";
import SmsVerificationButton from "@/common/Component/SmsVerification";

interface FormValues {
  phone: string;
  password: string;
}

interface SmsFormValues {
  phone: string;
  code: string;
}

function LoginPage() {
  const openUrl = useOpenUrl();
  usePageTitle({ title: "芜湖生活" });
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const route = params.get('route');
  const type = params.get('type') ? parseInt(params.get('type') as string) : 0;
  const [activeKey, setActiveKey] = useState("account");
  const [form] = Form.useForm<FormValues>();
  const [smsForm] = Form.useForm<SmsFormValues>();
  const passwordInputRef = useRef<InputRef>(null);
  const phoneInputRef = useRef<InputRef>(null);
  const smsPhoneInputRef = useRef<InputRef>(null);
  const smsCodeInputRef = useRef<InputRef>(null);
  const [pageSwicthVisible, setPageSwicthVisible] = useState(false);
  const [currentLoginConfig, setCurrentLoginConfig] = useState(USER_CONFIG.find(config => config.sysType === type) || USER_CONFIG[0]);

  const [reset] = useUserStore(useShallow((state) => [state.reset]));

  // 账号密码登录
  const { loading, run } = useRequest(login, {
    manual: true,
    onSuccess: (result) => {
      console.log(result);
      if (result.success) {
        // Redirect to home page or dashboard
        StorageService.shared.setToken(result.data.accessToken);
        StorageService.shared.setItem('refreshToken', result.data.refreshToken);
        StorageService.shared.setItem('sysType', result.data.sysType);
        
        // 重置corpId和clientId
        reset();

        if (route) {
          openUrl({
            url: `/${route}`,
            isRoute: true
          })
        } else {
          openUrl({
            url: currentLoginConfig.homePageUrl || '/tabBar',
            isRoute: true
          })
        }
      }
    }
  });

  // 获取验证码
  const { loading: smsLoading, run: getSmsCode } = useRequest(getSmsVerificationCode, {
    manual: true,
    onSuccess: (result) => {
      if (result.success) {
        // Success is handled by the SmsVerificationButton component
        Toast.show({
          icon: "success",
          content: "验证码发送成功",
        });
      }
    }
  });

  const handleSubmit = async (values: FormValues) => {
    run({
      principal: values.phone,
      credentials: values.password,
      loginType: 0,
      sysType: currentLoginConfig.sysType || 0,
    });
  };

  const handleSmsSubmit = async (values: SmsFormValues) => {
    run({
      principal: values.phone,
      credentials: values.code,
      loginType: 1,
      sysType: currentLoginConfig.sysType || 0,
    });
  };

  const handleGetSmsCode = () => {
    return smsForm.validateFields(['phone']).then(values => {
      return new Promise<boolean>((resolve) => {
        getSmsCode({
          phone: values.phone,
          type: 6,
          sysType: currentLoginConfig.sysType || 0,
        });
        
        // We're observing the result through the onSuccess callback of useRequest
        // Just resolve with true, and the actual success/failure will be handled in the onSuccess callback
        resolve(true);
      });
    }).catch(() => {
      Toast.show({
        icon: "fail",
        content: "请输入正确的手机号",
      });
      return false;
    });
  };

  const parseColumns = useMemo(() => {
    return USER_CONFIG.map((item) => ({
      label: item.title,
      value: item.title,
    }));
  }, []);

  const handleRegister = () => {
    openUrl({
      url: '/registerUser',
      isRoute: true
    })
  };

  const handleForgotPassword = () => {
    openUrl({
      url: '/resetLoginPassword',
      params: {
        type: currentLoginConfig.sysType,
        showTitle: true,
      },
      isRoute: true
    })
  };

  return (
    <div className={styles.loginContainer} style={{ backgroundImage: `url(${currentLoginConfig.bgImage})`, backgroundColor: currentLoginConfig.bgColor }}>
      <div className={styles.welcomeSection}>
        <h1 className={styles.greeting}>
          您好！{currentLoginConfig.title}
          <img 
            src={getEnvImageUrl('d8e2de29d77d9c726f386c294bb8ea38d055b5e8.png')}
            alt="icon"
            className={styles.greetingIcon}
            onClick={() => setPageSwicthVisible(true)}
          />
        </h1>
        <div className={styles.welcomeText}>
          欢迎使用 <span className={styles.appName} style={{ backgroundColor: currentLoginConfig.subTitleColor }}>芜湖本地生活</span>{currentLoginConfig.subTitle}
        </div>
      </div>

      <div className={styles.loginCard}>
        <div className={styles.tabsContainer}>
          <div 
            className={`${styles.tabItem} ${activeKey === 'account' ? styles.active : `${styles.inactive}`}`}
            onClick={() => setActiveKey('account')}
          >
            账号登录
          </div>
          <div 
            className={`${styles.tabItem} ${activeKey === 'sms' ? styles.active : `${styles.inactive}`}`}
            onClick={() => setActiveKey('sms')}
          >
            免密登录
          </div>
        </div>

        {activeKey === "account" && (
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            className={styles.passwordLoginForm}
            footer={
              <Button
                block
                type="submit"
                color="primary"
                style={{ backgroundColor: currentLoginConfig.btnColor }}
                loading={loading}
                className={styles.loginButton}
              >
                登录
              </Button>
            }
          >
            <Form.Item
              name="phone"
              rules={[{ required: true, message: "请输入手机号" }]}
            >
              <div className={styles.phoneInputWrapper}>
                <PhoneFill className={styles.phoneIcon} />
                <Input
                  placeholder="请输入手机号"
                  clearable
                  ref={phoneInputRef}
                  className={styles.customInput}
                />
              </div>
            </Form.Item>
            <Form.Item
              name="password"
              validateTrigger={['onBlur']}
              rules={[
                { required: true, message: "请输入密码" },
                { min: 6, max: 8, message: "密码长度为6-8位" }
              ]}
            >
              <div className={styles.phoneInputWrapper}>
                <LockFill className={styles.phoneIcon} />
                <Input
                  placeholder="请输入密码"
                  clearable
                  type="password"
                  ref={passwordInputRef}
                  className={styles.customInput}
                />
              </div>
            </Form.Item>
            <div className={styles.actionLinks}>
              <span className={styles.registerLink} onClick={handleRegister}>
                {currentLoginConfig.registerTitle}
              </span>
              <span
                className={styles.forgotPasswordLink}
                onClick={handleForgotPassword}
              >
                忘记密码
              </span>
            </div>
          </Form>
        )}

        {activeKey === "sms" && (
          <Form
            form={smsForm}
            layout="vertical"
            onFinish={handleSmsSubmit}
            className={styles.smsLoginForm}
            footer={
              <Button
                block
                type="submit"
                color="primary"
                style={{ backgroundColor: currentLoginConfig.btnColor }}
                loading={loading}
                className={styles.loginButton}
              >
                登录
              </Button>
            }
          >
            <Form.Item
              name="phone"
              validateTrigger={['onBlur']}
              rules={[
                { required: true, message: "请输入手机号" },
                { pattern: /^1[3-9]\d{9}$/, message: "请输入正确的手机号" }
              ]}
            >
              <div className={styles.phoneInputWrapper}>
                <PhoneFill className={styles.phoneIcon} />
                <Input
                  placeholder="请输入手机号"
                  clearable
                  ref={smsPhoneInputRef}
                  className={styles.customInput}
                />
              </div>
            </Form.Item>
            <Form.Item
              name="code"
              validateTrigger={['onBlur']}
              rules={[{ required: true, message: "请输入验证码" }]}
            >
              <div className={styles.phoneInputWrapper}>
                <CheckShieldFill className={styles.phoneIcon} />
                <Input
                  placeholder="请输入验证码"
                  clearable
                  ref={smsCodeInputRef}
                  maxLength={6}
                  className={[styles.customInput, styles.smsCodeInput].join(' ')}
                />
                <div className={styles.verifyCodeButton}>
                  <SmsVerificationButton
                    loading={smsLoading}
                    onGetCode={handleGetSmsCode}
                  />
                </div>
              </div>
              
            </Form.Item>
            <div className={styles.actionLinks}>
              <span className={styles.registerLink} onClick={handleRegister}>
                {currentLoginConfig.registerTitle}
              </span>
            </div>
          </Form>
        )}
      </div>
      <Picker
        columns={[parseColumns]}
        visible={pageSwicthVisible}
        onConfirm={(value) => {
          const selectedConfig = USER_CONFIG.find((item) => item.title === value[0]);
          if (selectedConfig) {
            setCurrentLoginConfig(selectedConfig);
            
            // Update URL with new type parameter without refreshing
            const newParams = new URLSearchParams(params.toString());
            newParams.delete('route');
            newParams.set('type', (selectedConfig.sysType || 0).toString());

            // Update URL without triggering page reload
            navigate(`?${newParams.toString()}`, { replace: true });
          }
          setPageSwicthVisible(false)
        }}
        onClose={() => {
          setPageSwicthVisible(false)
        }}
      />
    </div>
  );
}

export default LoginPage;
