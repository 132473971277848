import React from "react";
import { Button } from "antd-mobile";
import { formatCurrency } from "@/common/Utils/money";
import styles from "./index.module.scss";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import classNames from 'classnames';
import { PAY_TYPE } from "@/common/Config/Constant";
import { useShallow } from "zustand/react/shallow";
import useUserStore from "@/store/global";

interface IProps {
  data: any;
}

function Content({ data }: IProps) {
  const openUrl = useOpenUrl();
  const [platformContext] = useUserStore(
    useShallow((state) => [
      state.platformContext,
    ])
  );
  
  const { payAmount, createAt, buyAmount, orderId, orderNo, discountAmount, methodCd, body, buyerMobile } = data || {};

  return (
    <div className={styles.app}>
      <ul>
        <li className={styles.header}>
          <span>¥</span>
          <span className={styles.amount}>{formatCurrency(payAmount)}</span>
        </li>
        <li className={styles.list}>
          <span className={styles.label}>下单时间</span>
          <span className={styles.value}>{createAt}</span>
        </li>
        <li className={styles.list}>
          <span className={styles.label}>订单金额</span>
          <span className={styles.value}>¥{formatCurrency(buyAmount)}</span>
        </li>
        {
          discountAmount > 0 && (
            <li className={classNames(styles.list, styles.discount)}>
              <span className={styles.label}>{body}</span>
              <span className={styles.value}>-¥{formatCurrency(discountAmount)}</span>
            </li>
          )
        }
        <li className={styles.list}>
          <span className={styles.label}>手机号码</span>
          <span className={styles.value}>{buyerMobile}</span>
        </li>
        <li className={styles.list}>
          <span className={styles.label}>支付方式</span>
          <span className={styles.value}>{PAY_TYPE[methodCd as keyof typeof PAY_TYPE]}</span>
        </li>
        <li className={styles.list}>
          <span className={styles.label}>订单编号</span>
          <span className={styles.value}>{orderNo}</span>
        </li>
        <li className={styles.footer}>
          <Button
            shape="rounded"
            className={styles.btn}
            onClick={() => openUrl({
              isRoute: true,
              url: "/billDetail",
              params: {
                id: orderId,
              }
            })}
          >
            查看账单
          </Button>
          <Button
            shape="rounded"
            className={styles.btn}
            onClick={() => openUrl(platformContext.getHomePage())}
          >
            返回首页
          </Button>
        </li>
      </ul>
    </div>
  );
}

export default Content;
