import Buttons from "@/common/Component/Button";
import VerificationCode, { IRef } from "@/common/Component/VerificationCode";
import { phoneRegExp } from "@/common/Config/regExp";
import { Button, Form, Input, Toast, Switch } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import * as dd from "dingtalk-jsapi";
import { locateInMap } from "@/services/dd/locateInMap";
import { Container } from "@/common/Utils/container";
import Location, { ILocationRef } from "@/common/Component/Location";

interface TestProps {}

const Test: React.FC<TestProps> = (props) => {
  const {} = props;
  const ref = React.useRef<IRef>({});
  const onFinish = () => {
    ref.current?.focus?.();
    setVisible(true);
  };
  const [form] = Form.useForm();
  const phone = Form.useWatch("phone", form);
  const [visible, setVisible] = useState(false);
  const [isH5, setIsH5] = useState(Container.isH5());
  const [map, setMap] = useState<any>();
  const gaodeMapRef = useRef<ILocationRef>(null);

  useEffect(() => {
    dd.ready(() => {
      dd.biz.navigation.hideBar({
        hidden: true,
        onSuccess: function (result: any) {
          // 导航栏隐藏后的操作
          Toast.show({
            icon: "success",
            content: "导航栏隐藏成功",
            duration: 1000,
          });
        },
        onFail: function (err: any) {
          Toast.show({
            icon: "fail",
            content: "导航栏隐藏失败",
            duration: 1000,
          });
        },
      });
    });
  }, []);
  const [local, setLocal] = useState({});
  const getPOI = () => {
    locateInMap({ scope: 10000 })
      .then((res) => {
        // console.log("res", res);
        setLocal(res);
        Toast.show({
          icon: "success",
          content: JSON.stringify(res),
        });
      })
      .catch((e) => {});
  };
  return (
    <div>
      <div>
        <div>appType:{dd.env.appType}</div>
        <Button onClick={getPOI}>获取地址</Button>
        <div>{JSON.stringify(local)}</div>
      </div>
      <div>
        mock H5
        <Switch
          checked={isH5}
          onChange={() => {
            localStorage.setItem("appType", isH5 ? "app" : "h5");
            setIsH5(Container.isH5());
          }}
        />
      </div>
      <Form layout="horizontal" form={form} onFinish={onFinish}>
        <Form.Item
          name="phone"
          rules={[
            { required: true, message: "请输入手机号" },
            { pattern: phoneRegExp, message: "请输入正确的手机号" },
          ]}
          label="手机号"
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          name="phone1"
          rules={[
            { required: true, message: "请输入手机号" },
            { pattern: phoneRegExp, message: "请输入正确的手机号" },
          ]}
          label="手机号"
        >
          <Input type="password" id="passwordInput1" maxLength={6} pattern="\d*" inputMode="numeric" placeholder="请输入密码" />
        </Form.Item> */}
        <Form.Item>
          <Buttons block color="primary" type="submit">
            提交
          </Buttons>
        </Form.Item>
      </Form>
      <VerificationCode
        onClose={() => setVisible(false)}
        visible={visible}
        ref={ref}
        phone={phone}
      />
      <Location ref={gaodeMapRef} />
      <Buttons
        onClick={() => {
          if (gaodeMapRef.current) {
            gaodeMapRef.current.getLocation({
              onOk: (data: any) => {
                console.log("data", data);
                setMap(data);
              },
            });
          }
        }}
      >
        获取地址
      </Buttons>
      <div>{JSON.stringify(map)}</div>
    </div>
  );
};

export default React.memo(Test);
