import React, { useState, useEffect } from "react";
import { Button, Switch, Modal, Grid, Toast } from "antd-mobile";
import usePageTitle from "@/hooks/usePageTitle";
import { useRequest } from "ahooks";
import { setPayPassword, accounts } from "./Services";
import { useParams } from "react-router-dom";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import SmsSetting from "./Components/SmsSetting";

import styles from "./index.module.scss";

function SetPassword() {
  usePageTitle({ title: "设置支付密码" });
  const { accountId } = useParams<{ accountId: string }>();
  const [passwordState, setPasswordState] = useState({
    enabled: false,     // 开关状态
    hasSet: false,      // 是否已设置密码
    showSetting: false, // 是否显示设置表单
  });
  const [visiblePayCode, setVisiblePayCode] = useState(false);
  const openUrl = useOpenUrl();

  const { data: accountsData } = useRequest(accounts);
  const bankCards = accountsData?.data || [];
  const account = bankCards.find((card:any) => card.accountId === accountId);

  const { run: setPayPasswordRun } = useRequest(setPayPassword, {
    manual: true,
    onSuccess: (res) => {
      if (res.success) {
        Toast.show('关闭支付密码成功');
        closePwdCallback();
      } else {
        Toast.show(res.msg);
      }
    }
  });
  
  // 更新状态的辅助函数
  const updatePasswordState = (updates: Partial<typeof passwordState>) => {
    setPasswordState(prev => ({ ...prev, ...updates }));
  };

  useEffect(() => {
    if (account?.pwdPay) {
      updatePasswordState({
        enabled: true,
        hasSet: true
      });
    }
  }, [account]);


  if (!accountId) {
    Toast.show({
      content: '参数错误',
    });
    return null;
  }

  const handleSwitchChange = (checked: boolean) => {
    if (!checked) {
      setVisiblePayCode(true);
    } else {
      updatePasswordState({
        enabled: true,
        showSetting: true
      });
    }
  };

  const closePwdCallback = () => {
    updatePasswordState({
      enabled: false,
      hasSet: false,
      showSetting: false,
    });
  }

  const cancelPassword = () => {
    // 关闭密码支付
    setPayPasswordRun({
      accountId,
      setType: 0,
      payPassword: 'close',
      code: 'close',
    });

    setVisiblePayCode(false);
  }

  const successCallback = () => {
    updatePasswordState({
      showSetting: false,
      hasSet: true,
    });
  }

  if (!accountsData) {
    return null;
  }
  
  return (
    <div className={styles.container}>
      <div className={styles.switchContainer}>
        <div className={styles.switchTitle}>启用密码支付</div>
        <Switch 
          checked={passwordState.enabled}
          onChange={handleSwitchChange}
        />
      </div>
      <div className={styles.tips}>
        支付密码用于钱包余额支付，不启用密码支付时，将使用短信验证码的方式发送到{account?.opPhone}进行支付
      </div>
      <div className={styles.tips}>
        {passwordState.hasSet && 
          <div>
            <span>钱包（{account?.bankCardNo}）已设置密码</span>
            <Button
              fill='none'
              color='primary'
              className={styles.changePassword}
              onClick={() => openUrl({
                url: `/changePassword/${accountId}`,
                isRoute: true,
              })}
            >
              修改密码
            </Button>
          </div>
        }
      </div>
      <div className={styles.passwordContainer} style={{ display: passwordState.showSetting ? 'block' : 'none' }}>
        <SmsSetting account={account} successCallback={successCallback}/>
      </div>
      <Modal
        title='确认关闭密码支付'
        visible={visiblePayCode}
        className={styles.dialogContainer}
        content={
          <div className={styles.dialogContent}>
            <div className={styles.dialogTitle}>关闭后将清除已设置的密码，请谨慎操作</div>
            <Grid className={styles.dialogGrid} columns={2} gap={8}>
              <Grid.Item>
                <Button className={styles.backBtn} block size="middle" onClick={() => setVisiblePayCode(false)}>
                  取消
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button className={styles.continueBtn} block size="middle" onClick={cancelPassword}>
                  确定
                </Button>
              </Grid.Item>
            </Grid>
          </div>
        }
      />
    </div>
  );
}

export default SetPassword;