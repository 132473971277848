import axios, { IBaseRes } from "@/common/Utils/APIClient";

export interface IParams {
  partnerId?: string;
  pageSize: number;
  pageNum: number;
}

interface IRes {
  list: IListItemMonth[];
  pages: number;
  total: number;
}
export interface IListItemMonth {
  /**
   * 列表中的月份 ID
   * @example 202501
   */
  monthId: number;
  /** 商户邀约数（餐补企业） */
  shopNum: number;
  /** 商户推荐数（广告） */
  referralNum: number;
  /** 组织安装数（到店） */
  enterpriseNum: number;
}

/**
 * 合伙人月业绩分页列表byId
 */
export const mobilePerfPageMonth = (
  params: IParams
): Promise<IBaseRes<IRes>> => {
  return axios.get("/dmall_statistic/partnerPerf/mobile/perfPage", {
    param: params,
  });
};
