import axios, { IBaseRes } from "@/common/Utils/APIClient";

interface IReq {
  /**
   * 银行账户
   */
  bankNo: string;
  /**
   * 开户名
   */
  openingBank: string;
  /**
   * 银行名称
   */
  bankName: string;
  partnerId: string;
}
interface IRes {}
/**
 * 合伙人收款银行修改
 */
export const modifyBank = (params: IReq): Promise<IBaseRes<boolean>> => {
  return axios.post("/dmall-customer/partner/app/modify/bank", params);
};
