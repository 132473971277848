import { MAP_CONFIG } from "@/common/Config/Constant";
import { Toast } from "antd-mobile";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
const { key, securityJsCode } = MAP_CONFIG;

export interface ILocation {
  center: number[];
  result: Result;
  lat?: number;
  lng?: number;
  detailAddress?: string;
}

interface Result {
  info: string;
  regeocode: Regeocode;
}

interface Regeocode {
  addressComponent: AddressComponent;
  formattedAddress: string;
  roads: any[];
  crosses: any[];
  pois: any[];
}

interface AddressComponent {
  citycode: string;
  adcode: string;
  businessAreas: any[];
  neighborhoodType: string;
  neighborhood: string;
  building: string;
  buildingType: string;
  street: string;
  streetNumber: string;
  province: string;
  city: string;
  district: string;
  towncode: string;
  township: string;
}

export interface ILocationRef {
  getLocation: (data: IConfig, init?: boolean) => void;
}
interface IConfig {
  onOk?: (data: ILocation) => void;
  onError?: () => void;
}
interface TestLoginProps {}

const TestLogin: React.ForwardRefRenderFunction<
  ILocationRef,
  TestLoginProps
> = (props, ref) => {
  // const AMap = useRef<any>(null);
  const config = useRef<IConfig>();

  const map = useRef<any>(null);
  const container = useRef<HTMLDivElement>(null);
  const geolocation = useRef<any>(null);
  const geocoder = useRef<any>(null);
  const handleComplete = useCallback((data: any) => {
    const center = data.position;
    geocoder.current.getAddress(data.position, (status: any, result: any) => {
      if (status === "complete" && result.info === "OK") {
        const province = result.regeocode.addressComponent.province;
        const city = result.regeocode.addressComponent.city;
        const district = result.regeocode.addressComponent.district;
        const township = result.regeocode.addressComponent.township;
        // const street = result.regeocode.addressComponent.street;
        const detailAddress = result.regeocode.formattedAddress
          ?.replace(province, "")
          .replace(city, "")
          .replace(district, "")
          .replace(township, "");
        const data = {
          center,
          result,
          lat: center.lat,
          lng: center.lng,
          detailAddress,
        };
        // setResult(data);
        config?.current?.onOk && config?.current?.onOk(data);
      } else {
        Toast.show({
          icon: "error",
          content: "获取地址失败",
        });
      }
    });
  }, []);
  const handleError = useCallback((data: any) => {
    Toast.show("定位失败");
    config.current?.onError && config?.current?.onError();
  }, []);
  // 初始化定位插件
  const geolocationInit = useCallback(() => {
    // @ts-ignore
    geolocation.current = new window.AMap.Geolocation({
      radius: 20, //范围，默认：500
      extensions: "all",
      convert: true,
      panToLocation: true, // 定位成功后是否自动移动到响应位置
      enableHighAccuracy: true, // 是否使用高精度定位，默认：true
      timeout: 10000, // 设置定位超时时间，默认：无穷大
      zoomToAccuracy: true, // 定位成功后是否自动调整地图视野到定位
      position: "RB", // 控件位置
      offset: [10, 20],
      noIpLocate: 3, // 禁用IP定位
      showButton: true,
    });
    map.current.addControl(geolocation.current);
    geolocation?.current?.getCurrentPosition?.((status: any, data: any) => {
      if (status === "complete") {
        handleComplete(data);
      } else {
        handleError(data);
      }
    });
  }, []);
  /**
   * 初始化地址解析插件
   */
  const geocoderInit = useCallback(() => {
    // @ts-ignore
    geocoder.current = new window.AMap.Geocoder({
      city: "全国", // 可选参数，指定搜索的城市
    });
  }, []);
  /**
   * 初始化地图
   */
  const initMap = useCallback(
    () =>
      new Promise((reslove, reject) => {
        // @ts-ignore
        map.current = new window.AMap.Map(container.current, {
          resizeEnable: true,
        });
        // 加载定位插件
        // @ts-ignore
        window.AMap.plugin(["AMap.Geolocation", "AMap.Geocoder"], async () => {
          await geocoderInit();
          await geolocationInit();
          reslove(true);
        });
      }),
    [geocoderInit, geolocationInit]
  );
  useEffect(() => {
    // 动态加载高德地图脚本
    window._AMapSecurityConfig = {
      securityJsCode,
    };
    const script = document.createElement("script");
    script.src = `https://webapi.amap.com/maps?v=2.0&key=${key}`;
    script.async = true;
    script.onload = () => {
      initMap();
    };
    script.onerror = () => {
      config.current?.onError && config.current?.onError();
      Toast.show("地图脚步加载失败");
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
      const mapContainer = document.getElementById("container");
      if (mapContainer) {
        mapContainer.innerHTML = "";
      }
    };
  }, [initMap]);
  useImperativeHandle(ref, () => {
    return {
      getLocation: (data: IConfig, init?: boolean) => {
        config.current = data;
        if (init) {
          return;
        }
        geolocation?.current?.getCurrentPosition?.((status: any, data: any) => {
          if (status === "complete") {
            handleComplete(data);
          } else {
            handleError(data);
          }
        });
      },
    };
  });
  return (
    <div
      ref={container}
      style={{ width: "100%", height: "100%", display: "none" }}
    />
  );
};

export default React.forwardRef(TestLogin);
