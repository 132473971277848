import axios, { IBaseRes } from "@/common/Utils/APIClient";
import { IAddressPOI } from "@/types/address";

interface IParams {
  /**
   * 企业id
   */
  enterpriseId: number;
  /**
   * 企业名称
   */
  enterpriseName: string;
  /**
   * 企业简称
   */
  enterpriseShortName: string;
  /**
   * 企业地址
   */
  enterpriseAddress: string;
  /**
   * 企业地址POI
   */
  enterpriseAddressPoi: IAddressPOI;
  /**
   * 营业执照
   */
  creditCert: string;
  /**
   * 法人姓名
   */
  legalPersonName: string;
  /**
   * 法人手机号
   */
  legalPersonPhone: string;
  /**
   * 联系人**/
  contactName: string;
  /**
   * 联系人手机号
   */
  contactPhone: string;
  /**
   * 组织id
   */
  applyOrgId: string;
  /**
   * 合伙人推广码
   */
  partnerUnionCode: string;
  /**
   * 优惠申请时间
   */
  discountApplyTime: string;
  /**
   * 折扣申请状态
   */
  discountApplyStatus: number;
  /**
   * 企业折扣合同流程id
   */
  discountEsignFlowId: string;
}

/**
 * 企业到店折扣申请
 */
export const discountApply = (data: IParams): Promise<IBaseRes<boolean>> => {
  return axios.post("/dmall_account/Enterprise/discount/apply", data);
};
