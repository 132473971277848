import { ICON_PAY } from "@/common/Config/Constant";

export const processIcon = (appNo: string) => {
  if (appNo === '51851851990') {
    return ICON_PAY.HOME;
  } else if (appNo === 'dd-wh') {
    return ICON_PAY.STORE;
  } else if (appNo === '365ticket') {
    return ICON_PAY.TICKET_365;
  } else if (appNo === '365charge') {
    return ICON_PAY.CHARGE_365;
  } else if (appNo.includes('365')) {
    return ICON_PAY.MEAL_365;
  }
}