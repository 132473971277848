import React from "react";
import { Image } from "antd-mobile";
import get from "lodash/get";
import Icon from "@/common/Component/Icon";
import styles from "./index.module.scss";
import { convertMetersToKm } from "@/common/Utils/distanceUtils";
import { getDiscount } from "@/pages/ArriveStore/static";

interface ShopHeadProps {
  data?: { url: string }[];
  discountRate?: number;
  shopName?: string;
  // 距离
  distance?: number;
  onClick?: () => void;
}

const ShopHead: React.FC<ShopHeadProps> = (props) => {
  const { data, discountRate, shopName, distance, onClick } = props;
  const url = get(data, "0.url");
  const space = convertMetersToKm(distance);

  return (
    <div className={styles.container} onClick={onClick}>
      <Image src={url} className={styles.img} />
      <div className={styles.footer}>
        <div className={styles.shopInfo}>
          <div className={styles.shopName}>{shopName}</div>
          <div className={styles.shopAddress}>
            <Icon icon="icon-dingwei1" className={styles.addressIcon} />
            <span className={styles.shopDistance}>
              {space?.num?.toFixed(1)}
              {space && <span>{space?.hasKm ? "km" : "m"}</span>}
            </span>
          </div>
        </div>
        <div className={styles.discount}>
          <span className={styles.discountNum}>{getDiscount(discountRate)}</span>
          <span className={styles.discountUnit}>折</span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ShopHead);
