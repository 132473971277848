import apiClient, { IBaseRes } from "src/common/Utils/APIClient";

interface resetPasswordParam {
  phone: string;
  verifyCode: string;
  sysType: number;
}

export function resetPassword(params: resetPasswordParam): Promise<IBaseRes<any>> {
  return apiClient.post('/dmall_auth/ua/reset_password', params);
}

interface updatePasswordParam {
  oldPassword: string;
  newPassword: string;
}

export function updatePassword(params: updatePasswordParam): Promise<IBaseRes<any>> {
  return apiClient.put('/dmall_auth/update_password', params);
}

interface getSmsVerificationCodeParams {
  phone: string;
  type: number;
  sysType: number;
}

/**
 * 获取短信验证码
 */
export function getSmsVerificationCode(params: getSmsVerificationCodeParams): Promise<IBaseRes<any>> {
  return apiClient.get('/dmall-customer/ua/buyer/sendSmsCode', {
    param: params,
  });
}

/**
 * 退出登录
 */
export function logout(): Promise<IBaseRes<any>> {
  return apiClient.post('/dmall_auth/login_out');
}