import axios, { IBaseRes } from "@/common/Utils/APIClient";
import { SettlementStatusEnum } from "../enum";

interface IParams {
  partnerId?: string;
  /** 推广周期
   * @example 202401
   */
  monthId: string;
}

interface IRes {
  /** 账单佣金 */
  billCommis: number;
  /**
   * 数据状态
   * @example 30:未结算 40:已结算
   */
  status: SettlementStatusEnum;
}

/**
 * 合伙人收益by推广周期
 */
export const mobileCycleBillSummary = (
  params: IParams
): Promise<IBaseRes<IRes>> => {
  return axios.get(
    "/dmall_statistic/stMonthPartnerBill/mobile/cycleBillSummary",
    {
      param: params,
    }
  );
};
