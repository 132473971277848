import { useNavigate, NavigateOptions } from "react-router-dom";
import qs from "qs";
import { useCallback } from "react";
import { Toast } from "antd-mobile";
import { openHalfScreenH5 } from "@/services/dd/openLink";
import { PlatformFactory, PlatformType } from "@/common/Utils";
export interface IOpenUrlParams<T = any> {
  /**
   * 跳转链接
   */
  url?: string;
  /**
   * 提示信息
   */
  message?: string;
  /**
   * 是否使用浏览器原生跳转,还是使用路由跳转,默认非路由跳转
   */
  isRoute?: boolean;
  /**
   * 是否浮层打开，默认外链浮层打开
   */
  isOpenHalf?: boolean;
  /**
   * 跳转参数
   */
  params?: T;
  /**
   * 跳转参数, navigate的参数
   */
  options?: NavigateOptions;
  /**
   * 是否替换链接
   */
  isReplace?: boolean;
}
export const useOpenUrl = () => {
  const navigate = useNavigate();
  const openUrl = useCallback(
    <T>(data?: IOpenUrlParams<T>) => {
      if (!data) return;
      const {
        url,
        isRoute = false,
        params,
        options,
        message = "功能暂未开放,敬请期待",
        isOpenHalf = true,
        isReplace = false,
      } = data;
      if (!url) {
        Toast.show({
          content: message,
          duration: 2000,
        });
        return;
      }
      if (isRoute) {
        navigate(
          `${url}?${qs.stringify(params || {}, { encode: true })}`,
          Object.assign(
            {
              replace: isReplace,
            },
            options
          )
        );
        return;
      }
      if (
        (PlatformFactory.getCurrentPlatform().getPlatformType() ===
          PlatformType.DINGTALK_H5 ||
          PlatformFactory.getCurrentPlatform().getPlatformType() ===
            PlatformType.DINGTALK) &&
        isOpenHalf &&
        !isRoute
      ) {
        const height = 83;
        openHalfScreenH5(
          `dingtalk://dingtalkclient/action/im_open_hybrid_panel?panelHeight=percent${height}&hybridType=online&pageUrl=${encodeURIComponent(
            url
          )}`
        );
        return;
      }
      if (isReplace) {
        window.location.replace(url);
        return;
      }
      window.location.href = url;
    },
    [navigate]
  );
  return openUrl;
};
