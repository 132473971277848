import Buttons from "@/common/Component/Button";
import { Popup } from "antd-mobile";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import styles from "./index.module.scss";
import { getUUID } from "@/common/Utils/uuid";
import Decimal from "decimal.js";
import "react-image-crop/dist/ReactCrop.css";

const toBlob = (canvas: HTMLCanvasElement, type: string, quality: number) =>
  new Promise<Blob | null>((resolve) => canvas.toBlob(resolve, type, quality));
interface CropImageProps {}
interface IProps {
  onOk: (file?: File) => void;
  onCancel: () => void;
  src: string;
  fileName: string;
  aspect?: number;
}
const defaultCrop: Crop = {
  unit: "%",
  width: 50,
  height: 50,
  x: 0,
  y: 0,
};
export interface ICropImageRef {
  show: (data: IProps) => void;
}
const CropImage: React.ForwardRefRenderFunction<
  ICropImageRef,
  CropImageProps
> = (props, ref) => {
  const [src, setSrc] = useState("");
  const [visible, setVisible] = useState(false);
  const configRef = useRef<IProps>();
  const imageRef = useRef<HTMLImageElement>(null);
  const [aspect, setAspect] = useState<number | undefined>(undefined);

  const [crop, setCrop] = useState<Crop>(defaultCrop);
  useImperativeHandle(
    ref,
    () => ({
      show: (data: IProps) => {
        configRef.current = data;
        setVisible(true);
        setSrc(data.src);
        setAspect(data.aspect);
        setCrop({
          ...defaultCrop,
          width: 100,
          height: new Decimal(100).div(data.aspect || 1).toNumber(),
          unit: "px",
        });
      },
    }),
    []
  );

  // 处理裁剪后的图像
  const getCroppedImg = async (
    pixelCrop: Crop
  ): Promise<{ file: File; url: string } | undefined> => {
    const image = imageRef.current;
    if (!image) return;
    // 获取文件名
    const fileType = configRef.current?.fileName.substring(
      configRef.current?.fileName.indexOf(".") + 1
    );
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const canvas = document.createElement("canvas");
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");
    if (!ctx || !image) return;
    ctx.drawImage(
      image,
      pixelCrop.x * scaleX,
      pixelCrop.y * scaleY,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    // 将 canvas 转换为 Blob 并生成 File 对象
    const blob = await toBlob(canvas, `image/${fileType}`, 0.95);
    if (blob) {
      const fileName = `${getUUID()}-${Date.now()}${
        configRef.current?.fileName
      }`;
      const file = new File([blob], fileName, { type: `image/${fileType}` });
      return { file, url: URL.createObjectURL(blob) };
    }
  };
  useEffect(() => {
    return () => {
      if (visible) {
        setCrop(defaultCrop);
      }
    };
  }, [visible]);
  return (
    <Popup
      visible={visible}
      onMaskClick={async () => {
        setVisible(false);
      }}
      bodyStyle={{ height: "80vh" }}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <Buttons
            fill="none"
            color="primary"
            onClick={async () => {
              configRef.current?.onCancel();
              setVisible(false);
            }}
          >
            取消
          </Buttons>
          <Buttons
            fill="none"
            color="primary"
            disabled={!crop.width}
            onClick={async () => {
              if (crop.width && crop.height) {
                const { file } = (await getCroppedImg(crop)) || {};
                await configRef.current?.onOk(file);
                setVisible(false);
              }
            }}
          >
            确认
          </Buttons>
        </div>
        {src && (
          <div className={styles.cropContainer}>
            <ReactCrop
              className={styles.crop}
              aspect={aspect}
              crop={crop}
              onChange={(c, p) => {
                setCrop(c);
              }}
              // onComplete={(c, p) => {
              //   Toast.show({
              //     content: "裁剪成功",
              //     position: "bottom",
              //   });
              // }}
            >
              <div className={styles.imageContainer}>
                <img ref={imageRef} src={src} alt="" className={styles.image} />
              </div>
            </ReactCrop>
          </div>
        )}
      </div>
    </Popup>
  );
};

export default React.forwardRef(CropImage);
