import React, { useEffect, useState } from "react";
import { Modal } from 'antd-mobile';
import CountUp from 'react-countup';
import { regFenToYuan } from "src/common/Utils";
import { pxToRem } from "@/common/Utils/rem";
import styles from "./index.module.scss";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";

let lastFee = 0;
let modalShownRef = false;

function Header(props: any) {
  const { data, payInfo } = props || {};
  const { payAmount = 0, timeoutSeconds } = data || {};
  const currentFeeAmount = payInfo?.feeAmount || 0;
  const currentFee = payAmount + currentFeeAmount;
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [platformContext] = useUserStore(
    useShallow((state) => [
      state.platformContext,
    ])
  );
  const openUrl = useOpenUrl();

  useEffect(() => {
    lastFee = currentFee;
  }, [currentFee]);

  // 在组件卸载时清除所有 Modal
  useEffect(() => {
    return () => {
      if (modalShownRef) {
        Modal.clear();
      }
    };
  }, []);

  useEffect(() => {
  
    if (modalShownRef) return;

    const startCountTime = Math.floor(Date.now() / 1000);
    
    const endTime = new Date(timeoutSeconds).getTime();
    const now = new Date().getTime();
    const initialTimeLeft = Math.max(0, Math.floor((endTime - now) / 1000));
    
    setTimeLeft(initialTimeLeft);
    
    const timer = setInterval(() => {
      const now = Math.floor(Date.now() / 1000);
      const remainingTime = timeoutSeconds - (now - startCountTime);

      if (remainingTime <= 0 && !modalShownRef) {
        clearInterval(timer);
        modalShownRef = true;
        Modal.show({
          title: '订单已超时',
          content: '订单已取消，请重新下单',
          closeOnMaskClick: false,
          closeOnAction: false,
          actions: [
            {
              key: 'know',
              text: '知道了',
              style: {
                color: '#fff',
                height: pxToRem(88),
                fontSize: pxToRem(28),
                borderRadius: pxToRem(46),
                backgroundColor: '#F2302F',
              },
              onClick: () => {
                openUrl(platformContext.getHomePage());
              }
            }
          ],
        });
      } else {
        setTimeLeft(remainingTime);
      }
    }, 1000);
  }, [timeoutSeconds]);
  
  return (
    <ul className={styles.header}>
      <li className={styles.sub}>实付金额</li>
      <li className={styles.amount}>
        <span className={styles.symbol}>¥</span><CountUp
          className={styles.count}
          start={regFenToYuan(lastFee)}
          end={regFenToYuan(currentFee)}
          duration={0.8}
          decimals={2}
        ></CountUp>
      </li>
      <li className={styles.time}>
        剩余支付时间 {Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, '0')}
      </li>
    </ul>
  );
}

export default Header;
