import { bindDDInfo } from "@/services/customer/bindDDInfo";
import useUserStore from "@/store/global";
import qs from "qs";

/**
 * 钉钉绑定信息
 */
export async function bindDDInfoLoader() {

  const {
    corpId: localCorpId,
    clientId: localClientId,
    setIsInstallApp,
    getUserInfo,
    platformContext,
  } = useUserStore.getState();

  if (platformContext.isDingTalk() || platformContext.isDingTalkH5()) {
    
    let route = window.location.pathname;
    const search = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const corpId = (search?.corpId || localCorpId) as string;
    const clientId = (search?.clientId || localClientId) as string;
    const redirectUrl = window.location.origin;
    if (route[0] === "/") {
      route = route.slice(1);
    }

    // if (!corpId) {
    //   return { hasLogin: false };
    // }
    const { data } = await bindDDInfo({
      corpId,
      route,
      clientId: clientId || "",
      redirectUrl,
    });
    if (data?.isExpires && data?.url) {
      window.location.href = data.url;
      return { hasLogin: false };
    }
    await setIsInstallApp(corpId);
  }

  await getUserInfo();

  return { hasLogin: true };
}
