import { useOpenUrl } from "@/hooks/useOpenUrl";
import { ApplyStatusEnum } from "@/pages/Enterprise/services/enterpriseApplyGet";
import { ISettledInLoaderRes } from "@/router/loader/settledInLoader";
import React, { useEffect } from "react";
import { useLoaderData, useLocation, useSearchParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
interface SettledInProps {}

const SettledInAuth: React.FC<SettledInProps> = (props) => {
  const {} = props;
  const data = useLoaderData() as ISettledInLoaderRes;
  const [search] = useSearchParams();
  const hasDisaledLink = search.get("link") === "false";
  const openUrl = useOpenUrl();
  const location = useLocation();
  const discountApplyStatus =
    data?.enterpriseApplyData?.data?.discountApplyStatus;
  useEffect(() => {
    if (hasDisaledLink) {
      return;
    }
    const pathname = location.pathname;
    let url = "/arriveStore/settled/in";
    switch (discountApplyStatus) {
      case ApplyStatusEnum.SIGN_CONTRACT:
        // settledAction.tip = "待签署";
        url = "/arriveStore/settled/in/protocol";
        break;
      case ApplyStatusEnum.WATING_ESIGN_SEAL:
        // settledAction.tip = "签署中";
        url = "/arriveStore/settled/in/protocol";
        break;
      case ApplyStatusEnum.WATING_ESIGN_FILL:
        // settledAction.tip = "填写中";
        url = "/arriveStore/settled/in/protocol";
        break;
      case ApplyStatusEnum.PENDING_PLATFORM_REVIEW:
        // settledAction.tip = "审核中";
        url = "/arriveStore/settled/in/res";
        break;
      case ApplyStatusEnum.REVIEW_FAILED:
        // settledAction.tip = "已拒绝";
        url = "/arriveStore/settled/in/res";
        break;
      case ApplyStatusEnum.REVIEW_PASSED:
        // settledAction.tip = "已通过";
        url = "/arriveStore/settled/in/res";
        break;
      default:
        url = "/arriveStore/settled/in";
    }
    if (pathname !== url) {
      openUrl({
        url,
        isOpenHalf: false,
        isRoute: true,
        options: {
          replace: true,
        },
      });
    }
  }, [location, discountApplyStatus, hasDisaledLink]);
  return <Outlet />;
};

export default React.memo(SettledInAuth);
