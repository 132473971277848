import React, { useState, useRef } from "react";
import { Form, Input, Button, Toast, Checkbox } from "antd-mobile";
import { PhoneFill, UserOutline, LockOutline, CheckShieldOutline, EyeOutline, EyeInvisibleOutline } from 'antd-mobile-icons';
import styles from "./index.module.scss";
import { useRequest } from "ahooks";
import { getSmsVerificationCode, register, checkPhoneRegistered } from "./Services";
import type { InputRef } from "antd-mobile/es/components/input";
import usePageTitle from "@/hooks/usePageTitle";
import { useShallow } from "zustand/react/shallow";
import useUserStore from "@/store/global";
import SmsVerificationButton from "@/common/Component/SmsVerification";

interface RegisterFormValues {
  nickname: string;
  phone: string;
  code: string;
  password: string;
  confirmPassword: string;
}

function RegisterUser() {
  usePageTitle({ title: " 用户注册" });
  const [form] = Form.useForm<RegisterFormValues>();
  const [visible, setVisible] = useState(false);
  const nicknameInputRef = useRef<InputRef>(null);
  const phoneInputRef = useRef<InputRef>(null);
  const codeInputRef = useRef<InputRef>(null);
  const passwordInputRef = useRef<InputRef>(null);
  const confirmPasswordInputRef = useRef<InputRef>(null);
  const [agreed, setAgreed] = useState(false);
  const [platformContext] = useUserStore(
    useShallow((state) => [
      state.platformContext,
    ])
  );

  // 检查手机号是否已注册
  const { run: checkPhone } = useRequest(
    (phone: string, context?: any) => checkPhoneRegistered(phone),
    {
      manual: true,
      onSuccess: (result: any, params: [string, any?]) => {
        const [_, context] = params.length > 1 ? params : [null, undefined];
        
        if (result.success && result.data) {
          // 手机号已注册
          Toast.show({
            icon: "fail",
            content: "该手机号已注册，请直接登录",
          });
          form.setFields([
            {
              name: 'phone',
              errors: ['该手机号已注册，请直接登录'],
            },
          ]);
          // 如果是通过获取验证码触发的检查，返回失败结果
          if (context && context.resolve) {
            context.resolve(false);
          }
        } else if (result.success && !result.data) {
          // 手机号未注册，可以获取验证码
          const phone = form.getFieldValue('phone');
          if (phone) {
            getSmsCode(phone);
            // 如果是通过获取验证码触发的检查，返回成功结果
            if (context && context.resolve) {
              context.resolve(true);
            }
          }
        }
      }
    }
  );

  // 获取验证码
  const { loading: smsLoading, run: getSmsCode } = useRequest(getSmsVerificationCode, {
    manual: true,
    onSuccess: (result) => {
      if (result.success) {
        Toast.show({
          content: '验证码已发送',
        });
      }
    }
  });

  // 注册
  const { loading: registerLoading, run: registerUser } = useRequest(register, {
    manual: true,
    onSuccess: (result) => {
      if (result.success) {
        Toast.show({
          icon: "success",
          content: "注册成功",
        });
        // 注册成功后跳转到首页
        setTimeout(() => {
          platformContext.gotoLoginPage();
        }, 2000);
      }
    }
  });

  const handleSubmit = async (values: RegisterFormValues) => {
    if (!agreed) {
      Toast.show({
        icon: "fail",
        content: "请阅读并同意服务协议和认证协议",
      });
      return;
    }

    if (values.password !== values.confirmPassword) {
      Toast.show({
        icon: "fail",
        content: "两次输入的密码不一致",
      });
      return;
    }

    // 检查手机号是否已注册
    const result = await checkPhoneRegistered(values.phone);
    if (result.success && result.data) {
      Toast.show({
        icon: "fail",
        content: "该手机号已注册，请直接登录",
      });
      return;
    }

    // 注册
    registerUser({
      nickName: values.nickname,
      phone: values.phone,
      verificationCode: values.code,
      password: values.password,
    });
  };

  const handleGetSmsCode = () => {
    return form.validateFields(['phone']).then(values => {
      // 先检查手机号是否已注册
      return new Promise<boolean>((resolve) => {
        const context = { resolve };
        checkPhone(values.phone, context);
        // 验证结果会在checkPhone的回调中通过resolve返回
      });
      // 在 checkPhone 请求的回调里处理发送验证码
    }).catch(() => {
      Toast.show({
        icon: "fail",
        content: "请输入正确的手机号",
      });
      return false;
    });
  };

  // 检查手机号是否已注册的处理函数
  const handlePhoneBlur = () => {
    const phone = form.getFieldValue('phone');
    if (phone && /^1[3-9]\d{9}$/.test(phone)) {
      checkPhone(phone);
    }
  };

  return (
    <div className={styles.registerContainer}>
      <div className={styles.registerCard}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className={styles.registerForm}
          footer={
            <Button
              block
              type="submit"
              color="primary"
              loading={registerLoading}
              className={styles.registerButton}
              disabled={!agreed}
            >
              注册
            </Button>
          }
        >
          <Form.Item
            name="nickname"
            label={
              <div className={styles.labelWrapper}>
                <UserOutline />
                <span className={styles.labelText}>昵称</span>
              </div>
            }
            rules={[{ required: true, message: "请输入昵称" }]}
          >
            <div className={styles.inputWrapper}>
              <Input
                placeholder="请输入昵称"
                maxLength={8}
                ref={nicknameInputRef}
                className={styles.customInput}
              />
            </div>
          </Form.Item>

          <Form.Item
            name="phone"
            label={
              <div className={styles.labelWrapper}>
                <PhoneFill />
                <span className={styles.labelText}>手机号</span>
              </div>
            }
            validateTrigger={['onBlur']}
            rules={[
              { required: true, message: "请输入手机号" },
              { pattern: /^1[3-9]\d{9}$/, message: "请输入正确的手机号" }
            ]}
          >
            <div className={styles.inputWrapper}>
              <Input
                placeholder="请输入手机号"
                ref={phoneInputRef}
                className={styles.customInput}
                onBlur={handlePhoneBlur}
              />
            </div>
          </Form.Item>

          <Form.Item
            name="code"
            className={styles.codeInput}
            label={
              <div className={styles.labelWrapper}>
                <CheckShieldOutline />
                <span className={styles.labelText}>验证码</span>
              </div>
            }
            rules={[{ required: true, message: "请输入验证码" }]}
          >
            <div className={styles.inputWrapper}>
              <Input
                placeholder="请输入验证码"
                ref={codeInputRef}
                maxLength={6}
                className={styles.customInput}
              />
              <div className={styles.verifyCodeButton}>
                <SmsVerificationButton
                  onGetCode={handleGetSmsCode}
                  loading={smsLoading}
                />
              </div>
            </div>
          </Form.Item>

          <Form.Item
            name="password"
            label={
              <div className={styles.labelWrapper}>
                <LockOutline />
                <span className={styles.labelText}>密码</span>
              </div>
            }
            validateTrigger={['onBlur']}
            rules={[
              { required: true, message: "请输入密码" },
              { min: 6, max: 8, message: "密码长度为6-8位" }
            ]}
          >
            <div className={styles.inputWrapper}>
              <Input
                placeholder="请输入6-8位密码"
                type={visible ? "text" : "password"}
                ref={passwordInputRef}
                className={styles.customInput}
              />
              <div className={styles.eye}>
                {!visible ? (
                  <EyeInvisibleOutline color="#86909C" onClick={() => setVisible(true)} />
                ) : (
                  <EyeOutline color="#86909C" onClick={() => setVisible(false)} />
                )}
              </div>
            </div>
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label={
              <div className={styles.labelWrapper}>
                <LockOutline />
                <span className={styles.labelText}>确认密码</span>
              </div>
            }
            validateTrigger={['onBlur']}
            rules={[
              { required: true, message: "请确认密码" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('两次输入的密码不一致'));
                },
              }),
            ]}
          >
            <div className={styles.inputWrapper}>
              <Input
                placeholder="请再次输入密码"
                type={visible ? "text" : "password"}
                ref={confirmPasswordInputRef}
                className={styles.customInput}
              />
              <div className={styles.eye}>
                {!visible ? (
                  <EyeInvisibleOutline color="#86909C" onClick={() => setVisible(true)} />
                ) : (
                  <EyeOutline color="#86909C" onClick={() => setVisible(false)} />
                )}
              </div>
            </div>
          </Form.Item>

          <div className={styles.agreementSection}>
            <Checkbox
              checked={agreed}
              onChange={setAgreed}
              className={styles.agreementCheckbox}
            />
            <span className={styles.agreementText}>
              已阅读并同意 <a className={styles.agreementLink} href="/protocolInfo/user">《用户协议》</a> 和 <a className={styles.agreementLink} href="/protocolInfo/security">《隐私协议》</a>
            </span>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default RegisterUser;
